<template>
  
  
  <Loader v-if="loading" />
  <Card>
    <template v-slot:content>
      <div  class="p-3">
        <Button
          v-for="doc in docs"
          :key="doc.id"
          :label="doc.nome"
          :class="['p-button', { 'p-button-danger': doc.id === type_doc }]"
          style="margin-right: 10px"
          @click="setDocType(doc)"
        />
      </div>
    </template>
  </Card>


  <Card style="margin-top:10px">
      <template v-slot:title>
        <h3>Vendite prodotto "{{ nome_doc }}" per gruppo </h3>
      </template>
      <template v-slot:content>
        <Accordion :groups="logDataGruppi" />
      </template>
    </Card>
</template>

<script>
import {ref, onMounted, getCurrentInstance } from 'vue';
import Loader from "@/components/Loader.vue";
import Service from '@/services/levante-api/levante-api';
import { useToast } from 'primevue/usetoast';
import moment from 'moment'
import Card from '@/components/AccordionCardGroup.vue';
import Accordion from '@/components/AccordionProductGroup.vue';

export default {
  components: {
    Loader,
    Card,
    Accordion,
  },
  setup() {
    const loading = ref(true);
    const logData = ref([]);
    const logDataGruppi = ref([]);
    const docs = ref();
    const toast = useToast();
    const type_doc = ref(null)
    const nome_doc=ref("")
    const self = getCurrentInstance();
    const lifeTime = 3000;
    self.$eventBus = self.appContext.config.globalProperties.$eventBus
    const service = new Service(self.$eventBus)
    const getDocUrl = (imgPath) => {
            window.open( `${process.env.VUE_APP_LEVANTE_API_URL}/../storage/app/public/${imgPath}`);
        }

        const setDocType = (doc) => {
          type_doc.value = doc.id
          nome_doc.value = doc.nome
          service.getProdottiGruppo(type_doc.value).then((res) => {
            logDataGruppi.value = res.items
          });
        }
           
    const initData = () => {

      getProdottiToBuy().then((res)=>{
        service.getProdottiGruppo(type_doc.value).then((res) => {
          logDataGruppi.value = res.items
        });
      })
     
    }

    const getProdottiToBuy = async () => {
          await service.getProdottiToBuy()
          .then((res) => {
            docs.value = res.items
            nome_doc.value = docs.value[0].nome
            type_doc.value=docs.value[0].id
          })
      }
    

    onMounted(() => { 
      loading.value = true
      const promises = [initData()]
      
      Promise.all(promises)
      .then(()=>{
          
      }).finally(()=>{
          loading.value = false
      })
    })




    return {
      loading,logDataGruppi,
      logData,getDocUrl,setDocType,
      docs,type_doc,nome_doc
    };
  },
}
</script>

<style scoped>
.confirm-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);
  z-index: 1000;
}

.confirm-dialog-content {
  text-align: center;
}

.confirm-dialog-content h3 {
  margin-top: 0;
}

.confirm-dialog-content button {
  margin: 10px;
}
</style>
