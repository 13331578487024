import aggiungi from  './aggiungi'
import creakit from  './aggiungi-kit'

export default [
  // always
  {
    'key': 'pdrs-button-aggiungi',
    'label': 'AGGIUNGI',
    'classCss': 'p-button-secondary',
    'icon': ' pi pi-plus',
    'type': 'operation',
    'method': aggiungi,
    'display': 'always'
  },
  {
    'key': 'pdrs-button-aggiungi',
    'label': 'CREA KIT',
    'classCss': 'p-button-secondary',
    'icon': ' pi pi-plus',
    'type': 'operation',
    'method': creakit,
    'display': 'always'
  },
]
