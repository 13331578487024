<template>
    <Loader v-if="loading"/>
    <div v-if="loaded_data">
        <Card>
            <template #header>
                <div class="p-3 pb-0">
                    <h2>Atleta: {{ atleta.cognome }} {{ atleta.nome }}</h2>
                    
                </div>
            </template>
            <template #content>
                <div class="col-12 flex">
                    <div class="col-12 flex flex-column md:col-6" >
                        <Card style="background-color: #d32f2f;
                        text-align: center;
                        color: white;">
                            <template #title>Da pagare</template>
                            <template #content>
                                <h2>
                                    {{ totalToPay }} &euro;
                                </h2>
                            </template>
                        </Card>
                    </div>
                    <div class="col-12 flex flex-column md:col-6">
                        <Card style="background-color: #689f38; text-align: center; color: white;">
                            <template #title>Pagato</template>
                            <template #content>
                                <h2>{{ totalPaid }} &euro;</h2>
                            </template>
                        </Card>
                    </div>
                </div>

                <Accordion :activeIndex="3">
                    <AccordionTab header="Anagrafica">
                        <div @click="showIscrizione(atleta.id)" class='p-button'>Modulo di iscrizione</div>
                                       
                        <div class="flex align-items-start flex-wrap">
                            <div class="col-12 flex flex-wrap">
                                <div class="col-12 md:col-3 flex flex-column gap-2">
                                    <label for="cognome">Cognome</label>
                                    <InputText id="cognome" v-model="atleta.cognome" :disabled="!update" :class="['inputfield w-full',{'p-invalid': submitted && validationErrors.cognome}]"/>
                                </div>
                                <div class="col-12 md:col-3 flex flex-column gap-2">
                                    <label for="nome">Nome</label>
                                    <InputText id="nome" v-model="atleta.nome" :disabled="!update" :class="['inputfield w-full',{'p-invalid': submitted && validationErrors.nome}]"/>
                                </div>
                                <div class="col-12 md:col-3 flex flex-column gap-2">
                                    <label>Data di nascita</label>                   
                                    <Calendar :class="['inputfield w-full',{'p-invalid': submitted && validationErrors.datadinascita}]" v-model="atleta.datadinascita" dateFormat="dd/mm/yy" showIcon :showOnFocus="false" :disabled="!update"/>
                                </div>
                                
                                <div class="col-12 md:col-3 flex flex-column gap-2">
                                    <label>Luogo di nascita</label>                   
                                    <InputText :class="['inputfield w-full',{'p-invalid': submitted && validationErrors.luogodinascita}]" v-model="atleta.luogodinascita"  showIcon :showOnFocus="false" :disabled="!update"/>
                                </div>
                                
                                <div class="col-12 md:col-3 flex flex-column gap-2">
                                    <label>Indirizzo</label>                   
                                    <InputText :class="['inputfield w-full',{'p-invalid': submitted && validationErrors.indirizzo}]" v-model="atleta.indirizzo"  showIcon :showOnFocus="false" :disabled="!update"/>
                                </div>

                                  
                                <div class="p-field col-12 md:col-3">
                                    <label class="mb-3">Città</label><br>               
                                    
                                    <Dropdown v-model="atleta.comune" :options="citta" filter optionLabel="name" :disabled="!update" class="text_toupper inputfield w-full" />
                                </div>
                                
                                <div class="col-12 md:col-3 flex flex-column gap-2">
                                    <label>Cap</label>                   
                                    <InputText :class="['inputfield w-full',{'p-invalid': submitted && validationErrors.cap}]" v-model="atleta.cap"  showIcon :showOnFocus="false" :disabled="!update"/>
                                </div>
                                
                                <!-- Gender atleta -->
                                <div class="p-field col-12 md:col-3 flex flex-column justify-content-center align-items-center">
                                    <label class="mb-3">Sesso</label>                 
                                    
                                    <div class="flex justify-content-between">
                                        <div v-for="gender in genders" :key="gender.key"  class="flex align-items-center">
                                            <RadioButton v-model="atleta.sesso" :id="gender.key" name="dynamic" :value="gender.name" :disabled="!update"/>
                                            <label :for="gender.key" class="ml-2 mr-2" :disabled="!update">{{ gender.name }}</label>
                                        </div>
                                    </div>
                                </div>
                                
                                <!-- Codice fiscale atleta -->
                                <div class="p-field col-12 md:col-3">
                                    <label for="cf">Codice fiscale</label><br>
                                    <InputText id="cf" v-model="atleta.codfis" autofocus :class="['text_toupper inputfield w-full',{'p-invalid': submitted && validationErrors.codfis}]" :disabled="!update"/>
                                    
                                </div>
                                
                                <div class="p-field col-12 md:col-3">
                                    <label class="mb-3">Genitore/Profilo fiscale</label><br>               
                                    
                                    <Dropdown v-model="atleta.utente" :options="users" filter optionLabel="name" :disabled="!update" class="text_toupper inputfield w-full" />
                                    <div @click="showUtente(atleta.utente.id)" class='p-button'>Apri dettagli</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 sm:col-1 flex justify-content-center">
                            <img :src="atleta.photo" class="w-full img-atleta"/>
                        </div>
                        <div class="col-12 flex justify-content-end align-items-center">
                            <label class="mr-2">{{!update ? 'Modifica' : 'Salva'}} dati</label>
                            <InputSwitch v-model="update" @change="changeUpdate"/>
                        </div>
                    </AccordionTab>
                    <AccordionTab header="Documenti">
                        <Button label="Aggiungi" raised class="p-button" @click="openDialogFiles" style="margin-bottom:5px"/>
                        <p v-if="docs.length==0">Non ci sono documenti al momento</p>

                        <DataTable :value="docs" class="p-datatable-gridlines" v-else>
                            <Column field="creation_time" header="Data caricamento"></Column>
                            <Column field="datascadenza" header="Data Scadenza">
                                <template #body="{data}">
                                    {{ moment(data.datascadenza).format('DD/MM/Y') }}
                                </template>
                            </Column>
                            <Column field="tipo" header="Tipologia"></Column>
                            <Column field="nome" header="File">
                                <template #body="{data}">
                                    <Button severity="help" @click="getDocUrl(data.path)" >{{ data.nome }}</Button>
                                </template>
                                </Column>
                            <Column field="validato" header="Validato">
                                <template #body="{data}">
                                    {{ data.data_approvazione?moment(data.data_approvazione).format('DD/MM/Y HH:mm:ss'):'' }}
                                </template>
                            </Column>
                            <Column field="id" header="">
                                <template #body="{data}">
                                    <Button severity="danger" @click="removeDoc(data.id_documento)">Cancella</Button>
                                    </template>
                                </Column>
                        </DataTable>
                    </AccordionTab>
                    <AccordionTab header="Corsi">
                        <Button label="Aggiungi un corso" raised class="p-button p-button-warning" @click="addCorso" style="margin-bottom:5px"/>

                        <p v-if="corsi.length==0">Non ci sono corsi al momento</p>
                        <DataTable :value="corsi" class="p-datatable-gridlines" v-else>
                            <Column field="nome_corso" header="Nome Corso">
                                <template #body="{data}">
                                    <div @click="goToCorso(data.id_corso)" style='text-decoration:underline'>{{ data.nome_corso }}</div>
                                </template>
                            </Column>
                            <Column field="tipologia" header="Tipologia"></Column>
                            <Column field="data_inizio_corso" header="Data Inizio">
                                <template #body="{data}">
                                    {{ moment(data.data_inizio_corso).format('DD/MM/Y') }}
                                </template>
                            </Column>
                            <Column field="data_fine_corso" header="Data Fine">
                                <template #body="{data}">
                                    {{ moment(data.data_fine_corso).format('DD/MM/Y') }}
                                </template>
                            </Column>
                            <Column field="n_workouts" header="Allenamenti"></Column>
                            <Column field="presenze" header="Presenze" style="width: 6rem"></Column>
                            <Column field="assenze" header="Assenze" style="width: 6rem"></Column>
                            <Column field="pagato" header="Pagato" style="width: 6rem"></Column>
                            <Column field="da_pagare" header="Da Pagare" style="width: 6rem"></Column>
                            <Column style="width:16rem">
                                <template #body="{data}">
                                    <div class="flex flex-nowrap">
                                        <Button class="m-2 p-button-danger" @click="removeCorso(data.id_iscrizione)" label="Cancella"/>
                                        <Button v-if="data.n_rate == -1" class="m-2 p-button-success" @click="setRate(data)" label="Completa"/>
                                    </div>
                                </template>
                            </Column>
                        </DataTable>
                    </AccordionTab>
                    
                    <AccordionTab header="Da pagare">
                        <Button label="Aggiungi prodotto" raised class="p-button" @click="openDialogProdotti" style="margin-bottom:5px;margin-right:5px"/>
                        <Button label="Aggiungi kit" raised class="p-button" @click="openDialogKit" style="margin-bottom:5px;margin-right:5px"/>

                        <Button label="Aggiungi abbigliamento da store" raised class="p-button p-button-warning" @click="gotoVendita" style="margin-bottom:5px"/>


                        <p v-if="dapagare.length==0" style="padding:20px">Non c'è niente da pagare al momento</p>
                        <DataTable :value="dapagare" v-else class="p-datatable-gridlines" v-model:selection="pagamento.richieste_pagamenti" :metaKeySelection="false" editMode="cell" @cell-edit-complete="onCellEditComplete"
                        >
                       
                        <Column header="" style="width: 3rem;">
        <template #body="{ data }">
            <input 
                type="checkbox" 
                :checked="data.selected" 
                @change="onCheckboxChange(data)"
            />
        </template>
    </Column>

                       
                        <Column field="corso" header="Descrizione">
                            <template #body="{data}">
                                {{ data.n_rata ? 'Rata n. ' + data.n_rata + ' del corso ' + data.corso : '' }}
                                {{ data.kit ? 'Kit ' + data.kit : '' }}
                                {{ data.prodotto ? data.prodotto : '' }}
                            </template>
                     </Column>
                     <Column field="data_scadenza" header="Data scadenza">
                        <template #body="{data}">
                            {{ moment(data.data_scadenza).format('MM/Y')}}    
                        </template>
                        </Column>
                        <Column field="note" header="Note"></Column>
                        
                            <Column field="importo" header="Importo €" style='text-align: right'>
                                <template #editor="{ data, column }">
            <InputNumber 
                v-model="data[column.props.field]" 
                mode="currency" 
                currency="EUR" 
                locale="it-IT" 
                autofocus 
                fluid 
            /></template>
                            </Column>
                            <Column header="Azioni" style="text-align: center; width: 100px;">
                                <template #body="{ data }">
                                    <i class="pi pi-trash" style="cursor: pointer;" @click="removeToPay(data.id)"></i>
                                </template>
                                </Column>
                            <template #footer v-if="viewSetPagamento">
                                <div>
                                    <div class="p-field col-12">
                                            <Button v-for="(type_pay, index) in types_pay" :key="index" :label="type_pay.nome" :class="`m-2 p-button-${type_pay.selected ? 'info' : 'outlined'}`" @click="selectPayment(index, type_pay.id)" />
                                        </div>

                                    <div class="p-field col-12" v-if="viewTaglie">
                                            <Button v-for="(ts, index) in type_size" :key="index" :label="ts.nome" :class="`m-2 p-button-${ts.selected ? 'info' : 'outlined'}`" @click="selectSize(index, ts.id)" />
                                        </div>
                                    <div class="col-12 flex justify-content-between" style="font-size: 2rem;">

                                        <div class="col-4 flex justify-content-between">
                                            <p>Totale: € {{ pagamento.totale }}</p>
                                        </div>
                                        <div class="">
                                            <Calendar :class="['inputfield w-full']" v-model="pagamento.data" dateFormat="dd/mm/yy" showIcon :showOnFocus="false" />
                                        </div>
                                        <div class="col-6 flex justify-content-end">
                                            <Button class="p-button-success m-3" icon="pi pi-check" label="Completa pagamento" @click="sendPagamento" :disabled="canSavePagamento"/>
                                            <Button class="p-button-info m-3" icon="pi pi-file-pdf" label="Visualizza ricevuta" @click="viewRicevuta" v-if="id_pagamento"/>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </DataTable>
                    </AccordionTab>
                    <AccordionTab header="Pagamenti corsi">
                        <p v-if="pagamenti.length==0">Non ci sono pagamenti al momento</p>
                        <DataTable :value="pagamenti" class="p-datatable-gridlines" v-else>
                            <Column field="corso" header="Corso"></Column>
                            <Column field="n_rata" header="Rata n.">
                                <template #body="{data}">
                                    {{ data.n_rata }} di {{ data.n_rate }}
                                </template>
                            </Column>
                            <Column field="data_scadenza" header="Data scadenza">
                                <template #body="{data}">
                                    {{ moment(data.data_scadenza).format('DD/MM/Y') }}
                                </template></Column>
                            <Column field="importo" header="Importo"></Column>
                            <Column field="data_pagamento" header="Data pagamento">
                                <template #body="{data}">
                                    {{ moment(data.data_pagamento).format('DD/MM/Y') }}
                                </template>
                            </Column>
                            <Column field="nome_cassa" header="Cassa">
                                <template #body="{data}">
                                    <div class="flex justify-content-between align-items-center">
                                        <p>{{ data.nome_cassa }}</p>
                                            </div>
                                </template>
                            </Column>

                            <Column field="nome_cassa" header="" tyle='width:100px'>
                                <template #body="{data}">
                                    <div class="flex justify-content-between align-items-center">
                                        <Button icon="pi pi-file-pdf" class="p-button-raised p-button-danger p-button-text" text @click="viewRicevuta($event, data.id)" v-tooltip="`Visualizza ricevuta`"/>&nbsp;&nbsp;&nbsp;
                                
                                        <Button icon="pi pi-envelope" class="p-button-raised p-button-danger p-button-text" text @click="sendRicevuta($event, data.id)" v-tooltip="`Invia ricevuta`"/>
                                    </div>
                                </template>
                            </Column>

                            <Column field="nome_cassa" header="" tyle='width:100px'>
                                <template #body="{data}">
                                    <div class="flex justify-content-between align-items-center">
                                        <Button icon="pi pi-trash" class="p-button-raised p-button-danger p-button-text" text @click="cancellaRicevuta($event, data.id)" v-tooltip="`Cancella`"/>
                            
                                    </div>
                                </template>
                            </Column>
                        </DataTable>
                    </AccordionTab>
                    
                    <AccordionTab header="Pagamenti prodotti">
                        <p v-if="vendite.length==0">Non ci sono pagamenti al momento</p>
                        <Accordion v-else>
                            <AccordionTab v-for="vendita in vendite" :key="vendita.id" :header="`N. Vendita: ${vendita.id} del ${moment(vendita.data_vendita).format('DD/MM/Y')} - Totale € ${vendita.totale_pagato} - CASSA ${vendita.cassa}`">
                                <Button label="Mostra ricevuta" raised class="p-button" @click="viewRicevuta(evt,vendita.id_pagamento)" style="margin-bottom:5px"/>&nbsp;&nbsp;
                                <Button icon="pi pi-trash" class="p-button-raised p-button-warning p-button-danger" text @click="cancellaRicevuta($event, vendita.id_pagamento)" v-tooltip="`Cancella`" style="margin-bottom:5px"/>
                                <Button icon="pi pi-refresh" class="p-button-raised p-button-info" text @click="aggiornaRicevuta($event, vendita.id_pagamento)" v-tooltip="`Aggiorna`" style="margin-bottom:5px"/>
                                <Button label="Invia ricevuta tramite email" raised class="p-button p-button-warning" @click="sendRicevuta(evt,vendita.id_pagamento)" style="margin-bottom:5px;float:right"/>

                                <DataTable :value="vendita.dettaglio" class="p-datatable-gridlines">
                                    <Column field="nome" header="Prodotto"></Column>
                                    <Column field="size" header="Dettaglio"></Column>
                                    <Column field="quantita" header="Quantità"></Column>
                                    <Column field="prezzo" header="Importo"></Column>
                                </DataTable>
                            </AccordionTab>
                        </Accordion>
                    </AccordionTab>            
                </Accordion>
            </template>
        </Card>
    </div>

    <Dialog header="Seleziona il corso" v-model:visible="showAddCorso" modal>
        <form @submit.prevent="handleSubmitAddCorso">
        <div class="field">
          <label for="file">Corsi disponibili</label><br/>
          <Dropdown v-model="selectedCorso" :options="corsiToAdd" optionLabel="nome"  class="text_toupper inputfield w-full" />
                               
        </div>
        <div class="dialog-footer">
          <Button label="Salva" icon="pi pi-check" type="submit" />
        </div>
      </form>
    </Dialog>


    <Dialog header="Aggiungi un kit da acquistare" v-model:visible="showDialogKit" modal>
        <form @submit.prevent="handleSubmitKit">
        <div class="field">
          <label for="file">Seleziona Kit</label><br/>
          <Dropdown v-model="kitToBuy" :options="kits" optionLabel="nome"  class="text_toupper inputfield w-full" />
                               
        </div>
        <div class="field">
          <label for="expirationDate">Data di Scadenza</label>
          <Calendar :class="['inputfield w-full']" v-model="expirationDate" dateFormat="dd/mm/yy" showIcon :showOnFocus="false" />

        </div>
        
        <div class="dialog-footer">
          <Button label="Salva" icon="pi pi-check" type="submit" />
        </div>
      </form>
    </Dialog>

    <Dialog header="Aggiungi un prodotto da acquistare" v-model:visible="showDialogProdotti" modal>
        <form @submit.prevent="handleSubmitProducts">
        <div class="field">
          <label for="file">Seleziona Prodotto</label><br/>
          <Dropdown v-model="prodottoToBuy" :options="products" optionLabel="nome"  class="text_toupper inputfield w-full" @change="changeProdottoToBuy($event,data)"/>
                               
        </div>
        <div class="field">
          <label for="expirationDate">Data di Scadenza</label>
          <Calendar :class="['inputfield w-full']" v-model="expirationDate" dateFormat="dd/mm/yy" showIcon :showOnFocus="false" />

        </div>
        <div class="field" v-if='showexpirationDateProduct'>
          <label for="expirationDateProduct">Data di Scadenza Prodotto</label>
          <Calendar :class="['inputfield w-full']" v-model="expirationDateProduct" dateFormat="dd/mm/yy" showIcon :showOnFocus="false" />

        </div>
        <div class="field" v-if='showNote'>
          <label for="note">Note</label>
          <InputText :class="['inputfield w-full']" v-model="noteProduct" />

        </div>
        <div class="dialog-footer">
          <Button label="Salva" icon="pi pi-check" type="submit" />
        </div>
      </form>
    </Dialog>

    <Dialog header="Carica File" v-model:visible="showDialogCaricaFile" modal>
      <form @submit.prevent="handleSubmit">
        <div class="field">
          <label for="file">Seleziona File</label><br/>
          <input type="file" id="file" @change="handleFileChange" required />
        </div>
        
        <div class="field">
          <label for="type">Tipologia</label>
          <Dropdown v-model="type" :options="typeofFiles" optionLabel="nome" class="text_toupper inputfield w-full" />
                            
        </div>
        
        <div class="field">
          <label for="expirationDate">Data di Scadenza</label>
          <Calendar :class="['inputfield w-full']" v-model="expirationDate" dateFormat="dd/mm/yy" showIcon :showOnFocus="false" />

        </div>
        
        <div class="dialog-footer">
          <Button label="Salva" icon="pi pi-check" type="submit" />
        </div>
      </form>
    </Dialog>

    <Dialog header="Completa Iscrizione" v-model:visible="showDialogCompletaIscrizione" modal>
        <div class="flex col-12">
            <Button v-for="(mensilita, index) in mensilita_da_completare" :key="index" style="position:relative;" :class="`m-2 card p-button-${mensilita.selected ? 'info' : 'outlined'}`" @click="onSelectRata($event, index)">
                <div class="flex flex-column justify-content-center w-14rem">
                    <h3>€ {{ mensilita.importo * mensilita.n_rate }}</h3>
                    <p>
                        {{ mensilita.n_rate }} {{ mensilita.suffix }} da <b style="font-size: 20px;">{{ mensilita.importo }} €</b>
                    </p>
                    <h5>Piano {{ mensilita.descrizione }}</h5>
                </div>
            </Button>
        </div>

        <div class="mt-5 mr-5 col-12 flex justify-content-center">
            <Button :disabled="!canUpdateIscrizione" class="p-button-success" icon="pi pi-check" label="Salva" @click="updateRateIscrizione"/>
        </div>
    </Dialog>
    <Dialog header="Conferma" v-model:visible="isDialogVisible" modal="true" @hide="isDialogVisible = false">
      <p>Sei sicuro di voler rimuovere questo corso?</p>
      <div class="dialog-footer">
        <Button label="No" @click="isDialogVisible = false" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button icon="pi pi-check" class="p-button-danger" label="Sì" @click="confirmRemoveCorso" />
      </div>
    </Dialog>

    <Dialog header="Conferma" v-model:visible="isDialogVisibleToPay" modal="true" @hide="isDialogVisibleToPay = false">
      <p>Sei sicuro di voler rimuovere questo pagamento?</p>
      <div class="dialog-footer">
        <Button label="No" @click="isDialogVisibleToPay = false" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button icon="pi pi-check" class="p-button-danger" label="Sì" @click="confirmRemoveToPay" />
      </div>
    </Dialog>
</template>

<script>
import {ref, onMounted, getCurrentInstance, onUpdated, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router'
import Loader from "@/components/Loader.vue"
import Service from '@/services/levante-api/levante-api'
import moment from 'moment'
import { useToast } from 'primevue/usetoast';
export default {
    components: {
        Loader,
    },
    
    setup () { 
        const toast = useToast();
        const self = getCurrentInstance();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const service = new Service(self.$eventBus)
        const loading = ref(false)
        const loaded_data = ref(false)
        const update = ref(false);
        const submitted = ref(false)
        const validationErrors = ref({})
        const types_pay = ref([])
        const type_size = ref([])
        const isDialogVisible = ref(false);
        const isDialogVisibleToPay = ref(false);
        const selectedDocId = ref(null);
        const noteProduct = ref();
        const genders = [
        {key:0, name:'Maschio'},
        {key:1, name:'Femmina'}
        ]
        const citta = ref([])
        const corsiToAdd = ref([])
        const showDialogCaricaFile = ref(false);
        const showDialogProdotti = ref(false);
        const showNote = ref(false)
        const showDialogKit = ref(false);
        const showDialogCompletaIscrizione = ref(false);
        const file = ref(null);
        const type = ref('');
        const showexpirationDateProduct = ref(false);
        const showAddCorso = ref(false)
        const expirationDate = ref('');
        const expirationDateProduct = ref('');
        const users = ref([])
        const typeofFiles = ref([])
        const products = ref([])
        const kits = ref([])
        const prodottoToBuy = ref()
        const kitToBuy = ref()
        
        const atleta = ref({})
        const corsi = ref([])
        const vendite = ref([])
        const pagamenti = ref([])
        const docs = ref([])
        const dapagare = ref([])
        const route = useRoute()
        const id = route.params.id
        const totalToPay = ref(0);
        const lifeTime = 3000;
        const totalPaid = ref(0);
        const pagamento = ref({
            'richieste_pagamenti': [],
            'type_pay': null,
            'totale': 0,
            "data":moment(new Date()).format('DD/MM/YY'),
            'id_atleta': id
        })
        console.log("pagamento");
        console.log(pagamento);
        const setPagamentoDialog = ref(false)
        const metaKey = ref(true)
        const id_pagamento = ref()
        const selectedCorso = ref()

        const gotoVendita = () =>{
            window.open(`/add-store/${id}`)
        }
        const openDialogFiles = () =>{
            getTypesofFiles().then(()=>{
                showDialogCaricaFile.value = true
            })
        }  
        const openDialogProdotti = () =>{
            getProdottiToBuy().then(()=>{
                expirationDate.value=moment().format("01/MM/YYYY");
                showexpirationDateProduct.value=false
                showDialogProdotti.value = true
                prodottoToBuy.value=null
            })
        }
        const openDialogKit = () =>{
            getKitToBuy().then(()=>{
                showDialogKit.value = true
            })
        }
        const addCorso = () => {
            console.log("addCorso")
            service.getCorsi(id)
            .then((res) => {
                corsiToAdd.value = res.items
                showAddCorso.value=true
            })
        }
        const changeProdottoToBuy = (evt, prop) => {
            console.log(evt);
            let value = evt.value
            if (value.isPacchetto){
                showexpirationDateProduct.value=true
            }else{
                showexpirationDateProduct.value=false
            }
            if (value.isAcconto){
                showNote.value=true
            }else{
                showNote.value=false
            }
            console.log(value)
       
        }
        const onCheckboxChange = (rowData)=> {
            // Toggle the selection state
            rowData.selected = !rowData.selected;
            if (rowData.selected) {
        // Add the item to the array if it is checked
                if (!pagamento.value.richieste_pagamenti.includes(rowData)) {
                    pagamento.value.richieste_pagamenti.push(rowData);
                }
            } else {
                // Remove the item from the array if it is unchecked
                const index = pagamento.value.richieste_pagamenti.indexOf(rowData);
                if (index > -1) {
                    pagamento.value.richieste_pagamenti.splice(index, 1);
                }
            }

            setTotaleVendita()
            if (!Object.keys(pagamento.value.richieste_pagamenti).length) {
                pagamento.value.type_pay = null
                types_pay.value.forEach((type, i) => {
                    type.selected = false
                })
            }
        }
        
        const onCellEditComplete = (event) => {
            let { data, newValue, field } = event;
            console.log(data.value);
            console.log(newValue);
            console.log(field);
            const index = pagamento.value.richieste_pagamenti.indexOf(data.value);
            if (index > -1) {
                pagamento.value.richieste_pagamenti.splice(index, 1);
            }
            setTotaleVendita()
            switch (field) {
                case 'prezzo':
                
                break;
            }
        }
        const mensilita_da_completare = ref()
        const iscrizione_da_aggiornare = ref({
            iscrizione: null,
            id_atleta:id,
            rata:[]
        })

        const handleFileChange = (event) => {
             file.value = event.target.files[0];
        };
        /*const removeCorso = async(iddoc)=>{
            const formData = new FormData();
            formData.append('atleta', id);
            formData.append('id', iddoc);

            try{
                await service.delCorsoAtleta(formData) .then((res) => {
                    let severity = res.item.esito
                    let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
                    let detail = res.item.messaggio
                    if (severity != 'warning') {
                        toast.add({severity:severity, summary: summary, detail:detail,life:lifeTime});                            
                    }-
                    getCorsi()
                    getDaPagare()
                });
            }catch(error){
                alert('Errore');

            }
        }*/
        const removeCorso = async(iddoc) => {
            selectedDocId.value = iddoc;
            isDialogVisible.value = true; // Mostra il dialogo di conferma
        };
        const confirmRemoveCorso = async() => {
            const formData = new FormData();
            formData.append('atleta', id);
            formData.append('id', selectedDocId.value);

            try {
                await service.delCorsoAtleta(formData).then((res) => {
                let severity = res.item.esito;
                let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo';
                let detail = res.item.messaggio;
                if (severity != 'warning') {
                    toast.add({severity: severity, summary: summary, detail: detail, life: lifeTime});                            
                }
                getCorsi();
                getDaPagare();
                });
            } catch (error) {
                alert('Errore');
            } finally {
                isDialogVisible.value = false; // Chiudi il dialogo dopo l'operazione
            }
        };
        const removeToPay = async(iddoc) => {
            selectedDocId.value = iddoc;
            console.log(selectedDocId.value)
            isDialogVisibleToPay.value = true; // Mostra il dialogo di conferma
        };
        const confirmRemoveToPay = async() => {
            const formData = new FormData();
            formData.append('atleta', id);
            formData.append('id', selectedDocId.value);

            try {
                await service.delToPayAtleta(formData).then((res) => {
                let severity = res.item.esito;
                let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo';
                let detail = res.item.messaggio;
                if (severity != 'warning') {
                    toast.add({severity: severity, summary: summary, detail: detail, life: lifeTime});                            
                }
                getDaPagare();
                });
            } catch (error) {
                alert('Errore');
            } finally {
                isDialogVisibleToPay.value = false; // Chiudi il dialogo dopo l'operazione
            }
        };
        const removeDoc = async(iddoc)=>{
            const formData = new FormData();
            formData.append('atleta', id);
            formData.append('id', iddoc);

            try{
                await service.delDoc(formData) .then((res) => {
                    let severity = res.item.esito
                    let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
                    let detail = res.item.messaggio
                    if (severity != 'warning') {
                        toast.add({severity:severity, summary: summary, detail:detail,life:lifeTime});                            
                    }
                    getDocs()
                });
            }catch(error){
                alert('Errore');

            }
        }
        const handleSubmitAddCorso= async () => {
            
            goToIscrizioneAtleta(selectedCorso.value.id)
        }
        const handleSubmitKit = async () => {
            const formData = new FormData();
            formData.append('kit', kitToBuy.value.id);
            formData.append('id_atleta',id);
            formData.append('expirationDate', moment(expirationDate.value).format('01/MM/YYYY'));

            try {
                await service.sendKitToBuy(formData)
                .then((res) => {
                    let severity = res.item.esito
                    let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
                    let detail = res.item.messaggio
                    showDialogKit.value = false
                    if (severity != 'warning') {
                        toast.add({severity:severity, summary: summary, detail:detail,life:lifeTime});                            
                    } 
                });
            } catch (error) {
                alert('Errore nel caricamento del prodotto.');
            }
            showDialogKit.value = false
            getDaPagare()
        }
        const handleSubmitProducts = async () => {
            const formData = new FormData();
            formData.append('prodotto', prodottoToBuy.value.id);
            formData.append('id_atleta',id);
            formData.append('expirationDate', moment(expirationDate.value).format('01/MM/YYYY'));
            formData.append('expirationDateProduct',moment(expirationDateProduct.value).format('DD/MM/YYYY'));
            formData.append('note',noteProduct.value);

            try {
                await service.sendProductToBuy(formData)
                .then((res) => {
                    let severity = res.item.esito
                    let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
                    let detail = res.item.messaggio
                    showDialogProdotti.value = false
                    if (severity != 'warning') {
                        toast.add({severity:severity, summary: summary, detail:detail,life:lifeTime});                            
                    } 
                    expirationDate.value=null;
                    expirationDateProduct.value=null;
                    noteProduct.value=null;
                });
            } catch (error) {
                alert('Errore nel caricamento del prodotto.');
            }
            showDialogProdotti.value = false
            getDaPagare()
        }

        const handleSubmit = async () => {
            if (!file.value || !type.value || !expirationDate.value) {
                alert('Per favore, compila tutti i campi.');
                return;
            }

            const formData = new FormData();
            formData.append('file', file.value);
            formData.append('type', type.value.id);
            formData.append('id_atleta',id);
            formData.append('expirationDate', moment(expirationDate.value).format('01/MM/YYYY'));

            try {
                await service.sendDoc(formData)
                .then((res) => {
                    let severity = res.item.esito
                    let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
                    let detail = res.item.messaggio
                    showDialogCaricaFile.value = false
                    if (severity != 'warning') {
                        toast.add({severity:severity, summary: summary, detail:detail,life:lifeTime});                            
                    }
                    
                });
                
            } catch (error) {
                alert('Errore nel caricamento del file.');
            }
            showDialogCaricaFile.value = false
            getDocs()
        };

        const goToIscrizioneAtleta = (idcorso)=>{
            window.open(`/add-iscrizione-atleta/${idcorso}/${id}`)
        }

        const goToCorso = (id)=> {
            window.open(`/corso/${id}/dettagli`)
        }
        const showIscrizione = (id)=> {
            console.log(id)
            const url= `${process.env.VUE_APP_LEVANTE_API_URL}/api/get-iscrizione/pdf/${id}`;
            
            window.open(url)
        }
        const showUtente = (id)=> {
            console.log(id)
            const url= `/utente/${id}/dettagli`;
            
            window.open(url)
        }

        const getTypesofFiles = async () => {
            await service.getTypesOfFiles()
            .then((res) => {
                typeofFiles.value = res.items
            })
        }
        const getKitToBuy = async () => {
            await service.getKitToBuy()
            .then((res) => {
                kits.value = res.items
            })
        }
        const getProdottiToBuy = async () => {
            await service.getProdottiToBuy()
            .then((res) => {
                products.value = res.items
            })
        }

        const getUtenti = async () => {
            await service.getUtenti()
            .then((res) => {
                users.value = [{ id: -1, name: "Nessun utente" }, ...res.items];
                getDetails()
            })
        }
        const getPaga = async () => {
            totalPaid.value=0
            await service.getPagamenti('atleta', id)
            .then((res) => {
                pagamenti.value = res.items
                console.log("totalPaid.value")
                console.log(totalPaid.value)
                totalPaid.value = pagamenti.value.reduce((sum, item) => sum + item.importo, 0);
                console.log(totalPaid.value)
                getVendite()
            })
        }
        const getDetailsAccordion = (evt) => {
            let {index} = evt
            switch (index) {
                case 1:
                corsi.value = []
                
                getCorsi()
                break;
                
                default:
                break;
            }
        }
        const getDocs = async () => {
            await service.getDocs(id)
            .then((res) => {
                docs.value = res.items
            }).finally(()=>{
            })
        }
        const getDaPagare = async () => {
            await service.getDaPagare('atleta', id)
            .then((res) => {
                dapagare.value = res.items
                totalToPay.value = dapagare.value.reduce((sum, item) => sum + item.importo, 0);
            }).finally(()=>{
                initPagamento()
            })
        }
        const getCorsi = async () => {
            await service.getCorsiAtleta(id)
            .then((res) => {
                corsi.value = res.items
            })
        }
        const getCitta = async () => {
            await service.getCitta()
            .then((res) => {
                citta.value = res.items
            })
        }
        const getVendite = async () => {
            await service.getVendita('atleta', id)
            .then((res) => {
                vendite.value = res.items
                console.log("vendite")
                console.log(vendite.value)
                console.log(totalPaid.value)
                totalPaid.value+= vendite.value.reduce((sum, item) => sum + item.totale_pagato, 0);
                totalToPay.value-= vendite.value
                    .filter(item => item.dettaglio[0].isAcconto === 1)
                    .reduce((sum, item) => sum + item.totale_pagato, 0);
            })
        }
        const getDetails = async () => {
            await service.getDetails('atleta', id)
            .then((res) => {
                atleta.value = res.items[0]
                if (!atleta.value.photo) {
                    atleta.value.photo = `/images/avatar-${atleta.value.sesso.toLowerCase()}.jpg`
                }
                console.log(atleta.value.cod_comune)
                atleta.value.datadinascita = moment(atleta.value.datadinascita).format('DD/MM/Y')
                atleta.value.sesso = genders.find(gender => gender.name.startsWith(atleta.value.sesso.toUpperCase())).name
                atleta.value.utente = users.value.find(user => user.id == atleta.value.id_utente)
                atleta.value.comune = citta.value.find(comune => comune.id == atleta.value.cod_comune)
                console.log(atleta.value.comune)
                //console.log(citta)
                loaded_data.value = true
            })
        }
        const validateForm = () => {
            let regexCf = /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i
            
            // Validazione atleta
            let validate_properties = [
            'nome',
            'cognome',
            'datadinascita',
            'sesso',
            'codfis',
            'id_utente'
            ]
            validate_properties.forEach( prop => {
                if (atleta.value[prop] == '' || atleta.value[prop] == null) {
                    validationErrors.value[prop] = true
                } else {
                    delete validationErrors.value[prop];
                    if (prop == 'codfis') {
                        if (!regexCf.test(atleta.value[prop])) {
                            validationErrors.value[prop] = true
                        }
                    }
                }
            })
            // Fine Validazione atleta
            
            return !Object.keys(validationErrors.value).length;
        }
        const setDataTime = (el) => {
            if (typeof el == 'object') {
                return moment(el).format('DD/MM/YYYY')                
            }
            let dataPart = el.split('/')
            el = `${dataPart[2]}-${dataPart[1]}-${dataPart[0]}`
            return el
        } 
        
        const sendNewDataAtleta = () => {
            submitted.value = true;
            validationErrors.value = []
            if(validateForm()){
                // loading.value = true
                let fd = new FormData();
                atleta.value.birth = setDataTime(atleta.value.datadinascita)
                
                fd.append('atleta', JSON.stringify(atleta.value));
                
                service.sendNewDataAtleta(fd)
                .then((res) => {
                    let severity = res.item.esito
                    let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
                    let detail = res.item.messaggio
                    
                    if (severity != 'warning') {
                        toast.add({severity:severity, summary: summary, detail:detail,life:lifeTime});                            
                    }
                    
                    update.value = res.item.esito == 'danger' ? true : false
                })
            }else{
                update.value = true
                toast.add({severity:'warn', summary: 'Attenzione', detail:`Ci sono dei dati mancanti`,life:lifeTime});
            }
            
        }
        const changeUpdate = () => {
            if (!update.value) {
                sendNewDataAtleta()
            }
        }
        
        const setPagamento = () => {
            setPagamentoDialog.value = true
        }
        const getPromises = (promises) => {
            loading.value = true
            
            Promise.all(promises)
            .then(()=>{
                
            }).finally(()=>{
                loading.value = false
            })
        }
        
        const viewSetPagamento = computed(()=>{
            return Object.keys(pagamento.value.richieste_pagamenti).length
        })
 
        const viewTaglie = computed(()=>{
            var quanti= Object.keys(pagamento.value.richieste_pagamenti).length
            console.log(quanti);
            for(var i=0;i<quanti;i++){
                if (Object.keys(pagamento.value.richieste_pagamenti[i]) && pagamento.value.richieste_pagamenti[i].kit)
                    return true;
            }
            return false;
        })
   
        const getTypePay = async () => {
            await service.getTypePay()
            .then((res) => {
                types_pay.value = res.items
                types_pay.value.forEach(type => {
                    type.selected = false
                })
                getTypeTaglie()
            })
        }
        const getTypeTaglie = async () => {
            await service.getSizes()
            .then((res) => {
                type_size.value = res.items
                type_size.value.forEach(type => {
                    type.selected = false
                })
            })
        }
        const selectPayment = (index, id) => {
            pagamento.value.type_pay = id
            types_pay.value.forEach((type, i) => {
                type.selected = i == index ? true : false
            })
        }
        const selectSize = (index, id) => {
            pagamento.value.type_size = id
            type_size.value.forEach((type, i) => {
                type.selected = i == index ? true : false
            })
        }

        const sendPagamento = () => {
            loading.value = true
            let fd = new FormData();
            pagamento.value.data=moment(pagamento.value.data).format('DD/MM/Y')
            fd.append('pagamento', JSON.stringify(pagamento.value));
            
            service.sendPagamento(fd)
            .then((res) => {
                let severity = res.item.esito
                let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
                let detail = res.item.messaggio
                
                if (severity != 'warning') {
                    toast.add({severity:severity, summary: summary, detail:detail,life:lifeTime});  
                    if (severity == 'success') {
                        id_pagamento.value = res.item.dati.id_pagamento
                    }                          
                }
            }).finally(()=>{
                getPaga()
                getDaPagare()
                loading.value = false
            })
        }

        const initPagamento = () => {
            pagamento.value = {
                'type_pay': null,
                'type_size':null,
                'richieste_pagamenti': [],
                'totale': 0,
                'id_atleta':id,
                "data":moment(new Date()).format('DD/MM/YYYY'),
            }
        }
        const setTotaleVendita = () => {
            let totale = 0
            pagamento.value.richieste_pagamenti.forEach(prodotto => {
                totale += prodotto.importo
            })
            pagamento.value.totale = totale
        }

        const onRowSelect = (event) => {
            setTotaleVendita()
            if (!Object.keys(pagamento.value.richieste_pagamenti).length) {
                pagamento.value.type_pay = null
                types_pay.value.forEach((type, i) => {
                    type.selected = false
                })
            }
        }

        const canSavePagamento = computed(()=>{
            return !(Object.keys(pagamento.value.richieste_pagamenti).length && pagamento.value.type_pay)
        })

        const sendRicevuta= async (evt, id = id_pagamento.value) => {
            loading.value = true
            let fd = new FormData()
            await service.sendRicevuta(id,fd)
            .then((res)=> {
                // Supponiamo che tu abbia ricevuto i contenuti dei file PDF come dati JSON dal backend
                let severity = res.item.esito
                let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
                let detail = res.item.messaggio
                
                toast.add({severity:severity, summary: summary, detail:detail,life:lifeTime});  
            })
            .finally(()=>{
                id_pagamento.value = null
                loading.value = false
            })
        }

        const aggiornaRicevuta= async (evt, id) => {
            loading.value = true
            console.log("aggiornaRicevuta");
            let fd = new FormData()
            await service.aggiornaRicevuta(id,fd)
            .then((res)=> {
                // Supponiamo che tu abbia ricevuto i contenuti dei file PDF come dati JSON dal backend
                let severity = res.item.esito
                let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
                let detail = res.item.messaggio
                
                toast.add({severity:severity, summary: summary, detail:detail,life:lifeTime});  
            })
            .finally(()=>{
                loading.value = false
            })
        }
        
        const cancellaRicevuta= async (evt, id) => {
            loading.value = true
            console.log("cancellaRicevuta");
            let fd = new FormData()
            await service.cancellaRicevuta(id,fd)
            .then((res)=> {
                // Supponiamo che tu abbia ricevuto i contenuti dei file PDF come dati JSON dal backend
                let severity = res.item.esito
                let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
                let detail = res.item.messaggio
                
                toast.add({severity:severity, summary: summary, detail:detail,life:lifeTime});  
            })
            .finally(()=>{
                getDaPagare()
                getPaga()
                getDocs()
                id_pagamento.value = null
                loading.value = false
            })
        }

        const viewRicevuta = async (evt, id = id_pagamento.value) => {
            loading.value = true
            let fd = new FormData()
            fd.append('id_pagamento', id)
            await service.viewRicevuta(fd)
            .then((res)=> {
                // Supponiamo che tu abbia ricevuto i contenuti dei file PDF come dati JSON dal backend
                let pdfContents = res.item;
                if (pdfContents == 'Non ci sono documenti') {
                    toast.add({severity:'warn', summary:'Errore documenti', detail: pdfContents});
                } else {
                    pdfContents.forEach(pdf => {
                        const pdfContent = pdf[0]
                        const pdfType = pdf[1]
                        
                        const decodedData = Uint8Array.from(atob(pdfContent), c => c.charCodeAt(0));
                        const blob = new Blob([decodedData], { type: pdfType });
                        const pdfUrl = URL.createObjectURL(blob);
                        // Apri l'URL di dati in una nuova finestra del browser
                        
                        window.open(pdfUrl);
                    });          
                }
            })
            .finally(()=>{
                getDaPagare()
                getPaga()
                getDocs()
                types_pay.value.forEach(type => {
                    type.selected = false
                })
                id_pagamento.value = null
                loading.value = false
            })
        }

        const setRate = (data) => {
            mensilita_da_completare.value = data.mensilita
            iscrizione_da_aggiornare.value.iscrizione = data
            showDialogCompletaIscrizione.value = true
        }

        const onSelectRata = (evt, i) => {
            Object.keys(mensilita_da_completare.value).forEach(mensilita => {
                mensilita_da_completare.value[mensilita].selected = Number(mensilita) == i
            })
            iscrizione_da_aggiornare.value.rata = []
            iscrizione_da_aggiornare.value.rata.push(mensilita_da_completare.value[i])
        }
    
        const canUpdateIscrizione = computed(()=>{
            return iscrizione_da_aggiornare.value.rata.length
        })

        const updateRateIscrizione = async () => {
            loading.value = true
            let fd = new FormData();
            
            fd.append('iscrizione', JSON.stringify(iscrizione_da_aggiornare.value));
            
            service.updateRateIscrizione(fd)
            .then((res) => {
                let severity = res.item.esito
                let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
                let detail = res.item.messaggio
                
                toast.add({severity:severity, summary: summary, detail:detail,life:lifeTime});                            
                initData()
            }).finally(() => {
                loading.value = false
            })
        }
        const initData = () => {
            mensilita_da_completare.value = null
            iscrizione_da_aggiornare.value = {
                iscrizione: null,
                id_atleta:id,
                rata:[]
            }
            expirationDate.value=moment(new Date()).format('01/MM/YYYY')
            expirationDateProduct.value=moment(new Date()).format('01/MM/YYYY')
            showDialogCompletaIscrizione.value = false
            const promises = [getCitta(),getUtenti(),getDetails(),getCorsi(),getPaga(),getDocs(),getDaPagare(), getTypePay()]
            getPromises(promises)
        }

        onMounted(() => { 
            initData()
        })

        const getDocUrl = (imgPath) => {
            window.open( `${process.env.VUE_APP_LEVANTE_API_URL}/../storage/app/public/${imgPath}`);
        }
            
        return {
            moment,
            showNote,
            getDocUrl,
            loading,
            loaded_data,
            atleta,
            update,
            genders,
            dapagare,
            isDialogVisible,
            confirmRemoveCorso,
            isDialogVisibleToPay,
            onCellEditComplete,
            removeToPay,
            confirmRemoveToPay,
            sendRicevuta,
            cancellaRicevuta,
            users,
            citta,
            corsi,
            docs,
            totalToPay,
            totalPaid,
            handleSubmitProducts,
            sendNewDataAtleta,
            submitted,
            changeUpdate,
            validationErrors,
            getDetailsAccordion,
            setPagamentoDialog,
            setPagamento,
            pagamento,
            metaKey,
            viewSetPagamento,
            viewTaglie,
            types_pay,
            selectPayment,
            selectSize,
            type_size,
            sendPagamento,
            onRowSelect,
            canSavePagamento,
            pagamenti,
            vendite,
            noteProduct,
            id_pagamento,
            viewRicevuta,
            goToCorso,
            showIscrizione,
            showUtente,
            showDialogCaricaFile,
            showDialogCompletaIscrizione,
            file,
            type,
            aggiornaRicevuta,
            expirationDate,
            expirationDateProduct,
            handleFileChange,
            showexpirationDateProduct,
            handleSubmit,
            typeofFiles,
            openDialogFiles,
            removeDoc,
            removeCorso,
            setRate,
            mensilita_da_completare,
            onSelectRata,
            canUpdateIscrizione,
            updateRateIscrizione,
            iscrizione_da_aggiornare,
            showDialogProdotti,
            changeProdottoToBuy,
            openDialogProdotti,
            gotoVendita,
            onCheckboxChange,
            products,
            prodottoToBuy,
            kitToBuy,
            kits,
            handleSubmitKit,
            showDialogKit,
            corsiToAdd,
            openDialogKit,showAddCorso,handleSubmitAddCorso,addCorso,selectedCorso
        }
    },
}
</script>

<style>
.img-atleta{
    aspect-ratio: 4/2.5 !important;
}
.field {
  margin-bottom: 1em;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
