<template>
    <Loader v-if="loading"/>
    <div v-if="loaded_data">
        
       
                <Card>
                    <template #header>
                <div class="p-3 pb-0">
            
                    <h4>{{ prodotto.atleta }}</h4>
                    
                </div>
            </template>
            <template #content>
                <Button label="Mostra ricevuta" raised class="p-button" @click="viewRicevuta(evt,prodotto.id_pagamento)" style="margin-bottom:5px"/>&nbsp;&nbsp;
                                <Button icon="pi pi-trash" class="p-button-raised p-button-warning p-button-danger" text @click="cancellaRicevuta($event, prodotto.id_pagamento)" v-tooltip="`Cancella`" style="margin-bottom:5px"/>
                                <Button label="Invia ricevuta tramite email" raised class="p-button p-button-warning" @click="sendRicevuta(evt,prodotto.id_pagamento)" style="margin-bottom:5px;float:right"/>

                <DataTable :value="prodotto.dettaglio" class="p-datatable-gridlines">
                                    <Column field="nome" header="Prodotto"></Column>
                                    <Column field="size" header="Dettaglio"></Column>
                                    <Column field="quantita" header="Quantità"></Column>
                                    <Column field="prezzo" header="Importo"></Column>
                                </DataTable>
                </template>
                
                </Card>
          
    </div>



</template>
<script>
import {ref, onMounted, getCurrentInstance, onUpdated, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router'
import Loader from "@/components/Loader.vue"
import Service from '@/services/levante-api/levante-api'
import moment from 'moment'
import { useToast } from 'primevue/usetoast';

export default {
    components: {
        Loader
    },
    
    setup () { 
        const self = getCurrentInstance();
        const toast = useToast();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const service = new Service(self.$eventBus)
        const loading = ref(false)
        const loaded_data = ref(false)
        const route = useRoute()
        const id = route.params.id
        const prodotto = ref({})
        const lifeTime = 3000
        
        const getDetails = async () => {
            await service.getDetails('vendita', id)
            .then((res) => {
                prodotto.value = res.items[0]
                console.log(res.items)
                console.log(prodotto.value)
                loaded_data.value = true
            })
        }

    
       
        
        const navigateTo = (nome_rotta, id) => {
            console.log(id);
            window.open(`/${nome_rotta}/${id}/dettagli`, '_blank')
        }
        const sendRicevuta= async (evt, id) => {
            loading.value = true
            let fd = new FormData()
            await service.sendRicevuta(id,fd)
            .then((res)=> {
                // Supponiamo che tu abbia ricevuto i contenuti dei file PDF come dati JSON dal backend
                let severity = res.item.esito
                let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
                let detail = res.item.messaggio
                
                toast.add({severity:severity, summary: summary, detail:detail,life:lifeTime});  
            })
            .finally(()=>{
                loading.value = false
            })
        }

        
        const cancellaRicevuta= async (evt, id) => {
            loading.value = true
            console.log("cancellaRicevuta");
            let fd = new FormData()
            await service.cancellaRicevuta(id,fd)
            .then((res)=> {
                // Supponiamo che tu abbia ricevuto i contenuti dei file PDF come dati JSON dal backend
                let severity = res.item.esito
                let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
                let detail = res.item.messaggio
                
                toast.add({severity:severity, summary: summary, detail:detail,life:lifeTime});  
            })
            .finally(()=>{
                window.open(`/mindtab`, '_blank')
            })
        }

        const viewRicevuta = async (evt, id) => {
            loading.value = true
            let fd = new FormData()
            fd.append('id_pagamento', id)
            await service.viewRicevuta(fd)
            .then((res)=> {
                // Supponiamo che tu abbia ricevuto i contenuti dei file PDF come dati JSON dal backend
                let pdfContents = res.item;
                if (pdfContents == 'Non ci sono documenti') {
                    toast.add({severity:'warn', summary:'Errore documenti', detail: pdfContents});
                } else {
                    pdfContents.forEach(pdf => {
                        const pdfContent = pdf[0]
                        const pdfType = pdf[1]
                        
                        const decodedData = Uint8Array.from(atob(pdfContent), c => c.charCodeAt(0));
                        const blob = new Blob([decodedData], { type: pdfType });
                        const pdfUrl = URL.createObjectURL(blob);
                        // Apri l'URL di dati in una nuova finestra del browser
                        
                        window.open(pdfUrl);
                    });          
                }
            })
            .finally(()=>{
                
                loading.value = false
            })
        }

        
        onMounted(() => { 
            loading.value = true
            const promises = [getDetails()]
            
            Promise.all(promises)
            .then(()=>{
                
            }).finally(()=>{
                loading.value = false
            })
        })
        
        return {
            moment,
            viewRicevuta,sendRicevuta,cancellaRicevuta,
            loading,
            loaded_data,
            prodotto,
            navigateTo,
        }
    },
}
</script>

<style>
</style>
