<template>
    <div class="card" :style="style">
      <div class="card-title">
        <slot name="title"></slot>
      </div>
      <div class="card-content">
        <slot name="content"></slot>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Card',
    props: {
      style: {
        type: Object,
        default: () => ({}),
      },
    },
  };
  </script>
  
  <style>
  .card {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 16px;
  }
  .card-title {
    font-weight: bold;
    margin-bottom: 16px;
  }
  .card-content {
    margin-top: 16px;
  }
  </style>
  