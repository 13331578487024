<template>

  <div id="mind-tab">

    <accordion class="box-operation-mtab">
      <accordion-tab>
        <template #header>
          <i class="pi pi-plus-circle"> </i>
          <span class="pl-1">Operazioni</span>
        </template>      
        <span v-for="button in selectedTabs[activeTabIndex].buttons" :key="button.key">
          <span v-if="isButtonVisible(button)">
            <prime-button 
              v-if="button.method != ''"
              :label="button.label" 
              :icon="button.icon" 
              :class="button.classCss"
              iconPos="right" 
              class="m-1"
              @click="callDynamicButtonMethod(button.method)"
            /> 
          </span>
        </span>   
      </accordion-tab>
    </accordion>
<!--
    <accordion class="box-filter-mtab" >
      <accordion-tab>
        <template #header>
          <i class="pi pi-filter"></i>&nbsp;
          <span>Filtri <span class="num-filtri"> {{flatTableFilters.length}}</span> </span>
        </template>

        <div class="filter-wrap p-d-flex p-flex-wrap p-ai-center p-flex-sm-row">
          <div v-if="flatTableFilters.length"  @click="cleanAllFilters" class="clean-filters">
            Pulisci tutto<i class="pi pi-ban"></i> 
          </div>
            <chip 
              v-for="(element, key) in flatTableFilters"
              :key="element"
              class="filter-chip" 
              @remove="onFilterItemRemove({key, element})" 
              removable
            > 
              {{ `${element.table} - ${element.label}` }}
            </chip>
        </div>

      </accordion-tab>
    </accordion>
  -->
    <div class="wrapper-mindtab">
      <!--
      <multi-select 
      v-model="selectedTabs" 
      :options="tabs" 
      optionLabel="label" 
      placeholder="" 
      :filter="true" 
      id="multiselect-tabs"
    >
    <i class="pi pi-check"></i>
    </multi-select>
-->
    <tab-view v-model:activeIndex="activeTabIndex" scrollable>
      
      <tab-panel v-for="(tab) in selectedTabs" :key="tab.label">
        <template v-if="tab" #header>
            <i class="icon-tab" v-if="tab.icon" :class="tab.icon"></i>
            <span class="dynamic-font-size">{{tab.label}}</span>
            <span class="num-tab">{{tab.rows}}</span>
        </template>
        
        <keep-alive>
          <component v-if="tab" 
            :is="tab.component"
            
            :intialActiveTabs="activeTabList"
            :initialFilters="filter"

            @row-fetched="tab.onRowFetched"
            @row-selected="tab.onRowSelected"
            @row-filtered="tab.onRowFiltered"
            @="tab.onDataSearching"
          ></component>
        </keep-alive>
      </tab-panel>
    </tab-view>
    </div>



  </div>
  
</template>  

<script>
/* eslint-disable vue/no-unused-components */
import { reactive } from 'vue'
import { debounce } from 'underscore'

import PrimeButton from 'primevue/button';

import listOfTabs from '@/config/mind-tab/tabs'
import AtletiDataTable from '@/components/mindtab/tables/AtletiDataTable.vue'
import CorsiDataTable from '@/components/mindtab/tables/CorsiDataTable.vue'
//import IscrizioniDataTable from '@/components/mindtab/tables/IscrizioniDataTable.vue'
import TorneiDataTable from '@/components/mindtab/tables/TorneiDataTable.vue'
import MerceDataTable from '@/components/mindtab/tables/MerceDataTable.vue'
import KitDataTable from '@/components/mindtab/tables/KitDataTable.vue'
import VenditeDataTable from '@/components/mindtab/tables/VenditeDataTable.vue'
import StaffDataTable from '@/components/mindtab/tables/StaffDataTable.vue'
import PagamentiDataTable  from '@/components/mindtab/tables/PagamentiDataTable.vue'

/*
import ClientiDataTable from '@/components/ask/tables/ClientiDataTable.vue'
import FattureClientiDataTable from '@/components/ask/tables/FattureClientiDataTable.vue'
import FattureAcquistoDataTable from '@/components/ask/tables/FattureAcquistoDataTable.vue'
import FornitoriDataTable from '@/components/ask/tables/FornitoriDataTable.vue'
import ContrattiVenditaDataTable from '@/components/ask/tables/ContrattiVenditaDataTable.vue'
import ListiniDataTable from '@/components/ask/tables/ListiniDataTable.vue'
import PodsDataTable from '@/components/ask/tables/PodsDataTable.vue'
import PdrsDataTable from '@/components/ask/tables/PdrsDataTable.vue'
import RappresentantiDataTable from '@/components/ask/tables/RappresentantiDataTable.vue'
import AgentiDataTable from '@/components/ask/tables/AgentiDataTable.vue'
*/
export default {
  name: 'MindTab',

  components: {
    PrimeButton,
    AtletiDataTable,
    CorsiDataTable,
   // IscrizioniDataTable,
    TorneiDataTable,
    MerceDataTable,
    VenditeDataTable,
    StaffDataTable,
    PagamentiDataTable,
    KitDataTable
    /*
    
    FattureClientiDataTable,
    FattureAcquistoDataTable,
    FornitoriDataTable,
    ContrattiVenditaDataTable,
    ListiniDataTable,
    PodsDataTable,
    PdrsDataTable,
    RappresentantiDataTable,
    AgentiDataTable
    */
  },

  data() {
    return {
      pageName: 'Ask',

      emitFilterChanged: true,

      selectedRowsByTable: {},

      filtered: {
        items: {},
        ids: {},
      },

      filter: {
        searches: {},
        items: {}
      },
      tableFilters: {},

      tabs: [],
      activeTabIndex: 0,
      selectedTabs: [],
    }
  },

  created(){
    const allTabs = listOfTabs.map((el) => {
      
      const newEl = reactive({...el})

      newEl.rows = 0

      const onRowFetchedMethod = function(evt){
        this.rows = evt.rows
      }
      
      const onRowSelectedMethod = function(evt){
        let el = {}
        el[evt.source] = evt.items

        this.selectedRowsByTable = {...this.selectedRowsByTable, ...el}
      }

      const onRowFilteredMethod = function(evt){

        let el = {}
        el[evt.filter.key] = evt.filter.items.map((element) => {
          return {
            key: evt.filter.key,
            source: evt.filter.source,
            property: element.key,
            value: element.value,
            label: element.label,
          }
        })
        this.filter.items = {...this.filter.items, ...el}

        el = {}
        el[evt.filter.source] = evt.filter.items.map((element) => element.item)
        this.filtered.items = {...this.filtered.items, ...el}

        el = {}
        el[evt.filter.source] = evt.filter.items.map((element) => {
          return {
            key: evt.filter.key,
            property: element.key,
            value: element.value,
          }
        })
        this.filtered.ids = {...this.filtered.ids, ...el}
      }

      const onDataSearchingMethod = function(evt){
        console.log("onDataSearchingMethod")
        let el = {}
        const items = { ...evt.filter.items }

        items.source = evt.filter.source
        el[evt.filter.target] = items

        this.filter.searches = {...this.filter.searches, ...el}
      }

      newEl.onRowFetched = onRowFetchedMethod.bind(newEl)
      newEl.onRowSelected = onRowSelectedMethod.bind(this)
      newEl.onRowFiltered = onRowFilteredMethod.bind(this)
      newEl.onDataSearching = onDataSearchingMethod.bind(this)

      if ('buttons' in newEl && Object.keys(newEl.buttons).length != 0) {
         newEl.buttons = newEl.buttons.map((button) => {
          button.filter = newEl.name
          return button
        })
      }

      return newEl
    })

    const activeTabs = allTabs.filter((el) => el.default)

    activeTabs.forEach((el) => {delete el.default})
    allTabs.forEach((el) => {delete el.default})

    this.selectedTabs = activeTabs
    this.tabs = allTabs
  },

  mounted(){
    this.$eventBus.emit('page-change', {
      target: this,
      currentRoute: this.$router.currentRoute.value
    })
  },

  methods: {
    onFilterItemRemove(evt) {

      this.filter.items[evt.element.source].splice(
        this.filter.items[evt.element.source].findIndex( (e) => e.label === evt.element.label ),
        1
      )
    },

    cleanAllFilters(){
      this.filter.items = {}
      this.$eventBus.emit('data-all-filter-clean', this.filter.items)
    },

    isButtonVisible(button){
      switch (button.display) {
        case 'always':
          return true;

        case 'on-data-selected':{
          const properties = Object.keys(this.selectedRowsByTable).filter((k) => k == button.filter)
          if(properties.length > 0){
            if(this.selectedRowsByTable[button.filter]){
              return this.selectedRowsByTable[button.filter].length > 0
            }
          }
          return false
        }

        case 'on-data-filtered':{
          const properties = Object.keys(this.filtered.ids).filter((k) => k == button.filter)
          if(properties.length > 0){
            if(this.filtered.ids[button.filter]){
              return this.filtered.ids[button.filter].length > 0
            } 
          }
          return false
        }
      
        default:
          return false;
      }
    },

    callDynamicButtonMethod(methodMetadata) {
      if(typeof methodMetadata != 'string'){
        if (typeof methodMetadata.method === 'function') {
          if(methodMetadata.bindTo){
            switch (methodMetadata.bindTo) {
              case false:
                return methodMetadata.method.apply(null, [])
              
              case 'this':
                return methodMetadata.method.apply(this, [])
            
              default:
                return
            }
          }
          
          return methodMetadata.method.apply(null, [])
        }
      }

      return
    },
  },

  computed: {
    activeTabList: function(){
      return this.selectedTabs.map((el) => el.name)
    },

    flatTableFilters: function(){
      const tableFilters = this.tableFilters

      const list = Object.keys(this.tableFilters).reduce(function (r, k) {
        return r.concat(tableFilters[k].items);
      }, [])

      return list
    },
  },

  watch: {
    selectedTabs: {
      handler: function (newVal) {
        this.$eventBus.emit('active-tabs-changed', {
          target: this,
          tabs: JSON.parse( JSON.stringify(newVal.map((el) => el.name)) )  
        })
      },
      deep: true,
    },

    filter: {
      handler: function(newVal) {
        const eventBus = this.$eventBus
        const emitDataFilterChanged = debounce(function(evt) {
          eventBus.emit('data-filter-changed', evt)
        }) 

        this.tableFilters = {}
        Object.entries(newVal.items).map((el) => {
          const items = el[1].map((e) => {
            const table = this.tabs.filter((te) => {
              if(e.source === te.name){
                return e
              }
            })[0].label

            return {
              table,
              source: e.key,
              label: e.label
            }
          })

          this.tableFilters[el[0]] = {
            items
          }
        })

        const filterItems = Object.entries(newVal.items).reduce((old, [,items]) => [...old, ...items], [])

        this.filtered.items = Object.entries(this.filtered.items)
        .map(([source, items]) =>{
          return {
            [source]: items.filter(
              x => !!filterItems.find(
                y => (x[y.property] == y.value) && (y.source == source) 
              )
            )
          }
        })
        .reduce(( acc, el ) => Object.assign(acc, el), Object.create(null))

        this.filtered.ids = Object.entries(this.filtered.ids)
        .map(([source, items]) =>{
          return {
            [source]: items.filter(
              x => !!filterItems.find(
                y => (x.value == y.value) && (y.source == source) 
              )
            )
          }
        })
        .reduce(( acc, el ) => Object.assign(acc, el), Object.create(null))

        const newItems = {} 
        Object.entries(newVal.items).map((el) => {
          newItems[el[0]] = el[1].map((e) => e.value)
        })

        const newSearches = {}
        Object.entries(newVal.searches).map((el) => {
          newSearches[el[0]] = {
            from: el[1].from,
            to: el[1].to,
            search: el[1].search 
          }
        })

        if(this.emitFilterChanged){
          emitDataFilterChanged({
            target: this,
            filter: {
              searches: newSearches,
              items: newItems
            }
          })
        }else{
          this.emitFilterChanged = true
        }

        if(this.filter.searches && this.filter.searches.constructor === Object && Object.keys(this.filter.searches).length > 0){
          this.filter.searches = {}
          this.emitFilterChanged = false
        }
      },
      deep: true,
    }
  },
}
</script>

<style scoped>
  @media (max-width: 768px){
    .dynamic-font-size {
      max-width: 90px;
      font-size: 10px;
    }
  }

 

  .num-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    font-size: 14px;
    min-width: 40px;
    height: 20px;
    background-color: #e9e9e9;
    border-radius: 8px;
  }

  .icon-tab {
    font-size: 12px;
    margin-right: 8px;
  }

  .p-chip.search-chip {
    background: #2196f3;
    color: white;
  }

  .filter-wrap {
    max-height: 390px;
    overflow-y: scroll;
  }
  .filter-wrap::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background: #e9e9e9 !important;
  }

.filter-wrap::-webkit-scrollbar-thumb {
  background: linear-gradient(0deg, #0098f0 70%, #00f2db 100%) !important;
  border-radius: 10px;
}
  
  .filter-wrap::-webkit-scrollbar {
    width: 6px;
  }
  .p-chip.filter-chip {
    background: #e9e9e9;
    color: rgb(138, 138, 138);
    font-size: 11px;
    font-weight: bold;
    padding: 2px 8px;
    border-radius: 4px;
    margin: 5px;
  }
  .p-chip.filter-chip i{
    color: var(--primary-color);
  }

  .p-chip.filter-chip .pi-times-circle:before{
    color: red !important;
  }

  .wrapper-mindtab {
    position: relative;
  }

 .clean-filters {
  /* position: fixed;*/
  display: flex; 
  justify-content: center;
  align-items: center;
  margin: 6px;
  font-size: 12px;
  text-transform: uppercase;
  width: 140px;
  background-color: rgb(255, 120, 120);
  padding: 6px 12px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
 }
 .clean-filters i{
  margin: 0px 6px;
  font-size: 10px;
 }
 .num-filtri {
  padding: 8px;
  color: #0098f0;
 }
</style>
