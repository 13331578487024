<template>
  <v-app>
    <v-main>
      <news-table></news-table>
    </v-main>
  </v-app>
</template>

<script>
import NewsTable from '@/components/NewsTable.vue';

export default {
  components: {
    NewsTable
  }
};
</script>

<style>
</style>