import {wizard} from '@/config/sites'

const navigateToTab = () => {
  window.open(`crea-utente`, '_blank')
}

export default {
  'bindTo': false,
  'method': navigateToTab,
}
