<template>
    <div class="overlay">
    <div class="overlay__inner">
        <div class="overlay__content"><span class="spinner"></span></div>
    </div>
</div>
</template>

<style scoped>
.overlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background: linear-gradient(236deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.4906337535014006) 100%);
    z-index: 9999;
    max-height: 100vh;
}

/* .overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
} */

.overlay__content {
    left: 59%;
    position: absolute;
    top: 55%;
    transform: translate(-50%, -50%);
}

@media (min-width: 500px) and (max-width:991px){
  .overlay__content {
    left: 50%;
  }
}


.spinner {
    width: 60px;
    height: 60px;
    display: inline-block;
    border-width: 2px;
    border-color: #2196f3;
    border-top-color: rgba(255, 255, 255, 0);
    animation: spin 0.6s infinite linear;
    border-radius: 100%;
    border-style: solid;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>
