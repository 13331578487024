<template>
  <Loader v-if="loading" />
  <Card style="margin-top:20px">
    <template v-slot:title>
      <h3>Prenotazioni per corso</h3>
    </template>
    <template v-slot:content>
      <Accordion>
        <AccordionTab v-for="tab in logDataGruppi" :key="tab.id" :header="tab.nome">
          <DataTable :value="tab.sessioni" paginator :rows="50" :rowsPerPageOptions="[5, 10, 20, 50]" >
            <Column header="Data">
              <template #body="{data}">
                {{ moment(data.data_inizio).format('DD/MM/Y') }}
              </template>
            </Column>
            <Column field="name" header="Orario inizio">
              <template #body="{data}">
                {{ moment(data.data_inizio).format('H:mm') }}
              </template>
            </Column>
            <Column field="category" header="Orario fine">
              <template #body="{data}">
                {{ moment(data.data_fine).format('H:mm') }}
              </template>
            </Column>
            <Column field="category" header="Quante">
              <template #body="{data}">
                {{data.prenotazioni.length}}
              </template>
            </Column>
            <Column>
              <template #body="{data}">
                <Button label=" Prenotazioni" @click="setDialog(data, tab)" class="p-button p-button-danger"/>
              </template>
            </Column>
            <Column>
              <template #body="{data}">
                <Button label=" Messaggia" @click="setDialogMessage(data, tab)"/>
              </template>
            </Column>
          </DataTable>
        </AccordionTab>
      </Accordion>
    </template>
  </Card>
  <Dialog v-model:visible="visible" modal :header="`Prenotazioni per l'allenamento del ${moment(selectedSessione.data).format('DD/MM/Y H:mm')} del corso ${selectedSessione.nome_corso}`" @hide="resetSelectedSessione()">
    <div v-for="atleta in selectedSessione.prenotazioni" :key="atleta.id" style="padding:30px">
      <a style='cursor: pointer' @click="goToAtleta(atleta.id_atleta)">{{ atleta.nome_atleta }}</a>
    </div>
  </Dialog>


  <Dialog v-model:visible="visibleMessage" modal :header="`Invia un messaggio per l'allenamento del ${moment(selectedSessione.data).format('DD/MM/Y H:mm')} del corso ${selectedSessione.nome_corso}`" @hide="resetSelectedSessione()">
    <div class="p-field"><label>Titolo</label>
    <InputText v-model="box.title" placeholder="" :class="['inputfield w-full']"/>
    </div>
    <div class="p-field"><label>Messaggio</label>
    <InputText v-model="box.body" placeholder="Inserisci il messaggio" :class="['inputfield w-full']"/>
    </div>
    <Button label=" Invia" @click="sendMessagge"/>
  </Dialog>
</template>

<script>
import {ref, onMounted, getCurrentInstance } from 'vue';
import Loader from "@/components/Loader.vue";
import Service from '@/services/levante-api/levante-api';
import { useToast } from 'primevue/usetoast';
import moment from 'moment'

export default {
  components: {
    Loader,
  },
  setup() {
    const loading = ref(true);
    const logDataGruppi = ref([]);
    const toast = useToast();
    const self = getCurrentInstance();
    const lifeTime = 3000;
    self.$eventBus = self.appContext.config.globalProperties.$eventBus
    const service = new Service(self.$eventBus)
    const visible = ref(false)
    const box = ref({
      title: null,
      body: null,
      id: null,
    });
    const visibleMessage =ref(false)
    const selectedSessione = ref({
      data: null,
      nome_corso: null,
      prenotazioni: []
    })
    const goToAtleta = (idatleta)=> {
            window.open(`/atleta/${idatleta}/dettagli`)
        }
    const initData = () => {
      // restart var
      service.getPrenotazioniGruppo().then((res) => {
        logDataGruppi.value = res.items
        Object.keys(logDataGruppi.value).forEach(index_corso => {
          Object.keys(logDataGruppi.value[index_corso].sessioni).forEach(index_sessione => {
            logDataGruppi.value[index_corso].sessioni[index_sessione].data_fine = moment(logDataGruppi.value[index_corso].sessioni[index_sessione].data_fine).add(1000, 'milliseconds')
          })
        })
      });
    }
    const setDialog = (data, corso) => {
      if (data.prenotazioni.length) {
        selectedSessione.value.data = data.data_inizio
        selectedSessione.value.nome_corso = corso.nome
        selectedSessione.value.prenotazioni = data.prenotazioni
        visible.value = true
      }else{
        toast.add({severity: 'warn', detail: 'Non sono ancora presenti delle prenotazioni', life: lifeTime})
      }
    }
    const setDialogMessage = (data,corso)=>{
      visibleMessage.value=true;
      selectedSessione.value.data = data.data_inizio
      selectedSessione.value.nome_corso = corso.nome
      box.value.id=data.id
    }
    const resetSelectedSessione = () => {
      visible.value = false
      visibleMessage.value=false
      selectedSessione.value = {
        data: null,
        nome_corso: null,
        prenotazioni: []
      }
    }

    const sendMessagge = async() =>{

      try {
        let fd = new FormData();  
        fd.append('id',box.value.id);
        fd.append('title',box.value.title);
        fd.append('body',box.value.body);
        
        await service.sendMessaggeGruppoPrenotati(fd);  // assuming this endpoint exists
        toast.add({ severity: 'success', summary: 'Successo', detail: 'Richiesta aggiornata con successo',life: lifeTime});
        initData();
      } catch (error) {
        toast.add({ severity: 'error', summary: 'Errore', detail: 'Errore durante l\'aggiornamento della richiesta',life: lifeTime });
      } finally {
        visibleMessage.value=false;
      }
    }

    onMounted(() => { 
      loading.value = true
      const promises = [initData()]
      
      Promise.all(promises)
      .then(()=>{
          
      }).finally(()=>{
          loading.value = false
      })
    })

    return {
      loading,
      logDataGruppi,
      moment,
      visible,
      visibleMessage,
      selectedSessione,
      setDialog,
      setDialogMessage,
      resetSelectedSessione,
      box,goToAtleta,
      sendMessagge
    };
  },
}
</script>

<style scoped>
.confirm-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);
  z-index: 1000;
}

.confirm-dialog-content {
  text-align: center;
}

.confirm-dialog-content h3 {
  margin-top: 0;
}

.confirm-dialog-content button {
  margin: 10px;
}
</style>
