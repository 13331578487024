<template>
  <div v-if="confirmDialog.visible" class="confirm-dialog">
    <div class="confirm-dialog-content ">
      <h3>Conferma la richiesta</h3>
      <p>Sei sicuro di voler procedere?</p>
      <div class="flex">
        <div class="p-3 col-6">
          <label for="nome">Seleziona quante mensilità</label>
          <InputNumber v-model="mensilita" showButtons :min="1" :max="12" :class="['inputfield w-full']" />

        </div>
        <div class="p-3 col-6">
          <label for="nome">Seleziona pulmino</label><br />
          <Dropdown v-model="confirmDialog.selectedCorso" :options="pulmini" placeholder="Seleziona un pulmino" />
        </div>
        <br />
      </div>
      <br />
      <Button @click="confirmDialogAccept" label="Procedi" />
      <Button @click="confirmDialogReject" label="Annulla" class="p-button-secondary" />
    </div>
  </div>
  <div v-if="confirmDialogCancel.visible" class="confirm-dialog">
    <div class="confirm-dialog-content ">
      <h3>Conferma la richiesta</h3>
      <p>Sei sicuro di voler procedere ad annullare la richiesta di pulmino?</p>

      <Button @click="confirmDialogCancelAccept" label="Procedi" />
      <Button @click="confirmDialogCancelReject" label="Annulla" class="p-button-secondary" />
    </div>
  </div>
  <div v-if="confirmDialogDelete.visible" class="confirm-dialog">
    <div class="confirm-dialog-content ">
      <h3>Conferma la richiesta</h3>
      <p>Sei sicuro di voler procedere ad CANCELLARE definitivamente la richiesta di pulmino?</p>

      <Button @click="confirmDialogDeleteAccept" label="Procedi" />
      <Button @click="confirmDialogDeleteReject" label="Annulla" class="p-button-secondary" />
    </div>
  </div>


  <Loader v-if="loading" />
  <Card>
    <template v-slot:title>
      <h3>Richieste Pulmino</h3>
    </template>
    <template v-slot:content>
      <div class="col-12">
        <Button @click="addBox" class="p-button-info" :label="(boxes.length == 0) ? 'Nuova' : 'Chiudi'" />
        <div v-for="(box, index) in boxes" :key="index" class="box p-5"><br />
          <div class="flex">
            <div class="p-3 col-3">
              <label for="nome">Seleziona un atleta</label>


              <Dropdown 
    v-model="box.atleta" 
    :options="filteredAtleti" 
    optionLabel="label" 
    filter 
    filterPlaceholder="Cerca atleta..." 
    placeholder="Seleziona un atleta" 
    :class="['inputfield w-full']"
    @filter="onFilter"
  >
    <template #value="slotProps">
      <div v-if="slotProps.value" class="flex align-items-center">
        <div>{{ slotProps.value.nomecompleto }}</div>
      </div>
      <span v-else>
        {{ slotProps.placeholder }}
      </span>
    </template>
    <template #option="slotProps">
      <div class="flex align-items-center">
        <div>{{ slotProps.option.nomecompleto }} </div>
      </div>
    </template>
  </Dropdown>

            </div>
            <div class="p-3 col-3">
              <div class="p-field"><label for="nome">Indirizzo</label>
                <InputText v-model="box.indirizzo" placeholder="Indirizzo prelievo" :class="['inputfield w-full']" />
              </div>


            </div>
            <div class="p-3 col-3">
              <div class="p-field">
                <label for="costo">Costo</label><br>
                <InputText v-model="box.costo" placeholder="importo" :class="['inputfield w-full']" />
              </div>
            </div>

          </div>
          <div class="flex">
            <div class="p-3 col-3">
              <label for="nome">Seleziona una tratta</label>
              <Dropdown id="tratta" v-model="box.tratta" :options="tratte" :filter="true"
                placeholder="Seleziona una tratta" :class="['inputfield w-full']"
                :virtualScrollerOptions="{ itemSize: 38 }" @change="onChangeInput($event, 'tratta')">

                <template #value="slotProps">
                  <div v-if="slotProps.value" class="flex align-items-center">
                    <div>{{ slotProps.value }}</div>
                  </div>
                  <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                </template>

                <template #option="slotProps">
                  <div class="flex align-items-center">
                    <div>{{ slotProps.option }}</div>
                  </div>
                </template>

              </Dropdown>
            </div>
            <div class="p-3 col-3">
              <label for="nome">Seleziona un pagamento</label>
              <Dropdown id="rata" v-model="box.rata" :options="pagamenti" :filter="true"
                placeholder="Seleziona una rata" :class="['inputfield w-full']"
                :virtualScrollerOptions="{ itemSize: 38 }" @change="onChangeInput($event, 'rata')">

                <template #value="slotProps">
                  <div v-if="slotProps.value" class="flex align-items-center">
                    <div>{{ slotProps.value }}</div>
                  </div>
                  <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                </template>

                <template #option="slotProps">
                  <div class="flex align-items-center">
                    <div>{{ slotProps.option }}</div>
                  </div>
                </template>

              </Dropdown>
            </div>

            <div class="p-3 col-3">
              <label for="nome">Seleziona i giorni della settimana</label>
              <div v-for="day in days" :key="day.value">
                <label>
                  <input type="checkbox" v-model="box[day.value]" />
                  {{ day.label }}
                </label>
              </div>

            </div>
          </div>


          <div class="p-3 col-12">
            <Button class="p-button-success" icon="pi pi-check" @click="saveBox(index)" label="Salva" />
          </div>
        </div>
      </div>
      <div class="col-12 flex flex-column md:col-12" v-if="richiestePulmino.length > 0">
        <DataTable :value="richiestePulmino" tableStyle="min-width: 50rem">
          <Column field="creation_time" header="Data creazione">
            <template #body="slotProps">
              {{ moment(slotProps.data.creation_time).format('DD/MM/Y HH:mm') }}
            </template>
          </Column>
          <Column field="atleta" header="Atleta"></Column>
          <Column field="sorgente" header="Sorgente"></Column>
          <Column field="indirizzo" header="Indirizzo"></Column>
          <Column field="costo" header="Costo"></Column>
          <Column field="periodo" header="Periodo">
            <template #body="{ data }">
              {{ data.periodo == 1 ? 'Mensile' :
    data.periodo == 3 ? 'Trimestrale' : '' }}
            </template>
          </Column>
          <Column field="tratta" header="Tratta">
            <template #body="{ data }">
              {{ data.tratta == 1 ? 'Andata e Ritorno' :
    data.tratta == 2 ? 'Andata' : 'Ritorno' }}
            </template>
          </Column>
          <Column field="lun" header="Lun"></Column>
          <Column field="mar" header="Mar"></Column>
          <Column field="mer" header="Mer"></Column>
          <Column field="gio" header="Gio"></Column>
          <Column field="ven" header="Ven"></Column>
          <Column field="sab" header="Sab"></Column>
          <Column header="Approvato" field="approvato">
            <template #body="slotProps">
              <Button @click="openConfirmDialog(slotProps.data.id, slotProps.data.approvato, slotProps.data.periodo)"
                :label="(slotProps.data.approvato == 0) ? 'NO' : 'SI'"
                :class="!(slotProps.data.approvato == 0) ? 'p-button-success' : 'p-button-warning'" />
            </template>
          </Column>
          <Column field="data_approvazione" header="Data approvazione"></Column>
          <Column>
            <template #body="slotProps">
              <Button @click="openConfirmDialogDelete(slotProps.data.id)"
                icon="pi pi-trash"
                class="p-button-danger" />
            </template>
          </Column>
        </DataTable>
      </div>
      <div v-else style="padding:40px">Non ci sono richieste per pulmini da approvare</div>




      <Accordion :activeIndex="0">


        <AccordionTab v-for="(richiestePerGiorno, pulmino) in richiestePulminoApprovate" :key="pulmino"
          :header="'Pulmino ' + pulmino">
          <div v-for="giorno in giorni" :key="giorno" class="col-12 flex flex-column md:col-12">
            <div v-if="richiestePerGiorno[giorno] && Object.keys(richiestePerGiorno[giorno]).length > 0">
            
              <Panel :header="giorno">
              <!-- Itera attraverso ogni orario di inizio per il giorno specifico -->
              <div v-for="(richiestePerOrario, orario) in richiestePerGiorno[giorno]" :key="orario" class="col-12">
                <p><b>Orario di inizio: {{ orario }}</b></p>
                <ol>
                  <li v-for="richiesta in richiestePerOrario" :key="richiesta.id">
                    Atleta: <b>{{ richiesta.atleta }}</b> - Tratta : {{ richiesta.tratta==1?'A/R':richiesta.tratta==2?'Andata':'Ritorno' }} - Indirizzo: {{ richiesta.indirizzo }} - Telefono: {{
    richiesta.telefono }}
                    <Button @click="openConfirmDialogCancel(richiesta.id)" icon="pi pi-refresh" class="p-button-warning" />
                  </li>
                </ol>
              </div>
              </Panel>
            </div>
          </div>
          <div v-if="!haRichieste(richiestePerGiorno)" class="p-3">
            Nessuna richiesta disponibile per questo pulmino.
          </div>
        </AccordionTab>

        <!--

        <AccordionTab v-for="(richiestePerGiorno, pulmino) in richiestePulminoApprovate" :key="pulmino" :header="'Pulmino ' + pulmino">
            <div v-for="giorno in giorni" :key="giorno" class="col-12 flex flex-column md:col-12">
                <p v-if="richiestePerGiorno[giorno] && richiestePerGiorno[giorno].length > 0">
                   <b> {{ giorno }} </b>
                </p>
                <ol>
                    <li v-for="richiesta in richiestePerGiorno[giorno]" :key="richiesta.id">
                        Atleta: <b>{{ richiesta.atleta }}</b> - Indirizzo: {{ richiesta.indirizzo }} - Telefono: {{ richiesta.telefono }}  <Button @click="openConfirmDialogCancel(richiesta.id)" icon="pi pi-trash" class="p-button-danger"/>
                    </li>
                </ol>
            </div>
            <div v-if="!haRichieste(richiestePerGiorno)" class="p-3">
                Nessuna richiesta disponibile per questo pulmino.
            </div>
        </AccordionTab>
        -->
      </Accordion>
    </template>
  </Card>
</template>

<script>
import { ref, onMounted, getCurrentInstance,computed } from 'vue';
import Loader from "@/components/Loader.vue";
import Service from '@/services/levante-api/levante-api';
import { useToast } from 'primevue/usetoast';
import moment from 'moment'

export default {
  components: {
    Loader,
  },
  setup() {
    const ddddd = ref(null); // Inizializza a null

    const loading = ref(true);
    const richiestePulmino = ref([]);
    const richiestePulminoApprovate = ref([]);
    const self = getCurrentInstance();
    const toast = useToast();
    const corsi = ref([])
    const filterQuery = ref('')
    const tipocorsi = ref([])
    const atleti = ref([])
    self.$eventBus = self.appContext.config.globalProperties.$eventBus
    const service = new Service(self.$eventBus)
    const boxes = ref([])
    const lifeTime = 3000;
    const mensilita = ref()
    const tratte = ref(['Andata e ritorno', 'Solo Andata', 'Solo Ritorno'])
    const frequenze = ref([1, 2, 3])
    const pulmini = ref([1, 2, 3, 4])
    const giorni = ref(['lun', 'mar', 'mer', 'gio', 'ven', 'sab', 'dom'])
    const pagamenti = ref(['Mensile', 'Trimestrale'])

    const validationErrors = ref({})
    const confirmDialog = ref({
      visible: false,
      id: null,
      visibile: null,
      selectedCorso: null
    });
    const confirmDialogCancel = ref({
      visible: false,
      id: null,
    });
    const confirmDialogDelete = ref({
      visible: false,
      id: null,
    });
    const box = ref({
      lun: false,
      mar: false,
      mer: false,
      gio: false,
      ven: false,
      sab: false
    });
    const onFilter = (event) => {
      
  filterQuery.value=event.value
};
    const filteredAtleti = computed(() => {
      const query = filterQuery.value.toLowerCase();
      return atleti.value.map(atleta => ({
        ...atleta,
        label: `${atleta.nomecompleto}`, // Questo viene usato per il filtro automatico
      })).filter(atleta => atleta.label.toLowerCase().includes(query));
    });

    const removeDuplicates=(lista) =>{
  // Usa un Set per tenere traccia dei valori unici
      const seen = new Set();
      return lista.filter(item => {
        // Usa il campo `nomecompleto` come chiave unica
        const key = item.nomecompleto;
        if (seen.has(key)) {
          return false; // Rimuove il duplicato
        }
        seen.add(key); // Aggiunge il valore alla lista dei visti
        return true; // Mantiene il valore unico
      });
    }
    // Array dei giorni della settimana (escludendo la domenica)
    const days = [
      { label: 'Lunedì', value: 'lun' },
      { label: 'Martedì', value: 'mar' },
      { label: 'Mercoledì', value: 'mer' },
      { label: 'Giovedì', value: 'gio' },
      { label: 'Venerdì', value: 'ven' },
      { label: 'Sabato', value: 'sab' }
    ];

    const haRichieste = (richiestePerGiorno) => {
      return giorni.value.some(giorno => richiestePerGiorno[giorno] && richiestePerGiorno[giorno].length > 0);
    }
    const openConfirmDialogDelete = (id) => {
      console.log("openConfirmDialogDelete")
      confirmDialogDelete.value.visible = true;
      confirmDialogDelete.value.id = id;
      console.log(confirmDialogDelete.value)
    }

    const openConfirmDialogCancel = (id) => {

      confirmDialogCancel.value.visible = true;
      confirmDialogCancel.value.id = id;
    }
    const openConfirmDialog = (id, visibile, periodo) => {
      confirmDialog.value.visible = true;
      confirmDialog.value.id = id;
      confirmDialog.value.visibile = visibile;
      if (periodo == 1) {
        mensilita.value = 10
      } else {
        mensilita.value = 3
      }
      console.log(periodo)
    };

    
    const confirmDialogDeleteAccept = () => {
      toggleApproveDelete(confirmDialogDelete.value.id);
      confirmDialogDelete.value.visible = false;
    };

    const confirmDialogDeleteReject = () => {
      toast.add({ severity: 'error', summary: 'Annullato', detail: 'Operazione annullata', life: 3000 });
      confirmDialogDelete.value.visible = false;
    };
    const confirmDialogCancelAccept = () => {
      toggleApproveCancel(confirmDialogCancel.value.id);
      confirmDialogCancel.value.visible = false;
    };

    const confirmDialogCancelReject = () => {
      toast.add({ severity: 'error', summary: 'Annullato', detail: 'Operazione annullata', life: 3000 });
      confirmDialogCancel.value.visible = false;
    };
    const confirmDialogAccept = () => {
      toggleApprove(confirmDialog.value.id, confirmDialog.value.visibile, confirmDialog.value.selectedCorso);
      confirmDialog.value.visible = false;
    };

    const confirmDialogReject = () => {
      toast.add({ severity: 'error', summary: 'Annullato', detail: 'Operazione annullata', life: 3000 });
      confirmDialog.value.visible = false;
    };

    const toggleApproveDelete = async (id) => {

      loading.value = true;

      try {
        let fd = new FormData();
        fd.append('id', id);

        await service.updateDeleteRichiestaPulmino(fd);  // assuming this endpoint exists
        toast.add({ severity: 'success', summary: 'Successo', detail: 'Richiesta aggiornata con successo', life: lifeTime });
        initData();
      } catch (error) {
        toast.add({ severity: 'error', summary: 'Errore', detail: 'Errore durante l\'aggiornamento della richiesta', life: lifeTime });
      } finally {
        loading.value = false;
      }
      };

    const toggleApproveCancel = async (id) => {

      loading.value = true;

      try {
        let fd = new FormData();
        fd.append('id', id);

        await service.updateCancelApprovaRichiestaPulmino(fd);  // assuming this endpoint exists
        toast.add({ severity: 'success', summary: 'Successo', detail: 'Richiesta aggiornata con successo', life: lifeTime });
        initData();
      } catch (error) {
        toast.add({ severity: 'error', summary: 'Errore', detail: 'Errore durante l\'aggiornamento della richiesta', life: lifeTime });
      } finally {
        loading.value = false;
      }
    };

    const toggleApprove = async (id, approvato, selectedCorso) => {
      approvato = approvato === 0 ? 1 : 0;
      loading.value = true;

      try {
        let fd = new FormData();
        fd.append('id', id);
        if (selectedCorso) {
          fd.append('pulmino', selectedCorso);
        }
        fd.append("mensilita", mensilita.value);
        await service.updateApprovaRichiestaPulmino(fd);  // assuming this endpoint exists
        toast.add({ severity: 'success', summary: 'Successo', detail: 'Richiesta aggiornata con successo', life: lifeTime });
        initData();
      } catch (error) {
        toast.add({ severity: 'error', summary: 'Errore', detail: 'Errore durante l\'aggiornamento della richiesta', life: lifeTime });
      } finally {
        loading.value = false;
        confirmDialog.value.selectedCorso = null
        mensilita.value = 1
      }
    };
    const onChangeInput = (evt, prop) => {
      let value
      switch (prop) {
        case 'rata':
          value = evt.value
          if (value == 'Mensile') {
            mensilita.value = 10
          } else {
            mensilita.value = 3
          }
          break;
        case 'tratta':
          value = evt.value
          break;
        case 'corso':
          value = evt.value

          break;
        case 'atleta':
          value = evt.value

          break;
        default:
          value = evt.target.value
          break;
      }
      if (value == '' || value == null) {
        validationErrors.value[prop] = true
      } else {
        delete validationErrors.value[prop]
      }
    }


    const getTipoCorso = async () => {
      await service.getTypeCorso()
        .then((res) => {
          tipocorsi.value = res.items
        })
    }



    const getAtleti = async () => {
      await service.getAtleti()
        .then((res) => {
          console.log(res.items.length)
          atleti.value = removeDuplicates(res.items)

          console.log(atleti.value.length)
        })
    }

    const initData = () => {
      service.getRichiestePulmino().then((res) => {
        richiestePulmino.value = res.items

        richiestePulmino.value = richiestePulmino.value.map(item => {
          const getOrario = (giorno) => {
            const day = item.daysofweek.find(d => d.giorno === giorno);
            return day ? `SI ${day.orario_inizio.replaceAll("00:00", "00")}` : 'SI';
          };

          return {
            creation_time: item.creation_time,
            atleta: item.atleta,
            id: item.id,
            periodo: item.periodo,
            tratta: item.tratta,
            indirizzo: item.indirizzo,
            costo: item.costo,
            sorgente: item.sorgente,
            lun: item.lun == 0 ? 'NO' : getOrario(1),
            mar: item.mar == 0 ? 'NO' : getOrario(2),
            mer: item.mer == 0 ? 'NO' : getOrario(3),
            gio: item.gio == 0 ? 'NO' : getOrario(4),
            ven: item.ven == 0 ? 'NO' : getOrario(5),
            sab: item.sab == 0 ? 'NO' : getOrario(6),
            approvato: item.approvato,
            data_approvazione: item.data_approvazione
          };
        });
        loading.value = false;
      });
      service.getAllRichiestePulmino().then((res) => {
        richiestePulminoApprovate.value = res.items;
      })

      atleti.value = []
      getAtleti()
      tipocorsi.value = []
      getTipoCorso()
    }

    const addBox = () => {
      if (boxes.value.length == 0)
        boxes.value.push({
          title: '',
          corso: null,
          tipocorso: null,
          image: null,
          atleta: null
        });
      else {
        boxes.value.pop();
      }
    }
    const validateForm = () => {
      return true;
    }
    const saveBox = async (index) => {
      if (validateForm()) {
        loading.value = true
        const box = boxes.value[index];

        let fd = new FormData();
        fd.append('id_atleta', box.atleta.id);
        if (box.tratta == "Andata e ritorno") box.tratta = 1
        if (box.tratta == "Solo Andata") box.tratta = 2
        if (box.tratta == "Solo Ritorno") box.tratta = 3
        fd.append('tratta', box.tratta)

        if (box.rata == "Trimestrale") box.rata = 3
        if (box.rata == "Mensile") box.rata = 1
        fd.append('periodo', box.rata)
        fd.append('indirizzo', box.indirizzo)
        fd.append('costo', box.costo)
        if (box.lun)
          fd.append('lun', box.lun)
        if (box.mar)
          fd.append('mar', box.mar)
        if (box.mer)
          fd.append('mer', box.mer)
        if (box.gio)
          fd.append('gio', box.gio)
        if (box.ven)
          fd.append('ven', box.ven)
        if (box.sab)
          fd.append('sab', box.sab)
        service.sendRichiestaPulmino(fd)
          .then((res) => {
            let severity = res.item.esito
            let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
            let detail = res.item.messaggio
            //if(res.item.esito != 'success'){
            initData()
            //}
            if (res.item.esito == 'success') {
              boxes.value.pop();
            }
            toast.add({ severity: severity, summary: summary, detail: detail, life: lifeTime });
          }).finally(() => {
            loading.value = false
          })
      } else {
        toast.add({ severity: 'warn', summary: 'Attenzione', detail: `Ci sono dei dati mancanti`, life: lifeTime });
      }
    }

    onMounted(() => {
      loading.value = true
      const promises = [initData()]

      Promise.all(promises)
        .then(() => {

        }).finally(() => {
          loading.value = false
        })
    })

    return {
      loading,
      richiestePulmino,
      richiestePulminoApprovate,
      giorni,
      addBox,
      saveBox,
      box, days,
      boxes,
      ddddd,
      mensilita,
      corsi,
      atleti,
      onFilter,
      filteredAtleti,
      validationErrors,
      onChangeInput,
      moment, confirmDialog,
      confirmDialogDelete, openConfirmDialogDelete,
      openConfirmDialog, openConfirmDialogCancel,
      confirmDialogAccept,
      confirmDialogCancelAccept,
      confirmDialogCancelReject,
      confirmDialogDeleteAccept,
      confirmDialogDeleteReject,
      confirmDialogReject,
      confirmDialogCancel,
      haRichieste,
      tipocorsi, tratte, pagamenti, frequenze, pulmini
    };
  },
}
</script>

<style scoped>
.confirm-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.confirm-dialog-content {
  text-align: center;
}

.confirm-dialog-content h3 {
  margin-top: 0;
}

.confirm-dialog-content button {
  margin: 10px;
}
</style>
