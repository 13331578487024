<template>
  <Loader v-if="loading" />  
  <div class="flex">
    <Card class="col-8">
      <template v-slot:title>
        Crea Atleta
      </template>
      <template v-slot:content>
        <div class="grid">
          <div class="m-3">
            <div class="formgrid grid mt-4">
              <!-- Cognome Utente -->
              <div class="p-field col-12 md:col-6">
                <label for="cognome">Cognome</label><br>
                <InputText id="cognome" v-model="utente.cognome" :class="['inputfield w-full',{'p-invalid': validationErrors.cognome && submitted}]" @input="(val) => (utente.cognome = utente.cognome.toUpperCase())" @change="onChangeInput($event, 'cognome')"/>
                <small v-show="validationErrors.cognome && submitted" class="p-error">Campo richiesto.</small>
              </div>
              
              <!-- Nome Utente -->
              <div class="p-field col-12 md:col-6">
                <label for="nome">Nome</label><br>
                <InputText id="nome" v-model="utente.nome" :class="['inputfield w-full',{'p-invalid': validationErrors.nome && submitted}]" @input="(val) => (utente.nome = utente.nome.toUpperCase())" @change="onChangeInput($event, 'nome')"/>
                <small v-show="validationErrors.nome && submitted" class="p-error">Campo richiesto.</small>              
              </div>
              
              <!-- Data di nascita Utente -->
              <div class="p-field col-12 md:col-4">
                <label>Data di nascita</label><br>                   
                <Calendar :class="['inputfield w-full',{'p-invalid': validationErrors.birth && submitted}]" v-model="utente.birth" dateFormat="dd/mm/yy" :maxDate="maxDate" @change="onChangeInput($event, 'birth')" showIcon :showOnFocus="false"/>
                <small v-show="validationErrors.birth && submitted" class="p-error">Campo richiesto.</small>
              </div> 
              
              <!-- Gender Utente -->
              <div class="p-field col-12 md:col-4 flex flex-column justify-content-center align-items-center">
                <label class="mb-3
                ">Sesso</label>                 
                
                <div class="flex justify-content-between">
                  <div v-for="gender in genders" :key="gender.key"  class="flex align-items-center">
                    <RadioButton v-model="utente.gender" :id="gender.key" name="dynamic" :value="gender.name" />
                    <label :for="gender.key" class="ml-2 mr-2">{{ gender.name }}</label>
                  </div>
                  
                </div>
                <div v-show="validationErrors.gender && submitted" class="p-error">Campo richiesto.</div>
              </div>
              
              <!-- Codice fiscale Utente -->
              <div class="p-field col-12 md:col-4">
                <label for="cf">Codice fiscale</label><br>
                <InputMask id="cf" v-model="utente.cf" mask="aaaaaa99a99a999a" autofocus :class="['text_toupper inputfield w-full',{'p-invalid': validationErrors.cf && submitted}]" @change="onChangeInput($event, 'cf')"/>
  
                <div v-show="validationErrors.cf && submitted" class="p-error">Campo richiesto.</div>
              </div>
              
              <!-- Selezione Utente -->
              <div class="p-field col-12 md:col-4">
                <label for="id_utente">Utente</label><br>
  
                <Dropdown id="id_utente" v-model="utente.id_utente" :options="users" :filter="true" optionLabel="name" optionValue="id" placeholder="Associa Utente esistente" :class="['inputfield w-full',{'p-invalid': validationErrors.id_utente && submitted}]" :virtualScrollerOptions="{ itemSize: 38 }" @change="onChangeInput($event, 'id_utente')"/>
                <small v-show="validationErrors.id_utente && submitted" class="p-error">Campo richiesto.</small>
              </div>
            
              <!-- Bottone salva -->
              <div class="mt-5 col-12 flex justify-content-end">
                <Button class="p-button-success" icon="pi pi-check" label="Salva" @click="sendDataNewUser"/>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Card>
    <Card class="col-4">
      <template v-slot:title>
        Elenco atleti
      </template>

      <template #content>
        <div v-if="existent_atleti">
          <Listbox :options="existent_atleti" filter optionLabel="nome_completo" :virtualScrollerOptions="{ itemSize: 38 }" class="w-full" listStyle="height:600px" />
        </div>
        <div v-else>
          <div class="w-full xl:w-6 p-3">
                <Skeleton class="mb-2"></Skeleton>
                <Skeleton width="10rem" class="mb-2"></Skeleton>
                <Skeleton width="5rem" class="mb-2"></Skeleton>
                <Skeleton height="2rem" class="mb-2"></Skeleton>
                <Skeleton width="10rem" height="4rem"></Skeleton>
            </div>
        </div>
      </template>
    </Card>

  </div>  
</template>

<script>
import {ref, onMounted, getCurrentInstance, onUpdated, computed } from 'vue';
import Loader from "@/components/Loader.vue"
import Service from '@/services/levante-api/levante-api'
import moment from 'moment'
import { useToast } from 'primevue/usetoast';

export default {
  components: {
    Loader,
  },
  
  setup () { 
    
    const self = getCurrentInstance();
    const toast = useToast();
    self.$eventBus = self.appContext.config.globalProperties.$eventBus
    const service = new Service(self.$eventBus)
    const submitted = ref(false)
    const loading = ref(false)
    const utente = ref({})
    const validationErrors = ref({})
    const atleti = ref([])
    const existent_atleti = ref()
    const columns = [
    { field: 'surname', header: 'Cognome' },
    { field: 'name', header: 'Nome' },
    { field: 'birth', header: 'Data di nascita' },
    { field: 'gender', header: 'Sesso' },
    { field: 'cf', header: 'Codice fiscale' }
    ];
    const genders = [
      {key:0, name:'Maschio'},
      {key:1, name:'Femmina'}
    ]
    const users = ref([])
    const maxDate = ref();
    const today = new Date();
    const block_div_atleti = ref(true)
    const label_checkbox_associa = computed(() => {
      let status_checkbox = [
      {status: false, description: 'Associa'},
      {status: true, description: 'Dissocia'}
      ]
      return status_checkbox.find(status => status.status == block_div_atleti.value).description + ' atleti'
    })
    
    const onSelectedMobile = (evt) => {
      utente.value.cellulare = evt.target.value;
      delete validationErrors.value['cellulare'];
    }
    const addRow = () => {
      let newRow = columns.reduce((obj, column) => {
        obj[column.field] = '';
        return obj;
      }, {});
      newRow.gender = 'Maschio'
      newRow.surname = utente.value.cognome ?? ''
      atleti.value.push(newRow)   
    }
    const onCellEditComplete = (event) => {
      let { field, index } = event;
      if (field != 'gender' && field != 'birth') {
        atleti.value[index][field] = atleti.value[index][field].toUpperCase()
      }
    };
    const deleteRow = (row, index) => {
      atleti.value.splice(index, 1);
      validationErrors.value = []
    }
    const getUtenti = async () => {
      await service.getUtenti()
      .then((res) => {
        users.value = res.items
      })
    }
    const getAtleti = async () => {
      await service.getAtleti()
      .then((res) => {
        existent_atleti.value = res.items
        existent_atleti.value.forEach(item => {
          item.nome_completo = `${item.cognome} ${item.nome} - ${item.codfis} - Età: ${item.eta}`
        });
      })
    }
    const onChangeInput = (evt, prop) => {
      let value
      switch (prop) {
        case 'id_utente':
        value = evt.value
        break;
        
        default:
        value = evt.target.value
        break;
      }
      if (value == '' || value == null) {
        validationErrors.value[prop] = true
      }else{
        delete validationErrors.value[prop]
      }
    }
    
    const viewDataTableAtleti = () => {
      let div_atleti = document.getElementById('container-atleti')
      block_div_atleti.value = !block_div_atleti.value
      let style = block_div_atleti.value ? 'block' : 'none'
      div_atleti.style.display = style
      if (!block_div_atleti.value) {
        atleti.value = []
      }
    }
    
    const validateForm = () => {
      // Validazione Utente
      let validate_properties = [
      'nome',
      'cognome',
      'birth',
      'gender',
      'cf',
      'id_utente'
      ]
      
      validate_properties.forEach( prop => {
        if (utente.value[prop] == '' || utente.value[prop] == null) {
          validationErrors.value[prop] = true
        } else {
          delete validationErrors.value[prop];
        }
      })
      // Fine Validazione Utente
      
      return !Object.keys(validationErrors.value).length;
    }
    const setDataTime = (el) => {
      let giorno = el.getDate() 
      let mese = el.getMonth()+1
      mese = mese.toString().padStart(2,'0')
      let anno = el.getFullYear() 
      el = `${anno}-${mese}-${giorno}`
      return el
    } 
    
    
    const sendDataNewUser = async () => {
      submitted.value = true;
      validationErrors.value = []
      if(validateForm()){
        loading.value = true
        let fd = new FormData();
        utente.value.birth = setDataTime(utente.value.birth)

        fd.append('utente', JSON.stringify(utente.value));
        
        service.sendDataNewAtleta(fd)
        .then((res) => {
          let severity = res.item.error ? 'warn' : 'success'
          let summary = res.item.error ? 'Attenzione' : 'Successo'
          let detail = res.item.response
          
          toast.add({severity:severity, summary: summary, detail:detail});
        }).finally(() => {
          initData()
          loading.value = false
        })
      }else{
        toast.add({severity:'warn', summary: 'Attenzione', detail:`Ci sono dei dati mancanti`});
      }
    }
    
    const initData = () => {
      getUtenti()
      getAtleti()

      // restart var
      utente.value = {
        gender:'Maschio'
      }
      atleti.value = []
      block_div_atleti.value = true
      // setMaxData
      const currentYear = today.getFullYear();
      const previousYear = currentYear - 1;
      maxDate.value = new Date(previousYear, today.getMonth(), today.getDate());
    }
    
    onMounted(() => { 
      initData()
    })
    
    return {
      loading,
      submitted,
      utente,
      validationErrors,
      onSelectedMobile,
      atleti,
      users,
      columns,
      addRow,
      onCellEditComplete,
      genders,
      moment,
      deleteRow,
      maxDate,
      viewDataTableAtleti,
      block_div_atleti,
      label_checkbox_associa,
      sendDataNewUser,
      onChangeInput,
      existent_atleti,
    }
  },
}
</script>

<style>
.text_toupper{
  text-transform: uppercase;
} 
</style>
<style scoped>
#container-atleti{
  display: block;
}
.layout-main-container{
  width: fit-content !important;
}
.btn-error-filter{
  width: 200px;
}
</style>
