<template>
    <Dialog :visible="visible" modal :style="{ width: '50rem' }" :closable="false">  
        <template #header>
            <div class="flex justify-content-between w-full">
                <h3>Campo</h3>
                <Button icon="pi pi-times" @click="closeDialog" class="p-button-text p-button-rounded p-button-secondary"/>
            </div>
        </template>
        Seleziona il nuovo campo  
                    <Dropdown v-model="selectedCampo" :options="dialogCampi" optionLabel="nomecampo" class="text_toupper inputfield w-full" />
        <p class="p-3"><label>Clicca qui se vuoi cambiare questo campo per tutte le sessioni di questo giorno della settimana da ora in poi<br/></label>
        <input type="checkbox"  v-model="forever"/>
        </p>
        <template #footer>
            <Button label="Salva" @click="save"/>
        </template>
    </Dialog>
</template>

<script>
import { ref, watch } from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';

export default {
    name: 'DialogComponentCampi',
    components: {
        Dialog,
        Button
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
      
        campi: {
            type: Array,
            required: true
        }
    },
    setup(props, { emit }) {
        const selectedCampo = ref({});
        const selectedStaff = ref({});
        const dialogCampi = ref([...props.campi]);
        const forever = ref(false)
        
        watch(() => props.campi, (newVal) => {
            dialogCampi.value = [...newVal];
           forever.value=false
        });
      
       
        const closeDialog = () => {
            selectedCampo.value = ""
            emit('close');
        };

        const save = () => {
            emit('save', selectedCampo.value,forever.value);
            selectedCampo.value = ""
            closeDialog();
        };

        return {
            selectedCampo,
            closeDialog,
            dialogCampi,
            save,forever
        };
    }
};
</script>

<style scoped>
</style>
