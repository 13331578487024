import axios from 'axios';
import store from '../../store/index'
import Cookies from 'js-cookie';


const API_URL = `${process.env.VUE_APP_LEVANTE_API_URL}/api/`;
const instance = axios.create();
instance.interceptors.request.use(function (config) {
  config.headers["Authorization"] = ''
  return config;
});

class AuthService {

  async loginUserFromCookie(cookieTokens){
    
      return instance.post(API_URL + 'auth/signin-cookie', {
        data: cookieTokens
      }).then(response => {
        if (response.data.isAuthorized) {
          if (Cookies.get('google_access_token') || Cookies.get('ask_access_token')) {
            let user = Cookies.get('google_access_token') ?? Cookies.get('ask_access_token')
            user = JSON.parse(user)
            user.email = response.data.user[0].email
            user.profile = response.data.user[0].profile
            user.id = response.data.user[0].id
            store.dispatch("auth/loginWithCookie", user)
            return true            
          } else {
            return false
          }        
        }  else {
          return false
        }
      })
  }


}

export default new AuthService();
