<template>
    <Dialog :visible="visible" modal :style="{ width: '50rem' }" :closable="false">  
        <template #header>
            <div class="flex justify-content-between w-full">
                <h3>Assenze</h3>
                <Button icon="pi pi-times" @click="closeDialog" class="p-button-text p-button-rounded p-button-secondary"/>
            </div>
        </template>    
        <DataTable :value="localIscritti" tableStyle="min-width: 50rem">
            <Column field="nome" header="Nome"/>
            <Column field="cognome" header="Cognome"/>
            <Column field="assente" header="Assente">
                <template #body="{ data }">
                    <Checkbox :value="data.id" v-model="selectedIscritti[data.id]" :binary="true"/>
                </template>
            </Column>
            <Column field="id" header="Sostituto">
                <template #body="{ data }">
                    <Dropdown v-model="selectedStaff[data.id]" :options="dialogStaff" optionLabel="name" class="text_toupper inputfield w-full" />
                </template>

            </Column>
        </DataTable>
        <template #footer>
            <Button label="Salva" @click="save"/>
        </template>
    </Dialog>
</template>

<script>
import { ref, watch } from 'vue';
import Dialog from 'primevue/dialog';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Checkbox from 'primevue/checkbox';
import Button from 'primevue/button';

export default {
    name: 'DialogComponentStaff',
    components: {
        Dialog,
        DataTable,
        Column,
        Checkbox,
        Button
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        iscritti: {
            type: Array,
            required: true
        },
        staff: {
            type: Array,
            required: true
        }
    },
    setup(props, { emit }) {
        const localIscritti = ref([...props.iscritti]);
        const selectedIscritti = ref({});
        const selectedStaff = ref({});
        const dialogStaff = ref([...props.staff]);
        
        watch(() => props.staff, (newVal) => {
            dialogStaff.value = [...newVal];
           
        });
        watch(() => props.iscritti, (newVal) => {
            localIscritti.value = [...newVal];
            newVal.forEach(val => {
                selectedIscritti.value[val.id] = Boolean(val.assente)
                selectedStaff.value[val.id] =  dialogStaff.value.find(user => user.id == val.sostituto)
            })
        });
       
        const closeDialog = () => {
            emit('close');
        };

        const save = () => {
            emit('save', selectedIscritti.value,selectedStaff.value);
            selectedIscritti.value = []
            selectedStaff.value = []
            closeDialog();
        };

        return {
            selectedIscritti,
            localIscritti,
            selectedStaff,
            closeDialog,
            dialogStaff,
            save
        };
    }
};
</script>

<style scoped>
</style>
