<template>
  <div>
    <Loader v-if="loading" />  
    <Card >
     
      <template v-slot:title>
        <h3  style='float:left'>Merce da consegnare </h3>   
        <br/>   
      </template>
      <template v-slot:content>
       
        <div v-for="vendita in vendite" :key="vendita.id" style='padding:20px'>
          <h5><b @click='goToAtleta(vendita.id_atleta)' style='cursor:pointer;text-decoration:underline'>{{ vendita.atleta}}</b> n. vendita: {{vendita.id}} del {{moment(vendita.data_vendita).format('DD/MM/Y')}} - Totale € {{vendita.totale_pagato}}</h5>
            <DataTable :value="vendita.dettaglio" class="p-datatable-gridlines">
                <Column field="nome" header="Prodotto"></Column>
                <Column field="size" header="Dettaglio"></Column>
                <Column field="quantita" header="Quantità"></Column>
                <Column field="prezzo" header="Importo"></Column>
                <Column field="prezzo" header="Taglia consegnata">
                  <template #body="{data}">
                    <Dropdown id="id_atleta" v-model="vendita.cod_size"
                    optionValue="id"        
                    optionLabel="nome"
                    
                    :options="getSizes" :filter="true"  :filterBy="['name']" placeholder="Seleziona la taglia" :class="['inputfield w-full']" @change="onChangeInput($event, data.id)">
                      <template #value="slotProps">
                        <div v-if="slotProps.value" class="flex align-items-center">
                          <div>{{ getSizeName(slotProps.value) }} </div>
                        </div>
                        <span v-else>
                          {{ slotProps.placeholder }}
                        </span>
                      </template>
                      <template #option="slotProps">
                        <div class="flex align-items-center">
                          <div>{{ slotProps.option.nome }} </div>
                        </div>
                      </template>
                    </Dropdown>  
                  </template>
                </Column>
                <Column field="prezzo" header="Data consegna">
                  <template #body="{data}">
                  <Calendar v-model="currentDay" dateFormat="dd/mm/yy" showIcon :showOnFocus="false"  @change="onChangeDay(data.id)"/>
                  </template>
                </Column>

                <Column field="prezzo" header="">
                    <template #body="{data}">
                      <div class='p-button p-button-danger' @click="salva(vendita.id,vendita.cod_size,currentDay,data.id)">Salva</div>
                    </template>
                </Column>
            </DataTable>
        </div>
      </template>
    </Card>

     
  </div>
</template>

<script>
import { ref, onMounted, getCurrentInstance, computed, watchEffect } from 'vue';
import Loader from "@/components/Loader.vue";
import Service from '@/services/levante-api/levante-api'
import moment from 'moment';

import { useToast } from 'primevue/usetoast';
export default {
  components: {
    Loader,
  },
  methods: {
    getSizeName(id) {
      const size = this.getSizes.find((size) => size.id === id);
      return size ? size.nome : '';
    },
  },
  setup(){
    const self = getCurrentInstance();
    const currentMonth = ref(new Date());
    const currentDay = ref(new Date());
    const vendite = ref([]);
    const getSizes = ref([]);
    const loading = ref(true);
    const toast = useToast()

    const lifeTime=3000
    self.$eventBus = self.appContext.config.globalProperties.$eventBus;
    const service = new Service(self.$eventBus);
    const goToAtleta = (idatleta)=> {
      console.log(idatleta)
      window.open(`/atleta/${idatleta}/dettagli`, '_blank')
    }
    const goToCorso = (idatleta)=> {
      console.log(idatleta)
      window.open(`/corso/${idatleta}/dettagli`, '_blank')
    }

   
    const getVendite = async () => {
      loading.value = true;
            await service.getVenditeDaConsegnare()
            .then((res) => {
                vendite.value = res.items
                
                loading.value=false
            })
            await service.getSizes().then((res)=>{
                  getSizes.value=res.items
                })
        }

    const onChangeDay = () => {

    }

    const onChangeInput = () => {

    }

    const salva = (id,size,day) =>{
      console.log(id)
      console.log(size)
      console.log(day)
      let fd = new FormData();
            fd.append('id',id);
            fd.append('size', size);
            fd.append('day', moment(day).format("YYYY-MM-DD"));
            console.log(fd);
            service.saveConsegnaMerce(fd)
            .then((res) => {
                let severity = res.item.esito
                let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
                let detail = res.item.messaggio
                
                if (severity != 'warning') {
                    toast.add({severity:severity, summary: summary, detail:detail,life:lifeTime});                            
                }
                getVendite()
            }).finally(()=>{
            })
    }


    watchEffect(() => {
      getVendite();
    });

    return {
      moment,
      currentMonth,
      goToCorso,
      getSizes,
      vendite,
      loading,
      goToAtleta,
      currentDay,
      onChangeDay,
      salva,
      onChangeInput
    };
  },
};
</script>

<style scoped>
.month-selector {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
}
.totals-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Spazio tra i box */
}

.total-box {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  background-color: #f9f9f9;
  color:black
}
@media (max-width: 600px) {
  .total-box {
    flex: 100%;
  }
}
</style>
