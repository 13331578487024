<template>
    <div>
      <div v-for="field in fields" :key="field.id" class="accordion-item">
        <div class="accordion-header" @click="toggleAccordion(field.id)">
          <h3>{{ field.name }}</h3>
        </div>
        <div v-if="isOpen(field.id)" class="accordion-content">
          <vue-cal :events="field.sessions" @click-event="handleSlotClick" />
        </div>
      </div>

    </div>
  </template>
  
  <script>
  import VueCal from 'vue-cal';
  import 'vue-cal/dist/vuecal.css';
  import EditSlotDialog from './EditSlotDialog.vue';
  
  export default {
    name: 'AccordionFields',
    components: {
      VueCal,
    },
    props: {
      fields: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        openAccordions: [],
        selectedSlot: null,
      };
    },
    methods: {
      toggleAccordion(id) {
        const index = this.openAccordions.indexOf(id);
        if (index > -1) {
          this.openAccordions.splice(index, 1);
        } else {
          this.openAccordions.push(id);
        }
      },
      isOpen(id) {
        return this.openAccordions.includes(id);
      },
      handleSlotClick(event) {
        this.selectedSlot = event;
        this.$refs.editSlotDialog.show(event);
    },
    },
  };
  </script>
  
  <style>
  .accordion-item {
    border: 1px solid #ccc;
    margin: 10px 0;
  }
  
  .accordion-header {
    background-color: #f1f1f1;
    padding: 10px;
    cursor: pointer;
  }
  
  .accordion-content {
    padding: 10px;
    border-top: 1px solid #ccc;
  }
  .vue-cal .vue-cal-day-slot {
  display: none;
}
.vuecal__view-btn{
    display: none;
}


  </style>
  