import { createRouter, createWebHistory, /*createWebHashHistory*/ } from 'vue-router';
import Cookies from "js-cookie";
import service from '@/config/services'
import store from './store/index'
import roles from './config/roles'

import LoginView from '@/LoginView.vue';
import NotAuthorized from '@/NotAuthorized.vue'
import DashboardView from '@/DashboardView.vue'

import Home from '@/views/Home.vue'

import NuovoUtente from '@/views/NuovoUtente.vue'
import NuovoKIT from '@/views/NuovoKit.vue'
import NuovoProdotto from '@/views/NuovoProdotto.vue'
import NuovaVendita from '@/views/NuovaVendita.vue'
import NuovaVenditaAtleta from '@/views/NuovaVendita.vue'
import NuovaIscrizioneCorso from '@/views/NuovaIscrizione.vue'
import NuovaIscrizioneCorsoAtleta from '@/views/NuovaIscrizione.vue'
import NuovoAtleta from '@/views/NuovoAtleta.vue'
import NuovoPersonale from '@/views/NuovoPersonale.vue'
import NuovoCorso from '@/views/NuovoCorso.vue'
import NuovaIscrizione from '@/views/NuovaIscrizione.vue'
import MindTab from '@/views/MindTab.vue'

import AtletiDetail from '@/pages/details/AtletiDetail.vue'
import UtenteDetail from '@/pages/details/UtenteDetail.vue'
import CorsoDetail from '@/pages/details/CorsoDetail.vue'
import StaffDetail from '@/pages/details/StaffDetail.vue'
import ProdottoDetail from '@/pages/details/ProdottoDetail.vue'
import KitDetail from '@/pages/details/KitDetail.vue'
import VenditaDetail from '@/pages/details/VenditaDetail.vue'

import AppStatica from '@/views/AppStatica.vue'
import AppLogAccessi from '@/views/AppLogAccessi.vue'
import AppRichiestaProva from '@/views/AppRichiestaProva.vue'
import Statistiche from '@/views/Statistiche.vue'
import AppStatistiche from '@/views/AppStatistiche.vue'
import AppPagamenti from '@/views/AppPagamenti.vue'
import Pagamenti from '@/views/Pagamenti.vue'
import AppVerificaDocumenti from '@/views/AppVerificaDocumenti.vue'
import VerificaProdotti from '@/views/AppProdottiGruppo.vue'
import AttivitaExtra from '@/views/AttivitaExtra.vue'
import PrenotazioniSenior from '@/views/PrenotazioniSenior.vue'
import AppNews from '@/pages/details/NewsDetail.vue'
import Tornei from '@/views/Tornei.vue'
import Pulmino from '@/views/Pulmino.vue'
import PreOrdini from '@/views/PreOrdini.vue'
import Calendario from '@/views/Calendario.vue'
import Transactions from '@/views/Transactions.vue'
import Modulistica from '@/views/Modulistica.vue'
import ConsegnaMerce from '@/views/ConsegnaMerce.vue'
import Sponsor from '@/views/AppSponsor.vue'


import { getCurrentInstance } from 'vue'
import Service from '@/services/levante-api/levante-api'

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: LoginView,
    },
    {
      path: '/unauthorized',
      name: 'Unauthorized',
      component: NotAuthorized,
    },
    {
        path: '/',
        component: DashboardView,
        children: [
            {
                path: '/',
                component: Home
            },
            {
              path: '/crea-utente',
              name: 'Nuovo Utente', 
              component: NuovoUtente,
              beforeEnter: [hasPermission],
              meta: { authorize: [roles.admin.role] , profiles: ['all'] },
            },
            {
              path: '/crea-kit',
              name: 'Nuovo Kit', 
              component: NuovoKIT,
              beforeEnter: [hasPermission],
              meta: { authorize: [roles.admin.role] , profiles: ['all'] },
            },
            {
              path: '/crea-prodotto',
              name: 'Nuovo Prodotto', 
              component: NuovoProdotto,
              beforeEnter: [hasPermission],
              meta: { authorize: [roles.admin.role] , profiles: ['all'] },
            },
            {
              path: '/crea-atleta',
              name: 'Nuovo Atleta', 
              component: NuovoAtleta,
              beforeEnter: [hasPermission],
              meta: { authorize: [roles.admin.role] , profiles: ['all'] },
            },
            {
              path: '/crea-personale',
              name: 'Nuovo Personale', 
              component: NuovoPersonale,
              beforeEnter: [hasPermission],
              meta: { authorize: [roles.admin.role] , profiles: ['all'] },
            },
            {
              path: '/crea-corso',
              name: 'Nuovo Corso', 
              component: NuovoCorso,
              beforeEnter: [hasPermission],
              meta: { authorize: [roles.admin.role] , profiles: ['all'] },
            },
            {
              path: '/crea-iscrizione',
              name: 'Nuova Iscrizione', 
              component: NuovaIscrizione,
              beforeEnter: [hasPermission],
              meta: { authorize: [roles.admin.role] , profiles: ['all'] },
            },
            {
              path: '/add-iscrizione/:idcorso',
              name: 'Nuova Iscrizione Corso', 
              component: NuovaIscrizioneCorso,
              beforeEnter: [hasPermission],
              meta: { authorize: [roles.admin.role] , profiles: ['all'] },
            },
            {
              path: '/add-iscrizione-atleta/:idcorso/:idatleta',
              name: 'Nuova Iscrizione Corso Atleta', 
              component: NuovaIscrizioneCorsoAtleta,
              beforeEnter: [hasPermission],
              meta: { authorize: [roles.admin.role] , profiles: ['all'] },
            },
            {
              path: '/crea-vendita',
              name: 'Nuova Vendita', 
              component: NuovaVendita,
              beforeEnter: [hasPermission],
              meta: { authorize: [roles.admin.role] , profiles: ['all'] },
            },
            {
              path: '/add-store/:idatleta',
              name: 'Nuova Vendita Atleta', 
              component: NuovaVenditaAtleta,
              beforeEnter: [hasPermission],
              meta: { authorize: [roles.admin.role] , profiles: ['all'] },
            },
            {
              path:'/attivitaExtra',
              name:'AttivitaExtra',
              component: AttivitaExtra,
              beforeEnter: [hasPermission],
              meta: { authorize: [roles.admin.role] , profiles: ['all'] },
            },
            {
              path:'/sponsor',
              name:'Sponsor',
              component: Sponsor,
              beforeEnter: [hasPermission],
              meta: { authorize: [roles.admin.role] , profiles: ['all'] },
            },
            {
              path:'/mindtab',
              name:'Mindtab',
              component: MindTab,
              beforeEnter: [hasPermission],
              meta: { authorize: [roles.admin.role] , profiles: ['all'] },
            }, {
              path:'/transactions',
              name:'Transactions',
              component: Transactions,
              beforeEnter: [hasPermission],
              meta: { authorize: [roles.admin.role] , profiles: ['all'] },
            }, {
              path:'/modulistica',
              name:'Modulistica',
              component: Modulistica,
              beforeEnter: [hasPermission],
              meta: { authorize: [roles.admin.role] , profiles: ['all'] },
            }, {
              path:'/statistiche',
              name:'Statistiche',
              component: Statistiche,
              beforeEnter: [hasPermission],
              meta: { authorize: [roles.admin.role] , profiles: ['all'] },
            }, {
              path:'/pagamenti',
              name:'Pagamenti',
              component: Pagamenti,
              beforeEnter: [hasPermission],
              meta: { authorize: [roles.admin.role] , profiles: ['all'] },
            },{
              path:'/tornei',
              name:'Tornei',
              component: Tornei,
              beforeEnter: [hasPermission],
              meta: { authorize: [roles.admin.role] , profiles: ['all'] },
            },{
              path:'/calendario',
              name:'Calendario',
              component: Calendario,
              beforeEnter: [hasPermission],
              meta: { authorize: [roles.admin.role] , profiles: ['all'] },
            },{
              path:'/pulmino',
              name:'Pulmino',
              component: Pulmino,
              beforeEnter: [hasPermission],
              meta: { authorize: [roles.admin.role] , profiles: ['all'] },
            },{
              path:'/preordini',
              name:'PreOrdini',
              component: PreOrdini,
              beforeEnter: [hasPermission],
              meta: { authorize: [roles.admin.role] , profiles: ['all'] },
            },{
              path:'/verificaProdotti',
              name:'VerificaProdotti',
              component: VerificaProdotti,
              beforeEnter: [hasPermission],
              meta: { authorize: [roles.admin.role] , profiles: ['all'] },
            },{
              path:'/consegnamerce',
              name:'ConsegnaMerce',
              component: ConsegnaMerce,
              beforeEnter: [hasPermission],
              meta: { authorize: [roles.admin.role] , profiles: ['all'] },
            },
            {
              path: '/atleta/:id/dettagli',
              name: 'AtletiDetail',
              component: AtletiDetail,
              beforeEnter: [hasPermission],           
              meta: { authorize: [roles.admin.role] , profiles: ['all'] },
          },{
            path: '/utente/:id/dettagli',
            name: 'UtenteDetail',
            component: UtenteDetail,
            beforeEnter: [hasPermission],           
            meta: { authorize: [roles.admin.role] , profiles: ['all'] },
        },
          {
            path: '/corso/:id/dettagli',
            name: 'CorsoDetail',
            component: CorsoDetail,
            beforeEnter: [hasPermission],           
            meta: { authorize: [roles.admin.role] , profiles: ['all'] },
          },
          {
            path: '/staff/:id/dettagli',
            name: 'StaffDetail',
            component: StaffDetail,
            beforeEnter: [hasPermission],           
            meta: { authorize: [roles.admin.role] , profiles: ['all'] },
          },
          {
            path: '/prodotto/:id/dettagli',
            name: 'ProdottoDetail',
            component: ProdottoDetail,
            beforeEnter: [hasPermission],           
            meta: { authorize: [roles.admin.role] , profiles: ['all'] },
          },{
            path: '/kit/:id/dettagli',
            name: 'KitDetail',
            component: KitDetail,
            beforeEnter: [hasPermission],           
            meta: { authorize: [roles.admin.role] , profiles: ['all'] },
          },{
            path: '/vendita/:id/dettagli',
            name: 'VenditaDetail',
            component: VenditaDetail,
            beforeEnter: [hasPermission],           
            meta: { authorize: [roles.admin.role] , profiles: ['all'] },
          },{
            path:'/app/news',
            name:'News in app',
            component: AppNews,
            beforeEnter: [hasPermission],
            meta: { authorize: [roles.admin.role] , profiles: ['all'] },
          },{
            path:'/app/statiche',
            name:'Pagine per app',
            component: AppStatica,
            beforeEnter: [hasPermission],
            meta: { authorize: [roles.admin.role] , profiles: ['all'] },
          },{
            path:'/app/statistiche',
            name:'Andamento APP',
            component: AppStatistiche,
            beforeEnter: [hasPermission],
            meta: { authorize: [roles.admin.role] , profiles: ['all'] },
          },{
            path:'/app/pagamenti',
            name:'Pagamenti in APP',
            component: AppPagamenti,
            beforeEnter: [hasPermission],
            meta: { authorize: [roles.admin.role] , profiles: ['all'] },
          }, {
            path:'/app/logAccessi',
            name:'Accessi su app',
            component: AppLogAccessi,
            beforeEnter: [hasPermission],
            meta: { authorize: [roles.admin.role] , profiles: ['all'] },
          },
          {
            path:'/app/richiestaProva',
            name:'Richieste lezioni prova',
            component: AppRichiestaProva,
            beforeEnter: [hasPermission],
            meta: { authorize: [roles.admin.role] , profiles: ['all'] },
          },
          {
            path:'/app/verificaDocumenti',
            name:'Verifica Documenti',
            component: AppVerificaDocumenti,
            beforeEnter: [hasPermission],
            meta: { authorize: [roles.admin.role] , profiles: ['all'] },
          },
          {
            path:'/prenotazioni-senior',
            name:'Prenotazioni Senior',
            component: PrenotazioniSenior,
            beforeEnter: [hasPermission],
            meta: { authorize: [roles.admin.role] , profiles: ['all'] },
          },
          
          
     
        ]   
    }
];


const router = createRouter({
    // history: createWebHashHistory(),
    mode:'history',
    history: createWebHistory(),
    routes,
});



function hasPermission(to, from, next) {
  const { authorize, profiles } = to.meta;
  const role = 'admin';
  const userProfile = {all:1};

  if (authorize.length && !authorize.includes(role)) {
    console.log('access:denied')
      next({ path: '/' });
  } else {
    if (role == 'admin') {
      let allowed = profiles.some(profile => profile in userProfile && userProfile[profile] == 1)
      if (allowed) {
        next()
      } else {
        console.log('access:denied-profiles')
        next({ path: '/' });
      }      
    } else {
      next()
    }
  }
}




const setCookie = (name, cookie) => {
  if (cookie) {
    const thisdom = service.main_domain.cookie.replace(/^\.+/, '');
    Cookies.set(name, cookie,{ domain: thisdom, expires: 1 }) // 1 day  
  }
}

const getAddSourceCookie = () => {
  let cookie = null
  if (Cookies.get('ask_access_token')) {
    cookie =  Cookies.get('ask_access_token')
    setCookie('ask_access_token', cookie)
  }

  return cookie

}

// refresh accessToken when expired
import AuthRefresh from '@/services/auth/auth.refresh'
router.beforeEach((to, from, next) => {
    const path = to.path
    let cookie = getAddSourceCookie()
    const self = getCurrentInstance();

    const serviceVal = new Service(self)
    if (path!="/login")
    serviceVal.checkToken(cookie).then((res) => {
        if (res.status==1)
          next()  
        else{
          console.log("unauthorized");
          next({ name: 'Login' })
        }
    })
    else{
      next()  
    }

    // if (path != '/unauthorized' && cookie) {
    //     AuthRefresh.getLifetimeToken(cookie).then((secondToExpire)=>{
    //       if (secondToExpire < 300) {
    //         // console.log('scaduto')
    //         AuthRefresh.getNewAccessToken(cookie).then(() =>{
    //           next()
    //         })
    //       } else {
    //         next()
    //       }
    //     });
    // } else {
    //   next()
    // }
})

// Signin from cookie
import AuthService from './services/auth/auth.service';
router.beforeEach((to, from, next) => {
  let cookieToken = getAddSourceCookie()

  next()
  // if(cookieToken){
  //   if(to.name !== 'Unauthorized'){
   
  //     AuthService.loginUserFromCookie(JSON.stringify(cookieToken)).then((res)=>{

  //       if (res === true) {
  //         next()
  //       } else {
  //         next({ name: 'Unauthorized' })
  //       }     
  //     }).catch((err)=>{
  //       console.log(err)
  //       next({ name: 'Unauthorized' })
  //     })
  //   } else {
  //     next()
  //   }
  // }else{
  //   if(to.name !== 'Unauthorized'){
  //     next({ name: 'Unauthorized' })
  //   }else{
  //     next()
  //   }
    
  // }
})



export default router;
