<template>
  <Loader v-if="loading" />
  <Card>
    <template v-slot:title>
      <h3>Ultimi accessi alle APP</h3>
    </template>
    <template v-slot:content>
      <DataTable :value="logData" responsiveLayout="scroll">
        <Column field="creation_time" header="Data " />
        <Column field="nomeutente" header="Nome Utente" />
        <Column field="dispositivo" header="Dispositivo" />
        <Column></Column>
      </DataTable>
    </template>
  </Card>
</template>

<script>
import {ref, onMounted, getCurrentInstance } from 'vue';
import Loader from "@/components/Loader.vue";
import Service from '@/services/levante-api/levante-api';
import { useToast } from 'primevue/usetoast';
import moment from 'moment'

export default {
  components: {
    Loader,
  },
  setup() {
    const loading = ref(true);
    const logData = ref([]);
    const self = getCurrentInstance();
    self.$eventBus = self.appContext.config.globalProperties.$eventBus
    const service = new Service(self.$eventBus)

    const initData = () => {
      // restart var

      service.getAccessiApp().then((res) => {
        logData.value = res.items
        logData.value = logData.value.map(item => ({
          creation_time: moment(item.creation_time).format('DD/MM/Y HH:mm'),
          nomeutente: item.utente!=null?item.utente:"STAFF: "+item.personale,
          tipo:item.tipo,
          dispositivo: item.os === 0 ? 'Android' : 'iOS',
        }));
        console.log(logData.value);
      });

    }
    

    onMounted(() => { 
      loading.value = true
      const promises = [initData()]
      
      Promise.all(promises)
      .then(()=>{
          
      }).finally(()=>{
          loading.value = false
      })
    })
    return {
      loading,
      logData,
    };
  },
}
</script>

<style scoped>
/* Aggiungi gli stili per la tabella qui, se necessario */
</style>
