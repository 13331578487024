<template>
   <div v-if="confirmDialog.visible" class="confirm-dialog">
      <div class="confirm-dialog-content">
        <h3>Confermi di archiviare la richiesta</h3>
        <p>Sei sicuro di voler procedere?</p>

     
        <br/>
        <br/>
        <Button @click="confirmDialogAccept" label="Procedi" />
        <Button @click="confirmDialogReject" label="Annulla" class="p-button-secondary" />
      </div>
    </div>

  <Loader v-if="loading" />
  <Card>
    <template v-slot:title>
      <h3>Richieste per Attività extra</h3>
    </template>
    <template v-slot:content>
      <div class="col-12">
       
      </div>
      <div class="col-12 flex flex-column md:col-12">
            <DataTable :value="attivitaExtra" tableStyle="min-width: 50rem">
              <Column field="creation_time" header="Data creazione">
                <template #body="slotProps">
                  {{ moment(slotProps.data.creation_time).format('DD/MM/Y HH:mm') }}
                </template>
              </Column>
              <Column field="data" header="Data">
                <template #body="slotProps">
                  {{ moment(slotProps.data.data).format('DD/MM/Y') }}
                </template>
              </Column>
              <Column field="descrizione" header="Info"></Column>
              <Column field="atleta" header="Atleta">
                <template #body="slotProps">
                <p @click="goToAtleta(slotProps.data.id_atleta)" style="text-decoration: underline;">{{slotProps.data.atleta}}</p>
                </template>
              </Column>
              <Column field="nome" header="Nome"></Column>
              <Column field="cellulare" header="Telefono"></Column>
              <Column field="email" header="Email"></Column>
              
              <Column header="Gestito" field="approvato">
                <template #body="slotProps">
                  <Button @click="openConfirmDialog(slotProps.data.id, slotProps.data.approvato)" 
                          :label="(slotProps.data.approvato==0) ? 'NO' : 'SI'" 
                          :class="!(slotProps.data.approvato==0) ? 'p-button-success' : 'p-button-warning'"
                          />
                </template>
              </Column>
              <Column field="data" header="Data">
                <template #body="slotProps">
                  {{ slotProps.data.data_approvazione==null?'':moment(slotProps.data.data_approvazione).format('DD/MM/Y HH:mm') }}
                </template>
              </Column>
            </DataTable>
        </div>
    </template>
  </Card>
</template>

<script>
import {ref, onMounted, getCurrentInstance } from 'vue';
import Loader from "@/components/Loader.vue";
import Service from '@/services/levante-api/levante-api';
import { useToast } from 'primevue/usetoast';
import moment from 'moment'

export default {
  components: {
    Loader,
  },
  methods:{

    goToAtleta(idatleta){
        console.log(idatleta)
        window.open(`/atleta/${idatleta}/dettagli`, '_blank')
      },
  },
  setup() {
    const loading = ref(true);
    const attivitaExtra = ref([]);
    const self = getCurrentInstance();
    const toast = useToast();
    self.$eventBus = self.appContext.config.globalProperties.$eventBus
    const service = new Service(self.$eventBus)
    const lifeTime = 3000;

    const validationErrors = ref({})
    const confirmDialog = ref({
      visible: false,
      id: null,
      visibile: null,
    });



    const openConfirmDialog = (id, visibile) => {
      if (visibile==0){
      confirmDialog.value.visible = true;
      confirmDialog.value.id = id;
      confirmDialog.value.visibile = visibile;
      }
    };
    
    const confirmDialogAccept = () => {
      toggleApprove(confirmDialog.value.id, confirmDialog.value.visibile);
      confirmDialog.value.visible = false;
    };

    const confirmDialogReject = () => {
      toast.add({ severity: 'error', summary: 'Annullato', detail: 'Operazione annullata', life: 3000 });
      confirmDialog.value.visible = false;
    };
    const toggleApprove = async (id,approvato) => {
      approvato = approvato === 0 ? 1 : 0;
      loading.value = true;

      try {
        let fd = new FormData();  
        fd.append('id',id);
       
        await service.approvaAttivita(id,fd);  // assuming this endpoint exists
        toast.add({ severity: 'success', summary: 'Successo', detail: 'Richiesta aggiornata con successo',life: lifeTime});
        initData();
      } catch (error) {
        toast.add({ severity: 'error', summary: 'Errore', detail: 'Errore durante l\'aggiornamento della richiesta',life: lifeTime });
      } finally {
        loading.value = false;
      }
    };



    const initData = () => {
      // restart var

      service.getAttivita().then((res) => {
        attivitaExtra.value = res.items
        console.log(attivitaExtra.value);
        loading.value = false;
      });

      
    }

    onMounted(() => { 
      loading.value = true
      const promises = [initData()]
      
      Promise.all(promises)
      .then(()=>{
          
      }).finally(()=>{
          loading.value = false
      })
    })

    return {
      loading,
      attivitaExtra,
      validationErrors,
      moment,confirmDialog,
      openConfirmDialog,
      confirmDialogAccept,
      confirmDialogReject
    };
  },
}
</script>

<style scoped>
.confirm-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);
  z-index: 1000;
}

.confirm-dialog-content {
  text-align: center;
}

.confirm-dialog-content h3 {
  margin-top: 0;
}

.confirm-dialog-content button {
  margin: 10px;
}
</style>
