<template>
    <div class="page-atleti-detail">
      <atleti-detail />
    </div>
  </template>
  
  <script>
  import AtletiDetail from '@/components/details/AtletiDetail.vue'
  
  export default {
    name: 'ViewAtletiDetail',
    components: {
      AtletiDetail,
    },
  
    data() {
      return {
        pageName: 'AtletiDetail',
      }
    },
  
    created(){
  
    },
  
    mounted(){
      this.$eventBus.emit('page-change', {
        target: this,
        currentRoute: this.$router.currentRoute.value
      })
    },
    
  }
  </script>