<template>
  <Toast position="bottom-right" />

  <section class="mb-8">
    <div class="page-header pt-5 pb-11 m-3">
      <span class="mask bg-gradient-dark"></span>
      <div class="grid grid-nogutter">
        <div class="col-5 md:col-3 md:col-offset-7 col-offset-1 flex justify-content-start align-items-start"></div>
        <div class="col-12 flex justify-content-center mt-5">
          <img :src=getLogo height="100" />
        </div>
      </div>

    <div class="wrapper-form" style="margin-top:100px">
      <div class="grid">
        <div class="xl:col-4 lg:col-5 md:col-7 col-9 mx-auto">
          <div class="card">
            <div class="card-body">
              <center>Inserisci le tue credenziali</center>
              <form @submit.prevent="handleLogin" class="mt-4">
                <div class="mb-3">
                  <input
                    type="text"
                    v-model="user.email"
                    class="input-1"
                    placeholder="Email"
                  />
                </div>
                <div class="mb-3">
                  <input
                    type="password"
                    v-model="user.pwd"
                    class="input-1"
                    placeholder="Password"
                  />
                </div>
                <div class="text-center">
                  <button type="submit" class="btn-signin">
                    ACCEDI
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </section>
  <footer class="footer py-5">
    <div class="grid">
      <div class="col-8 mx-auto text-center mt-1">
        <p class="mb-0">©{{ currentYear }} made ASK Team Software</p>
      </div>
    </div>
  </footer>
</template>

<script>
import { useStore } from 'vuex'
import { computed, ref,getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import Service from '@/services/levante-api/levante-api'

export default {
  computed: {
	
  getLogo(){
    const LOGO = `${process.env.VUE_APP_LOGO}`;
    return LOGO
  }
},
  setup() {
    const toast = useToast()
    const router = useRouter()
    const user = ref({
      email: '',
      pwd: ''
    })
    const currentYear = computed(() => new Date().getFullYear())
    const self = getCurrentInstance();
    self.$eventBus = self.appContext.config.globalProperties.$eventBus
    const service = new Service(self.$eventBus)


    const handleLogin = async () => {
      console.log("handleLogin")
      console.log(user.value);
      let fd = new FormData()
      fd.append('login', JSON.stringify(user.value))
      for (let pair of fd.entries()) {
        console.log(pair[0] + ', ' + pair[1]);
      }
      try {
         await service.tryToLogin(fd).then((res) => {
          console.log(res);
          if (res == 503) {
            toast.add({ severity: 'error', summary: '503', detail: 'Servizio non disponibile', life: 3000 })
          } else if (res == null || !res.item.status) {
            toast.add({ severity: 'error', summary: 'Non autorizzato', detail: 'Utente sconosciuto', life: 3000 })
          } else {
            

            router.push('/')
          }
        })
      } catch (error) {
        console.log(error)
      }
    }

    return { currentYear, handleLogin, user }
  }
}
</script>

<style scoped>
.page-header {
  z-index: 1;
  height: 70%;
  min-height: 70vh;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  background-size: cover;
}

.mask {
  z-index: -2;
  position: absolute;
  background-size: cover;
  background-position: center center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background: #0098f0 !important;
  background: -webkit-linear-gradient(180deg, #0098f0 0%, #00f2c3 100%) !important;
  background: -o-linear-gradient(180deg, #0098f0 0%, #00f2c3 100%) !important;
  background: -moz-linear-gradient(180deg, #0098f0 0%, #00f2c3 100%) !important;
  background: linear-gradient(90deg, rgb(27, 51, 89) 0%, rgb(32, 89, 122) 100%) !important;
}

.btn-signin {
  all: unset;
  color: white;
  background-color: #000;
  display: block;
  width: 100%;
  padding: 14px 1px;
  border-radius: 0.5rem;
  font-size: 12px;
  font-weight: bold;
}

.input-1 {
  box-sizing: border-box;
  height: 50px;
  width: 100%;
  border-radius: 4px;
  color: #202124;
  border: 1px solid #dadce0;
  padding: 13px 15px;
  transition: 250ms;
}

.input-1:focus {
  outline: none;
  border: 2px solid #1a73e8;
  transition: 25ms;
}

.input-1::-webkit-input-placeholder { /* Edge */
  color: #8180786e;
}

.input-1:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #8180786e;
}

.input-1::placeholder {
  color: #8180786e;
}

.footer {
  color: #8392AB !important;
}

@media (min-height: 400px) and (max-height: 700px) {
  .card {
    margin-top: 50px;
  }
}
</style>
