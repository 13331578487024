<template>
   <div v-if="confirmDialog.visible" class="confirm-dialog">
      <div class="confirm-dialog-content">
        <h3>Conferma la richiesta</h3>
        <p>Sei sicuro di voler procedere?</p>
        <Button @click="confirmDialogAccept" label="Procedi" />
        <Button @click="confirmDialogReject" label="Annulla" class="p-button-secondary" />
      </div>
    </div>
  <Loader v-if="loading" />
  <Card>
    <template v-slot:content>
      <div  class="p-3">
        <Button
          v-for="doc in docs"
          :key="doc.id"
          :label="doc.nome"
          :class="['p-button', { 'p-button-danger': doc.id === type_doc }]"
          style="margin-right: 10px"
          @click="setDocType(doc)"
        />
      </div>
    </template>
  </Card>
  <Card>

    <template v-slot:title>
      <h3>Documenti da verificare: {{ nome_doc }}</h3>
    </template>
    <template v-slot:content>
      <div class="padding:20px" v-if="logData.length==0">Non ci sono documenti da validare</div>
      <DataTable v-else :value="logData" responsiveLayout="scroll">
        <Column field="creation_time" header="Data invio" />
        <Column field="nomeutente" header="Nome Utente" />
        <Column field="file" header="Documento">
          <template #body="{data}">
              <Button severity="help" @click="getDocUrl(data.path)" >{{ data.nome }}</Button>
          </template>
        </Column>
        <Column field="tipo" header="Tipologia" />
        <Column field="datascadenza" header="Data scadenza" />
        <Column field="dispositivo" header="Dispositivo" />
        <Column field="approvato" header="Approvato" >
          <template #body="slotProps">
            <Button @click="openConfirmDialog(slotProps.data.id, slotProps.data.approvato)" 
                    :label="slotProps.data.approvato==0 ? 'NO' : 'SI'" 
                    :class="!slotProps.data.approvato==0 ? 'p-button-success' : 'p-button-warning'"
                    />
          </template>
        </Column>
        <Column field="data_approvazione" header="Data approvazione" />
        <Column></Column>
      </DataTable>
    </template>
  </Card>

  <Card style="margin-top:10px">
      <template v-slot:title>
        <h3>Documenti di tipo "{{ nome_doc }}" per gruppo </h3>
      </template>
      <template v-slot:content>
        <Accordion :groups="logDataGruppi" />
      </template>
    </Card>
</template>

<script>
import {ref, onMounted, getCurrentInstance } from 'vue';
import Loader from "@/components/Loader.vue";
import Service from '@/services/levante-api/levante-api';
import { useToast } from 'primevue/usetoast';
import moment from 'moment'
import Card from '@/components/AccordionCardGroup.vue';
import Accordion from '@/components/AccordionGroup.vue';

export default {
  components: {
    Loader,
    Card,
    Accordion,
  },
  setup() {
    const loading = ref(true);
    const logData = ref([]);
    const logDataGruppi = ref([]);
    const docs = ref();
    const toast = useToast();
    const type_doc = ref(1)
    const nome_doc=ref("")
    const self = getCurrentInstance();
    const lifeTime = 3000;
    self.$eventBus = self.appContext.config.globalProperties.$eventBus
    const service = new Service(self.$eventBus)
    const getDocUrl = (imgPath) => {
            window.open( `${process.env.VUE_APP_LEVANTE_API_URL}/../storage/app/public/${imgPath}`);
        }

        const setDocType = (doc) => {
          type_doc.value = doc.id
          nome_doc.value = doc.nome
          initData()
        }
           
    const initData = () => {
      // restart var

      service.getDocumentiApp(type_doc.value).then((res) => {
        logData.value = res.items
        logData.value = logData.value.map(item => ({
          creation_time: moment(item.creation_time).format('DD/MM/Y H:m'),
          data_approvazione: item.data_approvazione!=null?moment(item.data_approvazione).format('DD/MM/Y HH:mm:ss'):'',
          nomeutente: item.atleta,
          datascadenza:moment(item.datascadenza).format('DD/MM/Y'),
          tipo:item.tipo,
          file:item.file,
          path:item.path,
          nome:item.nome,
          id:item.id_documento,
          approvato:item.approvato,
          dispositivo: item.os === 0 ? 'Android' : 'iOS',
        }));
        console.log(logData.value);
      });

      service.getDocumentiGruppo(type_doc.value).then((res) => {
        logDataGruppi.value = res.items
      });
    }

    const getTypesofFiles = async () => {
            await service.getTypesOfFiles()
            .then((res) => {
              docs.value = res.items
              nome_doc.value = docs.value[0].nome
              type_doc.value=docs.value[0].id
            })
        }
    

    onMounted(() => { 
      loading.value = true
      const promises = [getTypesofFiles(),initData()]
      
      Promise.all(promises)
      .then(()=>{
          
      }).finally(()=>{
          loading.value = false
      })
    })


    const confirmDialog = ref({
      visible: false,
      id: null,
      visibile: null
    });


    const openConfirmDialog = (id, visibile) => {
      confirmDialog.value.visible = true;
      confirmDialog.value.id = id;
      confirmDialog.value.visibile = visibile;
    };
    
    const confirmDialogAccept = () => {
      toggleApprove(confirmDialog.value.id, confirmDialog.value.visibile);
      confirmDialog.value.visible = false;
    };

    const confirmDialogReject = () => {
      toast.add({ severity: 'error', summary: 'Annullato', detail: 'Operazione annullata', life: 3000 });
      confirmDialog.value.visible = false;
    };
    const toggleApprove = async (id,approvato) => {
      approvato = approvato == 0 ? 1 : 0;
      loading.value = true;

      try {
        let fd = new FormData();  
        fd.append('approvato',approvato);
        await service.updateApprovaDocumento(id,fd);  // assuming this endpoint exists
        toast.add({ severity: 'success', summary: 'Successo', detail: 'Richiesta aggiornata con successo',life: lifeTime});
        initData();
      } catch (error) {
        toast.add({ severity: 'error', summary: 'Errore', detail: 'Errore durante l\'aggiornamento della richiesta',life: lifeTime });
      } finally {
        loading.value = false;
      }
    };
    return {
      loading,logDataGruppi,
      logData,confirmDialog,
      openConfirmDialog,
      confirmDialogAccept,
      confirmDialogReject,getDocUrl,setDocType,
      docs,type_doc,nome_doc
    };
  },
}
</script>

<style scoped>
.confirm-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);
  z-index: 1000;
}

.confirm-dialog-content {
  text-align: center;
}

.confirm-dialog-content h3 {
  margin-top: 0;
}

.confirm-dialog-content button {
  margin: 10px;
}
</style>
