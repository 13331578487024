<template>
  <Loader v-if="loading" />
     <Card style="margin-bottom:10px">

    <template v-slot:content>
          <button  class="highlight-btn p-button p-button-danger" @click="changeData()">Mostra {{ showAllText }}</button>
        
    </template>
    </Card>


    <Card style='margin-bottom:10px' v-if="viewShowAll">
    <template v-slot:title>
      <h3>Pagamenti in APP</h3>
    </template>
    <template v-slot:content  v-if='logDataAll.length>0'>
      <DataTable :value="logDataAll" responsiveLayout="scroll">
        <Column field="creation_time" header="Data pagamento" />
        <Column field="nomeutente" header="Nome Utente" />
        <Column field="datadinascita" header="Data di nascita" />
        <Column field="descrizione" header="Descrizione" />
        <Column field="importo" header="Importo" />
        <Column field="dispositivo" header="Dispositivo" />
       
      </DataTable>
    </template>
    <template v-slot:content v-else>
      Non ci sono movimenti da verificare
    </template>
  </Card>





  <Card style='margin-bottom:10px' v-if="!viewShowAll">
    <template v-slot:title>
      <h3>Pagamenti in APP da verificare</h3>
    </template>
    <template v-slot:content  v-if='logData.length>0'>
      <DataTable :value="logData" responsiveLayout="scroll">
        <Column field="creation_time" header="Data pagamento" />
        <Column field="nomeutente" header="Nome Utente" />
        <Column field="datadinascita" header="Data di nascita" />
        <Column field="descrizione" header="Descrizione" />
        <Column field="importo" header="Importo" />
        <Column field="dispositivo" header="Dispositivo" />
        <Column field="verificato" header="Verificato">
          <template #body="slotProps">
            <Button 
              @click="openConfirmDialog(slotProps.data.id)"
              :label="slotProps.data.verificato" 
              :class="slotProps.data.verificato !== 'SI' ? 'p-button-success' : 'p-button-warning'"
            />
          </template>
        </Column>
      </DataTable>
    </template>
    <template v-slot:content v-else>
      Non ci sono movimenti da verificare
    </template>
  </Card>

  <!-- Dialog di conferma per il cambio di stato Verificato -->
  <div v-if="confirmDialog.visible" class="confirm-dialog">
      <div class="confirm-dialog-content">
        <h3>Conferma la richiesta</h3>
        <p>Sei sicuro di voler procedere?</p>

       
        <br/>
        <br/>
        <Button @click="confirmDialogAccept" label="Procedi" />
        <Button @click="confirmDialogReject" label="Annulla" class="p-button-secondary" />
      </div>
    </div>



    <Card v-if="!viewShowAll">
    <template v-slot:title>
      <h3>Pagamenti in APP da contabilizzare</h3>
    </template>
    <template v-slot:content v-if='logDataUsed.length>0'>
      <DataTable :value="logDataUsed" responsiveLayout="scroll">
        <Column field="creation_time" header="Data pagamento" />
        <Column field="nomeutente" header="Nome Utente" />
        <Column field="datadinascita" header="Data di nascita" />
        <Column header="Descrizione">
      <template #body="slotProps">
        <span> {{slotProps.data.descrizione}}  
          <template v-for="match in highlightNames(slotProps.data.descrizione, atleti)" :key="match.id">
            <!-- Usa un pulsante Vue per gestire correttamente il binding degli eventi -->
            <button 
              class="highlight-btn p-button p-button-success"
              @click="openConfirmDialogUsed(match.id,slotProps.data.id)"
            >
           {{ match.text }}
            </button>
          </template>
        </span>
      </template>
    </Column>
        <Column field="importo" header="Importo" />
        <Column field="dispositivo" header="Dispositivo" />
        <Column field="used" header="Associato">
        </Column>
        <Column field="used" header="">
          <template #body="slotProps">
            <Button 
              @click="openConfirmDialogUsed(null,slotProps.data.id)"
              label="Gestisci" 
              :class="'p-button-warning'"
            />
          </template>
        </Column>
      </DataTable>
    </template>
    <template v-slot:content v-else>
      Non ci sono movimenti da contabilizzare
    </template>
  </Card>


  <div v-if="confirmDialogUsed.visible" class="confirm-dialog">
      <div class="confirm-dialog-content">
        <h3>Conferma la richiesta</h3>
        <p>Sei sicuro di voler procedere?</p>
        
        <div class="field" v-if="!pagamento.id_atleta">
          <label for="type">Scegli l'atleta</label>
          <Dropdown v-model="pagamento.atleta" :options="atleti" filter optionLabel="nomecompleto" class="text_toupper inputfield w-full" @change="changeAtleta($event)" />
                            
        </div>
        <DataTable :value="dapagare" class="p-datatable-gridlines" v-model:selection="pagamento.richieste_pagamenti" :metaKeySelection="false"
                        @rowSelect="onRowSelect" @rowUnselect="onRowSelect" selectionMode="multiple" >
                        <Column field="corso" header="Descrizione">
                            <template #body="{data}">
                                {{ data.n_rata ? 'Rata n. ' + data.n_rata + ' del corso ' + data.corso : '' }}
                                {{ data.kit ? 'Kit ' + data.kit : '' }}
                                {{ data.prodotto ? data.prodotto : '' }}
                            </template>
                     </Column>
                            <Column field="importo" header="Importo €" style='text-align: right'></Column>
                            <template #footer v-if="viewSetPagamento">
                                <div>
                                  
                                    <div class="col-12 flex justify-content-between" style="font-size: 2rem;">
                                        <div class="col-6 flex justify-content-between">
                                            <p>Totale: € {{ pagamento.totale }}</p>
                                        </div>
                                        <div class="col-6 flex justify-content-end">
                                            <Button class="p-button-success m-3" icon="pi pi-check" label="Completa pagamento" @click="sendPagamento" :disabled="canSavePagamento"/>
                                            <Button class="p-button-info m-3" icon="pi pi-file-pdf" label="Visualizza ricevuta" @click="viewRicevuta" v-if="id_pagamento"/>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </DataTable>
       
        <br/>
        <br/>
        <Button @click="confirmDialogRejectUsed" label="Annulla" class="p-button-secondary" />
      </div>
    </div>




</template>

<script>
import {ref, onMounted, getCurrentInstance,computed} from 'vue';
import Loader from "@/components/Loader.vue";
import Service from '@/services/levante-api/levante-api';
import { useToast } from 'primevue/usetoast';
import moment from 'moment'

export default {
  components: {
    Loader,
  },
  setup() {
 
    const loading = ref(true);
    const logData = ref([]);
    const logDataAll = ref([]);
    const dapagare = ref([]);
    const atleti = ref([]);
    const logDataUsed = ref([]);
    const self = getCurrentInstance();
    self.$eventBus = self.appContext.config.globalProperties.$eventBus
    const service = new Service(self.$eventBus)
    const toast = useToast();
    const confirmDialogVisible= ref(false);
    const userSelectionDialogVisible = ref(false);
    const selectedRecord = ref();
    const lifeTime = 3000;
    const showAll = ref(false)
    const showAllText = ref("tutti i pagamenti")
    const showAllTextAlt = ref("da contabilizzare")
    const confirmDialog = ref({
      visible: false,
      id: null,
      visibile: null,
      selectedCorso: null
    });
    const pagamento = ref({
            'idstripe':null,
            'richieste_pagamenti': [],
            'type_pay': 5,
            'atleta':null,
            'totale': 0,
            'id_atleta': null
        })
    const confirmDialogUsed = ref({
      visible: false,
      id: null,
      visibile: null,
      selectedCorso: null
    });
    const changeData = () => {
        console.log("changeData")
        showAll.value=!showAll.value
        if (showAll.value){
            showAllText.value="da contabilizzare";
            showAllTextAlt.value="contabilizzati"
        }else{
            showAllText.value="contabilizzati"
            showAllTextAlt.value="da contabilizzare"
        }
        initData()
    }
    const openConfirmDialogUsed = async (id, idstripe) => {
      console.log("openConfirmDialogUsed");
      
      console.log(id);
      pagamento.value.idstripe = idstripe
      if (id){
        pagamento.value.id_atleta = id
        await service.getDaPagare('atleta', id)
        .then((res) => {
          dapagare.value=res.items
          confirmDialogUsed.value.visible = true;
          confirmDialogUsed.value.id = id;

        });
      }else{
        console.log("ciao");
        pagamento.value.id_atleta = null
        pagamento.value.atleta = null
        confirmDialogUsed.value.visible = true;
      }
    };
    const sendPagamento = (id) => {
            loading.value = true
            let fd = new FormData();
            if (pagamento.value.atleta!=null){
              pagamento.value.id_atleta=pagamento.value.atleta.id
            }
            fd.append('pagamento',JSON.stringify(pagamento.value));
            
            service.sendPagamento(fd)
            .then((res) => {
             
                let severity = res.item.esito
                let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
                let detail = res.item.messaggio
                if (summary=='Successo'){
                  service.setStripeUsed(fd,pagamento.value.idstripe).then((res)=>{
                    confirmDialogUsed.value.visible = false;
                    initData();
                  })
                }
                if (severity != 'warning') {

                    toast.add({severity:severity, summary: summary, detail:detail,life:lifeTime});  
                                       
                }
            }).finally(()=>{
                loading.value = false
            })
        }
    const setTotaleVendita = () => {
            let totale = 0
            pagamento.value.richieste_pagamenti.forEach(prodotto => {
                totale += prodotto.importo
            })
            pagamento.value.totale = totale
        }

        const onRowSelect = (event) => {
            setTotaleVendita()
            if (!Object.keys(pagamento.value.richieste_pagamenti).length) {
                pagamento.value.type_pay = null
                
            }
        }

        const viewShowAll = computed(()=>{
            return showAll.value
        })
    const viewSetPagamento = computed(()=>{
            return Object.keys(pagamento.value.richieste_pagamenti).length
        })
    const highlightNames = (description, atleti) => {
      const matches = [];
      if (!description) return ''; // Restituisce una stringa vuota se la descrizione è undefined o null.
        description=description.replace("  "," ");
        atleti.forEach(atleta => {
            const nomeCompleto = atleta.nomecompleto.trim();
            const nomeCognome = `${atleta.nome.trim()} ${atleta.cognome.trim()}`;
            const cognomeNome = `${atleta.cognome.trim()} ${atleta.nome.trim()}`;

            // Crea la regex per cercare le varianti nel testo (case insensitive)
            const regexNomeCompleto = new RegExp(nomeCompleto, 'gi');
            const regexNomeCognome = new RegExp(nomeCognome, 'gi');
            const regexCognomeNome = new RegExp(cognomeNome, 'gi');

                       // Cerca le occorrenze e aggiungi le corrispondenze alla lista
        if (regexNomeCompleto.test(description)) {
            matches.push({ text: nomeCompleto, id: atleta.id });
        }
        if (regexNomeCognome.test(description)) {
            matches.push({ text: nomeCognome, id: atleta.id });
        }
        if (regexCognomeNome.test(description)) {
            matches.push({ text: cognomeNome, id: atleta.id });
        }  // Sostituisce le occorrenze trovate con una versione evidenziata

        });
        return matches;

}
    const confirmDialogAcceptUsed = () => {
      toggleApprove(confirmDialog.value.id, confirmDialog.value.visibile,confirmDialog.value.selectedCorso);
      confirmDialogUsed.value.visible = false;
    };

    const confirmDialogRejectUsed = () => {
      toast.add({ severity: 'error', summary: 'Annullato', detail: 'Operazione annullata', life: 3000 });
      confirmDialogUsed.value.visible = false;
    };
    const openConfirmDialog = (id, visibile,selectedCorso) => {
      confirmDialog.value.visible = true;
      confirmDialog.value.id = id;
      confirmDialog.value.visibile = visibile;
      console.log(selectedCorso)
    };
    
    const confirmDialogAccept = () => {
      toggleApprove(confirmDialog.value.id, confirmDialog.value.visibile,confirmDialog.value.selectedCorso);
      confirmDialog.value.visible = false;
    };

    const confirmDialogReject = () => {
      toast.add({ severity: 'error', summary: 'Annullato', detail: 'Operazione annullata', life: 3000 });
      confirmDialog.value.visible = false;
    };
    const toggleApprove = async (id,approvato,selectedCorso) => {
      approvato = approvato === 0 ? 1 : 0;
      loading.value = true;

      try {
        let fd = new FormData();  
        fd.append('approvato',approvato);
        fd.append('id',id);
        
        await service.updateApprovaStripe(fd,id);  // assuming this endpoint exists
        toast.add({ severity: 'success', summary: 'Successo', detail: 'Richiesta aggiornata con successo',life: lifeTime});
        initData();
      } catch (error) {
        toast.add({ severity: 'error', summary: 'Errore', detail: 'Errore durante l\'aggiornamento della richiesta',life: lifeTime });
      } finally {
        loading.value = false;
      }
    };

    const getAtleti = async () => {
      await service.getAtleti()
      .then((res) => {
        atleti.value = res.items
        initData();
      })
    }

    const initData = () => {
      // restart var
      if (!showAll.value){
      service.getPagamentiApp().then((res) => {
        logData.value = res.items
        logData.value = logData.value.map(item => ({
          creation_time: moment(item.creation_time).format('DD/MM/Y H:m'),
          nomeutente: item.atleta,
          datadinascita:moment(item.datadinascita).format('DD/MM/Y'),
          descrizione:item.descrizione,
          importo:item.importo,
          verificato:item.verificato=='0'?'NO':'SI',
          used:item.used==0?'NO':'SI',
          id:item.id,
          dispositivo: item.os == '0' ? 'Android' : 'iOS',
        }));
      });

      service.getPagamentiAppVerified().then((res) => {
        logDataUsed.value = res.items
        logDataUsed.value = logDataUsed.value.map(item => ({
          creation_time: moment(item.creation_time).format('DD/MM/Y H:m'),
          nomeutente: item.atleta,
          datadinascita:moment(item.datadinascita).format('DD/MM/Y'),
          descrizione:item.descrizione,
          importo:item.importo,
          id:item.id,
          verificato:item.verificato=='0'?'NO':'SI',
          used:item.used==0?'NO':'SI',
          dispositivo: item.os == '0' ? 'Android' : 'iOS',
        }));
      });
    }else{

      service.getPagamentiAppAll().then((res)=>{
        logDataAll.value=res.items;
        logDataAll.value = logDataAll.value.map(item => ({
          dispositivo: item.os == '0' ? 'Android' : 'iOS',
          creation_time: moment(item.creation_time).format('DD/MM/Y H:m'),
          nomeutente: item.atleta,
          datadinascita:moment(item.datadinascita).format('DD/MM/Y'),
          descrizione:item.descrizione,
          importo:item.importo,

        }))
      })
    }
    }
    const changeAtleta = async(event) => {
      console.log("changeAtleta");
      console.log(event)
      await service.getDaPagare('atleta',event.value.id)
        .then((res) => {
          dapagare.value=res.items
          confirmDialogUsed.value.visible = true;

        });
    }

    onMounted(() => { 
      loading.value = true
      const promises = [getAtleti(),]
      
      Promise.all(promises)
      .then(()=>{
          
      }).finally(()=>{
          loading.value = false
      })
    })
    return {
      confirmDialog,
      viewShowAll,
      logDataAll,
      openConfirmDialog,
      confirmDialogAccept,
      confirmDialogReject,
      highlightNames,
      viewSetPagamento,
      loading,
      confirmDialogUsed,
      sendPagamento,
      openConfirmDialogUsed,
      confirmDialogAcceptUsed,
      confirmDialogRejectUsed,
      selectedRecord,
      changeData,
      showAllText,
      dapagare,pagamento,onRowSelect,
      logData,logDataUsed,atleti,changeAtleta
    };
  },
}
</script>

<style scoped>
.confirm-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);
  z-index: 1000;
}

.confirm-dialog-content {
  text-align: center;
}

.confirm-dialog-content h3 {
  margin-top: 0;
}

.confirm-dialog-content button {
  margin: 10px;
}
.p-button{
  cursor: pointer;
}
/* Aggiungi gli stili per la tabella qui, se necessario */
</style>
