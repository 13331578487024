import { createStore } from 'vuex'
import { auth } from "./auth.module";

export default createStore({
  state: { 
    statusSII: null, 
    all:{
      bozzaId:0,
      data:{
          "step": "",
          "realod": false,
          "wizard": "",
          "azienda": "",
          "pageIndex": 0,
          "cliente": {
            "data": {
              "cap": "",
              "pec": "",
              "name": "",
              "note": "",
              "email": "",
              "phone": "",
              "score": "",
              "cityId": "",
              "civico": "",
              "mobile": "",
              "address": "",
              "surname": "",
              "cityName": "",
              "district": "",
              "toponimo": "",
              "cod_fiscale": "",
              "cod_fiscale_azienda": "",
              "partita_iva": "",
              "rag_sociale": '',
              "tipo_client": "",
              "codice_ateco": "",
              "rappresentanteId": 0,
              "tipo_client_name": "",
              "rappresentanteName": "",
              "codice_destinatario": ""
            },
            "type": ""
          },
          "contratto": {
            "rid": {
              "iban": "",
              "sottoscrittore": "",
              "codiceFiscaleCc": "",
              "intestazione": "",
              "istituto": "",
              "id": 0,
              "selectedTypeRid": {},
              "tipo_mandato": "",
              "dataFirmaRid": "",
              "dataRataRid": "",
            },
            "note": "",
            "dataFine": "",
            "dataFirma": "",
            "dataInizio": "",
            "reseller": "",
            "agente": "",
            "rinnovo": false,
            "documentazione": false,
            "iva": {
            },
            "regime": {
            },
            "offerta": {
            },
            "periodo": {
            },
            "tipoCtr": {
            },
            "scadenza": {
            },
            "pagamento": {
            },
            "spedizione": {
            },
            "acquisizione": {
            },
            "fatturazione": {
            }
          },
          "fornitura_power": {
            "data": {
              "cap": "",
              "pod": "",
              "note": "",
              "civico": "",
              "address": "",
              "voltaggio": "",
              "cod_fiscale": "",
              "partita_iva": "",
              "potenza_imp": "",
              "uso": {
              },
              "potenza_disp": "",
              "cityId": "",
              "consumo_annuo": "",
              "stato": {
              },
              "stima": {
              },
              "selectedCityName": "",
              "selectedDistrict": "",
              "tensione": {
              },
              "toponimo": "",
              "residente": {
              },
              "misuratore": {
              },
              "selectedRegionName": "",
              "distributore": {
              }
            }
          },
          "fornitura_gas":{
            "data":{
              "pdr": "",
              "toponimo": "",
              "address": "",
              "civico": "",
              "cap": "",
              "selectedDistrict": "",
              "cityId": "",
              "selectedCityName": "",
              "consumo_annuo": 0,
              "remi": "",
              "matricola": "",
              "uso": {
              },
              "classe": "",
              "categoria": {
              },
              "cod_fiscale": "",
              "partita_iva": "",
              "note": ""
            }
          },
          "rappresentante": {
            "id_existent": "",
            "cap": "",
            "pec": "",
            "name": "",
            "birth": "",
            "gender": "",
            "selectedBirthCity": "",
            "email": "",
            "phone": "",
            "civico": "",
            "mobile": "",
            "address": "",
            "surname": "",
            "cod_fiscale": "",
            "cityId": "",
            "selectedCityName": "",
            "selectedDistrict": "",
            "selectedRegionId": "",
            "toponimo": "",
            "selectedRegionName": "",
            "type": ""
          },
          "contatto":{
            "toponimo": "",
            "address": "",
            "civico": "",
            "cap": "",
            "mobile": "",
            "phone": "",
            "email": "",
            "pec": "",
            "selectedDistrict": "",
            "cityId": "",
            "selectedCityName": "",
          },
          'files': []
      }
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
  }
})
