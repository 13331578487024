<template>
  <Loader v-if="loading" />   
  <div class="flex">
    <Card class="col-6">
      <template v-slot:title>
        Crea Personale
      </template>
      <template v-slot:content>
        <div class="m-2">
          <div class="formgrid grid mt-4">
            <!-- Cognome Utente -->
            <div class="p-field col-12 md:col-6">
              <label for="cognome">Cognome</label><br>
              <InputText id="cognome" v-model="utente.cognome" :class="['inputfield w-full',{'p-invalid': validationErrors.cognome && submitted}]" @input="(val) => (utente.cognome = utente.cognome.toUpperCase())" @change="onChangeInput($event, 'cognome')"/>
              <small v-show="validationErrors.cognome && submitted" class="p-error">Campo richiesto.</small>
            </div>
            
            <!-- Nome Utente -->
            <div class="p-field col-12 md:col-6">
              <label for="nome">Nome</label><br>
              <InputText id="nome" v-model="utente.nome" :class="['inputfield w-full',{'p-invalid': validationErrors.nome && submitted}]" @input="(val) => (utente.nome = utente.nome.toUpperCase())" @change="onChangeInput($event, 'nome')"/>
              <small v-show="validationErrors.nome && submitted" class="p-error">Campo richiesto.</small>              
            </div>
            
            <!-- Codice fiscale Utente -->
            <div class="p-field col-12 md:col-6">
              <label for="indirizzo">Codice fiscale</label><br>
              <InputMask v-model="utente.cf" mask="aaaaaa99a99a999a" autofocus :class="['text_toupper inputfield w-full',{'p-invalid': validationErrors.cf && submitted}]" @change="onChangeInput($event, 'cf')"/>
              
              <div v-show="validationErrors.cf && submitted" class="p-error">Campo richiesto.</div>
            </div>
            
            <!-- Gender Utente -->
            <div class="p-field col-12 md:col-6 flex flex-column justify-content-center align-items-center">
              <label class="mb-3
              ">Tipologia</label>                 
              
              <div class="flex justify-content-between">
                <div v-for="type in types" :key="type.id"  class="flex align-items-center">
                  <Checkbox v-model="utente[type.nome]" :id="type.nome" :binary="true"/>
                  <label :for="type.nome" class="ml-2 mr-2">{{ type.nome }}</label>
                </div>
                
              </div>
              <div v-show="validationErrors.gender && submitted" class="p-error">Campo richiesto.</div>
            </div>
            
            <!-- Selezione tipologia corso -->
            <div class="p-field col-12">
              <label>Tipologia corso</label><br>
              
              <div class="card flex flex-wrap justify-content-start">
                <div v-for="(type_corso, index) in types_corso" :key="index">
                  <ToggleButton v-model="type_corso.value" class="w-8rem m-2 h-4rem" :onLabel="type_corso.nome" :offLabel="type_corso.nome"  @click="delete validationErrors.some_corsi"/>
                </div>
              </div>
              <small v-show="validationErrors.some_corsi && submitted" class="p-error">Seleziona almeno un corso</small>
            </div>
            
            <!-- Bottone salva -->
            <div class="mt-5 col-12 flex justify-content-end">
              <Button class="p-button-success" icon="pi pi-check" label="Salva" @click="sendDataNewPersonale"/>
            </div>
          </div>
        </div>
      </template>
    </Card>

    <Card class="col-6">
      <template v-slot:title>
        Elenco personale
      </template>
      <template #content>
        <div v-if="existent_personale">
          <Listbox :options="existent_personale" filter optionLabel="nome_completo" :virtualScrollerOptions="{ itemSize: 38 }" class="w-full" listStyle="height:600px" />
        </div>
        <div v-else>
          <div class="w-full xl:w-6 p-3">
                <Skeleton class="mb-2"></Skeleton>
                <Skeleton width="10rem" class="mb-2"></Skeleton>
                <Skeleton width="5rem" class="mb-2"></Skeleton>
                <Skeleton height="2rem" class="mb-2"></Skeleton>
                <Skeleton width="10rem" height="4rem"></Skeleton>
            </div>
        </div>
      </template>

    </Card>
  </div> 
</template>

<script>
import {ref, onMounted, getCurrentInstance, onUpdated, computed } from 'vue';
import Loader from "@/components/Loader.vue"
import Service from '@/services/levante-api/levante-api'
import moment from 'moment'
import { useToast } from 'primevue/usetoast';

export default {
  components: {
    Loader,
  },
  
  setup () { 
    const self = getCurrentInstance();
    const toast = useToast();
    self.$eventBus = self.appContext.config.globalProperties.$eventBus
    const service = new Service(self.$eventBus)
    const submitted = ref(false)
    const loading = ref(false)
    const utente = ref({})
    const validationErrors = ref({})
    const types = ref()
    const types_corso = ref()
    const existent_personale = ref()
    
    const getTypePersonale = async () => {
      await service.getTypePersonale()
      .then((res) => {
        types.value = res.items
        utente.value.type = types.value[0].nome
      })
    }

    const getAllPersonale = async () => {
      await service.getAllPersonale()
      .then((res) => {
        existent_personale.value = res.items
        existent_personale.value.forEach(item => {
          let nome_tipo_personale =item.is_responsabile==1?'Responsabile':''
          nome_tipo_personale +=item.is_allenatore==1?' Allenatore ':''
          let type_corso = []
          Object.keys(item.types_corso).forEach(item_type => {

            type_corso.push(types_corso.value.find(type => type.id == item.types_corso[item_type]).nome)
          });
          item.nome_completo = `${item.cognome} ${item.nome} - ${nome_tipo_personale} - ${type_corso.join(', ')}`
        });

      })
    }

    const onChangeInput = (evt, prop) => {
      let value
      switch (prop) {
        case 'id_utente':
        value = evt.value
        break;
        
        default:
        value = evt.target.value
        break;
      }
      if (value == '' || value == null) {
        validationErrors.value[prop] = true
      }else{
        delete validationErrors.value[prop]
      }
    }
    
    const validateForm = () => {
      // Validazione Utente
      let validate_properties = [
      'nome',
      'cognome',
      'cf',
      'type'
      ]
      
      validate_properties.forEach( prop => {
        if (utente.value[prop] == '' || utente.value[prop] == null) {
          validationErrors.value[prop] = true
        } else {
          delete validationErrors.value[prop];
        }
      })
      // Fine Validazione Utente
      
      // Validazione selezione corsi
      if(types_corso.value.some(corso => corso.value)){
        delete validationErrors.value.some_corsi
      }else{
        validationErrors.value.some_corsi = true
      }
      // Fine Validazione selezione corsi
      
      
      return !Object.keys(validationErrors.value).length;
    }
    
    const sendDataNewPersonale = async () => {
      submitted.value = true;
      validationErrors.value = []
      if(validateForm()){
        loading.value = true
        let fd = new FormData();        
        utente.value.type_corso = types_corso.value.filter(corso => corso.value)
        fd.append('utente', JSON.stringify(utente.value));
        
        service.sendDataNewPersonale(fd)
        .then((res) => {
          let severity = res.item.esito
          let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
          let detail = res.item.messaggio
          if(res.item.esito == 'success'){
            initData()
          }

          toast.add({severity:severity, summary: summary, detail:detail});
        }).finally(() => {
          loading.value = false
        })
      }else{
        toast.add({severity:'warn', summary: 'Attenzione', detail:`Ci sono dei dati mancanti`});
      }
    }
    
    const initData = async () => {
      // restart var
      loading.value = true

      utente.value = {}
      Promise.all([
        getTypePersonale(),
        getTypeCorso(),
        getAllPersonale()
      ]).then(() => {
        loading.value = false
      })
    }
    
    const getTypeCorso = async () => {
      await service.getTypeCorsoPersonale()
      .then((res) => {
        res.items.forEach(item => {
          item.value = Boolean(item.value)
        })
        types_corso.value = res.items
      })
    }
    
    
    onMounted(() => { 
      initData()
    })
    
    return {
      loading,
      submitted,
      utente,
      validationErrors,
      types,
      types_corso,
      moment,
      sendDataNewPersonale,
      onChangeInput,
      getTypePersonale,
      existent_personale,
      getAllPersonale,
    }
  },
}
</script>

<style>
.text_toupper{
  text-transform: uppercase;
} 
</style>
<style scoped>
#container-atleti{
  display: block;
}
.layout-main-container{
  width: fit-content !important;
}
.btn-error-filter{
  width: 200px;
}
</style>
