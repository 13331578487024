<template>
  <Loader v-if="loading" />
  <Card>
    <template v-slot:title>
      <h3>Statistiche Mensili</h3>
    </template>
    <template v-slot:content>
      <div class="charts-container">
        <div class="chart">
          <Chart type="bar" :data="userChartData" :options="chartOptions" />
        </div>
        <div class="chart">
          <Chart type="bar" :data="revenueChartData" :options="chartOptions" />
        </div>
        <div class="chart">
          <Chart type="bar" :data="attendanceChartData" :options="chartOptions" />
        </div>
        <div class="chart">
          <Chart type="bar" :data="salesChartData" :options="chartOptions" />
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import { ref, onMounted,getCurrentInstance } from 'vue';
import Loader from "@/components/Loader.vue";
import Service from '@/services/levante-api/levante-api'
import moment from 'moment';
import Chart from 'primevue/chart';


export default {
  components: {
    Loader,
    Chart
  },
  setup() {
    const self = getCurrentInstance();
    const loading = ref(true);
    const userChartData = ref(null);
    const revenueChartData = ref(null);
    const attendanceChartData = ref(null);
    const salesChartData = ref(null);

    self.$eventBus = self.appContext.config.globalProperties.$eventBus;
    const service = new Service(self.$eventBus);
    const chartOptions = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: '',
        },
      },
    };
   
    const fetchChartData = async () => {
      try {
        loading.value = true;
        const [userResponse, revenueResponse, attendanceResponse, salesResponse] = await Promise.all([
          service.userMonthly(),
          service.paymentsMonthly(),
          service.attendancesMonthly(),
          service.salesMonthly(),
        ]);
        

        const months = [...Array(12)].map((_, i) => moment().subtract(i, 'months').format('MMM YYYY')).reverse();

        userChartData.value = {
          labels: months,
          datasets: [{
            label: 'Numero Persone',
            data: userResponse.items.data,
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
          }],
        };

        revenueChartData.value = {
          labels: months,
          datasets: [{
            label: 'Incassi',
            data: revenueResponse.items.data,
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
          }],
        };

        attendanceChartData.value = {
          labels: months,
          datasets: [{
            label: 'Presenze',
            data: attendanceResponse.items.data,
            backgroundColor: 'rgba(255, 206, 86, 0.2)',
            borderColor: 'rgba(255, 206, 86, 1)',
            borderWidth: 1,
          }],
        };

        salesChartData.value = {
          labels: months,
          datasets: [{
            label: 'Vendite',
            data: salesResponse.items.data,
            backgroundColor: 'rgba(153, 102, 255, 0.2)',
            borderColor: 'rgba(153, 102, 255, 1)',
            borderWidth: 1,
          }],
        };
      } catch (error) {
        console.error('Errore nel recupero dei dati per i grafici:', error);
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      fetchChartData();
    });

    return {
      loading,
      userChartData,
      revenueChartData,
      attendanceChartData,
      salesChartData,
      chartOptions,
    };
  },
};
</script>

<style scoped>
.charts-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.chart {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}
</style>
