<template>
  <Loader v-if="loading" />    
  <Card style="width: fit-content;">
    <template v-slot:title>
      <h3>Crea Utente</h3>
    </template>
    <template v-slot:content>
      <div class="grid">
        <div class="m-3">
          <div class="formgrid grid mt-4">
            <!-- Cognome Utente -->
            <div class="p-field col-12 xl:col-6">
              <label for="cognome">Cognome</label><br>
              <InputText id="cognome" v-model="utente.cognome" :class="['inputfield w-full',{'p-invalid': validationErrors.cognome && submitted}]" @input="(val) => (utente.cognome = utente.cognome.toUpperCase())" @change="onChangeInput($event, 'cognome')"/>
              <small v-show="validationErrors.cognome && submitted" class="p-error">Campo richiesto.</small>
            </div>
            
            <!-- Nome Utente -->
            <div class="p-field col-12 xl:col-6">
              <label for="nome">Nome</label><br>
              <InputText id="nome" v-model="utente.nome" :class="['inputfield w-full',{'p-invalid': validationErrors.nome && submitted}]" @input="(val) => (utente.nome = utente.nome.toUpperCase())" @change="onChangeInput($event, 'nome')"/>
              <small v-show="validationErrors.nome && submitted" class="p-error">Campo richiesto.</small>              
            </div>
            
            <!-- Cellulare Utente -->
            <div class="p-field col-12 xl:col-4">
              <label for="mobile">Cellulare</label><br>                   
              <InputMask id="mobile" mask="999-999999?9" v-model="utente.cellulare" :class="['inputfield w-full',{'p-invalid': validationErrors.cellulare && submitted}]" @change="onChangeInput($event, 'cellulare')"/>
              <small v-show="validationErrors.cellulare && submitted" class="p-error">Campo richiesto.</small>
            </div> 
            
            <!-- Email Utente -->
            <div class="p-field col-12 xl:col-4">
              <label for="email">Email</label><br>
              <InputText id="email" v-model="utente.email" :class="['inputfield w-full',{'p-invalid': validationErrors.email || validationErrors.isEmail && submitted}]" @input="(val) => (utente.email = utente.email.toLowerCase())" @change="onChangeInput($event, 'email')"/>
              <div v-show="validationErrors.email && submitted" class="p-error">Campo richiesto.</div>
              <div v-show="validationErrors.isEmail && submitted" class="p-error">Formato errato.</div>
            </div>
                <!-- Email Utente -->
            <div class="p-field col-12 xl:col-4">
              <label for="codfis">Codice Fiscale</label><br>
              <InputMask  mask="aaaaaa99a99a999a" id="codfis" v-model="utente.codfis" :class="['inputfield w-full',{'p-invalid': validationErrors.codfis && submitted}]" @input="(val) => (utente.codfis = utente.codfis.toLowerCase())" @change="onChangeInput($event, 'codfis')"/>
              <div v-show="validationErrors.codfis && submitted" class="p-error">Campo richiesto.</div>
            </div>
            <!-- indirizzo Utente -->
            <div class="p-field col-12 xl:col-3">
              <label for="indirizzo">Indirizzo</label><br>
              <InputText id="indirizzo" v-model="utente.indirizzo" :class="['inputfield w-full',{'p-invalid': validationErrors.indirizzo && submitted}]" @input="(val) => (utente.indirizzo = utente.indirizzo.toUpperCase())" @change="onChangeInput($event, 'indirizzo')"/>
              <div v-show="validationErrors.indirizzo && submitted" class="p-error">Campo richiesto.</div>
            </div>
                 <!-- Comune Utente -->
            <div class="p-field col-12 md:col-8 xl:col-3">
              <label>Comune</label><br>
              <Dropdown v-model="utente.comune" :options="cities" :filter="true" optionLabel="name" optionValue="id" placeholder="Seleziona comune" :class="['inputfield w-full',{'p-invalid': validationErrors.comune && submitted}]" :virtualScrollerOptions="{ itemSize: 38 }" @change="onChangeInput($event, 'comune')"/>
              <small v-show="validationErrors.comune && submitted" class="p-error">Campo richiesto.</small>
            </div>
            
            <!-- Cap Utente -->
            <div class="p-field col-12 md:col-4 xl:col-3">
              <label for="luogodinascita">Luogo di nascita</label><br>
              <InputText id="luogodinascita" v-model="utente.luogodinascita" :class="['inputfield w-full',{'p-invalid': validationErrors.luogodinascita && submitted}]" @change="onChangeInput($event, 'luogodinascita')"/>
              <small v-show="validationErrors.luogodinascita && submitted" class="p-error">Campo richiesto.</small>
            </div>
            
              <!-- Cap Utente -->
            <div class="p-field col-12 md:col-4 xl:col-3">
              <label for="">Data di nascita</label><br>
                <Calendar v-model="utente.datadinascita" dateFormat="dd/mm/yy" showIcon :showOnFocus="false"  @change="onChangeInput($event, `datadinascita`)"/>
              <small v-show="validationErrors.datadinascita && submitted" class="p-error">Campo richiesto.</small>
            </div>
            
       
            
            <!-- <div class="p-field col-12 md:col-8 xl:col-4">
              <Button @click="viewDataTableAtleti" :label="`${block_div_atleti ? 'Dissocia' : 'Associa'} atleti`"/>
            </div> -->
            
            <!-- Checkbox associa atleti -->
            <div class="p-field col-12 flex align-items-center">
              <Checkbox v-model="block_div_atleti" id="check_associa_Atleti" :binary="true" @click="viewDataTableAtleti"/>
              <label for="check_associa_Atleti" class="ml-2">Crea Atleti</label>
            </div>
            
            <!-- Tabella Atleti -->
            <div class="col-12" id="container-atleti">
              <DataTable :value="atleti" editMode="cell" @cell-edit-complete="onCellEditComplete">
                <template #empty>
                  <div class="text-center">Nessun atleta inserito</div>
                </template>
                <Column :exportable="false" style="width: 3rem">
                  <template #body="{ data, index }">
                    <i class="pi pi-trash p-error" @click="deleteRow(data, index)" />
                  </template>
                </Column>
                <Column v-for="col of columns" :key="col.field" :field="col.field" :header="col.header" style="width:20%">
                  <template #body="{data, index}">
                    <div class="flex flex-column">
                      <div>
                        {{data[col.field] == '' ? 'Inserisci...' : (col.field == 'birth' ? moment(data[col.field]).format('DD/MM/YYYY') : data[col.field])}}                 
                      </div>
                      <div v-show="validationErrors[`${col.field}_${index}`]" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                    </div>
                  </template>
                  <template #editor="{ data, index }">
                    <div v-if="col.field == 'birth'">
                      <Calendar v-model="data[col.field]" dateFormat="dd/mm/yy" :showIcon="true" :showOnFocus="false" :maxDate="maxDate" @change="onChangeInput($event, `${col.field}_${index}`)"/>
                    </div>
                    <div v-if="col.field == 'gender'">
                      <div class="flex flex-column gap-3">
                        <div v-for="gender in genders" :key="gender.key" class="flex align-items-center">
                          <RadioButton v-model="data[col.field]" :id="`gender_${gender.key}_${index}`" name="dynamic" :value="gender.name" />
                          <label :for="`gender_${gender.key}_${index}`" class="ml-2">{{ gender.name }}</label>
                        </div>
                      </div>
                    </div>
                    <div v-if="col.field == 'cf'">
                      <InputMask v-model="data[col.field]" mask="aaaaaa99a99a999a" autofocus class="w-full text_toupper" @change="onChangeInput($event, `${col.field}_${index}`)"/>
                    </div>
                    <div v-if="col.field=='comune'">
                    <Dropdown v-model="data[col.field]" :options="cities" :filter="true" optionLabel="name" optionValue="id" placeholder="Seleziona comune" :class="['inputfield w-full',{'p-invalid': validationErrors.comune && submitted}]" :virtualScrollerOptions="{ itemSize: 38 }" @change="onChangeInput($event, `${col.field}_${index}`)"/>
                  </div>
                    <div v-if="col.field != 'birth' && col.field != 'gender' && col.field != 'comune' && col.field != 'cf'">
                      <InputText v-model="data[col.field]" autofocus class="w-full" @change="onChangeInput($event, `${col.field}_${index}`)"/>
                    </div>
                  </template>
                </Column>
              </DataTable>
              <div class="mt-2">
                <div class="flex justify-content-end">
                  <div>
                    <Button v-tooltip="`Aggiungi riga`" @click="addRow" icon="pi pi-plus" class="p-button-rounded p-button-success" />
                  </div>
                </div>
              </div>
            </div>
            
            <!-- Bottone salva -->
            <div class="mt-5 col-12 flex justify-content-end">
              <Button class="p-button-success" icon="pi pi-check" label="Salva" @click="sendDataNewUser"/>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import {ref, onMounted, getCurrentInstance, onUpdated, computed } from 'vue';
import Loader from "@/components/Loader.vue"
import Service from '@/services/levante-api/levante-api'
import moment from 'moment'
import { useToast } from 'primevue/usetoast';

export default {
  components: {
    Loader,
  },
  
  setup () { 
    
    const self = getCurrentInstance();
    const toast = useToast();
    self.$eventBus = self.appContext.config.globalProperties.$eventBus
    const service = new Service(self.$eventBus)
    const submitted = ref(false)
    const loading = ref(false)
    const utente = ref({})
    const lifeTime = 3000
    const validationErrors = ref({})
    const atleti = ref([])
    const columns = [
    { field: 'surname', header: 'Cognome' },
    { field: 'name', header: 'Nome' },
    { field: 'birth', header: 'Data di nascita' },
    { field: 'gender', header: 'Sesso' },
    { field: 'comune', header: 'Città' },
    { field: 'indirizzo', header: 'Indirizzo' },
    { field: 'luogodinascita', header: 'Luogo di Nascita' },
    { field: 'cf', header: 'Codice fiscale' }
    ];
    const genders = [
    {key:0, name:'Maschio'},
    {key:1, name:'Femmina'}
    ]
    const cities = ref([])
    const maxDate = ref();
    const today = new Date();
    const block_div_atleti = ref(true)
 
    
    const onSelectedMobile = (evt) => {
      utente.value.cellulare = evt.target.value;
      delete validationErrors.value['cellulare'];
    }
    const addRow = () => {
      let newRow = columns.reduce((obj, column) => {
        obj[column.field] = '';
        return obj;
      }, {});
      newRow.gender = 'Maschio'
      newRow.surname = utente.value.cognome ?? ''
      newRow.indirizzo = utente.value.indirizzo ?? ''
      newRow.comune = utente.value.comune ?? ''
      atleti.value.push(newRow)   
    }
    const onCellEditComplete = (event) => {
      let { field, index } = event;
      console.log(field);
      if (field != 'gender' && field != 'birth' && field != 'comune') {
        atleti.value[index][field] = atleti.value[index][field].toUpperCase()
      }
    };
    const deleteRow = (row, index) => {
      atleti.value.splice(index, 1);
      validationErrors.value = []
    }
    const getComuni = async () => {
      await service.getComuni()
      .then((res) => {
        cities.value = res.items
      })
    }
    const onChangeInput = (evt, prop) => {
      let value
      switch (prop) {
        case 'comune':
        value = evt.value
        break;
        
        default:
        value = evt.target.value
        break;
      }
      if (value == '' || value == null) {
        validationErrors.value[prop] = true
      }else{
        delete validationErrors.value[prop]
      }
    }
    
    const viewDataTableAtleti = () => {
      let div_atleti = document.getElementById('container-atleti')
      block_div_atleti.value = !block_div_atleti.value
      let style = block_div_atleti.value ? 'block' : 'none'
      div_atleti.style.display = style
      if (!block_div_atleti.value) {
        atleti.value = []
      }
    }
    
    const validateForm = () => {
      // Validazione Utente
      let validate_properties = [
      'nome',
      'cognome',
      'email',
      'cellulare',
      'indirizzo',
      'comune'
      ]
      let regex_email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      
      validate_properties.forEach( prop => {
        if (utente.value[prop] == '' || utente.value[prop] == null) {
          validationErrors.value[prop] = true
        } else {
          delete validationErrors.value[prop];
          if (prop == 'email') {
            if (regex_email.test(utente.value[prop])) {
              delete validationErrors.value.isEmail;
            } else {
              validationErrors.value.isEmail = true
            }
          }
        }
      })
      // Fine Validazione Utente
      
      // Validazione Atleti
      if (block_div_atleti.value) {
        if (atleti.value.length == 0) {
          toast.add({severity:'warn', summary: 'Errore', detail:`Nessun atleta inserito`, life: lifeTime});
          return false
        } else {
          atleti.value.forEach((atleta, indice) => {
            Object.keys(atleta).forEach(prop => {
              if (atleta[prop] == '' || atleta[prop] == null) {
                validationErrors.value[`${prop}_${indice}`] = true
              } else {
                delete validationErrors.value[`${prop}_${indice}`]
              }              
            });
          })
        }
      }
       
      // Fine Validazione Atleti
      return !Object.keys(validationErrors.value).length;
    }
    const setDataTime = (el) => {
      let giorno = el.getDate() 
      let mese = el.getMonth()+1
      mese = mese.toString().padStart(2,'0')
      let anno = el.getFullYear() 
      el = `${anno}-${mese}-${giorno}`
      return el
    } 
    
    
    const sendDataNewUser = async () => {
      submitted.value = true;
      validationErrors.value = []
      console.log("sendDataNewUser")
      if(validateForm()){
        loading.value = true
        let fd = new FormData();
        if (utente.value.datadinascita)
          utente.value.datadinascita = setDataTime(utente.value.datadinascita)
        atleti.value.forEach((atleta, indice) => {
          atleta.birth = setDataTime(atleta.birth)
        })
        fd.append('utente', JSON.stringify(utente.value));
        fd.append('atleti', JSON.stringify(atleti.value));
        
        service.sendDataNewUser(fd)
        .then((res) => {
          let severity = res.item.error ? 'warn' : 'success'
          let summary = res.item.error ? 'Attenzione' : 'Successo'
          let detail = res.item.response
          
          toast.add({severity:severity, summary: summary, detail:detail,life:lifeTime});
        }).finally(() => {
          initData()
          loading.value = false
        })
      }
//      else{
//        toast.add({severity:'warn', summary: 'Attenzione', detail:`Ci sono dei dati mancanti`});
//      }
    }
    
    const initData = () => {
      // restart var
      utente.value = {}
      atleti.value = []
      block_div_atleti.value = true
      // setMaxData
      const currentYear = today.getFullYear();
      const previousYear = currentYear - 1;
      maxDate.value = new Date(previousYear, today.getMonth(), today.getDate());
    }
    
    onMounted(() => { 
      initData()
      getComuni()
    })
    
    return {
      loading,
      submitted,
      utente,
      validationErrors,
      onSelectedMobile,
      atleti,
      cities,
      columns,
      addRow,
      onCellEditComplete,
      genders,
      moment,
      deleteRow,
      maxDate,
      viewDataTableAtleti,
      block_div_atleti,
      sendDataNewUser,
      onChangeInput,
    }
  },
}
</script>

<style>
.text_toupper{
  text-transform: uppercase;
} 
</style>
<style scoped>
#container-atleti{
  display: block;
}
.layout-main-container{
  width: fit-content !important;
}
.btn-error-filter{
  width: 200px;
}
</style>
