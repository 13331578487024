<template>
     <Loader v-if="loading"/>
    <div> 
        <Card style="margin-bottom:10px">
    <template v-slot:title>
      <h3>Carica un file </h3>
    </template>
    <template v-slot:content>
         <br/><input type="file" @change="onFileChange">
    </template>
    </Card>
        <Card>
    <template v-slot:title>
      <h3>Gestione modulistica</h3>
    </template>
    <template v-slot:content>
        <table>
            <thead>
                <tr>
                    <th>Data</th>
                    <th>Nome</th>
                    
                    <th>File</th>
                    <th>Visibile</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="n in transactions" :key="n.id">
                    <td>{{ n.creation_time }}</td>
                    <td>{{ n.nome }}</td>
                    <td> 
                                    <Button severity="help" @click="getDocUrl(n.path)" >{{ n.nome }}</Button>
                    </td>
                    <td style="text-align: center"> <Button @click="openConfirmDialog(n.id,n.visibile)" 
                      :label="(n.visibile==0)?'NO':'SI'" 
                      :class="(n.visibile==1) ? 'p-button-success' : 'p-button-warning'"
                      />
                        </td>
                        <td><Button @click="openConfirmDialogTrash(n.id,n.visibile)" class='p-button-warning'><i class='pi pi-trash'></i></Button></td>
               
                </tr>
            </tbody>
        </table>
    </template>
    </Card>

        <div v-if="showModal">
            <div class="modal">
                <h3>Contabilizza Transazione</h3>
                <label for="atleta">Seleziona Atleta:</label><br/>
                
                <Dropdown v-model="selectedAtleta" :options="atleti" :filter="true" optionLabel="nomecompleto" optionValue="id" placeholder="Seleziona atleta" :class="['inputfield w-full']" :virtualScrollerOptions="{ itemSize: 10 }" @change="onChangeInput($event, 'atleta')"/>

                <div class="p-3">
                <button @click="submitContabilizza" class="p-button">Conferma</button>
                <button @click="showModal = false" class="p-button p-button-warning">Annulla</button>
            </div>
        </div>
    </div>
    </div>

    <div v-if="confirmDialog.visible" class="confirm-dialog">
      <div class="confirm-dialog-content">
        <h3>Conferma la visibiltà</h3>
        <p>Sei sicuro di voler procedere?</p>
        <Button @click="confirmDialogAccept" label="Procedi" />
        <Button @click="confirmDialogReject" label="Annulla" class="p-button-secondary" />
      </div>
    </div>

    <div v-if="confirmDialogTrash.visible" class="confirm-dialog">
      <div class="confirm-dialog-content">
        <h3>Conferma l'eliminiazione</h3>
        <p>Sei sicuro di voler procedere?</p>
        <Button @click="confirmDialogTrashAccept" label="Procedi" />
        <Button @click="confirmDialogTrashReject" label="Annulla" class="p-button-secondary" />
      </div>
    </div>
</template>

<script>
import {ref,onMounted, getCurrentInstance } from 'vue';
import Service from '@/services/levante-api/levante-api'
import Loader from "@/components/Loader.vue";
import { useToast } from 'primevue/usetoast';
export default {
    components(){
        return [
            Loader
        ]
    },
    data() {
        return {
            showModal: false,
            selectedTransaction: null,
            selectedAtleta: '',
        };
    },
    methods: {
       
     
        contabilizza(transaction) {
            this.selectedTransaction = transaction;
            this.showModal = true;
        },
        submitContabilizza() {
            // Logica per contabilizzare la transazione con l'atleta selezionato
            this.showModal = false;
        }
    },
    setup(){
        const self = getCurrentInstance();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const service = new Service(self.$eventBus);
        const toast = useToast();
        const transactions = ref({})
        const atleti = ref({})

        const loading = ref(false)
        const lifeTime = 3000;
        const confirmDialog = ref({
      visible: false,
      id: null,
      visibile: null
    });
    const confirmDialogTrash = ref({
      visible: false,
      id: null,
      visibile: null
    });


    const openConfirmDialogTrash= (id, visibile) => {
      confirmDialogTrash.value.visible = true;
      confirmDialogTrash.value.id = id;
      confirmDialogTrash.value.visibile = visibile;
    };

    
    const confirmDialogTrashAccept = () => {
      deleteValue(confirmDialogTrash.value.id);
      confirmDialogTrash.value.visible = false;
    };

    const confirmDialogTrashReject = () => {
      toast.add({ severity: 'error', summary: 'Annullato', detail: 'Operazione annullata', life: 3000 });
      confirmDialogTrash.value.visible = false;
    };


        const fetchData = async() => {
            const response = await service.getAllModulistica();
            console.log(response);
            transactions.value = response.items;

        };
     
        const getDocUrl = (imgPath) => {
            window.open( `${process.env.VUE_APP_LEVANTE_API_URL}/../storage/app/public/${imgPath}`);
        }
        
        const openConfirmDialog = (id, visibile) => {
        confirmDialog.value.visible = true;
        confirmDialog.value.id = id;
        confirmDialog.value.visibile = visibile;
        };
        const toggleVisibile = async (id,visibile) => {
            visibile = visibile === 0 ? 1 : 0;
            loading.value = true;

            try {
                let fd = new FormData();  
                fd.append('visibile',visibile);
                await service.updateVisibileModulistica(id,fd);  // assuming this endpoint exists
                toast.add({ severity: 'success', summary: 'Successo', detail: 'Visibilità aggiornata con successo',life: lifeTime});
                fetchData();
            } catch (error) {
                toast.add({ severity: 'error', summary: 'Errore', detail: 'Errore durante l\'aggiornamento della visibilità',life: lifeTime });
            } finally {
                loading.value = false;
            }
        };
        const confirmDialogAccept = () => {
        toggleVisibile(confirmDialog.value.id, confirmDialog.value.visibile);
        confirmDialog.value.visible = false;
        };

        const confirmDialogReject = () => {
        toast.add({ severity: 'error', summary: 'Annullato', detail: 'Operazione annullata', life: 3000 });
        confirmDialog.value.visible = false;
        };

           const deleteValue = async (id) => {
      loading.value = true;

      try {
        await service.deleteModulistica(id);  // assuming this endpoint exists
        toast.add({ severity: 'success', summary: 'Successo', detail: 'Cancellazione effettuata con successo',life: lifeTime});
        fetchData();
      } catch (error) {
        toast.add({ severity: 'error', summary: 'Errore', detail: 'Errore durante la cancellazione',life: lifeTime });
      } finally {
        loading.value = false;
      }
    };
        const onFileChange = async (event) => {
            const file = event.target.files[0];
            let formData = new FormData();
            formData.append('file', file);

            service.sendModulistica(formData) .then((res) => {
                    let severity = res.item.esito
                    let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
                    let detail = res.item.messaggio
                    if (severity != 'warning') {
                        toast.add({severity:severity, summary: summary, detail:detail,life:lifeTime});                            
                    }
                    fetchData()
                });
            
        };
        const onChangeInput = (evt, prop) => {
             let value
            switch (prop) {
                case 'atleta':
                value = evt.value
                break;
                
                default:
                value = evt.target.value
                break;
            }
            
            
            };
        onMounted(() => { 
            loading.value = true
            const promises = [fetchData()]
            
            Promise.all(promises)
            .finally(()=>{
                loading.value = false
            })
        })
        return {
            onFileChange,onChangeInput,loading,transactions,atleti,openConfirmDialog,
            confirmDialog,
            confirmDialogReject,
            confirmDialogAccept,getDocUrl,
            openConfirmDialogTrash,
            confirmDialogTrash,
            confirmDialogTrashReject,
            confirmDialogTrashAccept
        };
    },
   
}
</script>

<style scoped>
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 1em;
    border: 1px solid #ccc;
}

.confirm-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);
  z-index: 1000;
}

.confirm-dialog-content {
  text-align: center;
}

.confirm-dialog-content h3 {
  margin-top: 0;
}

.confirm-dialog-content button {
  margin: 10px;
}
</style>
