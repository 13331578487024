<template>
	<footer class="footer" style=" text-align:center; display:block">
			<div class="copyright">
				<!-- <img alt="Logo" :src="darkTheme ? 'images/logo-dark.svg' : 'images/logo-white.svg'" height="20" class="mr-2" /> -->
				{{getYear}} © made by ASK
			</div>     
      </footer>
</template>

<script>
	export default {
		name: "AppFooter",
		computed: {
			getYear() {
				return new Date().getFullYear();
			}
		}
	}
</script>

<style scoped>
.footer {
	padding: 5px 20px;
	display: block;
}

</style>