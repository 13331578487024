import aggiungi from  './aggiungi'


export default [
  // always
  {
    'key': 'pdrs-button-aggiungi',
    'label': 'AGGIUNGI CORSO',
    'classCss': 'p-button-secondary',
    'icon': ' pi pi-plus',
    'type': 'operation',
    'method': aggiungi,
    'display': 'always'
  },
 

]
