<template> 
  <div v-if="confirmDialog.visible" class="confirm-dialog">
       <div class="confirm-dialog-content">
         <h3>Conferma la visibiltà</h3>
         <p>Sei sicuro di voler procedere?</p>
         <Button @click="confirmDialogAccept" label="Procedi" />
         <Button @click="confirmDialogReject" label="Annulla" class="p-button-secondary" />
       </div>
     </div>


     <div v-if="confirmDialogTrash.visible" class="confirm-dialog">
      <div class="confirm-dialog-content">
        <h3>Conferma l'eliminiazione</h3>
        <p>Sei sicuro di voler procedere?</p>
        <Button @click="confirmDialogTrashAccept" label="Procedi" />
        <Button @click="confirmDialogTrashReject" label="Annulla" class="p-button-secondary" />
      </div>
    </div>
   <Loader v-if="loading" />    
   <Card>
     <template v-slot:title>
       <h3>Configurazione NEWS in APP</h3>
     </template>
     <template v-slot:content>
      <div class="col-12">
        <Button @click="addBox" class="p-button-info" :label="(boxes.length==0)?'Nuova':'Chiudi'"/>
        <div v-for="(box, index) in boxes" :key="index" class="box p-5"><br/>
          <div class="p-field"><label for="nome">Titolo</label><InputText v-model="box.title" placeholder="Titolo" :class="['inputfield w-full']"/></div>
          <div class="p-field"><label for="nome">Descrizione</label><InputText v-model="box.description" placeholder="Descrizione" :class="['inputfield w-full']"/></div>
         
          <div class="p-field"><label for="nome">Immagine</label><br/><input type="file" @change="onFileChange($event, index)" /></div><br/><br/>
          <Button  class="p-button-success"  icon="pi pi-check" @click="saveBox(index)" label="Salva"/>
        </div>
      </div>
       <div class="col-12 flex flex-column md:col-12">
         <table>
               <thead>
                 <tr>
                   <th style="width: 150px;">Data creazione</th>
                   <th>Titolo</th>
                   <th>Descrizione</th>
                   <th style="width: 200px;">Immagine</th>
                   <th style="width: 100px;">Visibile</th>
                   <th style="width: 100px;"></th>
                 </tr>
               </thead>
               <tbody>
                 <tr v-for="n in newsInApp" :key="n.id">
                   <td>{{ moment(n.creation_time).format('DD/MM/Y') }}</td>
                   <td>{{ n.title }}</td>
                   <td>{{ n.description }}</td>
                   <td style="text-align: center;">
                     <img v-if="n.img" :src="getImageUrl(n.img)" alt="Image" style="max-width: 200px; max-height: 200px;" />
                   </td>
                   <td style="text-align: center"> <Button @click="openConfirmDialog(n.id,n.visibile)" 
                       :label="n.visibile?'NO':'SI'" 
                       :class="!n.visibile ? 'p-button-success' : 'p-button-warning'"
                       />
                    </td>
                    <td><Button @click="openConfirmDialogTrash(n.id,n.visibile)" class='p-button-warning'><i class='pi pi-trash'></i></Button></td>

                 </tr>
               </tbody>
             </table>
        
         </div>
     </template>
   </Card>
 </template>
 
 <script>
 import {ref, onMounted, getCurrentInstance, onUpdated, computed } from 'vue';
 import Loader from "@/components/Loader.vue"
 import Service from '@/services/levante-api/levante-api'
 import moment from 'moment'
 import { useToast } from 'primevue/usetoast';
 import locale from '@/config/localization/locale_it.js'
 import { useConfirm } from "primevue/useconfirm";
 
 export default {
   components: {
     Loader,
   },
   
   setup () { 
     const self = getCurrentInstance();
     const toast = useToast();
     self.$eventBus = self.appContext.config.globalProperties.$eventBus
     const service = new Service(self.$eventBus)
     const submitted = ref(false)
     const loading = ref(false)
     const newsInApp = ref([]);
     const utente = ref({
       days:[]
     })
     const validationErrors = ref({})
     const types = ref()
     const responsabili = ref()
     const date = moment();
     const minDateInizio = ref();
     const maxDateInizio = ref();
     const minDateFine = ref();
     const maxDateFine = ref();
     const buttonDays = ref([])
     const boxes = ref([])
     const lifeTime = 3000;
     const confirmDialog = ref({
       visible: false,
       id: null,
       visibile: null
     });
 
     const confirmDialogTrash = ref({
       visible: false,
       id: null,
       visibile: null
     });
     const addBox = () => {
      if (boxes.value.length==0)
        boxes.value.push({
          title: '',
          description: '',
          image: null,
        });
      else{
        boxes.value.pop();
      }
     }
     const getImageUrl = (imgPath) => {
       return `${process.env.VUE_APP_LEVANTE_API_URL}/../storage/app/public/${imgPath}`;
     }
     const validateForm = () => {
       return true;
     }
     const openConfirmDialogTrash= (id, visibile) => {
      confirmDialogTrash.value.visible = true;
      confirmDialogTrash.value.id = id;
      confirmDialogTrash.value.visibile = visibile;
    };

    
    const confirmDialogTrashAccept = () => {
      deleteValue(confirmDialogTrash.value.id);
      confirmDialogTrash.value.visible = false;
    };

    const confirmDialogTrashReject = () => {
      toast.add({ severity: 'error', summary: 'Annullato', detail: 'Operazione annullata', life: 3000 });
      confirmDialogTrash.value.visible = false;
    };


    const deleteValue = async (id) => {
      loading.value = true;

      try {
        await service.deleteValueNewsInApp(id);  // assuming this endpoint exists
        toast.add({ severity: 'success', summary: 'Successo', detail: 'Cancellazione effettuata con successo',life: lifeTime});
        initData();
      } catch (error) {
        toast.add({ severity: 'error', summary: 'Errore', detail: 'Errore durante la cancellazione',life: lifeTime });
      } finally {
        loading.value = false;
      }
    };
     const openConfirmDialog = (id, visibile) => {
       confirmDialog.value.visible = true;
       confirmDialog.value.id = id;
       confirmDialog.value.visibile = visibile;
     };
 
     const confirmDialogAccept = () => {
       toggleVisibile(confirmDialog.value.id, confirmDialog.value.visibile);
       confirmDialog.value.visible = false;
     };
 
     const confirmDialogReject = () => {
       toast.add({ severity: 'error', summary: 'Annullato', detail: 'Operazione annullata', life: 3000 });
       confirmDialog.value.visible = false;
     };
     const saveBox = async (index) => {
       submitted.value = true;
       validationErrors.value = []
 
       if(validateForm()){
         loading.value = true
         const box = boxes.value[index];
         let fd = new FormData();  
         fd.append('title', box.title);
         fd.append('description', box.description);
         if (box.image) {
           fd.append('image', box.image);
         }
         service.sendNews(fd)
         .then((res) => {
           let severity = res.item.esito
           let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
           let detail = res.item.messaggio
           //if(res.item.esito != 'success'){
             initData()
           //}
           if (res.item.esito=='success'){
             boxes.value.pop();
           }
           toast.add({severity:severity, summary: summary, detail:detail,life: lifeTime});
         }).finally(() => {
           loading.value = false
         })
       }else{
         toast.add({severity:'warn', summary: 'Attenzione', detail:`Ci sono dei dati mancanti`,life: lifeTime});
       }
     }
 
     const onFileChange = (event, index) => {
       const file = event.target.files[0];
       boxes.value[index].image = file;
     }
     const toggleVisibile = async (id,visibile) => {
       visibile = visibile === 0 ? 1 : 0;
       loading.value = true;
 
       try {
         let fd = new FormData();  
         fd.append('visibile',visibile);
         await service.updateVisibileNewsInApp(id,fd);  // assuming this endpoint exists
         toast.add({ severity: 'success', summary: 'Successo', detail: 'Visibilità aggiornata con successo',life: lifeTime});
         initData();
       } catch (error) {
         toast.add({ severity: 'error', summary: 'Errore', detail: 'Errore durante l\'aggiornamento della visibilità',life: lifeTime });
       } finally {
         loading.value = false;
       }
     };
  
     
     const initData = () => {
       // restart var
 
       service.getNewsInApp().then((res) => {
          newsInApp.value = res.items
       });
     }
  
     
 
     
     onMounted(() => { 
       loading.value = true
       const promises = [initData()]
       
       Promise.all(promises)
       .then(()=>{
           
       }).finally(()=>{
           loading.value = false
       })
     })
     
     return {
       loading,
       submitted,
       utente,
       validationErrors,
       types,
       moment,
       newsInApp,
       date,
       minDateInizio,
       maxDateInizio,
       minDateFine,
       maxDateFine,
       buttonDays,
       addBox,
       saveBox,
       onFileChange,
       boxes,
       confirmDialog,
       deleteValue,
       openConfirmDialog,
       confirmDialogAccept,
       confirmDialogReject,
       getImageUrl,
       confirmDialogTrash,
       confirmDialogTrashReject,
       openConfirmDialogTrash,
       confirmDialogTrashAccept
     }
   },
 }
 </script>
 
 <style>
 .text_toupper{
   text-transform: uppercase;
 } 
 </style>
 <style scoped>
 .counter{
   position:absolute;
   right:10px;
   top:10px;
 }
 #container-atleti{
   display: block;
 }
 .layout-main-container{
   width: fit-content !important;
 }
 .btn-error-filter{
   width: 200px;
 }
 
 .btn-campo{
   background:#ffffff url("/images/campo.jpg") no-repeat right top;
   background-size: contain;
   width: 100px;
   aspect-ratio:1 ;
 }
 
 .numero-campo{
   color: black;
   font-size: 40px;
   font-weight: 500;
   position: relative;
   z-index: 2;
 }
 table {
   width: 100%;
   border-collapse: collapse;
 }
 
 th, td {
   border: 1px solid #ddd;
   padding: 8px;
 }
 
 th {
   background-color: #f2f2f2;
 }
 
 .confirm-dialog {
   position: fixed;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   background: white;
   padding: 20px;
   box-shadow: 0 2px 10px rgba(0,0,0,0.2);
   z-index: 1000;
 }
 
 .confirm-dialog-content {
   text-align: center;
 }
 
 .confirm-dialog-content h3 {
   margin-top: 0;
 }
 
 .confirm-dialog-content button {
   margin: 10px;
 }
 </style>
 