<template>
    <div id="app">
        <h3>Gestione Occupazione Campi</h3>
      <AccordionFields :fields="fields" />
    </div>
  </template>
  
  <script>
  import {ref, onMounted, getCurrentInstance, onUpdated, computed } from 'vue';
import Service from '@/services/levante-api/levante-api'
  import AccordionFields from './AccordionFields.vue';
  
  export default {
    name: 'App',
    components: {
      AccordionFields,
    },
    data() {
      return {
        fields: [],
      };
    },
    created() {
      this.fetchFields();
    },
    methods: {
      async fetchFields() {
        try {
            const self = getCurrentInstance();
            self.$eventBus = self.appContext.config.globalProperties.$eventBus
            const service = new Service(self.$eventBus)
          const response = await service.getCorsoSessioni();
          console.log(response);
          this.fields = response.items;
        } catch (error) {
          console.error(error);
        }
      },
    },
  };
  </script>