import merceButtons  from './tab-buttons/merce' 
import atletiButtons from './tab-buttons/atleti'
import corsiButtons from './tab-buttons/corsi'
import iscrittiButtons from './tab-buttons/iscritti'
import pagaButtons from './tab-buttons/pagamenti'
import misterButtons from './tab-buttons/mister'
//import torneiButtons from './tab-buttons/tornei'
import venditeButtons from './tab-buttons/vendite'

export default [
  {
    label: 'ATLETI',
    icon: 'pi pi-user',
    component: 'AtletiDataTable',
    name: 'AtletiDataTable',
    default: true,
    buttons: atletiButtons
  },
  {
    label: 'CORSI',
    icon: 'pi pi-copy',
    component: 'CorsiDataTable',
    name: 'CorsiDataTable',
    default: true,
    buttons: corsiButtons
  },  

  {
    label: 'STAFF',
    icon: 'fas fa-lightbulb',
    component: 'StaffDataTable',
    name: 'StaffDataTable',
    default: true,
    buttons: misterButtons
  },
  {
    label: 'PRODOTTI',
    icon: 'pi pi-user',
    component: 'MerceDataTable',
    name: 'MerceDataTable',
    default: true,
    buttons: merceButtons
  },
  {
    label: 'KIT',
    icon: 'pi pi-user',
    component: 'KitDataTable',
    name: 'KitDataTable',
    default: true,
    buttons: merceButtons
  },
  {
    label: 'VENDITE',
    icon: 'pi pi-user',
    component: 'VenditeDataTable',
    name: 'VenditeDataTable',
    default: true,
    buttons: venditeButtons
  },
 

]
