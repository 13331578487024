<template>
    <div>
      <div v-for="(group, key) in groups" :key="key" class="accordion-item">
        <div class="accordion-header" @click="toggleGroup(key)">
          <h4>{{ group[group.length-1].nomecorso }}</h4>
        </div>
        <div v-if="isGroupOpen(key)" class="accordion-body">
          <table>
            <thead>
              <tr>
                <th>Atleta</th>
                <th style='width:180px;text-align:right'>Quantità</th>
                <th style='width:150px;text-align:right'>Data vendita</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in group" :key="item.id_corso + item.atleta" :style="{ backgroundColor: item.quanti > 0 ? '#a8e3a8' : '' }">
                <td @click="goToAtleta(item.id_atleta)" style="text-decoration: underline;">{{ item.atleta }}</td>
                <td style="text-align:right">{{ item.quanti }}</td>
                <td  style="text-align:right">{{ showDate(item.data_vendita)  }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  
  <script>
    import moment from 'moment'
  export default {
    name: 'Accordion',
    props: {
      groups: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        openGroups: [],
      };
    },
    methods: {
      goToAtleta(idatleta){
        console.log(idatleta)
        window.open(`/atleta/${idatleta}/dettagli`, '_blank')
      },
      toggleGroup(key) {
        if (this.openGroups.includes(key)) {
          this.openGroups = this.openGroups.filter(k => k !== key);
        } else {
          this.openGroups.push(key);
        }
      },
      isGroupOpen(key) {
        return this.openGroups.includes(key);
      },
      showDate(item){
        return item?moment(item).format('DD/MM/Y'): 'N/A'
      }
    },
  };
  </script>
  
  <style>
  .accordion-item {
    margin-bottom: 10px;
  }
  .accordion-header {
    cursor: pointer;
    background-color: #f5f5f5;
    padding: 10px;
    border: 1px solid #ddd;
  }
  .accordion-body {
    border: 1px solid #ddd;
    border-top: none;
    padding: 10px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  th {
    background-color: #f5f5f5;
  }
  </style>
  