const main_domain ={    
  url: `${process.env.VUE_APP_ASK_DOMAIN}`,
  cookie: `${process.env.VUE_APP_ASK_DOMAIN}`
}

const wizard = {    
  url: `${process.env.VUE_APP_ASK_WIZARD_URL}`,
}

export
{
  main_domain,
  wizard,
}
