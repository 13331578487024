import axios from 'axios'
import services from '@/config/services.js'
import authHeader from '../auth/auth-header';
import Cookies from 'js-cookie';
import service from '@/config/services'
import FileManager from '@/services/levante-file-manager/index.js'
import { property } from 'underscore';

export default class PraticheService{
  constructor(eventBus){
    this.eventBus = eventBus
    this.baseUrl = `${services.levante_api.url}`
    this.filemanager = new FileManager()
    axios.interceptors.request.use(
      (req) => {
        req.headers["Authorization"] = authHeader()
        req.headers["token"] = Cookies.get('ask_access_token')
        req.headers["Params"] = Cookies.get('google_access_token') || Cookies.get('ask_access_token')
        // this.eventBus.emit('data-loading');
        return req
      }
    )
  }
  async getPostByUrl(url,fd){
    let item = [];
    try {
      const res = await axios.post(url, fd)
      item = res.data
      if (item.status === false) {
        // Redirect to /login if status is false
        window.location.href = '/login';
        return;
      }
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }

  async getGetByUrl(url){
    let items = [];
    try {
      const res = await axios.get(url)
      items = res.data
      if (items.status === false) {
        // Redirect to /login if status is false
        window.location.href = '/login';
        return;
      }
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
    }
    return {
      items
    }   
  }

  async checkToken(token){
    const url = `${this.baseUrl}/logged`
    const res = await axios.get(url)
    var test = res.data
    const out=[];
    out["status"]=test;
    return out
  }
  async getDetails(type_search, id){
    const url = `${this.baseUrl}/get-${type_search}-${id}`
    return await this.getGetByUrl(url)
  }
  async getSessioni(id){
    const url = `${this.baseUrl}/get-sessioni-${id}`
    return await this.getGetByUrl(url)
  }
  async getKitDetails(id){
    const url = `${this.baseUrl}/getkitdet/${id}`
    return await this.getGetByUrl(url)
  }
  async getVenditeDaConsegnare(){
    const url = `${this.baseUrl}/get-vendite-daconsegnare`
    return await this.getGetByUrl(url)
  }
  async getComuni(){
    const url = `${this.baseUrl}/get-comuni`
    return await this.getGetByUrl(url)
  }
  async getDaPagare(type_search,id){
    const url = `${this.baseUrl}/get-dapagare-${type_search}-${id}`
    return await this.getGetByUrl(url)
  }
  async getPagamenti(type_search,id){
    const url = `${this.baseUrl}/getPagamenti-${type_search}-${id}`
    return await this.getGetByUrl(url)
  }
  async getVendita(type_search,id){
    const url = `${this.baseUrl}/getVendita-${type_search}-${id}`
    return await this.getGetByUrl(url)
  }
  async getAtletiUtente(id){
    const url = `${this.baseUrl}/getAtletiUtente/${id}`
    return await this.getGetByUrl(url)
  }
  async getProdotti(){
    const url = `${this.baseUrl}/get-prodotti`
    let item=await this.getGetByUrl(url)
    return item
  }
  async getKit(){
    const url = `${this.baseUrl}/get-kit`
    let item=await this.getGetByUrl(url)
    return item
  }
  async getTessere(){
    const url = `${this.baseUrl}/get-tessere`
    let item=await this.getGetByUrl(url)
    return item
  }
  async getTypesOfFiles(){
    const url = `${this.baseUrl}/getTypesOfFiles`
    let item=await this.getGetByUrl(url)
    return item
  }
  async getProdottiToBuy(){
    const url = `${this.baseUrl}/getProdottiToBuy`
    let item=await this.getGetByUrl(url)
    return item
  }
  async getKitToBuy(){
    const url = `${this.baseUrl}/getKitToBuy`
    let item=await this.getGetByUrl(url)
    return item
  }
  async getProdottiDisponibili(){
    const url = `${this.baseUrl}/get-prodotti-disponibili`
    let item=await this.getGetByUrl(url)
    return item
  }
  async getTypesProdotti(){
    const url = `${this.baseUrl}/get-types-prodotti`
    let item=await this.getGetByUrl(url)
    return item
  }
  async getAtleti(){
    const url = `${this.baseUrl}/get-atleti`
    let item=await this.getGetByUrl(url)
    return item
  }
  async getCorsoSessioni(){
    const url = `${this.baseUrl}/corso-sessioni`
    let item=await this.getGetByUrl(url)
    return item
  }
  async getAllModulistica(){
    const url = `${this.baseUrl}/modulistica/get-all`
    let item=await this.getGetByUrl(url)
    return item
  }
  async getAllTransactionsNoUsed(){
    const url = `${this.baseUrl}/transactions/get-all-notused`
    let item=await this.getGetByUrl(url)
    return item
  }
  async getAllTransactionsUsed(){
    const url = `${this.baseUrl}/transactions/get-all-used`
    let item=await this.getGetByUrl(url)
    return item
  }
  async getCorsi(){
    const url = `${this.baseUrl}/get-corsi`
    let item=await this.getGetByUrl(url)
    return item
  }
  async getCorsiAtleta(id){
    const url = `${this.baseUrl}/get-dettaglio-corso-for-atleta-${id}`
    let item=await this.getGetByUrl(url)
    return item
  }
  async getStatiche(){
    const url = `${this.baseUrl}/get-statiche`
    let item=await this.getGetByUrl(url)
    return item
  }
  async getNewsInApp(){
    const url = `${this.baseUrl}/get-newsInApp`
    let item=await this.getGetByUrl(url)
    return item
  }
  async getSponsor(){
    const url = `${this.baseUrl}/getSponsor`
    let item=await this.getGetByUrl(url)
    return item
  }
  async getRichiesteProva(){
    const url = `${this.baseUrl}/get-richiesteProva`
    let item=await this.getGetByUrl(url)
    return item
  }
  async getRichiestePulmino(){
    const url = `${this.baseUrl}/get-richiestePulmino`
    let item=await this.getGetByUrl(url)
    return item
  }
  async getAllRichiestePulmino(){
    const url = `${this.baseUrl}/getAllRichiestePulmino`
    let item=await this.getGetByUrl(url)
    return item
  }
  async getPreOrdini(){
    const url = `${this.baseUrl}/get-preOrdini`
    let item=await this.getGetByUrl(url)
    return item
  }
  async getRichiesteProvaCorso(id){
    const url = `${this.baseUrl}/get-richiesteProvaCorso/${id}`
    let item=await this.getGetByUrl(url)
    return item
  }
  async getToPay(mese,anno){
    const url = `${this.baseUrl}/get-ToPay-${mese}-${anno}`
    let item=await this.getGetByUrl(url)
    return item
  }
  async getPayments(mese,anno){
    const url = `${this.baseUrl}/get-Pagamenti-${mese}-${anno}`
    let item=await this.getGetByUrl(url)
    return item
  }
  async getPaymentsDay(giorno){
    const url = `${this.baseUrl}/get-Pagamenti/${giorno}`
    let item=await this.getGetByUrl(url)
    return item
  }
  async getStaff(){
    const url = `${this.baseUrl}/get-staffs`
    let item=await this.getGetByUrl(url)
    return item
  }
  async getVendite(){
    const url = `${this.baseUrl}/get-vendite`
    let item=await this.getGetByUrl(url)
    return item
  }
  async userMonthly(){
    const url = `${this.baseUrl}/userMonthly`
    return await this.getGetByUrl(url)
  }
  async salesMonthly(){
    const url = `${this.baseUrl}/salesMonthly`
    return await this.getGetByUrl(url)
  }
  async attendancesMonthly(){
    const url = `${this.baseUrl}/attendancesMonthly`
    return await this.getGetByUrl(url)
  }
  async paymentsMonthly(){
    const url = `${this.baseUrl}/paymentsMonthly`
    return await this.getGetByUrl(url)
  }
  async getTypePay(){
    const url = `${this.baseUrl}/get-type-pay`
    let item=await this.getGetByUrl(url)
    return item
  }  
  async getTypePayCassa(){
    const url = `${this.baseUrl}/get-type-pay-cassa`
    let item=await this.getGetByUrl(url)
    return item
  }
  async getDocumentiApp(id){
    const url = `${this.baseUrl}/get-documenti-app/${id}`
    let item=await this.getGetByUrl(url)
    return item
  }
  async getAttivita(){
    const url = `${this.baseUrl}/get-attivita`
    let item=await this.getGetByUrl(url)
    return item
  }
  async getProdottiGruppo(id){
    const url = `${this.baseUrl}/get-prodotti-gruppo/${id}`
    let item=await this.getGetByUrl(url)
    return item
  }
  async getDocumentiGruppo(id){
    const url = `${this.baseUrl}/get-documenti-gruppo/${id}`
    let item=await this.getGetByUrl(url)
    return item
  }
  async getPrenotazioniGruppo(){
    const url = `${this.baseUrl}/get-prenotazioni-gruppo`
    let item=await this.getGetByUrl(url)
    return item
  }
  async getCitta(){
    const url = `${this.baseUrl}/get-citta`
    let item=await this.getGetByUrl(url)
    return item
  }
  async getNotifiche(id){
    const url = `${this.baseUrl}/getNotifiche/${id}`
    let item=await this.getGetByUrl(url)
    return item

  }
  async getUtenti(){
    const url = `${this.baseUrl}/get-utenti`
    return await this.getGetByUrl(url)
  }
  async getCampi(){
    const url = `${this.baseUrl}/get-campi`
    return await this.getGetByUrl(url)
  }
  async getCampiForTypeCorso(id){
    const url = `${this.baseUrl}/get-campi-for-type-corso-${id}`
    return await this.getGetByUrl(url)
  }
  async getPresenzeStaff(id){
    const url = `${this.baseUrl}/get-presenze-staff/${id}`
    return await this.getGetByUrl(url)
  }
  async getTornei(){
    const url = `${this.baseUrl}/getTornei`
    return await this.getGetByUrl(url)
  }
  async getIscrittiSezione(id,idcorso){
    const url = `${this.baseUrl}/get-iscritti-sezione/${id}/${idcorso}`
    return await this.getGetByUrl(url)
  }
  async getStaffCorso(id,idcorso){
    const url = `${this.baseUrl}/get-staff-corso-sezione/${id}/${idcorso}`
    return await this.getGetByUrl(url)
  }
  async getTypePersonale(){
    const url = `${this.baseUrl}/get-type-personale`
    return await this.getGetByUrl(url)
  }
  async getTypeCorso(){
    const url = `${this.baseUrl}/get-type-corso`
    return await this.getGetByUrl(url)
  }
  async getCorsiAttivi(){
    const url = `${this.baseUrl}/get-active-corsi`
    return await this.getGetByUrl(url)
  }
  async getAtletiForCorso(id){
    const url = `${this.baseUrl}/get-atleti-for-corso-${id}`
    return await this.getGetByUrl(url)
  }
  async getDocs(id){
    const url = `${this.baseUrl}/get-docs/${id}`
    return await this.getGetByUrl(url)
  }
  async getAtletiInCorso(id){
    const url = `${this.baseUrl}/get-atleti-in-corso-${id}`
    return await this.getGetByUrl(url)
  }
  async getTypeCorsoPersonale(){
    const url = `${this.baseUrl}/get-type-corso-personale`
    return await this.getGetByUrl(url)
  }
  async getPagamentiApp(){
    const url = `${this.baseUrl}/get-pagamenti-app`
    return await this.getGetByUrl(url)
  }
  async getPagamentiAppVerified(){
    const url = `${this.baseUrl}/get-pagamenti-verificati-app`
    return await this.getGetByUrl(url)
  }
  async getPagamentiAppAll(){
    const url = `${this.baseUrl}/get-pagamenti-app-all`
    return await this.getGetByUrl(url)

  }
  async getAccessiApp(){
    const url = `${this.baseUrl}/get-accessi-app`
    return await this.getGetByUrl(url)
  }
  async getDetailTorneo(id){
    const url = `${this.baseUrl}/getDetailTorneo/${id}`
    return await this.getGetByUrl(url)
  }
  async getCorsiPersonale(id){
    const url = `${this.baseUrl}/get-corsi-personale-${id}`
    return await this.getGetByUrl(url)
  }
  async getPersonale(type){
    const url = `${this.baseUrl}/get-personale-${type}`
    return await this.getGetByUrl(url)
  }
  async getAllPersonale(){
    const url = `${this.baseUrl}/get-all-personale`
    return await this.getGetByUrl(url)
  }
  async getPersonaleForCorso(cod_type){
    const url = `${this.baseUrl}/get-personaleForCorso-${cod_type}`
    return await this.getGetByUrl(url)
  }
  async getSizes(){
    const url = `${this.baseUrl}/get-sizes`
    return await this.getGetByUrl(url)
  }
  async getTypesAtleta(){
    const url = `${this.baseUrl}/get-types-atleta`
    return await this.getGetByUrl(url)
  }
  async getAllItemsForMindTableClienti(opts = {}){
    let items = []
    let totalItems = 0

    const url = `${this.baseUrl}/clienti/search`
    
    try {
      let res = await this.http.post(url, {filters: opts})
      res = res.data

      items = res.data
      totalItems = res.meta.total_items
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded')
      
      // eslint-disable-next-line no-unsafe-finally
      return {
        items,
        total: totalItems,
      }
    }
  }


  async tryToLogin(fd){
    let item = [];
    const url = `${this.baseUrl}/tryToLogin`
    try {
      const res = await axios.post(url, fd)
      item = res.data
      if (item.status){
        const thisdom = service.main_domain.cookie.replace(/^\.+/, '');
        console.log(thisdom)
        Cookies.set('ask_access_token', item.token, { domain: thisdom, expires: 1 }); // Imposta il cookie per scadere tra 7 giorni
        axios.defaults.headers.common['Authorization'] = `Bearer ${item.token}`;
      }
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }

  async postRequest(endpoint, fd) {
    const url = `${this.baseUrl}/${endpoint}`;
    let item = [];
    try {
      const res = await axios.post(url, fd);
      item = res.data;
    } catch (err) {
      this.eventBus.emit('data-load-error', { message: err, url });
    } finally {
      this.eventBus.emit('data-loaded');
      // eslint-disable-next-line no-unsafe-finally
      return { item };
    }
  }

  async importTransaction(fd){
    return this.postRequest.call(this,'transactions/import', fd);
  }
  async delTransaction(fd){
    return this.postRequest.call(this,'transactions/delete', fd);

  }

  async delToPayAtleta(fd){
    let item = [];
    const url = `${this.baseUrl}/del-topay-atleta`
    try {
      const res = await axios.post(url, fd)
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }

  async delCorsoAtleta(fd){
    let item = [];
    const url = `${this.baseUrl}/remove-corso-atleta`
    try {
      const res = await axios.post(url, fd)
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }

  async delDoc(fd){
    let item = [];
    const url = `${this.baseUrl}/remove-doc`
    try {
      const res = await axios.post(url, fd)
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }
  async sendProductToBuy(fd){
    let item = [];
    const url = `${this.baseUrl}/save-product-to-buy`
    try {
      const res = await axios.post(url, fd,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }
  async sendKitToBuy(fd){
    let item = [];
    const url = `${this.baseUrl}/save-kit-to-buy`
    try {
      const res = await axios.post(url, fd,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }
  async sendReset(fd){
    let item = [];
    const url = `${this.baseUrl}/resetPwd`
    try {
      const res = await axios.post(url, fd,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }
  async sendDoc(fd){
    let item = [];
    const url = `${this.baseUrl}/save-doc`
    try {
      const res = await axios.post(url, fd,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }
  async sendModulistica(fd){
    let item = [];
    const url = `${this.baseUrl}/modulistica/save`
    try {
      const res = await axios.post(url, fd,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }
 
  
  async saveKit(fd){
    let item = [];
    const url = `${this.baseUrl}/save-kit`
    try {
      const res = await axios.post(url, fd)
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }

  async sendDataNewUser(fd){
    let item = [];
    const url = `${this.baseUrl}/creazione-utente`
    try {
      const res = await axios.post(url, fd)
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }
  async sendMessaggeGruppoPrenotati(fd){
    let item = [];
    const url = `${this.baseUrl}/sendNotificaGruppoCorsoPrenotati`
    try {
      const res = await axios.post(url, fd)
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }

  async sendRichiestaProva(fd){
    let item = [];
    const url = `${this.baseUrl}/sendRichiestaProva`
    try {
      const res = await axios.post(url, fd)
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }

  async sendRichiestaPulmino(fd){
    let item = [];
    const url = `${this.baseUrl}/savePulmino`
    try {
      const res = await axios.post(url, fd)
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }
  async savePresenze(fd){
    let item = [];
    const url = `${this.baseUrl}/save-presenze`
    try {
      const res = await axios.post(url, fd)
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }

  async saveAssenzeStaff(fd){
    let item = [];
    const url = `${this.baseUrl}/save-assenze-staff`
    try {
      const res = await axios.post(url, fd)
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }

  async sendDataNewAtleta(fd){
    let item = [];
    const url = `${this.baseUrl}/creazione-atleta`
    try {
      const res = await axios.post(url, fd)
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }

  
  async saveOrarioSessione(fd){
    let item = [];
    const url = `${this.baseUrl}/save-orario-sessione`
    try {
      const res = await axios.post(url, fd)
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }
  async saveCampoSessione(fd){
    let item = [];
    const url = `${this.baseUrl}/save-campo-sessione`
    try {
      const res = await axios.post(url, fd)
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }
  async saveAtleta(fd,id){
    const url = `${this.baseUrl}/saveAtleta/${id}`
    return await this.getPostByUrl(url, fd)
  }
  async sendTorneo(fd){
    const url = `${this.baseUrl}/sendTorneo`
    return await this.getPostByUrl(url, fd)
  }
  async saveConsegnaMerce(fd){
    const url = `${this.baseUrl}/saveConsegnaMerce`
    return await this.getPostByUrl(url, fd)
  }
  async sendNewDataUtente(fd){
    const url = `${this.baseUrl}/modifica-utente`
    return await this.getPostByUrl(url, fd)
  }
  async sendNewDataAtleta(fd){
    const url = `${this.baseUrl}/modifica-atleta`
    return await this.getPostByUrl(url, fd)
  }
  async updateRateIscrizione(fd){
    const url = `${this.baseUrl}/aggiorna-rate-iscrizione`
    return await this.getPostByUrl(url, fd)
  }
  async updateVenditaConsegnata(fd){
    const url = `${this.baseUrl}/updatePreOrdineConsegna`
    return await this.getPostByUrl(url, fd)
  }
  async delMovimento(fd){
    const url = `${this.baseUrl}/delMovimento`
    return await this.getPostByUrl(url, fd)
  }
  async viewRicevuta(fd){
    const url = `${this.baseUrl}/view-ricevuta`
    return await this.getPostByUrl(url, fd)
  }
  async sendRicevuta(id,fd){
    const url = `${this.baseUrl}/sendRicevuta/${id}`
    return await this.getPostByUrl(url, fd)
  }
  async approvaAttivita(id,fd){
    const url = `${this.baseUrl}/set-approved-attivita/${id}`
    return await this.getPostByUrl(url, fd)
  }
  async delCorsoData(fd){
    const url = `${this.baseUrl}/delCorsoData`
    return await this.getPostByUrl(url, fd)
  }
  async cancellaRicevuta(id,fd){
    const url = `${this.baseUrl}/cancellaRicevuta/${id}`
    return await this.getPostByUrl(url, fd)
  }
  async aggiornaRicevuta(id,fd){
    const url = `${this.baseUrl}/aggiornaRicevuta/${id}`
    return await this.getPostByUrl(url, fd)
  }
  async saveCorsoData(fd){
    const url = `${this.baseUrl}/update-corso`
    return await this.getPostByUrl(url, fd)
  }
  async saveProdottoData(fd){
    const url = `${this.baseUrl}/update-prodotto`
    return await this.getPostByUrl(url, fd)
  }
  async saveKitData(fd){
    const url = `${this.baseUrl}/update-kit`
    return await this.getPostByUrl(url, fd)
  }
  async sendVendita(fd){
    const url = `${this.baseUrl}/creazione-vendita`
    return await this.getPostByUrl(url, fd)
  }
  async sendPagamento(fd){
    const url = `${this.baseUrl}/creazione-pagamento`
    return await this.getPostByUrl(url, fd)
  }
  async saveMagazzino(fd){
    const url = `${this.baseUrl}/creazione-magazzino`
    return await this.getPostByUrl(url, fd)
  }
  async saveDataNewElement(type_element, fd){
    const url = `${this.baseUrl}/creazione-${type_element}`
    return await this.getPostByUrl(url, fd)
  }
  async sendDataNewPersonale(fd){
    const url = `${this.baseUrl}/creazione-personale`
    return await this.getPostByUrl(url, fd)
  }
  async setCompleteSession(fd){
    const url = `${this.baseUrl}/completa-sessione`
    return await this.getPostByUrl(url, fd)
  }
  async setStripeUsed(fd,id){
    const url = `${this.baseUrl}/setStripeUsed/${id}`
    return await this.getPostByUrl(url, fd)
  }
  async setBancaUsed(fd,id){
    const url = `${this.baseUrl}/setBancaUsed/${id}`
    return await this.getPostByUrl(url, fd)
  }
  async saveConvocazione(fd){
    const url = `${this.baseUrl}/saveConvocazione`
    return await this.getPostByUrl(url, fd)
  }
  
  async sendDataNewCorso(fd){
    let item = [];
    const url = `${this.baseUrl}/creazione-corso`
    try {
      const res = await axios.post(url, fd)
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }
  async sendDataNewIscrizione(fd){
    let item = [];
    const url = `${this.baseUrl}/creazione-iscrizione`
    try {
      const res = await axios.post(url, fd)
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }
  
  async updateVisibileNewsInApp(id, fd) {
    let item = [];
    // Implementa la logica per chiamare l'API e aggiornare la visibilità
    const url = `${this.baseUrl}/set-news/${id}/visibile`
    try {
      const res = await axios.post(url, fd)
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }
  async updateVisibileSponsor(id, fd) {
    let item = [];
    // Implementa la logica per chiamare l'API e aggiornare la visibilità
    const url = `${this.baseUrl}/set-sponsor/${id}/visibile`
    try {
      const res = await axios.post(url, fd)
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }
  async updateVisibileModulistica(id, fd) {
    let item = [];
    // Implementa la logica per chiamare l'API e aggiornare la visibilità
    const url = `${this.baseUrl}/modulistica/${id}/visibile`
    try {
      const res = await axios.post(url, fd)
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }
  async updateVisibile(id, fd) {
    let item = [];
    // Implementa la logica per chiamare l'API e aggiornare la visibilità
    const url = `${this.baseUrl}/set-statica/${id}/visibile`
    try {
      const res = await axios.post(url, fd)
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }

  async updateApprovaStripe(fd,id){
    let item = [];
    // Implementa la logica per chiamare l'API e aggiornare la visibilità
    const url = `${this.baseUrl}/approvaStripe/${id}`
    try {
      const res = await axios.post(url, fd)
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }
  async updateDeleteRichiestaPulmino(fd){
    let item = [];
    // Implementa la logica per chiamare l'API e aggiornare la visibilità
    const url = `${this.baseUrl}/deletePulmino`
    try {
      const res = await axios.post(url, fd)
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }
  async updateCancelApprovaRichiestaPulmino(fd){
    let item = [];
    // Implementa la logica per chiamare l'API e aggiornare la visibilità
    const url = `${this.baseUrl}/notApprovaPulmino`
    try {
      const res = await axios.post(url, fd)
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }
  async updateApprovaRichiestaPulmino(fd){
    let item = [];
    // Implementa la logica per chiamare l'API e aggiornare la visibilità
    const url = `${this.baseUrl}/approvaPulmino`
    try {
      const res = await axios.post(url, fd)
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }
  async updateApprovaRichiesta(id,fd){
    let item = [];
    // Implementa la logica per chiamare l'API e aggiornare la visibilità
    const url = `${this.baseUrl}/set-approved-richiesta/${id}`
    try {
      const res = await axios.post(url, fd)
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }

  async updateApprovaDocumento(id,fd){
    let item = [];
    // Implementa la logica per chiamare l'API e aggiornare la visibilità
    const url = `${this.baseUrl}/set-approved-documento/${id}`
    try {
      const res = await axios.post(url, fd)
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }
  
  async deleteModulistica(id, fd) {
    let item = [];
    // Implementa la logica per chiamare l'API e aggiornare la visibilità
    const url = `${this.baseUrl}/modulistica/${id}/delete`
    try {
      const res = await axios.post(url, fd)
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }


  async deleteValueStatica(id, fd) {
    let item = [];
    // Implementa la logica per chiamare l'API e aggiornare la visibilità
    const url = `${this.baseUrl}/del-statica/${id}`
    try {
      const res = await axios.post(url, fd)
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }

  async deleteValueNewsInApp(id, fd) {
    let item = [];
    // Implementa la logica per chiamare l'API e aggiornare la visibilità
    const url = `${this.baseUrl}/del-newsInApp/${id}`
    try {
      const res = await axios.post(url, fd)
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }  async deleteValueSponsor(id, fd) {
    let item = [];
    // Implementa la logica per chiamare l'API e aggiornare la visibilità
    const url = `${this.baseUrl}/del-sponsor/${id}`
    try {
      const res = await axios.post(url, fd)
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }
  
  async sendStatica(fd){
    let item = [];
    const url = `${this.baseUrl}/add-statica`
    try {
      const res = await axios.post(url, fd)
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }
  async sendNews(fd){
    let item = [];
    const url = `${this.baseUrl}/add-news`
    try {
      const res = await axios.post(url, fd)
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }
  async sendSponsor(fd){
    let item = [];
    const url = `${this.baseUrl}/add-sponsor`
    try {
      const res = await axios.post(url, fd)
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }
  async checkCampi(fd){
    let item = [];
    const url = `${this.baseUrl}/verifica-disponibilita`
    try {
      const res = await axios.post(url, fd)
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }
}
