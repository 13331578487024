<template>
    <div class="page-vendita-detail">
      <vendita-detail />
    </div>
  </template>
  
  <script>
  import VenditaDetail from '@/components/details/VenditaDetail.vue'
  
  export default {
    name: 'ViewVenditaDetail',
    components: {
      VenditaDetail,
    },
  
    data() {
      return {
        pageName: 'VenditaDetail',
      }
    },
  
    created(){
  
    },
  
    mounted(){
      this.$eventBus.emit('page-change', {
        target: this,
        currentRoute: this.$router.currentRoute.value
      })
    },
    
  }
  </script>