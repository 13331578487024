<template>
    <Dialog :visible="visible" modal :style="{ width: '50rem' }" :closable="false">  
        <template #header>
            <div class="flex justify-content-between w-full">
                <h3>Modifica orario inizio e fine Sessione</h3>
                <Button icon="pi pi-times" @click="closeDialog" class="p-button-text p-button-rounded p-button-secondary"/>
            </div>
        </template>
        Seleziona il nuovo orario  
        <div class="m-2">
            <label class="mr-2">Ora inizio</label>
            <Calendar v-model="slot.time_in" timeOnly :stepMinute="30" :manualInput="false"
            @update:modelValue="onChangeInput($event,'orario','in',index, id_slot)" :style="{width:'55px'}"/>
        </div>
        <div class="m-2">
            <label class="mr-2">Ora fine</label>
            <Calendar v-model="slot.time_out" timeOnly :stepMinute="30" :manualInput="false" :style="{width:'55px'}" 
            @update:modelValue="onChangeInput($event, 'orario', 'out')"/>
        </div>
        <p class="p-3"><label>Clicca qui se vuoi cambiare questo campo per tutte le sessioni di questo giorno della settimana da ora in poi<br/></label>
        <input type="checkbox"  v-model="forever"/>
        </p>
      
        <template #footer>
            <Button label="Salva" @click="save"/>
        </template>
    </Dialog>
</template>

<script>
import { ref, watch } from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';

export default {
    name: 'DialogComponentCampi',
    components: {
        Dialog,
        Button
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        startSlot: {
            type: String,
            required: true
        },
        endSlot: {
            type: String,
            required: true
        }
    },
    setup(props, { emit }) {
        const forever = ref(false)
        const slot = ref({});
        
        watch(() => props.startSlot, (newVal) => {
            console.log("newVal")
            console.log(newVal)
            slot.value.time_in = new Date(newVal);//new Date(newVal) ;
           
        });
        watch(() => props.endSlot, (newVal) => {
            slot.value.time_out = new Date(newVal);
           console.log(slot.value)
        });
        const closeDialog = () => {
            emit('close');
        };
        const save = () => {
            let formattedTime = slot.value.time_in.toLocaleTimeString('it-IT', { hour: '2-digit', minute: '2-digit' });
            let formattedTime_out = slot.value.time_out.toLocaleTimeString('it-IT', { hour: '2-digit', minute: '2-digit' });

            emit('save',  formattedTime,formattedTime_out,
            forever.value);
          
            closeDialog();
        };
        return {
            closeDialog,
            save,forever,slot
        };
    }
};
</script>

<style scoped>
</style>
