<template>
     <Loader v-if="loading"/>
    <div> 
        <Card style="margin-bottom:10px">

    <template v-slot:content>
          <button  class="highlight-btn p-button p-button-danger" @click="changeData()">Mostra {{ showAllText }}</button>
        
    </template>
    </Card>
        <Card style="margin-bottom:10px">
    <template v-slot:title>
      <h3>Carica un file dalla banca</h3>
    </template>
    <template v-slot:content>
         <br/><input type="file" @change="onFileChange">
    </template>
    </Card>
        <Card v-if="showAll">
    <template v-slot:title>
      <h3>{{ quanti }} Movimenti contabilizzati</h3>
    </template>
    <template v-slot:content>
        <DataTable :value="transactions" responsiveLayout="scroll" v-if="quanti>0">
            <Column field="data_valuta" header="Data Valuta" />
            <Column field="data_contabile" header="Data Contabile" />
            <Column field="importo" header="Importo" style="text-align: right;" />
            <Column field="descrizione" header="Descrizione">
        
            </Column>
            <Column header="Dettaglio">
                <template #body="slotProps">
                    <button
                    class="highlight-btn p-button p-button-info" @click="goToAtleta(slotProps.data.id_atleta)">  {{ slotProps.data.atleta }}</button>
                </template>
            </Column>

         
        </DataTable>
        <p v-else>Al momento non ci sono movimenti contabilizzati</p>
    </template>
    </Card>


    <Card v-if="!showAll">
    <template v-slot:title>
      <h3>No {{ quantiNo }} Movimenti da contabilizzare</h3>
    </template>
    <template v-slot:content>
        <DataTable :value="transactionsNotUsed" responsiveLayout="scroll" v-if="quantiNo>0">
            <Column field="data_valuta" header="Data Valuta" />
            <Column field="data_contabile" header="Data Contabile" />
            <Column field="importo" header="Importo" style="text-align: right;" />
            <Column field="descrizione" header="Descrizione">
                <template #body="slotProps">
                 <span> {{slotProps.data.descrizione}}  
                 <template  v-for="match in highlightNames(slotProps.data.descrizione, atleti)" :key="match.id">
                    <button
                        class="highlight-btn p-button p-button-success"
                        @click="openConfirmDialogUsed(match.id,slotProps.data.id)">
                        {{ match.text }}
                    </button>
                    </template>
                </span>
            </template>
            </Column>


            <Column header="Azioni" >
                <template #body="{ data }">
                    <div>
                    <Button v-if="!showAll"
                        label="Contabilizza" 
                        class="p-button" 
                        @click="openConfirmDialogUsed(null,data.id)" 
                    />
                    <Button   v-if="!showAll"
                        label="Cancella" 
                        class="p-button p-button-warning" 
                        @click="cancella(data.id)" 
                    />
                    </div>
                </template>
            </Column>
        </DataTable>
        <p v-else>Al momento non ci sono movimenti da contabilizzare</p>
    </template>
    </Card>

        <div v-if="showModal">
            <div class="modal">
                <h3>Contabilizza Transazione</h3>
                <label for="atleta">Seleziona Atleta:</label><br/>
                
                <Dropdown v-model="selectedAtleta" :options="atleti" :filter="true" optionLabel="nomecompleto" optionValue="id" placeholder="Seleziona atleta" :class="['inputfield w-full']" :virtualScrollerOptions="{ itemSize: 10 }" @change="changeAtleta($event)"/>
                <DataTable :value="dapagare" class="p-datatable-gridlines" v-model:selection="pagamento.richieste_pagamenti" :metaKeySelection="false"
                        @rowSelect="onRowSelect" @rowUnselect="onRowSelect" selectionMode="multiple" >
                        <Column field="corso" header="Descrizione">
                            <template #body="{data}">
                                {{ data.n_rata ? 'Rata n. ' + data.n_rata + ' del corso ' + data.corso : '' }}
                                {{ data.kit ? 'Kit ' + data.kit : '' }}
                                {{ data.prodotto ? data.prodotto : '' }}
                            </template>
                     </Column>
                            <Column field="importo" header="Importo €" style='text-align: right'></Column>
                            <template #footer v-if="viewSetPagamento">
                                <div>
                                  
                                    <div class="col-12 flex justify-content-between" style="font-size: 2rem;">
                                        <div class="col-6 flex justify-content-between">
                                            <p>Totale: € {{ pagamento.totale }}</p>
                                        </div>
                                        <div class="col-6 flex justify-content-end">
                                            <Button class="p-button-success m-3" icon="pi pi-check" label="Completa pagamento" @click="sendPagamento" :disabled="canSavePagamento"/>
                                            <Button class="p-button-info m-3" icon="pi pi-file-pdf" label="Visualizza ricevuta" @click="viewRicevuta" v-if="id_pagamento"/>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </DataTable>
       
        <br/>
        <br/>
        <Button @click="confirmDialogRejectUsed" label="Annulla" class="p-button-secondary" />
        </div>
    </div>
    </div>





</template>

<script>
import {ref,onMounted, getCurrentInstance,computed } from 'vue';
import Service from '@/services/levante-api/levante-api'
import Loader from "@/components/Loader.vue";
import { useToast } from 'primevue/usetoast';
export default {
    components(){
        return [
            Loader
        ]
    },
  
    setup(){
        const self = getCurrentInstance();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const service = new Service(self.$eventBus);
        const toast = useToast();
        const showAllText = ref("contabilizzati")
        const showAllTextAlt = ref("da contabilizzare")
        const transactions = ref({})
        const transactionsNotUsed = ref({})
        const atleti = ref({})
        const dapagare = ref({})
        const quanti = ref(0)
        const quantiNo = ref(0)
        const showModal = ref(false)
        const showAll = ref(false)
        const selectedTransaction = ref()
            const selectedAtleta =ref()
        const loading = ref(false)
        const lifeTime = 3000;
        const pagamento = ref({
            'idstripe':null,
            'richieste_pagamenti': [],
            'type_pay': 2,
            'totale': 0,
            'id_atleta': null,
            'id_transaction': null
        })
        const confirmDialogUsed = ref({
      visible: false,
      id: null,
      visibile: null,
      selectedCorso: null
    });
    const goToAtleta = (idatleta)=> {
            window.open(`/atleta/${idatleta}/dettagli`)
        }
    const changeData = () => {
        console.log("changeData")
        showAll.value=!showAll.value
        if (showAll.value){
            showAllText.value="da contabilizzare";
            showAllTextAlt.value="contabilizzati"
        }else{
            showAllText.value="contabilizzati"
            showAllTextAlt.value="da contabilizzare"
        }
        fetchData()
    }

        const openConfirmDialogUsed = async (id, idstripe) => {
            pagamento.value.idstripe = idstripe
            pagamento.value.id_transaction = idstripe;
            pagamento.value.totale = 0;
            pagamento.value.richieste_pagamenti = [];
            pagamento.value.id_atleta = null
            selectedAtleta.value=id
            dapagare.value=[]
            if (id){
                pagamento.value.id_atleta = id
                await service.getDaPagare('atleta', id)
                .then((res) => {
                dapagare.value=res.items
                showModal.value=true
                });
            }else{
                console.log("ciao");
                pagamento.value.id_atleta = null
                showModal.value=true
            }
        };
        const sendPagamento = (id) => {
            loading.value = true
            let fd = new FormData();
            fd.append('pagamento', JSON.stringify(pagamento.value));
            
            service.sendPagamento(fd)
            .then((res) => {
              service.setBancaUsed(fd,pagamento.value.idstripe).then((res)=>{

                  showModal.value = false;
                  fetchData();
              })
                let severity = res.item.esito
                let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
                let detail = res.item.messaggio
                
                if (severity != 'warning') {
                    toast.add({severity:severity, summary: summary, detail:detail,life:lifeTime});  
                                       
                }
            }).finally(()=>{
                loading.value = false
            })
        }
        const setTotaleVendita = () => {
            let totale = 0
            pagamento.value.richieste_pagamenti.forEach(prodotto => {
                totale += prodotto.importo
            })
            pagamento.value.totale = totale
        }

        const onRowSelect = (event) => {
            setTotaleVendita()
            if (!Object.keys(pagamento.value.richieste_pagamenti).length) {
                pagamento.value.type_pay = null
                
            }
        }
        const highlightNames = (description, atleti) => {
            const matches = [];
            if (showAll.value) return '';
            if (!description) return ''; // Restituisce una stringa vuota se la descrizione è undefined o null.
            description=description.replace("  "," ");
            atleti.forEach(atleta => {
                if (atleta){
                    if (atleta.nomecompleto){
                        const nomeCompleto = atleta.nomecompleto.trim();
                        const regexNomeCompleto = new RegExp(nomeCompleto, 'gi');
                        if (regexNomeCompleto.test(description)) {
                            matches.push({ text: nomeCompleto, id: atleta.id });
                        }
                    }
                    if (atleta.nome && atleta.cognome){
                        const nomeCognome = `${atleta.nome.trim()} ${atleta.cognome.trim()}`;
                        const cognomeNome = `${atleta.cognome.trim()} ${atleta.nome.trim()}`;
                        const regexNomeCognome = new RegExp(nomeCognome, 'gi');
                        const regexCognomeNome = new RegExp(cognomeNome, 'gi');
                        if (regexNomeCognome.test(description)) {
                            matches.push({ text: nomeCognome, id: atleta.id });
                        }
                        if (regexCognomeNome.test(description)) {
                            matches.push({ text: cognomeNome, id: atleta.id });
                        }  // Sostituisce le occorrenze trovate con una versione evidenziata
                    }
                }
            });
            
            return matches;

        }
        const viewSetPagamento = computed(()=>{
            return Object.keys(pagamento.value.richieste_pagamenti).length
        })
        const fetchData = async() => {
            if (showAll.value){
                service.getAllTransactionsUsed().then((res) => {
                    console.log("getAllTransactionsUsed");
                    console.log(res.items);
                    transactions.value = res.items;
                    quanti.value=Object.values(transactions.value).length;
                    console.log(quanti);
                    transactionsNotUsed.value=[]
                    quantiNo.value=0
                }).finally(()=>{
                    loading.value = false;
                });
                
            }else{
                service.getAllTransactionsNoUsed().then((response)=>{
                    console.log("getAllTransactionsNoUsed");
                    console.log(response.items);
                    transactionsNotUsed.value = response.items;
                    quantiNo.value=Object.values(transactionsNotUsed.value).length;
                    console.log(quantiNo.value);
                    transactions.value=[]
                    quanti.value=0
                }).finally(()=>{
                    loading.value = false;
                });
                
            }
        };

        const confirmDialogRejectUsed = () =>{
            showModal.value=false;
            dapagare.value=[];
            pagamento.value.id_atleta = null

        }
     
        const fetchAtleti = async() => {
    
            service.getAtleti().then((res) => {
                console.log(res);
                atleti.value = res.items;
                console.log(atleti.value.length);

                fetchData()

            });
        };


        const cancella = async(transaction)=> {
            let formData = new FormData();
            formData.append('transaction', transaction);

            service.delTransaction(formData).then((res) => {
                fetchData();
            });
            
        };
        const onFileChange = async (event) => {
            const file = event.target.files[0];
            let formData = new FormData();
            formData.append('file', file);

            service.importTransaction(formData) .then((res) => {
                    let severity = res.item.esito
                    let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
                    let detail = res.item.messaggio
                    if (severity != 'warning') {
                        toast.add({severity:severity, summary: summary, detail:detail,life:lifeTime});                            
                    }
                    fetchData()
                });
            
        };
        const changeAtleta = async(event) => {
            console.log("changeAtleta");
            console.log(event)
            await service.getDaPagare('atleta',event.value)
                .then((res) => {
                    pagamento.value.id_atleta = event.value
                dapagare.value=res.items

                });
            }

        const onChangeInput = (evt, prop) => {
             let value
            switch (prop) {
                case 'atleta':
                value = evt.value
                break;
                
                default:
                value = evt.target.value
                break;
            }
            
            
            };
           
        onMounted(() => { 
            loading.value = true
            const promises = [fetchAtleti()]
            
            Promise.all(promises)
            .finally(()=>{
            })
        })
        return {
            goToAtleta,showAllTextAlt,showAllText,changeData,showAll,quanti,onFileChange,onChangeInput,cancella,loading,transactions
            ,transactionsNotUsed,quantiNo,atleti,dapagare,confirmDialogRejectUsed,showModal,selectedAtleta,
            pagamento,changeAtleta,viewSetPagamento,onRowSelect,sendPagamento,highlightNames,openConfirmDialogUsed,confirmDialogUsed
        };
    },
   
}
</script>

<style scoped>
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 1em;
    border: 1px solid #ccc;
}
</style>
