<template>
     
    <router-view />
     
</template>

<script>

</script>

<style lang="scss">
// @import './App.scss';
.p-breadcrumb{
    margin-bottom: 20px;
}
</style>
