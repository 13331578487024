<template>
	<div class="layout-menu-container">
		<div class="logo">
			<div class="newlogo">
					<img :src="getLogo" width=100>
			</div>
		</div>
		<AppSubmenu :items="model" class="layout-menu" :root="true" @menuitem-click="onMenuItemClick" />
	</div>
</template>

<script>
import AppSubmenu from './AppSubmenu';

export default {
	props: {
		model: Array
	},

  methods: {
      onMenuItemClick(event) {
        this.$emit('menuitem-click', event);
      }
  },

	computed: {
		darkTheme() {
			return this.$appState.theme.startsWith('saga');
		},
		getLogo(){
			const LOGO = `${process.env.VUE_APP_LOGO}`;
			return LOGO
		}
	},
  
	components: {
		'AppSubmenu': AppSubmenu
	}
}
</script>

<style scoped>
.logo {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	padding-bottom: 15px;
	margin-bottom: 15px;
	flex-direction:column;
}
.logo:after {
	content: '';
	position: absolute;
	bottom: 0;
	right: 15px;
	height: 1px;
	width: calc(100% - 30px);
	background: rgba(255, 255, 255, 0.5);
}

.wizard-title {
  margin-top: -8px;
  color: rgb(255, 255, 255);
  font-size: 9px;
  letter-spacing: 16px;
  padding-left: 10px;
}
</style>
