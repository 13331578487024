<template>
    <Loader v-if="loading"/>
    <div v-if="loaded_data">
        
        <Card>
            <template #header>
                <div class="p-3 pb-0">
                    <h4>{{ utente.cognome }} {{ utente.nome }}</h4>
                </div>
            </template>
            <template #content>
                <Accordion :activeIndex="0"  @tab-open="getCorsiPersonale($event)">
                    <AccordionTab header="Dettagli">
                        <div class="flex align-items-start flex-wrap">
                            <div class="col-12 flex flex-wrap">
                                <div class="col-12 md:col-6 flex flex-column gap-2">
                                    <label for="codfis">Nome</label>
                                    <InputText id="codfis" v-model="utente.nome" :disabled="!update" :class="['inputfield w-full',{'p-invalid': submitted && validationErrors.nome}]"/>
                                </div>
                                <div class="col-12 md:col-6 flex flex-column gap-2">
                                    <label for="codfis">Cognome</label>
                                    <InputText id="codfis" v-model="utente.cognome" :disabled="!update" :class="['inputfield w-full',{'p-invalid': submitted && validationErrors.cognome}]"/>
                                </div>
                                <div class="col-12 md:col-6 flex flex-column gap-2">
                                    <label for="codfis">Codice fiscale</label>
                                    <InputText id="codfis" v-model="utente.codfis" :disabled="!update" :class="['inputfield w-full',{'p-invalid': submitted && validationErrors.codfis}]"/>
                                </div>
                                <div class="col-12 md:col-6 flex flex-column gap-2">
                                    <label for="codfis">Email</label>
                                    <InputText id="codfis" v-model="utente.username" :disabled="!update" :class="['inputfield w-full',{'p-invalid': submitted && validationErrors.email}]"/>
                                </div>
                                <div class="p-field col-12 md:col-6 flex flex-column">
                                    <label class="col-12">Tipologia</label><br>
                                     <div class="flex justify-content-between">
                                        <div v-for="type in types_personale" :key="type.id"  class="col-6 flex align-items-center">
                                            <Checkbox v-model="utente[type.prop]" :binary="true" :disabled="!update"/>
                                            <label class="ml-2 mr-2">{{ type.nome }}</label>
                                        </div>
                                     </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 flex justify-content-end align-items-center">
                            <label class="mr-2">{{!update ? 'Modifica' : 'Salva'}} dati</label>
                            <InputSwitch v-model="update" @change="changeUpdate"/>
                        </div>
                    </AccordionTab>
                    <AccordionTab header="Corsi">
                        <DataTable :value="corsi" tableStyle="min-width: 50rem" paginator :rows="10" :rowsPerPageOptions="[10, 20, 50]" >
                            <Column field="nome" header="Nome">
                                <template #body="{data}">
                                    <Button :label="data.nome" class="p-button-link" @click="navigateTo('corso',data.id)"/>
                                </template>
                            </Column>
                            <Column field="tipo_corso" header="Tipologia"></Column>

                            <Column field="data_inizio" header="Data inizio">
                                <template #body="{data}">
                                    {{ moment(data.data_inizio).format('DD/MM/Y') }}
                                </template>
                            </Column>
                            <Column field="data_fine" header="Data fine">
                                <template #body="{data}">
                                    {{ moment(data.data_fine).format('DD/MM/Y') }}
                                </template>
                            </Column>
                        </DataTable>
                    </AccordionTab>

                    <AccordionTab header="Presenze" v-if="utente.is_allenatore">
                        <DataTable :value="presenze" tableStyle="min-width: 50rem" paginator :rows="10" :rowsPerPageOptions="[10, 20, 50]" >
                            <Column field="nome" header="Nome">
                                <template #body="{data}">
                                    {{ data.mese }}
                                </template>
                            </Column>
                            <Column field="quanti" header="#Corsi"></Column>
                            <Column field="previste" header="Previste"></Column>
                            <Column field="assenze" header="Assenze"></Column>
                            <Column field="assenze" header="Totale">
                                <template #body="{data}">
                                    {{ data.previste - data.assenze }}
                                </template>
                            </Column>

                        </DataTable>
                    </AccordionTab>
                </Accordion>
            </template>
        </Card>
    </div>
</template>

<script>
import {ref, onMounted, getCurrentInstance, onUpdated, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router'
import Loader from "@/components/Loader.vue"
import Service from '@/services/levante-api/levante-api'
import moment from 'moment'
import { useToast } from 'primevue/usetoast';

export default {
    components: {
        Loader 
    },
    
    setup () { 
        const toast = useToast();
        const self = getCurrentInstance();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const service = new Service(self.$eventBus)
        const loading = ref(false)
        const loaded_data = ref(false)
        const update = ref(false);
        const submitted = ref(false)
        const validationErrors = ref({})
        const route = useRoute()
        const id = route.params.id
        const utente = ref()
        const types_personale = ref([]) 
        const corsi = ref([]) 
        const presenze = ref([])

        
        const getDetails = async () => {
            await service.getDetails('staff', id)
            .then((res) => {
                utente.value = res.items[0]
                utente.value.is_responsabile = Boolean(utente.value.is_responsabile)
                utente.value.is_allenatore = Boolean(utente.value.is_allenatore)
                loaded_data.value = true
                console.log(utente.value.is_allenatore);
            })
        }

        const getTipoPersonale = async () => {
            await service.getTypePersonale()
            .then((res) => {
                types_personale.value = res.items
                types_personale.value.forEach(type => {
                    type.prop = `is_${type.nome.toLowerCase()}`
                })
                loaded_data.value = true
            })
        }

        const getCorsiPersonale = async (event) => {
            if (event.index == 1) {            
                await service.getCorsiPersonale(id)
                .then((res) => {
                    corsi.value = res.items
                    loaded_data.value = true
                })
            }
        }     
         const getPresenze = async (event) => {
               
                await service.getPresenzeStaff(id)
                .then((res) => {
                    presenze.value = res.items
                    loaded_data.value = true
                })
            
        }

        const navigateTo = (nome_rotta, id) => {
            window.open(`/${nome_rotta}/${id}/dettagli`, '_blank')
        }
 

        onMounted(() => { 
            loading.value = true
            const promises = [getDetails(),getTipoPersonale(),getPresenze()]
            
            Promise.all(promises)
            .then(()=>{
                
            }).finally(()=>{
                loading.value = false
            })
        })
        
        return {
            moment,
            loading,
            loaded_data,
            utente,
            update,
            submitted,
            validationErrors,
            types_personale,
            corsi,
            presenze,
            getCorsiPersonale,
            navigateTo,
        }
    },
}
</script>

<style>
</style>
