<template>
    <Loader v-if="loading"/>
    <div v-if="loaded_data">
        
        <Card>
            <template #header>
                <div class="p-3 pb-0">
                    <h4>Kit: {{ prodotto.nome }}</h4>
                </div>
            </template>
            <template #content>
                <Accordion :activeIndex="0"  @tab-open="getCaricoScarico($event)">
                    <AccordionTab header="Dettagli">
                        <div class="flex align-items-start flex-wrap">
                            <div class="col-12 flex flex-wrap">
                                <div class="col-12 md:col-4 flex flex-column gap-2">
                                    <label for="nome">Nome</label>
                                    <InputText id="nome" v-model="prodotto.nome" :disabled="!update" :class="['inputfield w-full',{'p-invalid': submitted && validationErrors.nome}]"/>
                                </div>
                                <div class="col-12 md:col-4 flex flex-column gap-2">
                                    <label for="descrizione">Descrizione</label>
                                    <Textarea id="descrizione" v-model="prodotto.descrizione" :disabled="!update" :class="['inputfield w-full',{'p-invalid': submitted && validationErrors.descrizione}]"/>
                                </div>
                                <div class="col-12 md:col-4 flex flex-column gap-2">
                                    <label for="prezzo">Prezzo</label>
                                    <InputNumber id="prezzo" v-model="prodotto.prezzo" showButtons mode="currency" currency="EUR" :disabled="!update" :class="['inputfield w-full',{'p-invalid': submitted && validationErrors.prezzo}]" :step="0.01"/>
                                </div>
                                <div class="col-12">
                                    <label>In Vendita</label><br/>
                                    <Checkbox v-model="prodotto.invendita" :binary="true" />

                                </div>
                                
                                
                                
                            </div>
                        </div>
                        <div class="col-12 flex justify-content-end align-items-center">
                            <label class="mr-2">{{!update ? 'Modifica' : 'Salva'}} dati</label>
                            <InputSwitch v-model="update" @change="changeUpdate"/>
                        </div>
                    </AccordionTab>
                    <AccordionTab header="Prodotti">
                    
                        <DataTable :value="prodotti" class="p-datatable-gridlines">
                                    <Column field="nome" header="Nome"></Column>
                                    <Column field="rimanenza" header="Rimanenza"></Column>
                                </DataTable>
                    </AccordionTab>
                    
                </Accordion>
            </template>
        </Card>
    </div>


    

</template>
<script>
import {ref, onMounted, getCurrentInstance, onUpdated, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router'
import Loader from "@/components/Loader.vue"
import Service from '@/services/levante-api/levante-api'
import moment from 'moment'
import { useToast } from 'primevue/usetoast';
import Textarea from 'primevue/textarea';

export default {
    components: {
        Loader,Textarea
    },
    
    setup () { 
        const self = getCurrentInstance();
        const toast = useToast();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const service = new Service(self.$eventBus)
        const loading = ref(false)
        const loaded_data = ref(false)
        const update = ref(false);
        const submitted = ref(false)
        const validationErrors = ref({})
        const route = useRoute()
        const id = route.params.id
        const movimentazioni = ref()
        const visible = ref(false)
        const lifeTime = 3000
        const prodotto = ref()
        const prodotti = ref([])
        
        const getDetails = async () => {
            await service.getDetails('kit', id)
            .then(async (res) =>{
                prodotto.value = res.items
                await service.getKitDetails(id)
                .then((res) => {
                    prodotti.value=res.items
                    if (prodotto.value.invendita==0) prodotto.value.invendita=false;
                    else prodotto.value.invendita=true              
                    loaded_data.value = true
                })
            })
        }

        const addProdotto=async ()=>{

        }

        
        const validateForm = () => {
            
            let validate_properties = [
            'nome',
            ]
            validate_properties.forEach( prop => {
                if (prodotto.value[prop] == '' || prodotto.value[prop] == null) {
                    validationErrors.value[prop] = true
                } else {
                    delete validationErrors.value[prop];
                    
                    
                }
            })
            // Fine Validazione atleta
            
            return !Object.keys(validationErrors.value).length;
        }
        const sendData = () => {
            submitted.value = true;
            validationErrors.value = []
            if(validateForm()){
                
                let fd = new FormData();
                fd.append('kit', JSON.stringify(prodotto.value));
                
                service.saveKitData(fd)
                .then((res) => {
                    let severity = res.item.esito
                    let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
                    let detail = res.item.messaggio
                    
                    if (severity != 'warning') {
                        toast.add({severity:severity, summary: summary, detail:detail,life:lifeTime});                            
                    }
                    
                    update.value = res.item.esito == 'danger' ? true : false
                })
            }else{
                update.value = true
                toast.add({severity:'warn', summary: 'Attenzione', detail:`Ci sono dei dati mancanti`,life:lifeTime});
            }
            
        }
        const changeUpdate = () => {
            if (!update.value) {
                sendData()
            }
        }
        const navigateTo = (nome_rotta, id) => {
            console.log(id);
            window.open(`/${nome_rotta}/${id}/dettagli`, '_blank')
        }
        
        
        onMounted(() => { 
            loading.value = true
            const promises = [getDetails()]
            
            Promise.all(promises)
            .then(()=>{
                
            }).finally(()=>{
                loading.value = false
            })
        })
        
        return {
            moment,
            loading,
            loaded_data,
            prodotto,
            update,
            submitted,
            validationErrors,
            changeUpdate,
            navigateTo,
            movimentazioni,
            visible,prodotti
        }
    },
}
</script>

<style>
</style>
