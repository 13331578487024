import axios from 'axios'
import services from '@/config/services.js'
import authHeader from '../auth/auth-header';
import Cookies from 'js-cookie';
import FileManager from '@/services/levante-file-manager/index.js'
import { property } from 'underscore';

export default class DataService{
  constructor(eventBus){
    this.eventBus = eventBus
    this.baseUrl = `${services.levante_api.url}`
    this.filemanager = new FileManager()
    axios.interceptors.request.use(
      (req) => {
        req.headers["Authorization"] = authHeader()
        req.headers["Params"] = Cookies.get('google_access_token') || Cookies.get('ask_access_token')
        // this.eventBus.emit('data-loading');
        return req
      }
    )
  }
  getAllColumnsForMindTableVendite(){
    return [
      {
        property: 'atleta',
        label: 'Atleta',
        sortable: true,
        default: true,
      }, {
        property: 'quantita',
        label: 'Quantita',
        sortable: true,
        default: true,
      }, {
        property: 'totale_pagato',
        label: 'Importo',
        sortable: true,
        default: true,
      }, {
        property: 'cassa',
        label: 'Cassa',
        sortable: true,
        default: true,
      }, {
        property: 'data_vendita',
        label: 'Data',
        sortable: true,
        default: true,
      }
    ]
  }
  
  getAllColumnsForMindTableCorsi(){
    return [
      {
        property: 'nome',
        label: 'Nome',
        sortable: true,
        default: true,
      }, {
        property: 'tipologia',
        label: 'Tipologia',
        sortable: true,
        default: true,
      },{
        property:'iscritti',
        label:'Iscritti',
        sortable:true,
        default:true,
      },
      {
        property: 'data_inizio',
        label: 'Data Inizio',
        sortable: true,
        default: false,
      },
      {
        property: 'data_fine',
        label: 'Data Fine',
        sortable: true,
        default: false,
      },
      {
        property: 'annuale',
        label: 'Prezzo',
        sortable: true,
        default: true,
      },
      {
        property: 'n_allenamenti',
        label: 'Sessioni',
        sortable: true,
        default: true,
      },
    ]
  }
  getAllColumnsForMindTableClienti(){
    return [
      {
        property: 'nome',
        label: 'Nome',
        sortable: true,
        default: true,
      }, {
        property: 'cognome',
        label: 'Cognome',
        sortable: true,
        default: true,
      },
      {
        property:'corsi',
        label:'#Corsi',
        sortable:true,
        default:true
      },
      {
        property: "email",
        label: "Email",
        default: true,
        sortable:true,
      },
      {
        property: "telefono",
        label: "Telefono",
        default: true,
        sortable:true,
      },
      {
        property: "datadinascita",
        label: "Data di nascita",
        default: true,
        sortable:false,
      },
      {
        property: "note",
        label: "Note",
        default: true,
      },
      
    ]
  }

  getAllColumnsForMindTableStaff(){
    return [
      {
        property: 'nome',
        label: 'Nome',
        sortable: true,
        default: true,
      }, {
        property: 'cognome',
        label: 'Cognome',
        sortable: true,
        default: true,
      },
      {
        property: "is_responsabile",
        label: "Responsabile",
        sortable: true,
        default: true,
      },
      {
        property: "is_allenatore",
        label: "Allenatore",
        sortable: true,
        default: true,
      },
      {
        property:'quanti',
        label:'#Corsi',
        sortable:true,
        default:true
      },
      {
        property: "codfis",
        label: "Codice Fiscale",
        default: true,
      }      
    ]
  }

  getAllColumnsForMindTableMerce(){
    return [
      {
        property: 'nome',
        label: 'Nome',
        sortable: true,
        default: true,
      },
      {
        property: 'prezzo',
        label: 'Prezzo',
        sortable: true,
        default: true,
      },
      {
        property: 'rimanenza',
        label: 'In Magazzino ORA',
        sortable: true,
        default: true,
      },
      {
        property: 'venduti',
        label: 'Venduti',
        sortable: true,
        default: true,
      },
      {
        property: 'incasso',
        label: 'Incasso',
        sortable: true,
        default: true,
      }
    ]
  }

  getAllColumnsForMindTableKit(){
    return [
      {
        property: 'nome',
        label: 'Nome',
        sortable: true,
        default: true,
      },
      {
        property: 'prezzo',
        label: 'Prezzo',
        sortable: true,
        default: true,
      },
      {
        property: 'rimanenza',
        label: 'In Magazzino ORA',
        sortable: true,
        default: true,
      },
      {
        property: 'venduti',
        label: 'Venduti',
        sortable: true,
        default: true,
      },
      {
        property: 'incasso',
        label: 'Incasso',
        sortable: true,
        default: true,
      }
    ]
  }







}
