<template>
  <Loader v-if="loading" />    
  <Card>
    <template v-slot:title>
      <h3>Crea Corso</h3>
    </template>
    <template v-slot:content>
      <div>
        <div class="formgrid grid mt-2">
          <!-- Selezione tipologia corso -->
          <div class="p-field col-12">
            <label for="id_corso">Tipologia</label><br>
            <Dropdown id="id_corso" v-model="corso.cod_type_corso" :options="types" :filter="true" placeholder="Seleziona tipologia corso" :class="['inputfield w-full',{'p-invalid': validationErrors.cod_type_corso && submitted}]" :virtualScrollerOptions="{ itemSize: 38 }" @change="onChangeInput($event, 'cod_type_corso')">
              <template #value="slotProps">
                <div v-if="slotProps.value" class="flex align-items-center">
                  <div>{{ slotProps.value.nome }}</div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="flex align-items-center">
                  <div>{{ slotProps.option.nome }}  {{ slotProps.option.nota }}</div>
                </div>
              </template>
            </Dropdown>  
            <small v-show="validationErrors.cod_type_corso && submitted" class="p-error">Campo richiesto.</small>
          </div>
          <!-- Nome Corso -->
          <div class="p-field col-12 md:col-4" v-if="corso.cod_type_corso">
            <label for="nome">Nome</label><br>
            <InputText id="nome" v-model="corso.nome" :class="['inputfield w-full',{'p-invalid': validationErrors.nome && submitted}]" @input="(val) => (corso.nome = corso.nome.toUpperCase())" @change="onChangeInput($event, 'nome')"/>
            <small v-show="validationErrors.nome && submitted" class="p-error">Campo richiesto.</small>              
          </div>
          <!-- Valore Corso annuale-->
          <div class="p-field col-12 xl:col-2" v-if="corso.cod_type_corso">
            <label for="prezzo_anno">Prezzo annuale €</label><br>
            <InputNumber v-model="corso.prezzo_anno" :class="['inputfield w-full',{'p-invalid': validationErrors.prezzo_anno && submitted}]" @input="onChangeInput($event, 'prezzo_anno')"/>
            <small v-show="validationErrors.prezzo_anno && submitted" class="p-error">Campo richiesto o errato.</small>              
          </div>
          <!-- Valore Corso bimestrale-->
          <div class="p-field col-12 xl:col-2" v-if="corso.cod_type_corso">
            <label for="prezzo_semestre">Prezzo semestrale €</label><br>
            <InputNumber v-model="corso.prezzo_semestre" :class="['inputfield w-full',{'p-invalid': validationErrors.prezzo_semestre}]" @input="onChangeInput($event, 'prezzo_semestre')"/>
            <small v-show="validationErrors.prezzo_semestre" class="p-error">Campo richiesto o errato.</small>              
          </div>
           <!-- Valore Corso bimestrale-->
          <div class="p-field col-12 xl:col-2" v-if="corso.cod_type_corso">
            <label for="prezzo_semestre">Prezzo trimestrale €</label><br>
            <InputNumber v-model="corso.prezzo_trimestrale" :class="['inputfield w-full',{'p-invalid': validationErrors.prezzo_trimestrale}]" @input="onChangeInput($event, 'prezzo_trimestrale')"/>
            <small v-show="validationErrors.prezzo_trimestrale" class="p-error">Campo richiesto o errato.</small>              
          </div>
          <!-- Valore Corso mensile-->
          <div class="p-field col-12 xl:col-2" v-if="corso.cod_type_corso">
            <label for="prezzo_mese">Prezzo mensile €</label><br>
            <InputNumber v-model="corso.prezzo_mese" :class="['inputfield w-full',{'p-invalid': validationErrors.prezzo_mese}]" @input="onChangeInput($event, 'prezzo_mese')"/>
            <small v-show="validationErrors.prezzo_mese" class="p-error">Campo richiesto o errato.</small>              
          </div>
          <!-- Numero massimo di atleti per corso-->
          <div class="p-field col-12 xl:col-3" v-if="corso.cod_type_corso">
            <label for="max_n_atleti">Numero massimo di atleti</label><br>
            <InputNumber v-model="corso.max_n_atleti" :class="['inputfield w-full',{'p-invalid': validationErrors.max_n_atleti && submitted}]" @input="onChangeInput($event, 'max_n_atleti')"/>
            <small v-show="validationErrors.max_n_atleti && submitted" class="p-error">Campo richiesto.</small>              
          </div>
          <!-- Numero allenamenti per corso-->
          <div class="p-field col-12 xl:col-3" v-if="corso.cod_type_corso">
            <label for="n_allenamenti">Numero allenamenti settimanali</label><br>
            <InputNumber v-model="corso.n_allenamenti" mode="decimal" showButtons :min="1" :max="15" :class="['inputfield w-full',{'p-invalid': validationErrors.n_allenamenti && submitted}]" @input="onChangeInput($event, 'n_allenamenti')"/>
            <small v-show="validationErrors.n_allenamenti && submitted" class="p-error">Campo richiesto.</small>              
          </div>


          <!-- Selezione data inizio -->
          <div class="p-field col-12 md:col-3" v-if="corso.cod_type_corso">
            <label>Data inizio</label><br>
            
            <Calendar v-model="corso.data_inizio" dateFormat="dd/mm/yy" :class="['inputfield w-full',{'p-invalid': validationErrors.data_inizio && submitted}]" :minDate="minDateInizio" :maxDate="maxDateInizio" @change="onChangeInput($event, 'data_inizio')" @date-select="onChangeInput($event, 'data_inizio')"/>
            
            <small v-show="validationErrors.data_inizio && submitted" class="p-error">Campo richiesto.</small>
          </div>
          <!-- Selezione data fine -->
          <div class="p-field col-12 md:col-3" v-if="corso.cod_type_corso">
            <label>Data fine</label><br>
            
            <Calendar v-model="corso.data_fine" dateFormat="dd/mm/yy" :class="['inputfield w-full',{'p-invalid': validationErrors.data_fine && submitted}]" :minDate="minDateFine" :maxDate="maxDateFine" @change="onChangeInput($event, 'data_fine')" @date-select="onChangeInput($event, 'data_fine')" :disabled="isDisableDataFine" @focus="showMinDatePossible"/>
            <!-- <InlineMessage v-show="isDisableDataFine" severity="warn">Seleziona prima data inizio</InlineMessage> -->
            <small v-show="validationErrors.data_fine && submitted" class="p-error">Campo richiesto.</small>
          </div>
        
          <!-- Selezione responsabile -->
          <div class="p-field col-12 md:col-6" v-if="corso.cod_type_corso">
            <label>Responsabili</label><br>
            <Listbox v-model="corso.responsabili" :options="responsabili" multiple optionLabel="nome_completo" filter class="w-full md:w-56" @update:modelValue="onChangeInput($event, 'responsabili')" listStyle="max-height:200px"/>
            <small v-show="validationErrors.responsabili && submitted" class="p-error">Campo richiesto.</small>
          </div>
          
          <!-- Selezione responsabile -->
          <div class="p-field col-12 md:col-6" v-if="corso.cod_type_corso">
            <label>Allenatori</label><br>
            <Listbox v-model="corso.allenatori" :options="allenatori" multiple optionLabel="nome_completo" filter class="w-full md:w-56" @update:modelValue="onChangeInput($event, 'allenatori')" listStyle="max-height:200px"/>

            <small v-show="validationErrors.allenatori && submitted" class="p-error">Campo richiesto.</small>
          </div>
          
          <div class="p-field col-12" v-if="corso.cod_type_corso">
            <!-- Selezione ricorrenza -->
            <div class="flex-auto">
              <label>Sessioni del corso</label>
              
                <div class="card flex">
                  <div class="ml-2 col-12" style="position:relative;">
                    <div v-for="(day, index) in corso.days" :key="index" class="flex align-items-center m-2">
                      <div>
                        <ToggleButton v-model="day.value" class="w-6rem m-3 col-2 btn-day" :onLabel="day.name" :offLabel="day.name" :disabled="!can_selected && !day.value" @click="onChangeInput($event, 'periodi', null, index)"/>
                        <Button icon="pi pi-plus" class="p-button-success p-button-text p-button-rounded" @click="addSlot(day)" v-if="corso.cod_type_corso.id == 7 && day.value && day.slots.length < 3"/>
                      </div>
                      <div v-if="day.value" class="flex">
                        <div class="flex-auto">
                          <div v-for="(slot, id_slot) in day.slots" :key="id_slot" class="flex align-items-center"> 
                            <div class="flex justify-content-between w-15rem align-items-center">
                              <div class="m-2">
                                <label class="mr-2">Ora inizio</label>
                                <Calendar v-model="slot.time_in" timeOnly :stepMinute="30" :manualInput="false"
                                @update:modelValue="onChangeInput($event,'orario','in',index, id_slot)" :style="{width:'55px'}"/>
                              </div>
                              <div class="m-2">
                                <label class="mr-2">Ora fine</label>
                                <Calendar v-model="slot.time_out" timeOnly :stepMinute="30" :minDate="slot.minDate" :manualInput="false" :style="{width:'55px'}" 
                                @update:modelValue="onChangeInput($event, 'orario', 'out')"/>
                              </div>

                              <Button icon="pi pi-trash" class="p-button-danger p-button-text p-button-rounded" v-if="day.slots.length > 1" @click="removeSlot(day, id_slot)"/>
                            </div>
                            <div class="flex justify-content-center p-4">   
                              <div v-if="campi.length">
                                <label v-if="campi.length != 1 && day.campo == null">Scegli il campo</label>
                                <SelectButton v-model="day.campo" :options="campi" optionLabel="nomecampo" dataKey="numero" aria-labelledby="custom" @update:modelValue="onChangeInput($event, 'campo')">
                                  <template #option="slotProps">
                                    <div class="btn-campo numero-campo flex justify-content-center align-items-end">
                                      {{ slotProps.option.nomecampo }}
                                    </div>
                                  </template>
                                </SelectButton>                        
                              </div>
                              <div v-else>
                                <InlineMessage severity="warn">Non ci sono campi disponibili</InlineMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="flex justify-content-center p-4">   
                          <div v-if="campi.length">
                            <label v-if="campi.length != 1 && day.campo == null">Scegli il campo</label>
                            <SelectButton v-model="day.campo" :options="campi" optionLabel="nomecampo" dataKey="numero" aria-labelledby="custom" @update:modelValue="onChangeInput($event, 'campo')">
                              <template #option="slotProps">
                                <div class="btn-campo numero-campo flex justify-content-center align-items-end">
                                  {{ slotProps.option.nomecampo }}
                                </div>
                              </template>
                            </SelectButton>                        
                          </div>
                          <div v-else>
                            <InlineMessage severity="warn">Non ci sono campi disponibili</InlineMessage>
                          </div>
                        </div> -->
                      </div>
                    </div>
                    <div class="counter"><strong>{{selected_day}}/{{n_work}}</strong></div>
                  </div>
                </div>
              <small v-show="validationErrors.periodi && submitted" class="p-error">Seleziona tutti i giorni necessari</small>
            </div>
                        
            <!-- <div class="flex justify-content-center mt-2" v-if="!loaded_checkCampi">
              <div class="flex flex-column">
                <Button label="Verifica disponibilità" @click="checkCampi()" :disabled="isDisabledCheck"/>
              </div>
            </div>   -->
          </div>
          
          
          <!-- Note -->
          <div class="p-field col-12" v-if="corso.cod_type_corso">
            <label for="note">Note</label><br>
            
            <Textarea id="note" v-model="corso.nota" autoResize rows="5" cols="30" class="w-full"/>
          </div>
          
          <!-- Bottone salva -->
          <div class="mt-5 col-12 flex justify-content-end">
            <Button class="p-button-success" icon="pi pi-check" label="Salva" :disabled="isDisabledCanSaveNewCorso" @click="sendDataNewCorso"/>
          </div>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import {ref, onMounted, getCurrentInstance, onUpdated, computed } from 'vue';
import Loader from "@/components/Loader.vue"
import Service from '@/services/levante-api/levante-api'
import moment from 'moment'
import { useToast } from 'primevue/usetoast';
import locale from '@/config/localization/locale_it.js'

export default {
  components: {
    Loader,
  },
  
  setup () { 
    const self = getCurrentInstance();
    const toast = useToast();
    self.$eventBus = self.appContext.config.globalProperties.$eventBus
    const service = new Service(self.$eventBus)
    const submitted = ref(false)
    const loading = ref(false)
    const corso = ref({
      nome:'',
      days:[],
      allenatori:[],
      responsabili:[],
      cod_type_corso: null,
      max_n_atleti:0,
      n_allenamenti:0,
      prezzo_anno:0,
      prezzo_mese:1,
      prezzo_semestre:1,
      prezzo_trimestrale:1,
      data_inizio: null,
      data_fine: null
    })
    const validationErrors = ref({})
    const types = ref()
    const responsabili = ref([])
    const allenatori = ref([])
    const date = moment();
    const minDateInizio = ref();
    const maxDateInizio = ref();
    const minDateFine = ref();
    const maxDateFine = ref();
    const buttonDays = ref([])
    const n_work = ref(0)
    const orario = ref()
    const campi = ref([])
    const loaded_checkCampi = ref(false)
    const selected_day = computed (() => {
      let n = []
      if (n_work.value) {
        n = corso.value.days.filter(btn => btn.value)
      }
      return n.length
    })
    const isDisableDataFine = computed(()=>{
      return corso.value.data_inizio ? false : true
    })
    
    const isDisabledCheck = computed(()=>{
      let props = [
      'cod_type_corso',
      'data_inizio',
      'data_fine',
      'responsabili',
      'allenatori',
      'can_selected',
      ]
      let is_disable = false
      props.forEach(prop => {
        if (prop == 'can_selected') {
          if (can_selected.value) {
            is_disable = true
          }
        } else {
          let check
          switch (prop) {
            case 'responsabili':
            case 'allenatori':
              check = !corso.value[prop].length
              break;
          
            default:
              check = corso.value[prop] == '' || corso.value[prop] == null
              break;
          }
          
          if (check) {
            is_disable = true
          }          
        }
      });
      return is_disable
    })
    
    const can_selected = computed(()=>{
      let can_be_selected = true
      if (n_work.value && selected_day.value >= n_work.value) {
        can_be_selected = false
      }
      return can_be_selected
    })

    const getCampi = async () => {
      await service.getCampi()
      .then((res) => {
        campi.value = res.items
      })
    }
    const getCampiForTypeCorso = async (id) => {
      await service.getCampiForTypeCorso(id)
      .then((res) => {
        campi.value = res.items
      })
    }
    
    const getTypeCorso = async () => {
      await service.getTypeCorso()
      .then((res) => {
        types.value = res.items
      })
    }
    const getPersonaleForCorso = async (cod_type_corso) => {
      await service.getPersonaleForCorso(cod_type_corso)
      .then((res) => {
        res.items.forEach(item => {
          item.nome_completo = `${item.cognome} ${item.nome}`
        });
        responsabili.value = res.items.filter(item => item.is_responsabile == 1).map(item => {
          return {
            ...item,
            cod_type_personale: 1
          }
        });

        allenatori.value = res.items.filter(item => item.is_allenatore == 1).map(item => {
          return {
            ...item,
            cod_type_personale: 2
          }
        });
      })
    }
    const onChangeInput = (evt, prop, type = null, id = null, id_slot = null) => {
      switch (prop) {
        case 'cod_type_corso':
        case 'data_inizio':
        case 'data_fine':
        case 'orario':
        case 'periodi':
        loaded_checkCampi.value = false
        break;
      }
      
      if (prop != 'periodi') {
        let value
        switch (prop) {
          case 'cod_type_corso':
          initCorso()
          corso.value.cod_type_corso = evt.value
          corso.value.prezzo_anno = Number(evt.value.annual_amount)
          corso.value.max_n_atleti = evt.value.max_n_atleti
          corso.value.n_allenamenti = evt.value.n_allenamenti
          getPersonaleForCorso(corso.value.cod_type_corso.id)
          getCampiForTypeCorso(corso.value.cod_type_corso.id)
          setButtondays()
          n_work.value = evt.value.n_allenamenti
          value = evt.value.id

          break;
          
          
          case 'data_inizio':
          initDataFine()
          value = evt
          break;

          case 'responsabili':
          case 'allenatori':
          case 'data_fine':
          case 'campo':

            value = evt
            break;
            
          case 'orario':
            value = typeof evt === 'object' ? setTime(evt) : evt
            
            if (type == 'in') {              
              let time_in = evt.getTime()

              let time_out = getTimeOut(time_in, corso.value.cod_type_corso.durata)
              
              corso.value.days[id].slots[id_slot].time_out = new Date(time_out)
              corso.value.days[id].slots[id_slot].minDate = new Date(time_in)
              corso.value.days[id].slots[id_slot].maxDate = new Date(time_in + (3 * 3600000))
            }
            break
          case 'prezzo_anno':
            value = evt.value ? Number(evt.value) : 0
            break;
          case 'prezzo_semestre':
            value = evt.value ? Number(evt.value) : 0
            break;
          case 'prezzo_mese':
            value = evt.value ? Number(evt.value) : 0
            break;
          case 'prezzo_trimestrale':
            value = evt.value ? Number(evt.value) : 0
            break;
          case 'max_n_atleti':
            value = evt.value ? Number(evt.value) : 1
            break;
          case 'n_allenamenti':
            value = evt.value ? Number(evt.value) : 1
            corso.value.n_allenamenti = value
            n_work.value = value

            if (n_work.value < selected_day.value) {
              for (let index = corso.value.days.length-1; index > 0; index--) {
                if (corso.value.days[index].value) {
                  corso.value.days[index].value = !corso.value.days[index].value
                  break
                }
              }
            }
            break;

          default:
            value = evt.target.value
            break;
        }
        let check
        
        if (typeof value === 'object' && ['prezzo', 'max_n_atleti'].includes(prop)) {
          check = !value.length
        }else{
          check = value == '' || value == null
        }

        if (check) {
          validationErrors.value[prop] = true
        }else{
          delete validationErrors.value[prop]
        }        
      }else{
        delete validationErrors.value[prop]
        if(corso.value.days[id].value && campi.value.length){
          corso.value.days[id].campo = campi.value[0]
        }
      }
    }
    
    const validateForm = () => {
      // Validazione corso
      let validate_properties = [
        'nome',
        'cod_type_corso',
        'responsabili',
        'allenatori',
        'data_inizio',
        'data_fine',
        'prezzo_anno',
        'prezzo_semestre',
        'prezzo_trimestrale',
        'prezzo_mese'
      ]
      
      validate_properties.forEach( prop => {
        let check
        switch (prop) {
          case 'responsabili':
          case 'allenatori':
            check = !corso.value[prop].length
            break;
          
        
          default:
            check = corso.value[prop] == '' || corso.value[prop] == null
        }
        if (check) {
          validationErrors.value[prop] = true
        } else {
          delete validationErrors.value[prop];
        }
      })
      // Fine Validazione corso
      
      // Validazione Periodicità
      if(selected_day.value == n_work.value){
        delete validationErrors.value.periodi
      }else{
        validationErrors.value.periodi = true
      }
      // Fine Validazione Periodicità
      
      return !Object.keys(validationErrors.value).length;
    }
    
    const setDataTime = (el) => {
      let giorno = el.getDate() 
      let mese = el.getMonth()+1
      mese = mese.toString().padStart(2,'0')
      let anno = el.getFullYear() 
      el = `${anno}-${mese}-${giorno}`
      return el
    } 

    const setTime = (el) => {
      let hours = el.getHours().toString().padStart(2,'0');
      let minutes = el.getMinutes().toString().padStart(2,'0');
      return `${hours}:${minutes}:00`
    }
    const isDisabledCanSaveNewCorso = computed (() => {
      let is_disable = false

      let properties = [
        corso.value.nome != '',
        corso.value.allenatori.length > 0,
        corso.value.responsabili.length > 0,
        corso.value.max_n_atleti != 0,
        corso.value.n_allenamenti != 0,
        corso.value.prezzo_anno != 0,
        corso.value.prezzo_semestre != 0,
        corso.value.prezzo_trimestrale!=0,
        corso.value.prezzo_mese != 0,
        corso.value.data_inizio != null,
        corso.value.data_fine != null,
        !can_selected.value
      ]
      for (let index = 0; index < properties.length; index++) {        
        if(!properties[index]){
          is_disable = true
          break;
        }        
      }
      return is_disable
    })
    
    const sendDataNewCorso = async () => {
      submitted.value = true;
      validationErrors.value = []

      if(validateForm()){
        loading.value = true
        let fd = new FormData();  
        let data_inizio = corso.value.data_inizio  
        let data_fine = corso.value.data_fine   
        let days = corso.value.days
        corso.value.data_inizio = setDataTime(corso.value.data_inizio)
        corso.value.data_fine = setDataTime(corso.value.data_fine)
        corso.value.days.forEach(day => {         
          day.slots.forEach(slot => {
            slot.time_in = setTime(slot.time_in)
            slot.time_out = setTime(slot.time_out)
          })
        })
        
        fd.append('corso', JSON.stringify(corso.value));
        
        service.sendDataNewCorso(fd)
        .then((res) => {
          let severity = res.item.esito
          let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
          let detail = res.item.messaggio
          if(res.item.esito == 'success'){
            initData()
          }else{
            corso.value.data_inizio = data_inizio
            corso.value.data_fine = data_fine
            corso.value.days = days
          }
          
          toast.add({severity:severity, summary: summary, detail:detail});
        }).finally(() => {
          loading.value = false
        })
      }else{
        toast.add({severity:'warn', summary: 'Attenzione', detail:`Ci sono dei dati mancanti`});
      }
    }
    const initCorso = () => {
      corso.value = {
        nome: '',
        days:[],
        allenatori:[],
        responsabili:[],
        cod_type_corso: null,
        max_n_atleti:0,
        n_allenamenti:0,
        prezzo_anno:1,
        prezzo_mese:1,
        prezzo_semestre:1,
        prezzo_trimestrale:1,
        data_inizio: new Date(),
        data_fine:null
      }
    }
    
    const initData = () => {
      // restart var
      initCorso()
      // setButtondays()
      getTypeCorso()
      minDateInizio.value = new Date()
      maxDateInizio.value = new Date(date.add(1, 'month'))
    }
    const initDataFine = () => {
      corso.value.data_fine = null
      minDateFine.value = new Date(moment(corso.value.data_inizio).add(0.8, 'years')) 
      corso.value.data_fine = minDateFine.value
      maxDateFine.value = new Date(moment(corso.value.data_inizio).add(1, 'years')) 
    }
    
    const showMinDatePossible = () => {
      corso.value.data_fine = minDateFine.value
      delete validationErrors.value.data_fine
    }

    const setTimeStamp = (hourString, type_out = null) => {

      let now = new Date()
      let h = hourString.split(':')[0]
      let m = hourString.split(':')[1]
      let s = hourString.split(':')[2]

      if (type_out) {
        return {h,m,s}
      }

      return now.setHours(h,m,s)
    }
    const setButtondays = () => {
      corso.value.days = []
      let days = locale.dayNames

      let time_in = setTimeStamp(corso.value.cod_type_corso.orario)

      let time_out = getTimeOut(time_in, corso.value.cod_type_corso.durata)

      days.forEach(day=>{
        corso.value.days.push({
          name: day,
          value: false,
          slots: [
            { time_in: new Date(time_in), minDate: new Date(time_out), maxDate: new Date(time_in + (3 * 3600000)), time_out: new Date(time_out) }
          ]
        })
      })
    }
    const addSlot = (day) => {
      if (day.slots.length < 3) {
        let indice = day.slots.length-1
        
        let time_in = setTimeStamp(moment(day.slots[indice].time_out).add(2, 'hours').format('H:mm:ss'))

        let time_out = getTimeOut(time_in, corso.value.cod_type_corso.durata)

        day.slots.push({ time_in: new Date(time_in), minDate: new Date(time_out), maxDate: new Date(time_in + (3 * 3600000)), time_out: new Date(time_out) })
      }
      
    }
    const removeSlot = (day, id_slot) => {
      if (day.slots.length > 1) {
        day.slots.splice(id_slot, 1)
      }
    }


    const getTimeOut = (time_in, period) => {
      let h = setTimeStamp(period, 'h').h
      let m = setTimeStamp(period, 'm').m

      // Convertire il tempo in formato decimale
      const tempoDecimale = Number(h) + (Number(m) / 60);

      const millisecondi = tempoDecimale * 3600000;

      return time_in + millisecondi
    }

    const getSelectedDays = (days) => {
      let array_days = []
      Object.keys(days).forEach(day => {
        if (days[day].value) {
          let time_in = typeof days[day].time_in === 'object' ? setTime(days[day].time_in) : days[day].time_in
          let time_out = typeof days[day].time_out === 'object' ? setTime(days[day].time_out) : days[day].time_out
          array_days.push({
            'value': day,
            'time_in': time_in,
            'time_out': time_out
          })
        }
      })
      return array_days
    }
    
    const checkCampi = () => {
      loading.value = true

      let check = {
        cod_type_campo:corso.value.cod_type_corso.cod_type_campo,
        data_inizio:setDataTime(corso.value.data_inizio),
        data_fine: setDataTime(corso.value.data_fine),
        giorni: getSelectedDays(corso.value.days),
        allenatori: corso.value.allenatori
      }
      let fd = new FormData();        
      
      fd.append('check', JSON.stringify(check));
      
      service.checkCampi(fd)
      .then((res) => {
        campi.value = res.item
        if (campi.value.length == 1) {
          corso.value.campo = campi.value[0]
        }
        loaded_checkCampi.value = true
      }).finally(() => {
        loading.value = false
      })
      
    }
    
    onMounted(() => { 
      initData()
    })
    
    return {
      loading,
      submitted,
      corso,
      validationErrors,
      types,
      moment,
      sendDataNewCorso,
      onChangeInput,
      responsabili,
      allenatori,
      date,
      minDateInizio,
      maxDateInizio,
      minDateFine,
      maxDateFine,
      isDisableDataFine,
      showMinDatePossible,
      buttonDays,
      can_selected,
      selected_day,
      n_work,
      orario,
      checkCampi,
      isDisabledCheck,
      campi,
      loaded_checkCampi,
      isDisabledCanSaveNewCorso,
      addSlot,
      removeSlot,
    }
  },
}
</script>

<style>
.btn-day{
  height: 40px !important;
}
.text_toupper{
  text-transform: uppercase;
} 
</style>
<style scoped>
.counter{
  position:absolute;
  left:-10px;
  top:-10px;
}
#container-atleti{
  display: block;
}
.layout-main-container{
  width: fit-content !important;
}
.btn-error-filter{
  width: 200px;
}

.btn-campo{
  background:#ffffff url("/images/campo.jpg") no-repeat right top;
  background-size: contain;
  width: 65px;
  aspect-ratio:1/1.5 ;
}

.numero-campo{
  color: black;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  z-index: 2;
}
</style>
