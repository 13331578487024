export default
{
  main_domain:{    
    url: `${process.env.VUE_APP_LEVANTE_DOMAIN}`,
    cookie: `${process.env.VUE_APP_LEVANTE_COOKIE_DOMAIN}`
  },
  levante_api: {
    url: `${process.env.VUE_APP_LEVANTE_API_URL}/api`
  },
}
