<template>
  <Loader v-if="loading" />    
  <Card style="width: fit-content;">
    <template v-slot:title>
      <h3>Crea Kit</h3>
    </template>
    <template v-slot:content>
      <div class="grid">
        <div class="m-3">
          <div class="formgrid grid mt-4">
            <!-- Cognome Utente -->
            <div class="p-field col-12 xl:col-6">
              <label for="nome">Nome</label><br>
              <InputText id="nome" v-model="prodotto.nome" :class="['inputfield w-full',{'p-invalid': validationErrors.nome && submitted}]" @input="(val) => (prodotto.nome = prodotto.nome.toUpperCase())" @change="onChangeInput($event, 'nome')"/>
              <small v-show="validationErrors.nome && submitted" class="p-error">Campo richiesto.</small>
            </div>
            <div class="p-field col-12 xl:col-6">
              <label for="prezzo">Prezzo</label><br>
              <InputText id="prezzo" v-model="prodotto.prezzo" :class="['inputfield w-full',{'p-invalid': validationErrors.prezzo && submitted}]" @input="(val) => (prodotto.prezzo = prodotto.prezzo.toUpperCase())" @change="onChangeInput($event, 'prezzo')"/>
              <small v-show="validationErrors.prezzo && submitted" class="p-error">Campo richiesto.</small>
            </div>

            
            <div class="p-field col-12 md:col-8 xl:col-4">
              <label>Prodotto</label><br>
              <Dropdown v-model="prodotto.p1" :options="prodotti" :filter="true" optionLabel="nome" optionValue="id" placeholder="Seleziona prodotto" :class="['inputfield w-full']" :virtualScrollerOptions="{ itemSize: 10 }" @change="onChangeInput($event, 'prodotto')"/>
         
            </div>
            <div class="p-field col-12 md:col-8 xl:col-4">
              <label>Prodotto</label><br>
              <Dropdown v-model="prodotto.p2" :options="prodotti" :filter="true" optionLabel="nome" optionValue="id" placeholder="Seleziona prodotto" :class="['inputfield w-full']" :virtualScrollerOptions="{ itemSize: 10 }" @change="onChangeInput($event, 'prodotto')"/>
         
            </div>
            <div class="p-field col-12 md:col-8 xl:col-4">
              <label>Prodotto</label><br>
              <Dropdown v-model="prodotto.p3" :options="prodotti" :filter="true" optionLabel="nome" optionValue="id" placeholder="Seleziona prodotto" :class="['inputfield w-full']" :virtualScrollerOptions="{ itemSize: 10 }" @change="onChangeInput($event, 'prodotto')"/>
         
            </div>
            <div class="p-field col-12 md:col-8 xl:col-4">
              <label>Prodotto</label><br>
              <Dropdown v-model="prodotto.p4" :options="prodotti" :filter="true" optionLabel="nome" optionValue="id" placeholder="Seleziona prodotto" :class="['inputfield w-full']" :virtualScrollerOptions="{ itemSize: 10 }" @change="onChangeInput($event, 'prodotto')"/>
         
            </div>
            <div class="p-field col-12 md:col-8 xl:col-4">
              <label>Prodotto</label><br>
              <Dropdown v-model="prodotto.p5" :options="prodotti" :filter="true" optionLabel="nome" optionValue="id" placeholder="Seleziona prodotto" :class="['inputfield w-full']" :virtualScrollerOptions="{ itemSize: 10 }" @change="onChangeInput($event, 'prodotto')"/>
         
            </div>
            <div class="p-field col-12 md:col-8 xl:col-4">
              <label>Prodotto</label><br>
              <Dropdown v-model="prodotto.p6" :options="prodotti" :filter="true" optionLabel="nome" optionValue="id" placeholder="Seleziona prodotto" :class="['inputfield w-full']" :virtualScrollerOptions="{ itemSize: 10 }" @change="onChangeInput($event, 'prodotto')"/>
            </div>

            <div class="p-field col-12 md:col-8 xl:col-4">
              <label>Prodotto</label><br>
              <Dropdown v-model="prodotto.p7" :options="prodotti" :filter="true" optionLabel="nome" optionValue="id" placeholder="Seleziona prodotto" :class="['inputfield w-full']" :virtualScrollerOptions="{ itemSize: 10 }" @change="onChangeInput($event, 'prodotto')"/>
            </div>

            <div class="p-field col-12 md:col-8 xl:col-4">
              <label>Prodotto</label><br>
              <Dropdown v-model="prodotto.p8" :options="prodotti" :filter="true" optionLabel="nome" optionValue="id" placeholder="Seleziona prodotto" :class="['inputfield w-full']" :virtualScrollerOptions="{ itemSize: 10 }" @change="onChangeInput($event, 'prodotto')"/>
            </div>

            <div class="p-field col-12 md:col-8 xl:col-4">
              <label>Prodotto</label><br>
              <Dropdown v-model="prodotto.p9" :options="prodotti" :filter="true" optionLabel="nome" optionValue="id" placeholder="Seleziona prodotto" :class="['inputfield w-full']" :virtualScrollerOptions="{ itemSize: 10 }" @change="onChangeInput($event, 'prodotto')"/>
            </div>

            <div class="p-field col-12 md:col-8 xl:col-4">
              <label>Prodotto</label><br>
              <Dropdown v-model="prodotto.p10" :options="prodotti" :filter="true" optionLabel="nome" optionValue="id" placeholder="Seleziona prodotto" :class="['inputfield w-full']" :virtualScrollerOptions="{ itemSize: 10 }" @change="onChangeInput($event, 'prodotto')"/>
            </div>

            <div class="p-field col-12 md:col-8 xl:col-4">
              <label>Prodotto</label><br>
              <Dropdown v-model="prodotto.p11" :options="prodotti" :filter="true" optionLabel="nome" optionValue="id" placeholder="Seleziona prodotto" :class="['inputfield w-full']" :virtualScrollerOptions="{ itemSize: 10 }" @change="onChangeInput($event, 'prodotto')"/>
            </div>

            <div class="p-field col-12 md:col-8 xl:col-4">
              <label>Prodotto</label><br>
              <Dropdown v-model="prodotto.p12" :options="prodotti" :filter="true" optionLabel="nome" optionValue="id" placeholder="Seleziona prodotto" :class="['inputfield w-full']" :virtualScrollerOptions="{ itemSize: 10 }" @change="onChangeInput($event, 'prodotto')"/>
            </div>

            <div class="p-field col-12 md:col-8 xl:col-4">
              <label>Prodotto</label><br>
              <Dropdown v-model="prodotto.p13" :options="prodotti" :filter="true" optionLabel="nome" optionValue="id" placeholder="Seleziona prodotto" :class="['inputfield w-full']" :virtualScrollerOptions="{ itemSize: 10 }" @change="onChangeInput($event, 'prodotto')"/>
            </div>

            <div class="p-field col-12 md:col-8 xl:col-4">
              <label>Prodotto</label><br>
              <Dropdown v-model="prodotto.p14" :options="prodotti" :filter="true" optionLabel="nome" optionValue="id" placeholder="Seleziona prodotto" :class="['inputfield w-full']" :virtualScrollerOptions="{ itemSize: 10 }" @change="onChangeInput($event, 'prodotto')"/>
            </div>

            <div class="p-field col-12 md:col-8 xl:col-4">
              <label>Prodotto</label><br>
              <Dropdown v-model="prodotto.p15" :options="prodotti" :filter="true" optionLabel="nome" optionValue="id" placeholder="Seleziona prodotto" :class="['inputfield w-full']" :virtualScrollerOptions="{ itemSize: 10 }" @change="onChangeInput($event, 'prodotto')"/>
            </div>

            <div class="p-field col-12 md:col-8 xl:col-4">
              <label>Prodotto</label><br>
              <Dropdown v-model="prodotto.p16" :options="prodotti" :filter="true" optionLabel="nome" optionValue="id" placeholder="Seleziona prodotto" :class="['inputfield w-full']" :virtualScrollerOptions="{ itemSize: 10 }" @change="onChangeInput($event, 'prodotto')"/>
            </div>

            <div class="p-field col-12 md:col-8 xl:col-4">
              <label>Prodotto</label><br>
              <Dropdown v-model="prodotto.p17" :options="prodotti" :filter="true" optionLabel="nome" optionValue="id" placeholder="Seleziona prodotto" :class="['inputfield w-full']" :virtualScrollerOptions="{ itemSize: 10 }" @change="onChangeInput($event, 'prodotto')"/>
            </div>

            <div class="p-field col-12 md:col-8 xl:col-4">
              <label>Prodotto</label><br>
              <Dropdown v-model="prodotto.p18" :options="prodotti" :filter="true" optionLabel="nome" optionValue="id" placeholder="Seleziona prodotto" :class="['inputfield w-full']" :virtualScrollerOptions="{ itemSize: 10 }" @change="onChangeInput($event, 'prodotto')"/>
            </div>

            <div class="p-field col-12 md:col-8 xl:col-4">
              <label>Prodotto</label><br>
              <Dropdown v-model="prodotto.p19" :options="prodotti" :filter="true" optionLabel="nome" optionValue="id" placeholder="Seleziona prodotto" :class="['inputfield w-full']" :virtualScrollerOptions="{ itemSize: 10 }" @change="onChangeInput($event, 'prodotto')"/>
            </div>

            <div class="p-field col-12 md:col-8 xl:col-4">
              <label>Prodotto</label><br>
              <Dropdown v-model="prodotto.p20" :options="prodotti" :filter="true" optionLabel="nome" optionValue="id" placeholder="Seleziona prodotto" :class="['inputfield w-full']" :virtualScrollerOptions="{ itemSize: 10 }" @change="onChangeInput($event, 'prodotto')"/>
            </div>
          </div>
        </div>
      </div>
    </template>
     <template v-slot:footer>
                <div class="grid justify-content-between">
                    <i></i>
                    <Button  label="Salva" :disabled="isDisable" @click="complete()" icon="pi pi-check" iconPos="right" class="p-button-success"/>  
                </div>
            </template>
  </Card>
</template>

<script>
import {ref, onMounted, getCurrentInstance, onUpdated, computed } from 'vue';
import Loader from "@/components/Loader.vue"
import Service from '@/services/levante-api/levante-api'
import moment from 'moment'
import { useToast } from 'primevue/usetoast';

export default {
  components: {
    Loader,
  },
  
  setup () { 
    
    const self = getCurrentInstance();
    const toast = useToast();
    self.$eventBus = self.appContext.config.globalProperties.$eventBus
    const service = new Service(self.$eventBus)
    const submitted = ref(false)
    const loading = ref(false)
    const prodotto = ref({})
    const validationErrors = ref({})
    const atleti = ref([])
    const columns = [
    { field: 'surname', header: 'Cognome' },
    { field: 'name', header: 'Nome' },
    { field: 'birth', header: 'Data di nascita' },
    { field: 'gender', header: 'Sesso' },
    { field: 'cf', header: 'Codice fiscale' }
    ];
    const genders = [
    {key:0, name:'Maschio'},
    {key:1, name:'Femmina'}
    ]
    const prodotti = ref([])
    const maxDate = ref();
    const lifeTime = 3000;
    const today = new Date();
    const block_div_atleti = ref(true)
    const label_checkbox_associa = computed(() => {
      let status_checkbox = [
      {status: false, description: 'Associa'},
      {status: true, description: 'Dissocia'}
      ]
      return status_checkbox.find(status => status.status == block_div_atleti.value).description + ' atleti'
    })
    
 
    const deleteRow = (row, index) => {
      atleti.value.splice(index, 1);
      validationErrors.value = []
    }
    const getProdotti = async () => {
      await service.getProdotti()
      .then((res) => {
        prodotti.value = res.items
      })
    }
    const onChangeInput = (evt, prop) => {
      let value
      switch (prop) {
        case 'prodotto':
        value = evt.value
        break;
        
        default:
        value = evt.target.value
        break;
      }
      if (value == '' || value == null) {
        validationErrors.value[prop] = true
      }else{
        delete validationErrors.value[prop]
      }
    }
    
 
    
    const validateForm = () => {
      return true
    }

    
    const complete = async () => {
      submitted.value = true;
      validationErrors.value = []
      if(validateForm()){
        loading.value = true
        let fd = new FormData();
        
        fd.append('prodotto', JSON.stringify(prodotto.value));
        
        service.saveKit(fd)
        .then((res) => {
          let severity = res.item.error ? 'warn' : 'success'
          let summary = res.item.error ? 'Attenzione' : 'Successo'
          let detail = res.item.response
          
          toast.add({severity:severity, summary: summary, detail:detail, life: lifeTime});
        }).finally(() => {
          initData()
          loading.value = false
        })
      }else{
        toast.add({severity:'warn', summary: 'Attenzione', detail:`Ci sono dei dati mancanti`,life: lifeTime});
      }
    }
    
    const initData = () => {
      // restart var
      prodotti.value = {}
      block_div_atleti.value = true
      // setMaxData
      const currentYear = today.getFullYear();
      const previousYear = currentYear - 1;
      maxDate.value = new Date(previousYear, today.getMonth(), today.getDate());
    }
    
    onMounted(() => { 
      initData()
      getProdotti()
    })
    
    return {
      loading,
      submitted,
      prodotto,
      validationErrors,
      atleti,
      prodotti,
      columns,
      genders,
      moment,
      deleteRow,
      maxDate,
      block_div_atleti,
      label_checkbox_associa,
      complete,
      onChangeInput,
    }
  },
}
</script>

<style>
.text_toupper{
  text-transform: uppercase;
} 
</style>
<style scoped>
#container-atleti{
  display: block;
}
.layout-main-container{
  width: fit-content !important;
}
.btn-error-filter{
  width: 200px;
}
</style>
