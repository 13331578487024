<template>
    <Loader v-if="loading"/>
    <div v-if="loaded_data">
        
        <Card>
            <template #header>
                <div class="p-3 pb-0">
                    <h4>Prodotto: {{ prodotto.nome }}</h4>
                </div>
            </template>
            <template #content>
                <div class="col-12 flex">
                    <div class="col-12 flex flex-column md:col-4" >
                        <Card style="background-color: #0c4987;
                        text-align: center;
                        color: white;">
                            <template #title>Acquistati</template>
                            <template #content>
                                <h2>
                                    {{ prodotto.movimenti.acquisti_det.totale }} 
                                </h2>
                            </template>
                        </Card>
                    </div>
                    <div class="col-12 flex flex-column md:col-4" >
                        <Card style="background-color: #4c870c;
                        text-align: center;
                        color: white;">
                            <template #title>Venduti</template>
                            <template #content>
                                <h2>
                                    {{ prodotto.movimenti.vendite_det.totale }} 
                                </h2>
                            </template>
                        </Card>
                    </div>
                    <div class="col-12 flex flex-column md:col-4" >
                        <Card style="background-color: #d32f2f;
                        text-align: center;
                        color: white;">
                            <template #title>Rimanenza</template>
                            <template #content>
                                <h2>
                                    {{ prodotto.movimenti.acquisti_det.totale - prodotto.movimenti.vendite_det.totale }} 
                                </h2>
                            </template>
                        </Card>
                    </div>
                    
                </div>

                <Accordion :activeIndex="1"  @tab-open="getCaricoScarico($event)">
                    <AccordionTab header="Dettagli">
                        <div class="flex align-items-start flex-wrap">
                            <div class="col-12 flex flex-wrap">
                                <div class="col-12 md:col-6 flex flex-column gap-2">
                                    <label for="nome">Nome</label>
                                    <InputText id="nome" v-model="prodotto.nome" :disabled="!update" :class="['inputfield w-full',{'p-invalid': submitted && validationErrors.nome}]"/>
                                </div>
                                <div class="col-12 md:col-6 flex flex-column gap-2">
                                    <label for="nome">EAN</label>
                                    <InputText id="ean" v-model="prodotto.ean" :disabled="!update" :class="['inputfield w-full',{'p-invalid': submitted && validationErrors.nome}]"/>
                                </div>
                                <div class="col-12 md:col-6 flex flex-column gap-2">
                                    <label for="descrizione">Descrizione</label>
                                    <Textarea id="descrizione" v-model="prodotto.descrizione" :disabled="!update" :class="['inputfield w-full',{'p-invalid': submitted && validationErrors.descrizione}]"/>
                                </div>
                                <div class="col-12 md:col-6 flex flex-column gap-2">
                                    <label for="prezzo">Prezzo</label>
                                    <InputNumber id="prezzo" v-model="prodotto.prezzo" showButtons mode="currency" currency="EUR" :disabled="!update" :class="['inputfield w-full',{'p-invalid': submitted && validationErrors.prezzo}]" :step="0.01"/>
                                </div>
                                <div class="col-12">
                                    <label>In Vendita</label><br/>
                                    <Checkbox v-model="prodotto.attivo" :binary="true" />

                                </div>
                                <div class="col-12">
                                    <img :src="'demo/images/product/' + prodotto.immagine" :alt="prodotto.nome" class="product-image" />
                                </div>
                            </div>
                        </div>
                        <div class="col-12 flex justify-content-end align-items-center">
                            <label class="mr-2">{{!update ? 'Modifica' : 'Salva'}} dati</label>
                            <InputSwitch v-model="update"  @change="changeUpdate" />
                        </div>
                    </AccordionTab>
                    <AccordionTab header="Movimenti">
                        <Button label="Aggiungi" class="p-button" @click="visible = true" style="margin-bottom:5px"/>
                        <div v-for="(movimento, tipo_movimento) in prodotto.movimenti" :key="tipo_movimento">
                            <DataTable :value="movimento.datas" tableStyle="min-width: 50rem" paginator :rows="10" :rowsPerPageOptions="[10, 20, 50]" >
                                <template #header>
                                    <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                                        <span class="text-xl text-900 font-bold">{{ tipo_movimento.replace('_det', '').toUpperCase() }}</span>
                                    </div>
                                </template>
                                <Column field="atleta" header="Atleta" v-if="tipo_movimento == 'vendite_det'">
                                    <template #body="{data}">
                                        <Button :label="data.atleta" class="p-button-link" @click="navigateTo('atleta',data.id_atleta)"/>
                                    </template>
                                </Column>                                
                                <Column field="creation_time" header="Data movimento">
                                    <template #body="{data}">
                                        {{ moment(data.creation_time).format('DD/MM/Y') }}
                                    </template>
                                </Column>
                                <Column field="quantita" header="Quantità"/>
                                <Column field="prezzo" header="Importo €"/>
                                <Column header="Taglia" v-if="prodotto.hasSize">
                                    <template #body="{data}">
                                        {{ sizes.find(size => size.id == data.cod_size).nome }}
                                    </template>
                                </Column>
                                <Column field="atleta" header="" v-if="tipo_movimento == 'acquisti_det'">
                                    <template #body="{data}">
                                        <Button 
                                            label="Cancella" 
                                            class="p-button p-button-warning" 
                                            v-if="data.id!=null"
                                            @click="showConfirmToDel(data)" 
                                        />
                                    </template>
                                    </Column>
                            </DataTable>
                        </div>
                    </AccordionTab>
                </Accordion>
            </template>
        </Card>
    </div>

<Dialog v-model:visible="visible" modal header="Aggiungi Prodotto in magazzino" :style="{ width: '40rem' }">
    <div class="flex mb-4 align-items-center">
        <label for="qta" class="font-semibold w-24 mr-3">Quantità</label>
        <InputText id="qta" v-model="magazzinoq" class="flex-auto" autocomplete="off" />
    </div>
    <div class="flex mb-4 align-items-center">
        <label for="prezzo" class="font-semibold w-24 mr-3">Prezzo</label>
        <InputText id="prezzo" v-model="magazzinop" class="flex-auto" autocomplete="off" />
    </div>
    
    <div class="flex mb-4 align-items-center" v-if="prodotto.hasSize">
        <label class="font-semibold w-24 mr-3">Taglia</label>

        <Dropdown v-model="magazzinos" :options="sizes" optionLabel="nome" placeholder="Seleziona una taglia" class="w-full" />
    </div>
    <div class="flex justify-content-center">
        <Button type="button" label="Cancella" severity="secondary" @click="initData" class="p-button-danger mr-3"/>
        <Button type="button" label="Salva" @click="saveMagazzino" class="p-button-success"/>
    </div>
</Dialog>


<Dialog header="Conferma" v-model:visible="isDialogVisible" modal="true" @hide="isDialogVisible = false">
      <p>Sei sicuro di voler rimuovere questo movimento?</p>
      <div class="dialog-footer">
        <Button label="No" @click="isDialogVisible = false" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button icon="pi pi-check" class="p-button-danger" label="Sì" @click="cancella" />
      </div>
    </Dialog>


</template>
<script>
import {ref, onMounted, getCurrentInstance, onUpdated, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router'
import Loader from "@/components/Loader.vue"
import Service from '@/services/levante-api/levante-api'
import moment from 'moment'
import { useToast } from 'primevue/usetoast';
import Dialog from 'primevue/dialog';
import Textarea from 'primevue/textarea';

export default {
    components: {
        Loader,Dialog,Textarea
    },
    
    setup () { 
        const self = getCurrentInstance();
        const toast = useToast();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const service = new Service(self.$eventBus)
        const loading = ref(false)
        const loaded_data = ref(false)
        const update = ref(false);
        const submitted = ref(false)
        const validationErrors = ref({})
        const route = useRoute()
        const id = route.params.id
        const prodotto = ref()
        const magazzinoq = ref(0)
        const magazzinop = ref(0)
        const magazzinos = ref()
        const magazzinot_a = ref(1)
        const isDialogVisible = ref(false)
        const movimentazioni = ref()
        const visible = ref(false)
        const lifeTime = 3000
        const sizes = ref()
        const types_atleta = ref()
        const datatodel=ref()
        
        const getDetails = async () => {
            await service.getDetails('prodotto', id)
            .then((res) => {
                prodotto.value = res.items      
                if (prodotto.value.attivo==0) prodotto.value.attivo=false;
                else prodotto.value.attivo=true                    
                loaded_data.value = true
            })
        }

        const showConfirmToDel = async (data) => {
            isDialogVisible.value=true
            datatodel.value=data
        }

        const validateForm = () => {
            
            let validate_properties = [
            'nome',
            ]
            validate_properties.forEach( prop => {
                if (prodotto.value[prop] == '' || prodotto.value[prop] == null) {
                    validationErrors.value[prop] = true
                } else {
                    delete validationErrors.value[prop];
                    
                    
                }
            })
            // Fine Validazione atleta
            
            return !Object.keys(validationErrors.value).length;
        }
        const cancella = () => {
            isDialogVisible.value=false
            let fd = new FormData();
                fd.append('id', datatodel.value.id_acquisto);
                fd.append('iddet', datatodel.value.id);
                
                service.delMovimento(fd)
                .then((res) => {
                    let severity = res.item.esito
                    let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
                    let detail = res.item.messaggio
                    
                    if (severity != 'warning') {
                        toast.add({severity:severity, summary: summary, detail:detail,life:lifeTime});                            
                    }
                }).finally(()=>{
                    getDetails()
                })
        }
        const sendData = () => {
            submitted.value = true;
            validationErrors.value = []
            if(validateForm()){
                
                let fd = new FormData();
                fd.append('prodotto', JSON.stringify(prodotto.value));
                
                service.saveProdottoData(fd)
                .then((res) => {
                    let severity = res.item.esito
                    let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
                    let detail = res.item.messaggio
                    
                    if (severity != 'warning') {
                        toast.add({severity:severity, summary: summary, detail:detail,life:lifeTime});                            
                    }
                    
                    update.value = res.item.esito == 'danger' ? true : false
                })
            }else{
                update.value = true
                toast.add({severity:'warn', summary: 'Attenzione', detail:`Ci sono dei dati mancanti`,life:lifeTime});
            }
            
        }
        const changeUpdate = () => {
            if (!update.value) {
                sendData()
            }
        }


        const saveMagazzino = async ()=>{
            if (prodotto.value.hasSize && magazzinos.value == null) {
                toast.add({severity:'warn', summary: 'Attenzione', detail:'Seleziona una taglia', life:10000});
            } else {
                loading.value = true
                let fd = new FormData();
                fd.append('prodotto', id);
                fd.append('qta', magazzinoq.value);
                fd.append('prezzo', magazzinop.value);
                if (prodotto.value.hasSize) {
                    fd.append('id_type_atleta', 1);
                    fd.append('id_size', magazzinos.value.id);
                }
    
                service.saveMagazzino(fd).then((res) => {
                    let severity = res.item.esito
                    let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
                    let detail = res.item.messaggio
                    toast.add({severity:severity, summary: summary, detail:detail});
                    update.value = res.item.esito != 'success' ? true : false;
                })
                .then(()=>{
                    let event = {
                        index:1
                    }
                    getCaricoScarico(event)
                    initData()
                })
                .finally(()=>{
                    getDetails()
                    loading.value = false
                })
            }               
        }
            
        
        const getCaricoScarico = async (event) => {
            if (event.index == 1) {            
                await service.getDetails('movimentazioni',id)
                .then((res) => {
                    movimentazioni.value = res.items                    
                    loaded_data.value = true
                })
            }
        }
        const getSizes = async () => {
            await service.getSizes()
            .then((res) => {
                sizes.value = res.items               
            })
        }
        const getTypesAtleta = async () => {
            await service.getTypesAtleta()
            .then((res) => {
                types_atleta.value = res.items
            })
        }
        
        const navigateTo = (nome_rotta, id) => {
            window.open(`/${nome_rotta}/${id}/dettagli`, '_blank')
        }

        const initData = () => {
            magazzinoq.value = 0
            magazzinop.value = 0
            magazzinot_a.value = 1
            magazzinos.value = null
            visible.value = false
        }
        
        
        onMounted(() => { 
            loading.value = true
            const promises = [getSizes(), getTypesAtleta(), getDetails()]
            
            Promise.all(promises)
            .then(()=>{
                
            }).finally(()=>{
                loading.value = false
            })
        })
        
        return {
            moment,
            cancella,
            loading,
            loaded_data,
            prodotto,
            update,
            submitted,
            validationErrors,
            getCaricoScarico,
            navigateTo,
            movimentazioni,
            visible,
            magazzinop,
            magazzinoq,
            saveMagazzino,
            changeUpdate,
            magazzinos,
            magazzinot_a,
            types_atleta,
            sizes,
            isDialogVisible,
            showConfirmToDel,
            initData,
        }
    },
}
</script>

<style>
</style>
