<template>
<Toast position="bottom-right" />

<section class="mb-8">
  <div class="page-header  pt-5 pb-11 m-3">
      <span class="mask bg-gradient-dark"></span>
        <div class="grid grid-nogutter">
 
          <div class="col-12 flex justify-content-center  mt-5">
            <img :src=getLogo height="100" />
          </div>
        
          
      </div>
    </div>

    <div class="wrapper-form">
      <div class="grid">
        <div class="xl:col-4 lg:col-5 md:col-7 col-9 mx-auto">
          <div class="card">
            <div class="card-body text-center">
              Non autorizzato
            </div>
          </div>
        </div>
      </div> 
    </div>
</section>
<footer class="footer py-5">
    <div class="grid">
      <div class="col-8 mx-auto text-center mt-1"> 
        <p class="mb-0">©{{currentYear}} made AGF Team Software</p>
      </div>
    </div>
</footer>
</template>

<script>

import {  computed} from 'vue'


export default {
  computed: {
	
  getLogo(){
    const LOGO = `${process.env.VUE_APP_LOGO}`;
    return LOGO
  }
},
  setup(){
    
    const currentYear = computed(() => new Date().getFullYear())

    return {currentYear}

  }
}
</script>
<style scoped>
.page-header {
  z-index: 1;
  height: 50%;
  min-height: 50vh;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  background-size: cover;
}

.mask {
    z-index: -2;
    position: absolute;
    background-size: cover;
    background-position: center center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background: #0098f0 !important;
    background: -webkit-linear-gradient(
180deg, #0098f0 0%, #00f2c3 100%) !important;
    background: -o-linear-gradient(180deg, #0098f0 0%, #00f2c3 100%) !important;
    background: -moz-linear-gradient(180deg, #0098f0 0%, #00f2c3 100%) !important;
    background: linear-gradient(90deg, rgb(27, 51, 89) 0%, rgb(32, 89, 122) 100%) !important;
}

.icona-skin {
    opacity: 0.5;
     height:18px;
}

.btn-black {
  background-color: #000;
  font-size: 12px;
  font-weight: bold;
  padding: 8px 25px;
}

.wizard-title {
  margin-top: -8px;
  color: rgb(255, 255, 255);
  font-size: 9px;
  letter-spacing: 16px;
  padding-left: 10px;
}

.wrapper-form {
  position: relative;
  margin-top: -200px;
  z-index: 4;
}


.login-with-google-btn {
  transition: background-color 0.3s, box-shadow 0.3s;
  padding: 12px 16px 12px 42px;
  border: 1px solid rgba(172, 172, 172, 0.1);
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
}
.login-with-google-btn:hover {
  cursor: pointer !important;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
}
.login-with-google-btn:active {
  background-color: #eeeeee;
}
.login-with-google-btn:focus {
  outline: none;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25), 0 0 0 3px #c8dafc;
}
.login-with-google-btn:disabled {
  filter: grayscale(100%);
  background-color: #ebebeb;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.btn-signin  {
  all: unset;
  color: white;
  background-color: #000;
  display: block;
  width: 100%;
  padding: 14px 1px;
  border-radius: 0.5rem;
  font-size: 12px;
  font-weight: bold;
}

.input-1 {
  box-sizing: border-box;
  height: 50px;
  width: 100%;
  border-radius: 4px;
  color: #202124;
  border: 1px solid #dadce0;
  padding: 13px 15px;
  transition: 250ms;
}
.input-1:focus {
  outline: none;
  border: 2px solid #1a73e8;
  transition: 25ms;
}

.input-1::-webkit-input-placeholder { /* Edge */
  color: #8180786e;
}

.input-1:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #8180786e;
}

.input-1::placeholder {
  color: #8180786e;
}


.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
}
.hr-text:before {
  content: "";
  background: #81807850;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}
.hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  color: black;
  padding: 0 0.5em;
  line-height: 1.5em;
  color: #818078;
  background-color: #fcfcfa;
}

.footer {
  color: #8392AB !important;
}

@media (min-height:400px) and (max-height:780px) {
  .card {
    margin-top: 80px;
  }
}

 
</style>
