<template>
  <Loader v-if="loading" />  
  <div class="flex">
    <Card class="col-8">
      <template v-slot:title>
        Crea Prodotto
      </template>
      <template v-slot:content>
        <div class="grid">
          <div class="m-3">
            <div class="formgrid grid mt-4">
              <!-- Selezione tipologia prodotto -->
              <div class="p-field col-12  md:col-6">
                <label for="cod_type_prodotto">Tipologia prodotto</label><br>
                
                <Dropdown id="cod_type_prodotto" v-model="prodotto.cod_type_prodotto" :options="types_prodotti" :filter="true" placeholder="Seleziona tipologia prodotto" :class="['inputfield w-full',{'p-invalid': validationErrors.cod_type_prodotto && submitted}]" :virtualScrollerOptions="{ itemSize: 38 }" @change="onChangeInput($event, 'cod_type_prodotto')">
                  <template #value="slotProps">
                    <div v-if="slotProps.value" class="flex align-items-center">
                      <div>{{ slotProps.value.nome }}</div>
                    </div>
                    <span v-else>
                      {{ slotProps.placeholder }}
                    </span>
                  </template>
                  <template #option="slotProps">
                    <div class="flex align-items-center">
                      <div>{{ slotProps.option.nome }}</div>
                    </div>
                  </template>
                </Dropdown>  
                <small v-show="validationErrors.cod_type_prodotto && submitted" class="p-error">Campo richiesto.</small>
              </div>
              
              <div class="p-field col-12 md:col-6">
                <label for="nome">Nome prodotto</label><br>
                <InputText id="nome" v-model="prodotto.nome" :class="['inputfield w-full',{'p-invalid': validationErrors.nome && submitted}]" @input="(val) => (prodotto.nome = prodotto.nome.toUpperCase())" @change="onChangeInput($event, 'nome')"/>
                <small v-show="validationErrors.nome && submitted" class="p-error">Campo richiesto.</small>
              </div>
              
              <!-- prezzo vendita-->
              <div class="p-field col-12 md:col-6">
                <label for="prezzo">Prezzo vendita</label><br>
                <InputNumber v-model="prodotto.prezzo" inputId="currency-italy" mode="currency" currency="EUR" locale="it-IT" :class="['inputfield w-full',{'p-invalid': validationErrors.prezzo && submitted}]" @input="onChangeInput($event, 'prezzo')"/>
                <small v-show="validationErrors.prezzo && submitted" class="p-error">Campo richiesto.</small>              
              </div>
                            
              <!-- Codice ean-->
              <div class="col-12 md:col-6">
                <div class="mb-3">
                  <label for="ean">Codice Ean</label><br>
                  <InputMask id="ean" v-model="prodotto.ean" mask="99999999?999999" autofocus :class="['text_toupper inputfield w-full',{'p-invalid': validationErrors.ean && submitted}]" @change="onChangeInput($event, 'ean')" :disabled="isDisabledEan"/>
                  <div v-show="validationErrors.ean && submitted" class="p-error">Campo richiesto.</div>
                </div>
                <div class="flex align-items-center">
                  <Checkbox v-model="isDisabledEan" :binary="true" id="isDisabledEan" class="mr-2" @update:modelValue="updateIsDisabledEan"/>                  
                  <label for="isDisabledEan">Codice Ean non presente</label>
                </div>
              </div>

              <!-- Descrizione-->
              <div class="p-field col-12">
                <label>Descrizione</label><br>
                <InputText v-model="prodotto.descrizione" class="w-full"/>
              </div>
              <div class="col-12 md:col-6">
               
                <div class="flex align-items-center">                 
                  <Checkbox v-model="prodotto.isTessera" :binary="true" id="isTessera" class="mr-2"/> 

                  <label>Si tratta di una tessera?</label>
                </div>
              </div>
              <div class="col-12 md:col-6">
               
               <div class="flex align-items-center">                 
                 <Checkbox v-model="prodotto.hasSize" :binary="true" id="hasSize" class="mr-2"/> 

                 <label>Questo prodotto ha varianti di taglia (es. S, M, L)?</label>
               </div>
             </div>
             <br/><br/>
              <div class="col-12 md:col-12" style="margin-top:20px">
               
                <div class="flex align-items-center">                 
                  <Checkbox v-model="prodotto.isPacchetto" :binary="true" id="isPacchetto" class="mr-4"/> 

                  <label>Si tratta di una Pacchetto Ingressi?</label>
                  <label for="ingressi">Quanti ingressi</label><br>
                  <InputMask id="ingressi" v-model="prodotto.ingressi" mask="99" autofocus :class="['text_toupper inputfield w-full',{'p-invalid': validationErrors.ingressi && submitted}]" @change="onChangeInput($event, 'ingressi')" :disabled="!prodotto.isPacchetto"/>
                  <div v-show="validationErrors.ingressi && submitted" class="p-error">Campo richiesto.</div>
                </div>
              </div>
      
              <!-- Bottone salva -->
              <div class="mt-5 col-12 flex justify-content-end">
                <Button class="p-button-success" icon="pi pi-check" label="Salva" @click="sendDataNewProdotto"/>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Card>
    <Card class="col-4">
      <template v-slot:title>
        Elenco prodotti
      </template>

      <template #content>
        <div v-if="existent_prodotti">
          <Listbox :options="existent_prodotti" filter optionLabel="nome_completo" :virtualScrollerOptions="{ itemSize: 38 }" class="w-full" listStyle="height:600px" />
        </div>
        <div v-else>
          <div class="w-full xl:w-6 p-3">
                <Skeleton class="mb-2"></Skeleton>
                <Skeleton width="10rem" class="mb-2"></Skeleton>
                <Skeleton width="5rem" class="mb-2"></Skeleton>
                <Skeleton height="2rem" class="mb-2"></Skeleton>
                <Skeleton width="10rem" height="4rem"></Skeleton>
            </div>
        </div>
      </template>
    </Card>

  </div>  
</template>

<script>
import {ref, onMounted, getCurrentInstance, onUpdated, computed } from 'vue';
import Loader from "@/components/Loader.vue"
import Service from '@/services/levante-api/levante-api'
import { useToast } from 'primevue/usetoast';

export default {
  components: {
    Loader,
  },
  
  setup () { 
    
    const self = getCurrentInstance();
    const toast = useToast();
    self.$eventBus = self.appContext.config.globalProperties.$eventBus
    const service = new Service(self.$eventBus)
    const submitted = ref(false)
    const loading = ref(false)
    const prodotto = ref({})
    const validationErrors = ref({})
    const capitalizeFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1);
    const isDisabledEan = ref(true)
    const existent_prodotti = ref()
    const types_prodotti = ref()

    const getProdotti = async () => {
      await service.getProdotti()
      .then((res) => {
        existent_prodotti.value = res.items
        existent_prodotti.value.forEach(item => {
          let nome_tipo = types_prodotti.value.find(type => type.id == item.cod_type_prodotto).nome
          item.nome_completo = `${item.nome} - ${nome_tipo}`+(item.ean ? ` Ean: ${item.ean}`: '')
        });
      })
    }
    const getTypesProdotti = async () => {
      await service.getTypesProdotti()
      .then((res) => {
        types_prodotti.value = res.items
        types_prodotti.value.forEach(type => {
          type.nome = capitalizeFirstLetter(type.nome)
        })
      })
    }
    const updateIsDisabledEan = () => {
      prodotto.value.ean = null
      delete validationErrors.value.ean
    }
    const onChangeInput = (evt, prop) => {
      let value
      switch (prop) {
        case 'cod_type_prodotto':
        value = evt.value
        break;

        case 'prezzo':
          value = evt.value ? String(evt.value) : null
          break;

        
        default:
        value = evt.target.value
        break;
      }
      let check
        
      if (typeof value === 'object' && ['prezzo'].includes(prop)) {
        check = !value.length
      }else{
        check = value == '' || value == null
      }

      if (check) {
        validationErrors.value[prop] = true
      }else{
        delete validationErrors.value[prop]
      }
    }
    
    const validateForm = () => {
      // Validazione prodotto
      let validate_properties = [
      'nome',
      'cod_type_prodotto',
      'prezzo',
      ]

      if (!isDisabledEan.value) {
        validate_properties.push('ean')
      }

      validate_properties.forEach( prop => {
        if (prodotto.value[prop] == '' || prodotto.value[prop] == null) {
          validationErrors.value[prop] = true
        } else {
          delete validationErrors.value[prop];
        }
      })
      // Fine Validazione prodotto
      
      return !Object.keys(validationErrors.value).length;
    }
    
    const sendDataNewProdotto = async () => {
      submitted.value = true;
      validationErrors.value = []
      if(validateForm()){
        loading.value = true
        let fd = new FormData();

        fd.append('prodotto', JSON.stringify(prodotto.value));
        
        service.saveDataNewElement('prodotto', fd)
        .then((res) => {
          let severity = res.item.esito
          let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
          let detail = res.item.messaggio
          if(res.item.esito == 'success'){
            initData()
          }
          
          toast.add({severity:severity, summary: summary, detail:detail, life: 5000});
        }).finally(() => {
          loading.value = false
        })
      }else{
        toast.add({severity:'warn', summary: 'Attenzione', detail:`Ci sono dei dati mancanti`, life: 3000});
      }
    }
    
    const initData = () => {
      getTypesProdotti()
      getProdotti()

      // restart var
      prodotto.value = {
        descrizione: '',
        isPacchetto:false,
        ean: null,
        nome: null,
        prezzo: null,
        cod_type_prodotto: null,
        hasSize: false,
        isTessera: false
      }      
      isDisabledEan.value = true
    }
    
    onMounted(() => { 
      initData()
    })
    
    return {
      loading,
      submitted,
      prodotto,
      validationErrors,
      sendDataNewProdotto,
      onChangeInput,
      existent_prodotti,
      types_prodotti,
      isDisabledEan,
      updateIsDisabledEan,
    }
  },
}
</script>

<style>
.text_toupper{
  text-transform: uppercase;
} 
</style>
<style scoped>
#container-prodotti{
  display: block;
}
.layout-main-container{
  width: fit-content !important;
}
.btn-error-filter{
  width: 200px;
}
</style>
