<template>
  <div>
    <Loader v-if="loading" />  
    <Card v-else-if="showMese">
     
      <template v-slot:title>
        <h3  style='float:left'>Pagamenti nel mese </h3>   
        <div @click="showMese=false" class='p-button p-button-danger' style='float:right'>Vedi Giorno</div>
        <br/>   
      </template>
      <template v-slot:content>
        <div class="month-selector">
          <Button @click="prevMonth">Mese Precedente</button>
          <h2>{{ formattedMonth }}</h2>
          <Button @click="nextMonth">Mese Successivo</button>
        </div>
            
        <div class="col-12 flex">
          <div class="col-12 flex flex-column md:col-6" >
            <Card style="background-color: #d32f2f; text-align: center; color: white;">
              <template #title>Da pagare</template>
              <template #content>
                <h2>{{ totalToPay }} &euro;</h2>
              </template>
            </Card>
            <table>
              <thead>
                <tr>
                  <th>Data scadenza</th>
                  <th>Corso</th>
                  <th>Utente</th>
                  <th>Importo</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="payment in topay" :key="payment.id">
                  <td>{{ moment(payment.data_scadenza).format('DD/MM/Y') }}</td>
                  <td @click="goToCorso(payment.id_corso)" style="cursor: pointer;text-decoration: underline;">{{ payment.corso }}</td>
                  <td @click="goToAtleta(payment.id_atleta)" style="cursor: pointer;text-decoration: underline">{{ payment.atleta }}</td>
                  <td style="text-align:right">{{ payment.importo }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-12 flex flex-column md:col-6">
            <Card style="background-color: #689f38; text-align: center; color: white;">
              <template #title>Pagato</template>
              <template #content>
                <h2>{{ totalePagato }} &euro;</h2>
              </template>
            </Card>
            <table>
              <thead>
                <tr>
                  <th>Data pagamento</th>
                  <th>Corso</th>
                  <th>Utente</th>
                  <th>Importo</th>
                  <th>Conto</th>
                  <th>Sorgente</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="payment in payments" :key="payment.id">
                  <td>{{ moment(payment.data_pagamento).format('DD/MM/Y') }}</td>
                  <td @click="goToCorso(payment.id_corso)" style="cursor: pointer;text-decoration: underline;">{{ payment.corso }}</td>
                  <td @click="goToAtleta(payment.id_atleta)" style="cursor: pointer;text-decoration: underline;">{{ payment.atleta }}</td>
                  <td style="text-align:right">{{ payment.importo }}</td>
                  <td>{{ payment.type_pay }}</td>
                  <td>{{ payment.type_source }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
    </Card>
    <Card v-else>

      <template v-slot:title>
        <h3 style='float:left'>Incassi giornalieri </h3> <div @click="showMese=true" class='p-button p-button-danger' style='float:right'>Vedi Mese</div>
        <br/>   
      </template>
      <template v-slot:content> 
        <div class="month-selector">
          <Calendar v-model="currentDay" dateFormat="dd/mm/yy" showIcon :showOnFocus="false"  @change="onChangeDay()"/>
        </div>
            
        <div class="col-12 flex flex-column md:col-12">
            <Card style="background-color: #689f38; text-align: center; color: white;">
              <template #title>Incasso {{ totalePagato }} &euro;</template>
              <template #content>
                 <div class="flex">
                <div v-for="cassa in casse" :key="cassa.type_pay" class="total-box">
                  <p><b>{{ cassa.type_pay }}</b><br/>{{ cassa.total.toFixed(2) }} €</p>
                   <!-- Tabella degli operatori per ogni cassa -->
      <table>
        <thead>
          <tr>
            <th>Operatore</th>
            <th style='text-align:right'>Totale</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="operatore in cassa.operatori" :key="operatore.operatore">
            <td>{{ operatore.operatore }}</td>
            <td style='text-align:right'>{{ operatore.total.toFixed(2) }} €</td>
          </tr>
        </tbody>
      </table>
                </div>
              </div>
              </template>
            </Card>
            <h4>Dettaglio</h4>
            <table>
              <thead>
                <tr>
                  <th>Data pagamento</th>
                  <th>Dettaglio</th>
                  <th>Atleta</th>
                  <th>Conto</th>
                  <th>Sorgente</th>
                  <th>Operatore</th>
                  <th style='text-align:right'>Importo</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="payment in payments" :key="payment.id">
                  <td>{{ moment(payment.data_pagamento).format('DD/MM/Y') }}</td>
                  <td v-if="payment.id_corso" @click="goToCorso(payment.id_corso)" style="cursor: pointer;text-decoration: underline;">{{ payment.corso }}</td>
                  <td v-else>Vendita</td>
                  <td @click="goToAtleta(payment.id_atleta)" style="cursor: pointer;text-decoration: underline;">{{ payment.atleta }}</td>
           
                  <td>{{ payment.type_pay }}</td>
                  <td>{{ payment.type_source }}</td>
                  <td>{{ payment.operatore }}</td>       
                  <td style="text-align:right">{{ payment.importo.toFixed(2) }} €</td>
                </tr>
              </tbody>
            </table>
          </div>
      </template>
    </Card>
  </div>
</template>

<script>
import { ref, onMounted, getCurrentInstance, computed, watchEffect } from 'vue';
import Loader from "@/components/Loader.vue";
import Service from '@/services/levante-api/levante-api'
import moment from 'moment';

export default {
  components: {
    Loader,
  },
  setup(){
    const self = getCurrentInstance();
    const currentMonth = ref(new Date());
    const currentDay = ref(new Date());
    const payments = ref([]);
    const topay = ref([]);
    const loading = ref(true);
    const totalePagato = ref(0);
    const casse = ref([])
    const casseOperatore = ref([])
    const totalToPay = ref(0);
    const showMese = ref(false)
    self.$eventBus = self.appContext.config.globalProperties.$eventBus;
    const service = new Service(self.$eventBus);
    const goToAtleta = (idatleta)=> {
      console.log(idatleta)
      window.open(`/atleta/${idatleta}/dettagli`, '_blank')
    }
    const goToCorso = (idatleta)=> {
      console.log(idatleta)
      window.open(`/corso/${idatleta}/dettagli`, '_blank')
    }
    const fetchPayments = async () => {
      loading.value = true;
      try {
        var res;

        if (showMese.value){
          res = await service.getPayments(currentMonth.value.getMonth()+1, currentMonth.value.getFullYear());
        }else{
          res = await service.getPaymentsDay(moment(currentDay.value).format('YYYY-MM-DD'));
        }
        payments.value = res.items;
        totalePagato.value = payments.value.reduce((sum, item) => sum + item.importo, 0);
        casse.value=[]
        payments.value.forEach(prop => {
        // Calcolo totale per cassa
        let cassa = casse.value.find(c => c.type_pay === prop.type_pay);
        if (!cassa) {
          cassa = { type_pay: prop.type_pay, total: 0, operatori: [] }; // Aggiungi 'operatori' per la tabella
          casse.value.push(cassa);
        }
        cassa.total += prop.importo;

        // Calcolo totale per operatore all'interno della cassa
        let cassaOp = cassa.operatori.find(op => 
          op.type_pay === prop.type_pay && op.operatore === prop.operatore
        );
        
        if (!cassaOp) {
          cassaOp = { type_pay: prop.type_pay, operatore: prop.operatore, total: 0 };
          cassa.operatori.push(cassaOp);
        }
        cassaOp.total += prop.importo;
      });
      } catch (error) {
        console.log(error);
      } finally {
        loading.value = false;
      }
      fetchToPay();
    };
    const fetchToPay = async () => {
      loading.value = true;
      try {
        if (showMese.value){
          const res = await service.getToPay(currentMonth.value.getMonth()+1, currentMonth.value.getFullYear());
          topay.value = res.items;
          totalToPay.value = topay.value.reduce((sum, item) => sum + item.importo, 0);
          console.log(topay);
        }
      } catch (error) {
        console.log(error);
      } finally {
        loading.value = false;
      }
    };

    const formattedMonth = computed(() => {
      console.log(currentMonth)
      return currentMonth.value.toLocaleDateString('it-IT', { year: 'numeric', month: 'long' }).toUpperCase();
    });

    const prevMonth = () => {
      currentMonth.value = new Date(currentMonth.value.setMonth(currentMonth.value.getMonth() - 1));
    };

    const nextMonth = () => {
      currentMonth.value = new Date(currentMonth.value.setMonth(currentMonth.value.getMonth() + 1));
    };

    const onChangeDay = () =>{
      currentDay.value=moment(currentDay.value).format('DD/MM/YYYY')
      fetchPayments()
    }

    watchEffect(() => {
      fetchPayments();
    });

    return {
      moment,
      currentMonth,
      goToCorso,
      payments,
      topay,
      loading,
      formattedMonth,
      prevMonth,
      nextMonth,
      totalePagato,
      totalToPay,
      goToAtleta,
      currentDay,
      showMese,
      casse,
      casseOperatore,
      onChangeDay
    };
  },
};
</script>

<style scoped>
.month-selector {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
}
.totals-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Spazio tra i box */
}

.total-box {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  background-color: #f9f9f9;
  color:black
}
@media (max-width: 600px) {
  .total-box {
    flex: 100%;
  }
}
</style>
