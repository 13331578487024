<template>
  <Loader v-if="loading" />    
  <div class="flex">
    <Card class="col-9">
      <template v-slot:title>
        Iscrizione al corso
      </template>
      <template v-slot:content>
        <div class="formgrid grid mt-4">
          <!-- Selezione corso -->
          <div class="col-12 md:col-6">
            <label for="id_corso">Corso</label><br>
            
            <Dropdown id="id_corso" v-model="corso.corso" :options="corsi" :filter="true" placeholder="Seleziona corso" :class="['inputfield w-full',{'p-invalid': validationErrors.corso && submitted}]" :virtualScrollerOptions="{ itemSize: 38 }" @change="onChangeInput($event, 'corso')">
              <template #value="slotProps">
                <div v-if="slotProps.value" class="flex align-items-center">
                  <div>{{ slotProps.value.nome }} [ {{  slotProps.value.iscritti }} iscritti ]</div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="flex align-items-center">
                  <div>{{ slotProps.option.nome }}  {{ slotProps.option.nota }} [ {{  slotProps.option.iscritti }} iscritti ]</div>
                </div>
              </template>
            </Dropdown>  
            <small v-show="validationErrors.corso && submitted" class="p-error">Campo richiesto.</small>
          </div>
          
          <!-- Selezione atleti-->
          <div class="p-field col-12 p-3" v-if="loaded_atleti_for_corso">
            <div v-if="countIscritti == corso.corso.max_n_atleti" class="flex align-items-center mt-4">
              <InlineMessage severity="warn">Sono chiuse le iscrizioni per questo corso</InlineMessage>
            </div>
            <div v-else>
              <label>Seleziona Atleti (<b>{{ quantiSelect }} selezionati</b>)</label><br>
              <div v-if="quantiSelect > 0" class="flex">
                <p v-for="(atleta, index) in atleti_for_corso_filtered.filter(atleta => atleta.selected)" :key="index" class="p-3">
                 <em class="p-button p-info">{{ atleta.nome }} {{ atleta.cognome }} </em> 
                </p>  
              </div>

              <ScrollPanel class="pl-3 card" style="width: 100%; height: 380px">
              <div class="col-12 flex justify-content-start">
                <InputText v-model="filter" @update:modelValue="searchAtleta($event)" placeholder="Ricerca atleta" />
              </div>
              <Button v-for="(atleta, index) in atleti_for_corso_filtered" :key="index" style="position:relative;" :class="`m-2 card flip-card p-button-${atleta.selected ? 'info' + (atleta.sesso == 'M' ? '' : '-f' ) : 'outlined' + (atleta.sesso == 'M' ? '' : '-f' )}`" @click="onSelectAtleta($event, index)" @mouseover="flip[index] = true" @mouseleave="flip[index] = false">
                <div :class="[`flip-card-inner`,{ 'flip-card-inner-flip': flip[index] }]">
                  <h6 style="margin-bottom:0.1rem">{{atleta.cognome}} {{atleta.nome}}</h6>
                  <div :class="`flip-card-front flex flex-column justify-content-center ${atleta.selected ? 'p-text-white' : 'p-text-black'}`">
                    <div class="fw-1">
                      Sesso: <strong>{{atleta.sesso}}</strong>
                      Età: <strong>{{atleta.eta}}</strong>
                    </div>
                  </div>
                  <div class="flip-card-back p-0 flex flex-column justify-content-center">
                    <img :src="atleta.photo" :alt="`${atleta.nome} ${atleta.cognome}`" class="w-full"/>
                  </div>
                </div>
                <div style="position:absolute;top: 7px;right:7px;" class="flex justify-content-center">
                  <Checkbox v-model="atleta.selected" :binary="true" />
                </div>
              </Button>
              <div v-if="!atleti_for_corso_filtered.length" class="card flex flex-wrap align-items-center justify-content-center gap-3">
                <InlineMessage severity="warn">Non ci sono atleti disponibili</InlineMessage>
              </div>
              </ScrollPanel>
              <div class="flex col-12">
                <Button v-for="(mensilita, index) in corso.corso.mensilita" :key="index" style="position:relative;" :class="`m-2 card p-button-${mensilita.selected ? 'info' : 'outlined'}`" @click="onSelectRata($event, index)">
                  <div class="flex flex-column justify-content-center w-14rem">
                    <h3>€ {{ mensilita.importo * mensilita.n_rate }}</h3>
                    <p>
                      {{ mensilita.n_rate }} {{ mensilita.suffix }} da <b style="font-size: 20px;">{{ mensilita.importo }} €</b>
                    </p>
                    <h5>Piano {{ mensilita.descrizione }}</h5>
                  </div>
                </Button>
                
              </div>

              <div class="p-field p-3  col-12">
                Aggiungi un kit
                <Dropdown v-model="corso.kit" :options="kits" :filter="true" placeholder="Seleziona kit se non in possesso" :class="['inputfield w-full',{'p-invalid': validationErrors.corso && submitted}]" :virtualScrollerOptions="{ itemSize: 38 }" @change="onChangeInput($event, 'kit')">
                  <template #value="slotProps">
                    <div v-if="slotProps.value" class="flex align-items-center">
                      <div>{{ slotProps.value.nome }}</div>
                    </div>
                    <span v-else>
                      {{ slotProps.placeholder }}
                    </span>
                  </template>
                  <template #option="slotProps">
                    <div class="flex align-items-center">
                      <div>{{ slotProps.option.nome }}  {{ slotProps.option.nota }}</div>
                    </div>
                  </template>
                </Dropdown>  
                
              </div>


              <div class="p-field p-3 col-12">
                Aggiungi una tessera
                <Dropdown v-model="corso.tessera" :options="tessere" :filter="true" placeholder="Seleziona tessera se non già acquistata" :class="['inputfield w-full',{'p-invalid': validationErrors.corso && submitted}]" :virtualScrollerOptions="{ itemSize: 38 }" @change="onChangeInput($event, 'tessera')">
                  <template #value="slotProps">
                    <div v-if="slotProps.value" class="flex align-items-center">
                      <div>{{ slotProps.value.nome }}</div>
                    </div>
                    <span v-else>
                      {{ slotProps.placeholder }}
                    </span>
                  </template>
                  <template #option="slotProps">
                    <div class="flex align-items-center">
                      <div>{{ slotProps.option.nome }}  [ {{ slotProps.option.prezzo }} &euro; ]</div>
                    </div>
                  </template>
                </Dropdown>  
              </div>
              <div class="p-field p-3 col-12">
                Quanti pagamenti effettivi vuoi inserire? (da usare in caso di iscrizione durante l'anno e non ad inizio stagione)  

                <InputNumber v-model="corso.quante"  />
              </div>


              <div class="mt-5 mr-5 col-12 flex justify-content-center">
                <Button :disabled="!canSaveIscrizione" class="p-button-success" icon="pi pi-check" label="Salva" @click="sendDataNewIscrizione"/>
              </div>
            </div>
          </div>
          
          <!-- Bottone salva -->
        </div>
      </template>
    </Card>
    <Card class="col-3" v-if="corso.corso">
      <template v-slot:title>
       {{ quanti }} Iscritti
      </template>
      <template v-slot:content>
        <div v-if="atleti_in_corso">
          <Listbox :options="atleti_in_corso" filter optionLabel="nome_completo" :virtualScrollerOptions="{ itemSize: 38 }" class="w-full" listStyle="height:600px" />
        </div>
        <div v-else>
          <div class="w-full xl:w-6 p-3">
                <Skeleton class="mb-2"></Skeleton>
                <Skeleton width="10rem" class="mb-2"></Skeleton>
                <Skeleton width="5rem" class="mb-2"></Skeleton>
                <Skeleton height="2rem" class="mb-2"></Skeleton>
                <Skeleton width="10rem" height="4rem"></Skeleton>
            </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import {ref, onMounted, getCurrentInstance, onUpdated, computed } from 'vue';
import Loader from "@/components/Loader.vue"
import Service from '@/services/levante-api/levante-api'
import moment from 'moment'
import { useRoute, useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast';
import { template } from 'underscore';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { isEmpty } from 'underscore';

export default {
  components: {
    Loader,
  },
  
  setup () {
    const route = useRoute()
    const self = getCurrentInstance();
    const toast = useToast();
    self.$eventBus = self.appContext.config.globalProperties.$eventBus
    const service = new Service(self.$eventBus)
    const submitted = ref(false)
    const loading = ref(false)
    const corso = ref({
      'corso': null,
      "quante":0,
      'rata': [],
      'atleti': []
    })
    const validationErrors = ref({})
    const corsi = ref([])
    const atleti_for_corso = ref([])
    const atleti_in_corso = ref([])
    const atleti_for_corso_filtered = ref([])
    const loaded_atleti_for_corso = ref(false)
    const idcorso = route.params.idcorso
    const idatleta = route.params.idatleta
    const flip = ref([])
    const kits = ref([])
    const kit = ref()
    const tessere = ref([])
    const tessera = ref()
    const layout = ref('grid');
    const filter = ref()
    const lifeTime = 3000
    const quanti = ref(0)
    const selectedRata = ref()
    const canSaveIscrizione = computed(()=>{
      return corso.value.atleti.length
    })
    const countIscritti = computed(()=>{
      return atleti_in_corso.value.length
    })

    const quantiSelect = computed(()=>{
      var quanti=0;
      atleti_for_corso_filtered.value.forEach(item => {
          if (item.selected) quanti++;
        });
        return quanti;
    })
    
    const getAtletiForCorso = async () => {
      loaded_atleti_for_corso.value = false
      loading.value = true
      atleti_for_corso.value = []
      await service.getAtletiForCorso(corso.value.corso.id)
      .then((res) => {
        res.items.forEach(item => {
          item.selected = Boolean(item.selected)
          if (!item.photo) {
            item.photo = `/images/avatar-${item.sesso.toLowerCase()}.jpg`
          }
          flip.value.push(false)
        })
        
        atleti_for_corso.value = res.items
        atleti_for_corso_filtered.value = [...atleti_for_corso.value]
        if (idatleta){
          console.log("idatleta found")
          console.log(idatleta)
          let index = atleti_for_corso_filtered.value.findIndex(atleta => atleta.id ==idatleta)
          console.log(index)
          corso.value.atleti.push(atleti_for_corso_filtered.value[index])
          console.log(atleti_for_corso_filtered.value[index])
          console.log(corso.value.atleti)
          atleti_for_corso_filtered.value[index].selected=true
        }
      })
      .finally(() => {
        loading.value = false
        loaded_atleti_for_corso.value = true
      })
    }

    const getAtletiInCorso = async () => {
      atleti_in_corso.value = []
      await service.getAtletiInCorso(corso.value.corso.id)
      .then((res) => {
        atleti_in_corso.value = res.items
        atleti_in_corso.value.forEach(item => {
          item.nome_completo = `${item.cognome} ${item.nome} - ${item.codfis}`
        });
        quanti.value=res.items.length
      })
    }

    const getKit = async () => {
      kits.value = []
      await service.getKit()
      .then((res) => {
        kits.value = res.items
        
      })
    }

    const getTessera = async () => {
      tessere.value = []
      await service.getTessere()
      .then((res) => {
        tessere.value = res.items
       
      })
    }

    
    const onChangeInput = (evt, prop) => {
      let value
      switch (prop) {
        case 'corso':
        value = evt.value
        
        getAtletiForCorso()
        getAtletiInCorso()
        break;
        
        case 'tessera':
        value = evt.value
        
        break;
        
        case 'kit':
        value = evt.value
        
        break;
        default:
        value = evt.target.value
        break;
      }
      if (value == '' || value == null) {
        validationErrors.value[prop] = true
      }else{
        delete validationErrors.value[prop]
      }
    }
    
    const searchAtleta = (evt) => {
      atleti_for_corso_filtered.value = []
      console.log(evt);
      if (evt == null || evt == '') {
        atleti_for_corso_filtered.value = [...atleti_for_corso.value]
      } else {
        let props = ['nome','cognome']
        let filtrato = []
        props.forEach(prop => {
          let ricerca = atleti_for_corso.value.filter(atleta => atleta[prop].toUpperCase().includes(evt.toUpperCase()))
          ricerca.forEach(el => filtrato.push(el))
        })
        atleti_for_corso_filtered.value = [...new Set(filtrato)]
      }
    }
    
    const sendDataNewIscrizione = async () => {
      submitted.value = true;
      validationErrors.value = []
      if(isEmpty(corso.value.atleti)){
        toast.add({severity:'warn', summary: 'Attenzione', detail:`Ci sono dei dati mancanti`});
      }else{
        loading.value = true
        let fd = new FormData();
        
        fd.append('corso', JSON.stringify(corso.value));
        
        service.sendDataNewIscrizione(fd)
        .then((res) => {
          let severity = res.item.error ? 'warn' : 'success'
          let summary = res.item.error ? 'Attenzione' : 'Successo'
          let detail = res.item.response
          
          toast.add({severity:severity, summary: summary, detail:detail,life: lifeTime});
          initData()
        }).finally(() => {
          loading.value = false
        })
      }
    }
    
    const onSelectAtleta = (evt, i) => {
      atleti_for_corso_filtered.value[i].selected = !atleti_for_corso_filtered.value[i].selected
      if (atleti_for_corso_filtered.value[i].selected) {
        corso.value.atleti.push(atleti_for_corso_filtered.value[i])
      }else{
        let index = corso.value.atleti.findIndex(atleta => atleta.id == atleti_for_corso_filtered.value[i].id)
        corso.value.atleti.splice(index, 1)
      }
    }
    const onSelectRata = (evt, i) => {
      Object.keys(corso.value.corso.mensilita).forEach(mensilita => {
        corso.value.corso.mensilita[mensilita].selected = Number(mensilita) == i
      })
      corso.value.rata = []
      corso.value.rata.push(corso.value.corso.mensilita[i])
      console.log(corso.value)
      corso.value.quante=corso.value.rata[0].n_rate
    }
    
    const getCorsiAttivi = async () => {
      await service.getCorsiAttivi()
      .then((res) => {
        corsi.value = res.items
        if (idcorso!=null){
          corsi.value.forEach(c=>{
            if (c.id==idcorso){
              corso.value.corso=c;
              return;
            }
          })
          console.log(corso.value);
          getAtletiForCorso()
          getAtletiInCorso()
      }
      })
    }
    
    const initData = () => {
      // restart var
      loaded_atleti_for_corso.value = false
      corso.value = {
        'corso': null,
        'rata': [],
        'atleti': []
      }
      corsi.value = []
      getCorsiAttivi()
      atleti_for_corso.value = []
      atleti_for_corso_filtered.value = []
      flip.value = []
      getKit()
      getTessera()
     
    }
    
    onMounted(() => { 
      initData()
    })
    
    return {
      loading,
      submitted,
      corso,
      validationErrors,
      corsi,
      onChangeInput,
      atleti_for_corso,
      atleti_for_corso_filtered,
      loaded_atleti_for_corso,
      onSelectAtleta,
      flip,
      searchAtleta,
      layout,
      filter,
      canSaveIscrizione,
      sendDataNewIscrizione,
      selectedRata,
      onSelectRata,
      getAtletiInCorso,
      atleti_in_corso,
      tessere,
      countIscritti,
      quantiSelect,
      tessera,
      kit,
      kits,
      quanti
    }
  },
}
</script>

<style>
.text_toupper{
  text-transform: uppercase;
} 
</style>
<style scoped>
.flip-card {
  /* background-color: transparent; */
  width: 200px; /* Larghezza per un aspetto 4:3 */
  aspect-ratio:5/2;
}

.flip-card-inner {
  width: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card-inner-flip {
 /* transform: rotateY(180deg);*/
}

.flip-card-front,
.flip-card-back {
  width: 100%;
  position: absolute;
  backface-visibility: hidden;
}

.flip-card-front {
  color: black;
}

.flip-card-back {
  transform: rotateY(180deg);
}

.p-text-white{
  color:white;
}

.p-text-black{
  color:black;
}
.content-card-atleti{
  margin: 0 !important;
  padding: 0 !important;
}
.btn-card-atleta{
  aspect-ratio:4/3;
  margin: 0 !important;
  padding: 0 !important;
}
#container-atleti{
  display: block;
}
.layout-main-container{
  width: fit-content !important;
}
.btn-error-filter{
  width: 200px;
}

.p-button-outlined-f {
  background-color: transparent;
  color: #f664d6;
  border: 1px solid;
}

.p-button-info-f {
  color: #121212;
  background: #f664d6;
  border: 1px solid #f664d6;
}
.p-button-info-f:enabled:hover{
  background: #f664d6;
  color: #121212;
  border-color: #f664d6;
}
.p-button-outlined-f:enabled:hover {
  background: rgba(100, 181, 246, 0.04);
  color: #f664d6;
  border: 1px solid;
}

</style>
