import Cookies from "js-cookie";

export default function authHeader() {

  let cookie = null
  if (Cookies.get('google_access_token')) {
    cookie =  JSON.parse(Cookies.get('google_access_token'))
  } else if (Cookies.get('ask_access_token')) {
    cookie =  Cookies.get('ask_access_token')
  }
  
  if (cookie) {
    return 'Bearer ' + cookie;
  } else {
    return {};
  }

}

