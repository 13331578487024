<template>
   <div v-if="confirmDialog.visible" class="confirm-dialog">
      <div class="confirm-dialog-content">
        <h3>Conferma la richiesta</h3>
        <p>Sei sicuro di voler procedere?</p>

     
        <br/>
        <br/>
        <Button @click="confirmDialogAccept" label="Procedi" />
        <Button @click="confirmDialogReject" label="Annulla" class="p-button-secondary" />
      </div>
    </div>

  <Loader v-if="loading" />
  <Card>
    <template v-slot:title>
      <h3>Pre Ordini da APP</h3>
    </template>
    <template v-slot:content>
      <div class="col-12">
       
      </div>
      <div class="col-12 flex flex-column md:col-12">
            <DataTable :value="richiesteOrdine" tableStyle="min-width: 50rem">
              <Column field="creation_time" header="Data creazione">
                <template #body="slotProps">
                  {{ moment(slotProps.data.data_vendita).format('DD/MM/Y HH:mm') }}
                </template>
              </Column>
              <Column field="atleta" header="Atleta"></Column>
              <Column field="totale_pagato" header="Costo"></Column>
              <Column field="type_pay" header="Pagamento"></Column>
              <Column field="details" header="Dettagli">
                <template #body="slotProps">
                    <p v-for="(detail, index) in slotProps.data.details" :key="index">
                      <strong>{{ detail.nome }}</strong>: <br/>
                      Quantità - {{ detail.quantita }}, <br/>
                      Prezzo - {{ detail.prezzo }} €
                    </p>
                </template>
                </Column>
              
              <Column header="Consegnato" field="consegnato">
                <template #body="slotProps">
                  <Button @click="openConfirmDialog(slotProps.data.id, slotProps.data.consegnato)" 
                          :label="(slotProps.data.consegnato==0) ? 'NO' : 'SI'" 
                          :class="!(slotProps.data.consegnato==0) ? 'p-button-success' : 'p-button-warning'"
                          />
                </template>
              </Column>
              <Column field="data_consegna" header="Data consegna"></Column>
            </DataTable>
        </div>
    </template>
  </Card>
</template>

<script>
import {ref, onMounted, getCurrentInstance } from 'vue';
import Loader from "@/components/Loader.vue";
import Service from '@/services/levante-api/levante-api';
import { useToast } from 'primevue/usetoast';
import moment from 'moment'

export default {
  components: {
    Loader,
  },
  setup() {
    const loading = ref(true);
    const richiesteOrdine = ref([]);
    const self = getCurrentInstance();
    const toast = useToast();
    const corsi = ref([])
    const tipocorsi = ref([])
    const atleti = ref([])
    self.$eventBus = self.appContext.config.globalProperties.$eventBus
    const service = new Service(self.$eventBus)
    const boxes = ref([])
    const lifeTime = 3000;
    const tratte = ref(['Andata e ritorno','Solo Andata','Solo Ritorno'])
    const frequenze = ref([1,2,3])
    const pulmini = ref([1,2,3,4])
    const pagamenti = ref(['Mensile','Trimestrale'])

    const validationErrors = ref({})
    const confirmDialog = ref({
      visible: false,
      id: null,
      visibile: null,
    });


    const openConfirmDialog = (id, visibile) => {
      confirmDialog.value.visible = true;
      confirmDialog.value.id = id;
      confirmDialog.value.visibile = visibile;
    };
    
    const confirmDialogAccept = () => {
      toggleApprove(confirmDialog.value.id, confirmDialog.value.visibile);
      confirmDialog.value.visible = false;
    };

    const confirmDialogReject = () => {
      toast.add({ severity: 'error', summary: 'Annullato', detail: 'Operazione annullata', life: 3000 });
      confirmDialog.value.visible = false;
    };
    const toggleApprove = async (id,approvato) => {
      approvato = approvato === 0 ? 1 : 0;
      loading.value = true;

      try {
        let fd = new FormData();  
        fd.append('id',id);
       
        await service.updateVenditaConsegnata(fd);  // assuming this endpoint exists
        toast.add({ severity: 'success', summary: 'Successo', detail: 'Richiesta aggiornata con successo',life: lifeTime});
        initData();
      } catch (error) {
        toast.add({ severity: 'error', summary: 'Errore', detail: 'Errore durante l\'aggiornamento della richiesta',life: lifeTime });
      } finally {
        loading.value = false;
      }
    };
    const onChangeInput = (evt, prop) => {
      let value
      switch (prop) {
        case 'corso':
        value = evt.value
        
        getAtleti()
        getCorsiAttivi()
        break;
        case 'atleta':
        value = evt.value
        
        getAtleti()
        getCorsiAttivi()
        break;
        default:
        value = evt.target.value
        break;
      }
      if (value == '' || value == null) {
        validationErrors.value[prop] = true
      }else{
        delete validationErrors.value[prop]
      }
    }


    const getTipoCorso = async () => {
      await service.getTypeCorso()
      .then((res) => {
        tipocorsi.value = res.items
      })
    }


    const getCorsiAttivi = async () => {
      await service.getCorsiAttivi()
      .then((res) => {
        corsi.value = res.items
      })
    }
    const getAtleti = async () => {
      await service.getAtleti()
      .then((res) => {
        atleti.value = res.items
      })
    }
    const initData = () => {
      // restart var

      service.getPreOrdini().then((res) => {
        richiesteOrdine.value = res.items
        console.log(richiesteOrdine.value);
        loading.value = false;
      });

      corsi.value = []
      getCorsiAttivi()
      atleti.value = []
      getAtleti()
      tipocorsi.value=[]
      getTipoCorso()
    }
 
    const addBox = () => {
      if (boxes.value.length==0)
        boxes.value.push({
          title: '',
          corso: null,
          tipocorso:null,
          image: null,
          atleta:null
        });
      else{
        boxes.value.pop();
      }
    }
    const validateForm = () => {
      return true;
    }
    const saveBox = async (index) => {
      if(validateForm()){
        loading.value = true
        const box = boxes.value[index];
        let fd = new FormData();  
        console.log(box.corso);
        fd.append('tipocorso', box.tipocorso.id);
        fd.append('atleta', box.atleta.id);
        
        service.sendRichiestaProva(fd)
        .then((res) => {
          let severity = res.item.esito
          let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
          let detail = res.item.messaggio
          //if(res.item.esito != 'success'){
            initData()
          //}
          if (res.item.esito=='success'){
            boxes.value.pop();
          }
          toast.add({severity:severity, summary: summary, detail:detail,life: lifeTime});
        }).finally(() => {
          loading.value = false
        })
      }else{
        toast.add({severity:'warn', summary: 'Attenzione', detail:`Ci sono dei dati mancanti`,life: lifeTime});
      }
    }
   
    onMounted(() => { 
      loading.value = true
      const promises = [initData()]
      
      Promise.all(promises)
      .then(()=>{
          
      }).finally(()=>{
          loading.value = false
      })
    })

    return {
      loading,
      richiesteOrdine,
      addBox,
      saveBox,
      boxes,
      corsi,
      atleti,
      validationErrors,
      onChangeInput,
      moment,confirmDialog,
      openConfirmDialog,
      confirmDialogAccept,
      confirmDialogReject,
      tipocorsi,tratte,pagamenti,frequenze,pulmini
    };
  },
}
</script>

<style scoped>
.confirm-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);
  z-index: 1000;
}

.confirm-dialog-content {
  text-align: center;
}

.confirm-dialog-content h3 {
  margin-top: 0;
}

.confirm-dialog-content button {
  margin: 10px;
}
</style>
