<template>
  <Loader v-if="loading" />    
  <Card>
    <template v-slot:title>
      <h3>Nuova Vendita</h3>
    </template>
    <template v-slot:content>
      <div class="m-2">
        <div class="formgrid grid mt-4">
          <div class="p-field col-12 md:col-6">
            <label>Atleta</label>
            <Dropdown v-model="vendita.id_atleta" :options="atleti" :filter="true"  :filterBy="['nome', 'cognome','nomecompleto']" optionLabel="nomecompleto" placeholder="Seleziona un atleta" class="w-full">
              <template #value="slotProps">
                <div v-if="slotProps.value" class="flex align-items-center">
                  <div>{{ slotProps.value.nome }} {{ slotProps.value.cognome }}</div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="flex align-items-center">
                  <div>{{ slotProps.option.nome }} {{ slotProps.option.cognome }}</div>
                </div>
              </template>
            </Dropdown>
          </div>
          <div class="p-field col-12">
            <ScrollPanel class="pl-0 card grid" style="width: 100%; height: 280px" :pt="{
              wrapper: {
                style: 'border-right: 10px solid var(--surface-50); border-bottom: 10px solid var(--surface-50);'
              },
              bary: 'surface-300 opacity-100 border-noround'
            }">
              <div class="col-12 flex justify-content-start">
                <InputText v-model="filter" @update:modelValue="searchProdotto($event)" placeholder="Ricerca prodotto" />
              </div>
              <Button v-for="(prodotto, index) in prodotti_disponibili_filtered" :key="index" style="position:relative;" class="m-2 card p-button-info" @click="addProdotto(index)" >
                <div class="flex flex-column justify-content-center">
                  <p>
                    {{prodotto.nome}}
                    <br/>
                    {{ prodotto.prezzo }} €
                  <br/>
                    qta disponibile: {{ prodotto.rimanenza }}
                  </p>
                </div>
              </Button>
              <div v-if="!prodotti_disponibili_filtered.length" class="card flex flex-wrap align-items-center justify-content-center gap-3">
                <InlineMessage severity="warn">Non ci sono prodotti disponibili</InlineMessage>
              </div>
            </ScrollPanel>
          </div>

         
          <DataTable :value="vendita['prodotti']" editMode="cell" @cell-edit-complete="onCellEditComplete">
            <Column v-for="col of columns" :key="col.field" :field="col.field" :header="col.header" >
              <template #body="{ data, column}">
                <template v-if="column.props.field === 'taglia'">
                  {{ getTagliaLabel(data[column.props.field]) }}
                </template>
                <template v-else-if="column.props.field === 'tipologia'">
                  {{ getTipologieLabel(data[column.props.field]) }}
                </template>
                <template v-else>
                {{ data[column.props.field]}}
              </template>
            </template>
              <template #editor="{ data, column }">
                <template v-if="column.props.field === 'taglia'">
                  <Dropdown 
                    v-model="data[column.props.field]" 
                    :options="taglie" 

                    optionLabel="nome"
                    optionValue="id"
                    placeholder="Seleziona una taglia" 
                  />
                </template>
                <template v-else-if="column.props.field === 'tipologia'">
                  <Dropdown 
                    v-model="data[column.props.field]" 
                    :options="tipologie" 
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Seleziona una tipologia" 
                  />
                </template>
                <template v-else-if="column.props.field !== 'prezzo'">
                  {{data[column.props.field]}}
                </template>
                <template v-else>
                  <InputNumber v-model="data[column.props.field]" autofocus />
                </template>
              </template>
            </Column>
           
            
            <Column :exportable="false" style='width:100px'>
              <template #body="{ data, index }">
                <i class="pi pi-trash p-error" @click="removeProdotto(data, index)" />
              </template>
            </Column>
            <template #footer>
              <div class="flex justify-content-end m-3" style="font-size: 2rem;">
                <div class="col-6 flex justify-content-between">
                  <p>Quantità: {{ vendita.quantita }}</p>
                  <p>Totale: € {{ vendita.totale }}</p>
                </div>
              </div>
            </template>
          </DataTable>
        
        
        
        
          <div class="p-field col-12">
            <Button v-for="(type_pay, index) in types_pay" :key="index" :label="type_pay.nome" style="position:relative;" :class="`m-2 p-button-${type_pay.selected ? 'info' : 'outlined'}`" @click="selectPayment(index, type_pay.id)" />
          </div>


          <div class="">
            <label>Data Vendita</label>
              <Calendar :class="['inputfield w-full']" v-model="vendita.datain" dateFormat="dd/mm/yy" showIcon :showOnFocus="false" />
          </div>
          <!-- Note -->
          <!-- <div class="p-field col-12">
            <label for="note">Note</label><br>
            <Textarea id="note" v-model="vendita['nota']" autoResize rows="5" cols="30" class="w-full"/>
          </div> -->
          
          <!-- Bottone salva -->
          <div class="mt-5 col-12 flex justify-content-end">
            <Button class="p-button-success" icon="pi pi-check" label="Completa vendita" :disabled="canSaveVendita" @click="sendVendita"/>
          </div>

        </div>
        <div v-if="showBtnAtleta">
          <center>
          <Button class="p-button-info" label="Apri dettaglio Atleta" @click="goToAtleta"/>
        </center>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import {ref, onMounted, getCurrentInstance, onUpdated, computed } from 'vue';
import Loader from "@/components/Loader.vue"
import { useRoute, useRouter } from 'vue-router'
import Service from '@/services/levante-api/levante-api'
import moment from 'moment'
import { useToast } from 'primevue/usetoast';

export default {
  components: {
    Loader,
  },
  
  setup () { 
    const self = getCurrentInstance();
    const toast = useToast();
    self.$eventBus = self.appContext.config.globalProperties.$eventBus
    const service = new Service(self.$eventBus)
    const submitted = ref(false)
    const loading = ref(false)
    const loaded_data = ref(false)
    const route = useRoute()
    const idatleta = route.params.idatleta
    const vendita = ref({
      'quantita': 0,
      'prodotti': [],
      'nota':'',
      'id_atleta': null,
      'totale':0,
      'type_pay': null,
      "datain":moment(new Date()).format('DD/MM/YYYY'),
    })
    const showBtnAtleta = ref(false)
    const validationErrors = ref({})
    const prodotti_disponibili = ref([])
    const prodotti_disponibili_filtered = ref([])
    const columns = [
    { field: 'nome', header: 'Nome'},
    { field: 'quantita', header: 'Quantità' },
    { field: 'taglia', header: 'Taglia' },
    { field: 'tipologia', header: 'Tipologia' },
    { field: 'prezzo', header: 'Prezzo €'},
    ];
    const taglie = ref([
        
      ])
    const tipologie =  ref([
        { label: 'Bambino', value: 1 },
        { label: 'Uomo', value: 2 },
        { label: 'Donna', value: 3 }
      ])
    const atleti = ref([])
    const lifeTime=3000
    const types_pay = ref([])
    const idatletaToGo = ref()

    // const validateForm = () => {
    //   // Validazione Utente
    //   let validate_properties = [
    //   'nome',
    //   'cod_type_corso',
    //   'cod_resp',
    //   'data_inizio',
    //   'data_fine',
    //   'campo',
    //   'orario'
    //   ]
    
    //   validate_properties.forEach( prop => {
    //     if (utente.value[prop] == '' || utente.value[prop] == null) {
    //       validationErrors.value[prop] = true
    //     } else {
    //       delete validationErrors.value[prop];
    //     }
    //   })
    //   // Fine Validazione Utente
    
    //   // Validazione Periodicità
    //   if(selected_day.value == n_work.value){
    //     delete validationErrors.value.periodi
    //   }else{
    //     validationErrors.value.periodi = true
    //   }
    //   // Fine Validazione Periodicità
    
    //   return !Object.keys(validationErrors.value).length;
    // }
    const getTagliaLabel= (value) => {
      const taglia = taglie.value.find(t => t.id === value);
      return taglia ? taglia.nome : value;
    }
    const getTipologieLabel= (value) => {
      const taglia = tipologie.value.find(t => t.value === value);
      return taglia ? taglia.label : value;
    }

    const getProdottiDisponibili = async () => {
      loaded_data.value = false
      await service.getProdottiDisponibili()
      .then((res) => {
        prodotti_disponibili.value = res.items
        prodotti_disponibili_filtered.value = [...prodotti_disponibili.value]
        loaded_data.value = true
      })
    }
    const searchProdotto = (evt) => {
      prodotti_disponibili_filtered.value = []
      if (evt == null || evt == '') {
        prodotti_disponibili_filtered.value = [...prodotti_disponibili.value]
      } else {
        let props = ['nome']
        let filtrato = []
        props.forEach(prop => {
          let ricerca = prodotti_disponibili.value.filter(prodotto => 
          (prodotto[prop].toUpperCase()).includes(evt.toUpperCase()))
          
          ricerca.forEach(el => filtrato.push(el))
        })
        prodotti_disponibili_filtered.value = [...new Set(filtrato)]
      }
    }
    
    const addProdotto = (i) => {
      prodotti_disponibili_filtered.value[i].rimanenza--
      let prodotto = {...prodotti_disponibili_filtered.value[i]}
      prodotto.quantita = 1
      prodotto.taglia = 1
      prodotto.tipologia = 1
      vendita.value.prodotti.push(prodotto)
      if (prodotti_disponibili_filtered.value[i].rimanenza == 0) {
        prodotti_disponibili_filtered.value.splice(i,1)
      }
      setTotaleVendita()
      setTotaleQuantitaVendita()
    }
    const removeProdotto = (data, i) => {
      let id_obj_prodotto = prodotti_disponibili_filtered.value.findIndex(prodotto => prodotto.id == data.id)
      
      if (id_obj_prodotto > -1) {
        prodotti_disponibili_filtered.value[id_obj_prodotto].rimanenza++
      }else{
        data.rimanenza = 1
        delete data.quantita
        prodotti_disponibili_filtered.value.push(data)
      }
      vendita.value.prodotti.splice(i, 1)
      setTotaleVendita()
      setTotaleQuantitaVendita()
    }
    const setTotaleVendita = () => {
      let totale = 0
      vendita.value.prodotti.forEach(prodotto => {
        totale += prodotto.prezzo
      })
      vendita.value.totale = totale
    }
    const setTotaleQuantitaVendita = () => {
      vendita.value.quantita = vendita.value.prodotti.length
    }
    
    
    const onCellEditComplete = (event) => {
      let { data, newValue, field } = event;
      
      switch (field) {
        case 'prezzo':
        
        setTotaleVendita()
        break;
      }
    }
    
    const getAtleti = async () => {
      await service.getAtleti()
      .then((res)=>{
        atleti.value = res.items
        atleti.value.forEach(atleta => {
          atleta.nome_completo = `${atleta.cognome} ${atleta.nome}`
          if (idatleta!=null && idatleta==atleta.id){
            vendita.value.id_atleta=atleta
          }
        })
      })
    }
    
    const canSaveVendita = computed(()=>{
      return !(vendita.value.id_atleta && vendita.value.prodotti.length && vendita.value.type_pay && vendita.value.datain)
    })
    
    const sendVendita = () => {
      let fd = new FormData();
      vendita.value.data=moment(vendita.value.datain, 'DD/MM/YYYY').format('YYYY-MM-DD')
      fd.append('vendita', JSON.stringify(vendita.value));
      
      service.sendVendita(fd)
      .then((res) => {
        let severity = res.item.esito
        let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
        let detail = res.item.messaggio
        
        if (severity != 'warning') {
          toast.add({severity:severity, summary: summary, detail:detail, life: lifeTime});   
          showBtnAtleta.value=true       
          idatletaToGo.value=vendita.value.id_atleta.id                  
        }
      }).finally(()=>{
        initVendita()
      })
    }

    const getTypePay = async () => {
      await service.getTypePayCassa()
      .then((res) => {
        types_pay.value = res.items
        types_pay.value.forEach(type => {
          type.selected = false
        })
      })
    }

    const selectPayment = (index, id) => {
      vendita.value.type_pay = id
      types_pay.value.forEach((type, i) => {
        type.selected = i == index ? true : false
      })
    }
    const getSizes = async()=>{
      await service.getSizes()
      .then((res) => {
        taglie.value = res.items
        
      })
    }
    const goToAtleta = () =>{
            window.open(`/atleta/${idatletaToGo.value}/dettagli`,'_self')
        }
    onMounted(() => { 
      mount()
    })

    const mount = () => {
      loading.value = true
      const promises = [getAtleti(), getProdottiDisponibili(), getTypePay(),getSizes()]
      
      Promise.all(promises)
      .then(()=>{
        
      }).finally(()=>{
        loading.value = false
      })
    } 

    const initVendita = () => {
      vendita.value = {
        'quantita': 0,
        'prodotti': [],
        'nota':'',
        'id_atleta': null,
        'totale':0,
        'type_pay': null 
      }
   
      prodotti_disponibili.value = []
      loaded_data.value = false

      mount()
    }
    
    return {
      loading,
      submitted,
      validationErrors,
      moment,
      prodotti_disponibili,
      vendita,
      loaded_data,
      prodotti_disponibili_filtered,
      searchProdotto,
      addProdotto,
      goToAtleta,
      removeProdotto,
      columns,
      onCellEditComplete,
      atleti,
      canSaveVendita,
      sendVendita,
      showBtnAtleta,
      types_pay,
      getTypePay,
      selectPayment,
      taglie,
      tipologie,
      getTagliaLabel,getTipologieLabel,idatleta
    }
  },
}
</script>

<style>
.text_toupper{
  text-transform: uppercase;
} 
</style>
<style scoped>
.counter{
  position:absolute;
  right:10px;
  top:10px;
}
#container-atleti{
  display: block;
}
.layout-main-container{
  width: fit-content !important;
}
.btn-error-filter{
  width: 200px;
}

.btn-campo{
  background:#ffffff url("/images/campo.jpg") no-repeat right top;
  background-size: contain;
  width: 100px;
  aspect-ratio:1 ;
}

.numero-campo{
  color: black;
  font-size: 40px;
  font-weight: 500;
  position: relative;
  z-index: 2;
}
</style>
