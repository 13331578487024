<template>
    <Loader v-if="loading"/>
    <div v-if="loaded_data">
        <Card>
            <template #header>
                <div class="p-3 pb-0">
                    <h4>Dettagli del corso: {{ corso.nome }} [ {{ corso.type }} ]</h4>
                </div>
            </template>
            <template #content>
                <div class="col-12 flex">
                    <div class="col-12 flex flex-column md:col-4" >
                        <Card style="background-color: #0000ff;
                        text-align: center;
                        color: white;">
                            <template #title>Iscritti</template>
                            <template #content>
                                <h2>
                                    {{ totalPeople }} 
                                </h2>
                            </template>
                        </Card>
                    </div>
                    <div class="col-12 flex flex-column md:col-4">
                        <Card style="background-color: #689f38; text-align: center; color: white;">
                            <template #title>Incassato</template>
                            <template #content>
                                <h2>{{ totalPay }} &euro;</h2>
                            </template>
                        </Card>
                    </div>
                    <div class="col-12 flex flex-column md:col-4">
                        <Card style="background-color: #d32f2f; text-align: center; color: white;">
                            <template #title>Da incassare</template>
                            <template #content>
                                <h2>{{ totalToPay }}&euro;</h2>
                            </template>
                        </Card>
                    </div>
                </div>

                <Accordion :activeIndex="1"  >
                    <AccordionTab header="Dettagli">
                        <div class="flex align-items-start flex-wrap">
                            <div class="col-12 flex flex-wrap">
                                <div class="col-12 md:col-3 flex flex-column gap-2">
                                    <label for="type">Nome corso</label>
                                    <InputText id="type" v-model="corso.nome" :disabled="!update" :class="['inputfield w-full',{'p-invalid': submitted && validationErrors.type}]"/>
                                </div>
                                <div class="p-field col-12 md:col-2">
                                    <label for="price">Costo Annuale</label><br>
                                    <InputNumber id="price" v-model="corso.annuale" autofocus :class="['text_toupper inputfield w-full',{'p-invalid': submitted && validationErrors.annual_amount}]" :disabled="true"/>
                                    
                                </div>
                                <div class="p-field col-12 md:col-2">
                                    <label for="pricesemestrale">Costo Semestrale</label><br>
                                    <InputNumber id="pricesemestrale" v-model="corso.semestrale" autofocus :class="['text_toupper inputfield w-full',{'p-invalid': submitted && validationErrors.semestrale}]" :disabled="true"/>
                                    
                                </div>
                                <div class="p-field col-12 md:col-2">
                                    <label for="pricetrimestrale">Costo Trimestrale</label><br>
                                    <InputNumber id="pricetrimestrale" v-model="corso.trimestrale" autofocus :class="['text_toupper inputfield w-full',{'p-invalid': submitted && validationErrors.trimestrale}]" :disabled="true"/>
                                    
                                </div>
                                <div class="p-field col-12 md:col-2">
                                    <label for="pricemensile">Costo Mensile</label><br>
                                    <InputNumber id="pricemensile" v-model="corso.mensile" autofocus :class="['text_toupper inputfield w-full',{'p-invalid': submitted && validationErrors.mensile}]" :disabled="true"/>
                                    
                                </div>
                                <div class="p-field col-12 md:col-2">
                                    <label for="n_allenamenti">Numero incontri</label><br>
                                    <InputNumber id="n_allenamenti" v-model="corso.n_allenamenti" :class="['text_toupper inputfield w-full',{'p-invalid': submitted && validationErrors.mensile}]" :disabled="true"/>
                                    
                                </div>
                                <div class="p-field col-12 md:col-2">
                                    <label for="max_n_atleti">Capienza massima</label><br>
                                    <InputNumber id="max_n_atleti" v-model="corso.max_n_atleti" :class="['text_toupper inputfield w-full',{'p-invalid': submitted && validationErrors.mensile}]" :disabled="!update"/>
                                    
                                </div>
                              
                                <div class="col-12 md:col-3 flex flex-column gap-2">
                                    <label>Data inizio</label>                   
                                    <Calendar :class="['inputfield w-full',{'p-invalid': submitted && validationErrors.data_inizio}]" v-model="corso.data_inizio" dateFormat="dd/mm/yy" showIcon :showOnFocus="false" :disabled="!update"/>
                                </div>
                                
                                <div class="col-12 md:col-3 flex flex-column gap-2">
                                    <label>Data fine</label>                   
                                    <Calendar :class="['inputfield w-full',{'p-invalid': submitted && validationErrors.data_fine}]" v-model="corso.data_fine" dateFormat="dd/mm/yy" showIcon :showOnFocus="false" :disabled="!update"/>
                                </div>
                                <div class="p-field col-12 flex">
                                    <div class="p-field col-12 md:col-6">
                                        <label class="mb-3">Responsabili</label>
                                        <div class="flex align-items-center w-full">
                                            <Listbox v-model="selectedResponsabili" :options="responsabili" multiple optionLabel="name" class="w-full md:w-25rem" v-if="update" listStyle="max-height:200px"/>
                                            <Button v-if="is_selectedResponsabili" icon="pi pi-angle-double-right" class="p-button-text p-button-success m-5" @click="addPersonale('responsabili')"/>
                                            <ul>
                                                <li v-for="(responsabile, indice) in corso.responsabili" :key="responsabile" class="w-25rem flex align-items-center justify-content-between">
                                                    <!-- <div @click="goToStaff(responsabile.id)">
                                                        <span style="text-decoration:underline">{{ responsabile.nome }}</span>
                                                    </div> -->
                                                    <Button @click="goToStaff(responsabile.id)" class="p-button-link" :label="responsabile.nome" style="text-decoration:underline"/>
                                                    <Button v-if="update" icon="pi pi-times" class="p-button-text p-button-danger" @click="removePersonale('responsabili', indice, responsabile.id)"/>
                                                </li>
                                            </ul>
                                        </div> 
                                    </div>
                                    
                                    <div class="p-field col-12 md:col-6">
                                        <label class="mb-3">Allenatori</label>
                                        <div class="flex align-items-center">
                                            <Listbox v-model="selectedAllenatori" :options="allenatori" multiple optionLabel="name" class="w-full md:w-25rem" v-if="update" listStyle="max-height:200px"/>
                                            <Button v-if="is_selectedAllenatori" icon="pi pi-angle-double-right" class="p-button-text p-button-success m-5" @click="addPersonale('allenatori')"/>
                                            <ul>
                                                <li v-for="(allenatore, indice) in corso.allenatori" :key="allenatore" class="w-25rem flex align-items-center justify-content-between">
                                                    <!-- <div @click="goToStaff(allenatore.id)" style='text-decoration:underline'>{{ allenatore.nome }}</div> -->
                                                    <Button @click="goToStaff(allenatore.id)" class="p-button-link" :label="allenatore.nome" style="text-decoration:underline"/>
                                                    <Button v-if="update" icon="pi pi-times" class="p-button-text p-button-danger" @click="removePersonale('allenatori', indice, allenatore.id)"/>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-field col-12 md:col-4">
                                    <label for="nota">Note</label><br>
                                    <TextArea id="nota" v-model="corso.nota" autofocus :class="['text_toupper inputfield w-full',{'p-invalid': submitted && validationErrors.nota}]" :disabled="!update"/>
                                    
                                </div>
                            </div>
                        </div>
                        <Button label="Cancella corso" v-if='update' class="p-button p-button-danger" @click="deleteCorso" />

                        <div class="col-12 flex justify-content-end align-items-center">
                            <label class="mr-2">{{!update ? 'Modifica' : 'Salva'}} dati</label>
                            <InputSwitch v-model="update" @change="changeUpdate"/>
                        </div>
                    </AccordionTab>
                    
                    <AccordionTab header="Sessioni previste">
                        <DataTable :value="sessioni" tableStyle="min-width: 50rem" paginator :rows="10" :rowsPerPageOptions="[10, 20, 50]" :rowClass="rowClass">
                            <Column field="data_inizio" header="Data inizio" style="width: 15rem;">
                                <template #body="{data}">
                                    <Button class="p-button m-2" @click="openDialogOrario(data.id,data.data_inizio,data.data_fine)" >{{ moment(data.data_inizio).format('DD/MM/Y H:mm:ss') }}</Button>
                                </template>
                            </Column>
                            <Column field="data_fine" header="Data fine" style="width: 15rem;">
                                <template #body="{data}">
                                    {{ moment(data.data_fine).format('DD/MM/Y H:mm:ss') }}
                                </template>
                            </Column>
                            <Column field="completata" header="Sessione completata" style="width: 7rem;"/>
                            <Column field="presenze" header="Presenze" style="width: 100px;">
                                
                                <template #body="{ data }">
                                    
                                        <Button class="p-button m-2" @click="openDialog(data.id,data.id_corso)" >{{ data.presenze }}</Button>
                                    </template>
                                </Column>

                            <Column field="assenze" header="Assenze" style="width: 5rem;">
                                <template #body="{data}">
                                    {{ parseInt(totalPeople) - data.presenze}}
                                    </template>
                                </Column>
                            <Column field="nomecampo" header="Campo" style="width:100px;">
                                <template #body="{ data }">
                                        <Button class="p-button-warning m-2" @click="openDialogCampo(data.id,data.id_corso)" :label="data.nomecampo"/>
                                    </template>
                            </Column>
                            <Column  style="width:100px;">
                                 
                                <template #body="{ data }">
                                    <div class="flex justify-content-end">
                                        <Button class="p-button-info m-2" @click="openDialogStaff(data.id,data.id_corso)" label="Assenze Staff"/>
                                       <!-- <Button label="Completa allenamento" class="p-button-success m-2" @click="completeSession(data.id)"/>-->
                                    </div>
                                </template>
                            </Column>
                        </DataTable>
                    </AccordionTab>

                    <AccordionTab header="Atleti iscritti">
                        <Button label="Aggiungi iscritto" class="p-button" @click="goToNewIscrizione" style="margin-bottom:5px"/>

                        <DataTable :value="iscritti" class="p-datatable-gridlines">
                            <Column field="data_inizio" header="Data iscrizione">
                                <template #body="{data}">
                                    {{ moment(data.data_inizio).format('DD/MM/Y H:mm:ss') }}
                                </template>
                            </Column>
                            <Column field="cognome" header="Atleta">
                                <template #body="{data}">
                                    <div @click="goToAtleta(data.id)" style='text-decoration:underline'>{{ data.nome }} {{ data.cognome }}</div>
                                </template>
                            </Column>
                            <Column field="datadinascita" header="Data di Nascita">
                                <template #body="{data}">
                                    {{ moment(data.datadinascita).format('DD/MM/Y') }}
                                </template>
                            </Column>
                            <Column field="stato" header="Stato">
                                <template #body="{ data }">
                                <Button v-if="data.n_rate == '-1'"
                                    class="data.n_rate == '-1' ? 'p-button-danger': 'p-button-success' m-3"
                                    :icon="data.n_rate == '-1' ? 'pi pi-question' : 'pi pi-check'"
                                    :label="data.n_rate == '-1' ? 'Completa' : 'Dettagli'"
                                    @click="setRate(data)"
                                />
                                </template>
                            </Column>
                            <Column field="presenze" header="Presenze"></Column>
                            <Column field="assenze" header="Assenze"></Column>
                            <Column field="pagato" header="Pagato"></Column>
                            <Column field="da_pagare" header="Da pagare"></Column>
                            <Column field="lastpay" header="Ultimo pagamento"></Column>
                        </DataTable>
                    </AccordionTab>

                    <AccordionTab header="Richieste prova">
                     
                        <DataTable :value="richiesteprova" class="p-datatable-gridlines">
                            <Column field="creation_time" header="Data Richiesta">
                                <template #body="{data}">
                                    {{ moment(data.creation_time).format('DD/MM/Y H:mm:ss') }}
                                </template>
                            </Column>
                            <Column field="creation_time" header="Data Approvazione">
                                <template #body="{data}">
                                    {{ moment(data.data_approvazione).format('DD/MM/Y H:mm:ss') }}
                                </template>
                            </Column>
                            <Column field="cognome" header="Atleta">
                                <template #body="{data}">
                                    <div @click="goToAtleta(data.id_atleta)" style='text-decoration:underline'>{{ data.atleta }}</div>
                                </template>
                            </Column>
                            <Column field="datadinascita" header="Data di Nascita">
                                <template #body="{data}">
                                    {{ moment(data.datadinascita).format('DD/MM/Y') }}
                                </template>
                            </Column>
                       
                        </DataTable>
                    </AccordionTab>
                </Accordion>
            </template>
        </Card>
    </div>


    <DialogComponent
      :visible="isDialogVisible"
      :iscritti="dialogIscritti"
      @close="handleClose"
      @save="handleSave"
    />
    <DialogComponentStaff
      :visible="isDialogStaffVisible"
      :iscritti="dialogStaff"
      :staff="allStaff"
      @close="handleCloseStaff"
      @save="handleSaveStaff"
    />

    <DialogComponentOrario
      :visible="isDialogOrarioVisible"
      :startSlot="startSlot"
      :endSlot="endSlot"
      @close="handleCloseOrario"
      @save="handleSaveOrario"
    />
    <DialogComponentCampi
      :visible="isDialogCampiVisible"
      :campi="dialogCampi"
      @close="handleCloseCampi"
      @save="handleSaveCampi"
    />
    
    <Dialog header="Completa Iscrizione" v-model:visible="showDialogCompletaIscrizione" modal>
        <div class="flex col-12">
            <Button v-for="(mensilita, index) in mensilita_da_completare" :key="index" style="position:relative;" :class="`m-2 card p-button-${mensilita.selected ? 'info' : 'outlined'}`" @click="onSelectRata($event, index)">
                <div class="flex flex-column justify-content-center w-14rem">
                    <h3>€ {{ mensilita.importo * mensilita.n_rate }}</h3>
                    <p>
                        {{ mensilita.n_rate }} {{ mensilita.suffix }} da <b style="font-size: 20px;">{{ mensilita.importo }} €</b>
                    </p>
                    <h5>Piano {{ mensilita.descrizione }}</h5>
                </div>
            </Button>
        </div>

        <div class="mt-5 mr-5 col-12 flex justify-content-center">
            <Button :disabled="!canUpdateIscrizione" class="p-button-success" icon="pi pi-check" label="Salva" @click="updateRateIscrizione"/>
        </div>
    </Dialog>
</template>

<script>
import {ref, onMounted, getCurrentInstance, onUpdated, computed } from 'vue';
import { useRoute } from 'vue-router'
import Loader from "@/components/Loader.vue"
import Service from '@/services/levante-api/levante-api'
import moment from 'moment'
import { useToast } from 'primevue/usetoast';
import DialogComponent from '../DialogComponent.vue';
import DialogComponentStaff from '../DialogComponentStaff.vue';
import DialogComponentOrario from '../DialogComponentOrario.vue';
import DialogComponentCampi from '../DialogComponentCampi.vue';

export default {
    components: {
        Loader,
        DialogComponent,
        DialogComponentStaff,
        DialogComponentOrario,
        DialogComponentCampi
    },

    setup () { 
        const self = getCurrentInstance();
        const toast = useToast();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const service = new Service(self.$eventBus)
        const loading = ref(false)
        const loaded_data = ref(false)
        const update = ref(false);
        const submitted = ref(false)
        const validationErrors = ref({})
        const route = useRoute()
        const id = route.params.id
        const corso = ref()
        const sessione = ref(0)
        const sessioni = ref([])
        const totalPeople = ref(0)
        const iscritti = ref([])
        const totalPay = ref(0)
        const totalToPay = ref(0)
        const isDialogVisible = ref(false)
        const isDialogStaffVisible = ref(false)
        const isDialogOrarioVisible = ref(false)
        const isDialogCampiVisible = ref(false)
        const dialogIscritti = ref([]);
        const dialogStaff = ref([]);
        const dialogCampi = ref([]);
        const allStaff = ref([]);
        const lifeTime = 3000;
        const richiesteprova =ref([])
        const startSlot = ref();
        const endSlot = ref();
        const showDialogCompletaIscrizione = ref(false);
        const mensilita_da_completare = ref()
        const iscrizione_da_aggiornare = ref({
            iscrizione: null,
            id_atleta:id,
            rata:[]
        })
        const allResponsabili = ref([])
        const responsabili = ref([])
        const selectedResponsabili = ref([])

        const allAllenatori = ref([])
        const allenatori = ref([])
        const selectedAllenatori = ref([])

        const getStaff = async () => {
            await service.getStaff()
            .then((res) => {
                allStaff.value = res.items
                allResponsabili.value = allStaff.value.filter(staff => staff.is_responsabile == 'SI')
                allResponsabili.value.forEach(responsabile => {
                    let index = corso.value.responsabili.findIndex(resp => resp.id == responsabile.id)
                    
                    if (index == -1) {
                        responsabili.value.push(responsabile)
                    }
                });
                allAllenatori.value = allStaff.value.filter(staff => staff.is_allenatore == 'SI')
                allAllenatori.value.forEach(allenatore => {
                    let index = corso.value.allenatori.findIndex(all => all.id == allenatore.id)
                    if (index == -1) {
                        allenatori.value.push(allenatore)
                    }
                });
            })
        }

        const getDetails = async () => {
            await service.getDetails('corso', id)
            .then((res) => {
                corso.value = res.items[0]
                corso.value.data_inizio = moment(corso.value.data_inizio).format('DD/MM/Y')
                corso.value.data_fine = moment(corso.value.data_fine).format('DD/MM/Y')
                loaded_data.value = true
            })
        }
        const fetchIscrittiSezione = async (sessionId,corsoId) => {
            try {
                const res = await service.getIscrittiSezione(sessionId,corsoId);
                dialogIscritti.value = res.items;
            } catch (error) {
                console.error("Error fetching iscritti sezione", error);
                toast.add({ severity: 'error', summary: 'Errore', detail: 'Non è stato possibile ottenere gli iscritti.' });
            }
        };

        const fetchStaffCorso = async (sessionId,corsoId) => {
            try {
                const res = await service.getStaffCorso(sessionId,corsoId);
                dialogStaff.value = res.items;
            } catch (error) {
                console.error("Error fetching iscritti sezione", error);
                toast.add({ severity: 'error', summary: 'Errore', detail: 'Non è stato possibile ottenere gli iscritti.' });
            }
        };
        const fetchCampi = async () => {
            try {
                const res = await service.getCampi();
                dialogCampi.value = res.items;
            } catch (error) {
                toast.add({ severity: 'error', summary: 'Errore', detail: 'Non è stato possibile ottenere i campi.' });
            }
        };
        const openDialogCampo = async (sessionId,corsoId) => {
            loading.value = true
            sessione.value = sessionId
            await fetchCampi()
            .finally(()=>{
                loading.value = false
            })
            isDialogCampiVisible.value = true;
        };
        const openDialogOrario = async (sessionId,start,end) => {
            
            sessione.value = sessionId
            startSlot.value=start
            endSlot.value=end
            isDialogOrarioVisible.value = true;
        };

        const openDialogStaff = async (sessionId,corsoId) => {
            loading.value = true
            sessione.value = sessionId
            await fetchStaffCorso(sessionId,corsoId)
            .finally(()=>{
                loading.value = false
            })
            isDialogStaffVisible.value = true;
        };

        const openDialog = async (sessionId,corsoId) => {
            loading.value = true
            sessione.value = sessionId
            await fetchIscrittiSezione(sessionId,corsoId)
            .finally(()=>{
                loading.value = false
                
                
            })
            isDialogVisible.value = true;
        };
        const handleClose = async () => {
            isDialogVisible.value = false;
        }
        const handleCloseStaff = async () => {
            isDialogStaffVisible.value = false;
        }
        const handleCloseCampi= async () => {
            isDialogCampiVisible.value = false;
        }
        const handleCloseOrario = async () => {
            isDialogOrarioVisible.value = false;
        }
        const handleSaveOrario = async (time_in,time_out,forever)=> {
            // Logica per salvare le presenze aggiornate
            let fd = new FormData();
            fd.append('time_in', time_in);
            fd.append('time_out', time_out);
            fd.append('sessionId', sessione.value);
            if (forever){
                fd.append('forever', 1);
            }else{
                fd.append("forever",0);
            }
            service.saveOrarioSessione(fd)
            .then((res) => {
                let severity = res.item.esito
                let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
                let detail = res.item.messaggio
                
                if (severity != 'warning') {
                    toast.add({severity:severity, summary: summary, detail:detail,life:lifeTime});                            
                }
                getSessioni()
            }).finally(()=>{
                isDialogCampiVisible.value = false;
            })
        }
        const handleSaveCampi = async (updatedIscritti,forever)=> {
            // Logica per salvare le presenze aggiornate
            let fd = new FormData();
            fd.append('campo', JSON.stringify(updatedIscritti));
            fd.append('sessionId', sessione.value);
            if (forever){
                fd.append('forever', 1);
            }else{
                fd.append("forever",0);
            }
            service.saveCampoSessione(fd)
            .then((res) => {
                let severity = res.item.esito
                let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
                let detail = res.item.messaggio
                
                if (severity != 'warning') {
                    toast.add({severity:severity, summary: summary, detail:detail,life:lifeTime});                            
                }
                getSessioni()
            }).finally(()=>{
                isDialogCampiVisible.value = false;
            })
        }
        const handleSave = async (updatedIscritti)=> {
            // Logica per salvare le presenze aggiornate
            let fd = new FormData();
            fd.append('presenze', JSON.stringify(updatedIscritti));
            fd.append('sessionId', sessione.value);
            
            service.savePresenze(fd)
            .then((res) => {
                let severity = res.item.esito
                let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
                let detail = res.item.messaggio
                
                if (severity != 'warning') {
                    toast.add({severity:severity, summary: summary, detail:detail,life:lifeTime});                            
                }
                getSessioni()
            }).finally(()=>{
                isDialogVisible.value = false;
            })
        }
        const handleSaveStaff = async (updatedIscritti,alternativeStaff)=> {
            // Logica per salvare le presenze aggiornate
            let fd = new FormData();
            fd.append('assenze', JSON.stringify(updatedIscritti));
            fd.append('sessionId', sessione.value);
            fd.append('alternativeStaff', JSON.stringify(alternativeStaff));
            
            service.saveAssenzeStaff(fd)
            .then((res) => {
                let severity = res.item.esito
                let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
                let detail = res.item.messaggio
                
                if (severity != 'warning') {
                    toast.add({severity:severity, summary: summary, detail:detail,life:lifeTime});                            
                }
                getSessioni()
            }).finally(()=>{
                isDialogStaffVisible.value = false;
            })
        }
        const getAtletiInCorso = async () => {
            iscritti.value = []
            totalPay.value=0
            totalToPay.value=0
            await service.getAtletiInCorso(id)
            .then((res) => {
                iscritti.value = res.items
                iscritti.value.forEach(item => {
                    item.nome_completo = `${item.cognome} ${item.nome} - ${item.codfis}`
                    totalPay.value+=item.pagato
                    totalToPay.value+=item.da_pagare
                });
                totalPeople.value = iscritti.value.length

            })

            richiesteprova.value = []
            await service.getRichiesteProvaCorso(id)
            .then((res) => {
                richiesteprova.value = res.items
               
                
            })
        }

        const validateForm = () => {
            return true
        }
 
        const setRate = (data) => {
            mensilita_da_completare.value = data.mensilita
            iscrizione_da_aggiornare.value.iscrizione = data
            showDialogCompletaIscrizione.value = true
        }

        const onSelectRata = (evt, i) => {
            Object.keys(mensilita_da_completare.value).forEach(mensilita => {
                mensilita_da_completare.value[mensilita].selected = Number(mensilita) == i
            })
            iscrizione_da_aggiornare.value.rata = []
            iscrizione_da_aggiornare.value.rata.push(mensilita_da_completare.value[i])
        }
    
        const canUpdateIscrizione = computed(()=>{
            return iscrizione_da_aggiornare.value.rata.length
        })

        const updateRateIscrizione = async () => {
            loading.value = true
            let fd = new FormData();
            
            fd.append('iscrizione', JSON.stringify(iscrizione_da_aggiornare.value));
            
            service.updateRateIscrizione(fd)
            .then((res) => {
                let severity = res.item.esito
                let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
                let detail = res.item.messaggio
                
                toast.add({severity:severity, summary: summary, detail:detail,life:lifeTime});                            
                onMounted()
            }).finally(() => {
                showDialogCompletaIscrizione.value = false
                loading.value = false
            })
        }
        const saveCorso = () => {
            submitted.value = true;
            validationErrors.value = []
            if(validateForm()){
                let fd = new FormData();
                fd.append('corso', JSON.stringify(corso.value));
                service.saveCorsoData(fd).then((res) => {
                    let severity = res.item.esito
                    let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
                    let detail = res.item.messaggio
                    toast.add({severity:severity, summary: summary, detail:detail});
                    update.value = res.item.esito != 'success' ? true : false;
                });
            }
        }
        const deleteCorso=() =>{
            let fd = new FormData();
                fd.append('corso', id);
                service.delCorsoData(fd).then((res) => {
                    let severity = res.item.esito
                    let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
                    let detail = res.item.messaggio
                    toast.add({severity:severity, summary: summary, detail:detail});
                    update.value = res.item.esito != 'success' ? true : false;
                    if ( res.item.esito =='success'){
                        window.open(`/mindtab`,"_self");
                    }
                });
        }
        const changeUpdate = () => {
            console.log("changeUpdate");
            if (!update.value) {
                saveCorso()
            }
        }
        
        const getSessioni = async () => {
            await service.getSessioni(id)
            .then((res) => {
                res.items.forEach(item => {
                    item.completata = item.completata ? 'Si' : 'No'
                });
                sessioni.value = res.items
            })                
            
        }
        onMounted(() => { 
            loading.value = true
            mensilita_da_completare.value = null
            iscrizione_da_aggiornare.value = {
                iscrizione: null,
                id_atleta:id,
                rata:[]
            }
            showDialogCompletaIscrizione.value = false
            const promises = [getDetails(), getAtletiInCorso(),getStaff(),getSessioni()]
            
            Promise.all(promises)
            .finally(()=>{
                loading.value = false
            })
        })
        
        const goToAtleta = (idatleta)=> {
            window.open(`/atleta/${idatleta}/dettagli`)
        }
        const goToStaff = (id)=> {
            window.open(`/staff/${id}/dettagli`)
        }

        const goToNewIscrizione = () => {
            window.open(`/add-iscrizione/${id}`)
        }
        const completeSession = async (id_sessione) => {
            loading.value = true
            let fd = new FormData()
            fd.append('id_sessione', id_sessione)
            await service.setCompleteSession(fd)
            .then(res => {
                console.log(res);
                let severity = res.item.esito
                let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
                let detail = res.item.messaggio
                if(res.item.esito == 'success'){
                    loading.value = true                    
                    Promise.all([getSessioni()])
                    .finally(()=>{
                        loading.value = false
                    })
                }

                toast.add({severity:severity, summary: summary, detail:detail, life: 10000});

            })
            .finally(()=>{
                loading.value = false
            })
        }
        const rowClass = (data) => {
            return [{ 'bg-success': data.completata == 'Si' }];
        };

        const removePersonale = (tipo_personale, indice, id) => {
            let array_tipo_personale = corso.value[tipo_personale]
            
            array_tipo_personale.splice(indice, 1)           
            
            let array_personale
            let array_all_personale

            if (tipo_personale == 'responsabili') {
                array_personale = responsabili.value
                array_all_personale = allResponsabili.value

            } else {
                array_personale = allenatori.value
                array_all_personale = allAllenatori.value
            }

            array_personale.push(array_all_personale.find(pers => pers.id == id))
        }

        const is_selectedResponsabili = computed(()=>{
            return selectedResponsabili.value.length
        })
        const is_selectedAllenatori = computed(()=>{
            return selectedAllenatori.value.length
        })

        const addPersonale = (tipo_personale) => {
            let array_personale
            let array_selected

            if (tipo_personale == 'responsabili') {
                array_personale = responsabili.value
                array_selected = selectedResponsabili.value

            } else {
                array_personale = allenatori.value
                array_selected = selectedAllenatori.value
            }

            array_selected.forEach(selected_personale => {
                selected_personale.nome = selected_personale.name.trim()
                corso.value[tipo_personale].push(selected_personale)

                let index = array_personale.findIndex(personale => personale.id == selected_personale.id)
                array_personale.splice(index, 1)
            })
            
            if (tipo_personale == 'responsabili') {
                selectedResponsabili.value = []
            } else {
                selectedAllenatori.value = []
            }
        }
        return {
            moment,
            loading,
            loaded_data,
            corso,
            update,
            submitted,
            changeUpdate,
            validationErrors,
            getSessioni,
            sessioni,
            totalPeople,
            totalPay,
            totalToPay,
            showDialogCompletaIscrizione,
            getAtletiInCorso,
            iscritti,
            goToAtleta,
            openDialog,
            openDialogStaff,
            openDialogCampo,
            openDialogOrario,
            isDialogOrarioVisible,
            handleSave,
            handleSaveOrario,
            isDialogVisible,
            isDialogStaffVisible,
            handleCloseCampi,
            dialogIscritti,
            dialogStaff,
            dialogCampi,
            deleteCorso,
            allStaff,
            setRate,
            goToStaff,
            endSlot,
            startSlot,
            canUpdateIscrizione,updateRateIscrizione,
            handleClose,
            goToNewIscrizione,
            completeSession,
            rowClass,
            handleSaveStaff,
            handleCloseStaff,
            onSelectRata,
            mensilita_da_completare,
            isDialogCampiVisible,
            handleSaveCampi,
            richiesteprova,
            removePersonale,
            handleCloseOrario,
            addPersonale,
            responsabili,
            allenatori,
            selectedResponsabili,
            selectedAllenatori,
            is_selectedResponsabili,
            is_selectedAllenatori,
        }
    },
}
</script>

<style>
    .bg-success{
        background-color: #10b982 !important;
        color: white !important;
    }
</style>