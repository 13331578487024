<template>
    <Loader v-if="loading"/>
    <div v-if="loaded_data">
        <Card>
            <template #header>
                <div class="p-3 pb-0">
                    <h2>Profilo fiscale: {{ atleta.cognome }} {{ atleta.nome }}</h2>
                    
                </div>
            </template>
            <template #content>
                <Button label="Reset password" raised class="p-button" @click="openDialog" style="margin-bottom:5px"/>

                <Accordion :activeIndex="0">
                    <AccordionTab header="Dettagli">
                     
                        <div class="flex align-items-start flex-wrap">
                            <div class="col-12 flex flex-wrap">
                                <div class="col-12 md:col-3 flex flex-column gap-2">
                                    <label for="cognome">Cognome</label>
                                    <InputText id="cognome" v-model="atleta.cognome" :disabled="!update" :class="['inputfield w-full',{'p-invalid': submitted && validationErrors.cognome}]"/>
                                </div>
                                <div class="col-12 md:col-3 flex flex-column gap-2">
                                    <label for="nome">Nome</label>
                                    <InputText id="nome" v-model="atleta.nome" :disabled="!update" :class="['inputfield w-full',{'p-invalid': submitted && validationErrors.nome}]"/>
                                </div>
                                <div class="col-12 md:col-3 flex flex-column gap-2">
                                    <label>Data di nascita</label>                   
                                    <Calendar :class="['inputfield w-full',{'p-invalid': submitted && validationErrors.datadinascita}]" v-model="atleta.datadinascita" dateFormat="dd/mm/yy" showIcon :showOnFocus="false" :disabled="!update"/>
                                </div>
                                
                                <div class="col-12 md:col-3 flex flex-column gap-2">
                                    <label>Luogo di nascita</label>                   
                                    <InputText :class="['inputfield w-full',{'p-invalid': submitted && validationErrors.luogodinascita}]" v-model="atleta.luogodinascita"  showIcon :showOnFocus="false" :disabled="!update"/>
                                </div>
                                
                                <div class="col-12 md:col-3 flex flex-column gap-2">
                                    <label>Indirizzo</label>                   
                                    <InputText :class="['inputfield w-full',{'p-invalid': submitted && validationErrors.indirizzo}]" v-model="atleta.indirizzo"  showIcon :showOnFocus="false" :disabled="!update"/>
                                </div>

                                  
                               
                                  
                                <!-- Codice fiscale atleta -->
                                <div class="p-field col-12 md:col-3">
                                    <label for="cf">Telefono</label><br>
                                    <InputText id="telefono" v-model="atleta.telefono" autofocus :class="['text_toupper inputfield w-full',{'p-invalid': submitted && validationErrors.telefono}]" :disabled="!update"/>
                                    
                                </div>
                                <div class="p-field col-12 md:col-3">
                                    <label for="cf">Codice fiscale</label><br>
                                    <InputText id="telefono" v-model="atleta.codfis" autofocus :class="['text_toupper inputfield w-full',{'p-invalid': submitted && validationErrors.telefono}]" :disabled="!update"/>
                                    
                                </div>
                                <!-- Codice fiscale atleta -->
                                <div class="p-field col-12 md:col-3">
                                    <label for="cf">Email</label><br>
                                    <InputText id="email" v-model="atleta.email" autofocus :class="['text_toupper inputfield w-full',{'p-invalid': submitted && validationErrors.email}]" :disabled="!update"/>
                                    
                                </div>
                                <!-- Codice fiscale atleta -->
                                <div class="p-field col-12 md:col-3">
                                    <label for="cf">Username</label><br>
                                    <InputText id="username" v-model="atleta.username" autofocus :class="['text_toupper inputfield w-full',{'p-invalid': submitted && validationErrors.email}]" :disabled="!update"/>
                                    
                                </div>
                                
                            </div>
                        </div>
                       
                        
                        <div class="col-12 flex justify-content-end align-items-center">
                            <label class="mr-2">{{!update ? 'Modifica' : 'Salva'}} dati</label>
                            <InputSwitch v-model="update" @change="changeUpdate"/>
                        </div>
                    </AccordionTab>
                 
                    <AccordionTab header="Atleti associati">
                        <DataTable :value="atletiToShow" class="p-datatable-gridlines">
                            <Column field="nome" header="Nome"></Column>
                            <Column field="cognome" header="Cognome"></Column>
                            <Column field="datadinascita" header="Data di nascita"></Column>
                            <Column field="numcorsi" header="#Corsi"></Column>
                            <Column field="pagato" header="Pagato" style='text-align: right;'></Column> 
                            <Column field="numcorsi" header="">
                                <template #body="{data}">
                                    <div @click="goToAtleta(data.id)" class='p-button p-button-warning' >Visualizza</div>
                                </template>
                            </Column>
                        </DataTable>
<br/><br/>
                        <Button @click="addRow">Aggiungi</Button>
                        <DataTable :value="atleti" editMode="cell" @cell-edit-complete="onCellEditComplete" v-if="toshow">
                            <template #empty>
                            <div class="text-center">Nessun atleta inserito</div>
                            </template>
                           
                            <Column v-for="col of columns" :key="col.field" :field="col.field" :header="col.header" style="width:20%">
                            <template #body="{data, index}">
                                <div class="flex flex-column">
                                <div>
                                    {{data[col.field] == '' ? 'Inserisci...' : (col.field == 'birth' ? moment(data[col.field]).format('DD/MM/YYYY') : data[col.field])}}                 
                                </div>
                                <div v-show="validationErrors[`${col.field}_${index}`]" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                                </div>
                            </template>
                            <template #editor="{ data, index }">
                                <div v-if="col.field == 'birth'">
                                <Calendar v-model="data[col.field]" dateFormat="dd/mm/yy" :showIcon="true" :showOnFocus="false" :maxDate="maxDate" @change="onChangeInput($event, `${col.field}_${index}`)"/>
                                </div>
                                <div v-if="col.field == 'gender'">
                                <div class="flex flex-column gap-3">
                                    <div v-for="gender in genders" :key="gender.key" class="flex align-items-center">
                                    <RadioButton v-model="data[col.field]" :id="`gender_${gender.key}_${index}`" name="dynamic" :value="gender.name" />
                                    <label :for="`gender_${gender.key}_${index}`" class="ml-2">{{ gender.name }}</label>
                                    </div>
                                </div>
                                </div>
                                <div v-if="col.field == 'cf'">
                                <InputMask v-model="data[col.field]" mask="aaaaaa99a99a999a" autofocus class="w-full text_toupper" @change="onChangeInput($event, `${col.field}_${index}`)"/>
                                </div>
                                <div v-if="col.field=='comune'">
                                <Dropdown v-model="data[col.field]" :options="cities" :filter="true" optionLabel="name" optionValue="id" placeholder="Seleziona comune" :class="['inputfield w-full',{'p-invalid': validationErrors.comune && submitted}]" :virtualScrollerOptions="{ itemSize: 38 }" @change="onChangeInput($event, `${col.field}_${index}`)"/>
                            </div>
                                <div v-if="col.field != 'birth' && col.field != 'gender' && col.field != 'comune' && col.field != 'cf'">
                                <InputText v-model="data[col.field]" autofocus class="w-full" @change="onChangeInput($event, `${col.field}_${index}`)"/>
                                </div>
                            </template>
                            </Column>
                        </DataTable>
                        <Button @click="Save" class="p-button p-button-warning" v-if="toshow">Salva</Button>
                    </AccordionTab>


                    <AccordionTab header="Notifiche">
                        <DataTable :value="notifiche" class="p-datatable-gridlines">
                            <Column field="creation_time" header="Data"></Column>
                            <Column field="title" header="Titolo"></Column>
                            <Column field="body" header="Dettaglio"></Column>
                            <Column field="readed" header="Letto"></Column> <!-- Questa colonna non è necessaria se non c'è status -->
                        </DataTable>
                    </AccordionTab>
                </Accordion>
            </template>
        </Card>
    </div>


    <Dialog header="Reset Password" v-model:visible="showDialog" modal>
        <form @submit.prevent="handleSubmit">
        <div class="field">
          <label for="file">Confermi di voler inviare una nuova password all'utente?</label><br/>
        
        </div>
        
        <div class="dialog-footer">
          <Button label="Invia" icon="pi pi-check" type="submit" />
        </div>
      </form>
    </Dialog>

    
    

</template>

<script>
import {ref, onMounted, getCurrentInstance, onUpdated, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router'
import Loader from "@/components/Loader.vue"
import Service from '@/services/levante-api/levante-api'
import moment from 'moment'
import { Dialog } from 'primevue/dialog';
import { useToast } from 'primevue/usetoast';
import { Button } from 'primevue/button';

export default {
    components: {
        Loader,
    },
    
    setup () { 
        const toast = useToast();
        const self = getCurrentInstance();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const service = new Service(self.$eventBus)
        const loading = ref(false)
        const loaded_data = ref(false)
        const update = ref(false);
        const submitted = ref(false)
        const validationErrors = ref({})
        const showDialog = ref(false);
        
           const columns = [
    { field: 'surname', header: 'Cognome' },
    { field: 'name', header: 'Nome' },
    { field: 'birth', header: 'Data di nascita' },
    { field: 'gender', header: 'Sesso' },
    { field: 'indirizzo', header: 'Indirizzo' },
    { field: 'luogodinascita', header: 'Luogo di Nascita' },
    { field: 'cf', header: 'Codice fiscale' }
    ];
          const genders = [
    {key:0, name:'Maschio'},
    {key:1, name:'Femmina'}
    ]
        const users = ref([])
        const atleti = ref([])
        const atletiToShow = ref()
        const toshow = ref(false)
        
        const route = useRoute()
        const id = route.params.id
        const lifeTime = 3000;
        const atleta = ref({})
        const notifiche = ref({})
       
        const addRow = () => {
            let newRow = columns.reduce((obj, column) => {
                obj[column.field] = '';
                return obj;
            }, {});
            newRow.gender = 'Maschio'
            newRow.surname = ''
            newRow.indirizzo = ''
            newRow.comune =  ''
            if (!toshow.value)
                atleti.value.push(newRow)  
            toshow.value=true
        }


    const Save = async () => {
      submitted.value = true;
      validationErrors.value = []
      console.log("sendDataNewUser")
      if(validateForm()){
        loading.value = true
        let fd = new FormData();
       
        fd.append('atleti', JSON.stringify(atleti.value));
        
        service.saveAtleta(fd,id)
        .then((res) => {
          let severity = res.item.error ? 'warn' : 'success'
          let summary = res.item.error ? 'Attenzione' : 'Successo'
          let detail = res.item.response
          
          toast.add({severity:severity, summary: summary, detail:detail,life:lifeTime});
          atleti.value=[]
          toshow.value=false
        }).finally(() => {
          initData()
          loading.value = false
        })
      }
//      else{
//        toast.add({severity:'warn', summary: 'Attenzione', detail:`Ci sono dei dati mancanti`});
//      }
    }



        const goToAtleta = (id)=>{
            window.open(`/atleta/${id}/dettagli`,'_self')

        }
       
        const getAtleti = async () => {
            await service.getAtletiUtente(id)
            .then((res) => {
                atletiToShow.value = res.items
            })
        }
        
        const getNotifiche = async () => {
            await service.getNotifiche(id)
            .then((res) => {
                notifiche.value = res.items.notifiche
            })
        }
        
        const getDetails = async () => {
            await service.getDetails('utente', id)
            .then((res) => {
                atleta.value = res.items[0]
                
                atleta.value.datadinascita = moment(atleta.value.datadinascita).format('DD/MM/Y')
                loaded_data.value = true
            })
        }
        const validateForm = () => {
            let regexCf = /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i
            
            // Validazione atleta
            let validate_properties = [
            'nome',
            'cognome',
            'datadinascita',
            'email',
            'telefono',
            'username'
           
            ]
            validate_properties.forEach( prop => {
                if (atleta.value[prop] == '' || atleta.value[prop] == null) {
                    validationErrors.value[prop] = true
                } else {
                    delete validationErrors.value[prop];
                    if (prop == 'codfis') {
                        if (!regexCf.test(atleta.value[prop])) {
                            validationErrors.value[prop] = true
                        }
                    }
                }
            })
            // Fine Validazione atleta
            
            return !Object.keys(validationErrors.value).length;
        }
        const setDataTime = (el) => {
            if (typeof el == 'object') {
                return moment(el).format('DD/MM/YYYY')                
            }
            let dataPart = el.split('/')
            el = `${dataPart[2]}-${dataPart[1]}-${dataPart[0]}`
            return el
        } 
           const onChangeInput = (evt, prop) => {
      let value
      switch (prop) {
        case 'comune':
        value = evt.value
        break;
        
        default:
        value = evt.target.value
        break;
      }
      if (value == '' || value == null) {
        validationErrors.value[prop] = true
      }else{
        delete validationErrors.value[prop]
      }
    }
        const sendNewDataAtleta = () => {
            submitted.value = true;
            validationErrors.value = []
            if(validateForm()){
                // loading.value = true
                let fd = new FormData();
                atleta.value.birth = setDataTime(atleta.value.datadinascita)
                
                fd.append('utente', JSON.stringify(atleta.value));
                
                service.sendNewDataUtente(fd)
                .then((res) => {
                    let severity = res.item.esito
                    let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
                    let detail = res.item.messaggio
                    
                    if (severity != 'warning') {
                        toast.add({severity:severity, summary: summary, detail:detail,life:lifeTime});                            
                    }
                    
                    update.value = res.item.esito == 'danger' ? true : false
                })
            }else{
                update.value = true
                toast.add({severity:'warn', summary: 'Attenzione', detail:`Ci sono dei dati mancanti`,life:lifeTime});
            }
            
        }
        const changeUpdate = () => {
            if (!update.value) {
                sendNewDataAtleta()
            }
        }
        
       
        
        const getPromises = (promises) => {
            loading.value = true
            
            Promise.all(promises)
            .then(()=>{
                
            }).finally(()=>{
                loading.value = false
            })
        }
        
       
        const openDialog = ()=>{
            showDialog.value=true;
        }

        const handleSubmit = async () => {
            
            const formData = new FormData();
            formData.append('id', id);

            try {
                await service.sendReset(formData)
                .then((res) => {
                    let severity = res.item.esito
                    let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
                    let detail = res.item.messaggio
                    showDialog.value = false
                    if (severity != 'warning') {
                        toast.add({severity:severity, summary: summary, detail:detail,life:lifeTime});                            
                    }
                    
                });
                
            } catch (error) {
                alert('Errore nella richiesta.');
            }
            showDialog.value = false
            
        };
        const initData = () => {
            
            
            const promises = [getDetails(),getNotifiche(),getAtleti()]
            getPromises(promises)
        }

        onMounted(() => { 
            initData()
        })

       
        
        return {
            moment,
            showDialog,
            handleSubmit,
            notifiche,
            openDialog,
            loading,
            loaded_data,
            atleta,
            update,
            goToAtleta,
            users,
            Save,
            sendNewDataAtleta,
            validationErrors,
            toshow,
            submitted,
            onChangeInput,genders,
            atleti,
            atletiToShow,
            addRow,
            columns,
            changeUpdate,

        }
    },
}
</script>

<style>
.img-atleta{
    aspect-ratio: 4/2.5 !important;
}
.field {
  margin-bottom: 1em;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
