<template>
<div class="wrapper-table">
  <multi-select 
    v-model="table.columns" 
    :options="data.table.columns" 
    optionLabel="label" 
    placeholder="" 
    :filter="true" 
    class="multiselect-columns"
  />

  <dataTable 
    :ref="table.name"
    :value="data.items"
    dataKey="id"

    v-model:selection="data.selectedItems"
    @row-select="onChangeSelectedItems" 
    @row-unselect="onChangeSelectedItems"
    @row-select-all="onChangeSelectedItems"
    @row-unselect-all="onChangeSelectedItems"
    @row-dblclick="openDetail"
    :loading="table.loading"
    sortMode="multiple" 
    responsiveLayout="scroll"

    :paginator="true" 
    :rows="10"
    :rowsPerPageOptions="[10,25,50]"
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" 
    currentPageReportTemplate="{first} a {last} di {totalRecords} elementi"

  >
    <template #header>
      <div>

        <div class="table-header">
          <div class="grid">
            <div class="col-2">
              <i></i><br>
              <prime-button id="filter-button" icon="pi pi-window-maximize" class="p-button"  @click="onButtonFilter($event)" />
              <!-- <prime-button id="filter-button" icon="pi pi-window-maximize" class="p-button"  @click="test($event)" /> -->
            </div>
            
            <div class="col p-as-end">
              <form>
                <div class="grid flex align-items-end">
                  <div class="col">
                    <span class="p-input-icon-left">
                      <i class="pi pi-search" />
                      <input-text 
                        v-model="search.text"
                        type="text" 
                        placeholder="Cerca" 
                      />
                    </span>
                  </div>

                  <div class="col">
                    <calendar id="from-date" 
                      v-model="searchFromDate" 
                      :showIcon="true" 
                      placeholder="Da"
                      dateFormat="dd/mm/yy"
                    />
                  </div>

                  <div class="col">
                    <calendar id="to-date" 
                      v-model="searchToDate" 
                      :showIcon="true" 
                      placeholder="A"
                      dateFormat="dd/mm/yy"
                    />
                  </div>
                  <div class="col">
                    <i></i><br>
                    <prime-button id="search-button" type="submit" icon="pi pi-search" class="p-button-rounded" @click.prevent="onButtonSearch($event)" />
                  </div>
                </div>
              </form>
            </div>

            <div class="col-2">
              <i></i><br>
              <prime-button icon="pi pi-external-link" label="Esporta Dati" @click="exportCSV($event)" />
            </div>
          </div>
        </div>

      </div>

    </template>

    <template #empty>
      <div class="box-center">Nessun dato trovato.</div>
    </template>

    <column selectionMode="multiple" headerStyle="width: 3em"></column>
    <column 
      v-for="column of table.columns" 
      :field="column.property" 
      :header="column.label" 
      :key="column.property"
      :sortable="column.sortable"
    >
      <template #body="{data}">
        <span v-if="column.type == null">
          {{ data[column.property] }}
        </span>

        <span v-if="column.type?.isBoolean">
          {{ (data[column.property])? 'SI' : 'NO' }} 
        </span>

        <span v-if="column.type?.isDate">
          {{ (data[column.property] !== null)? moment(data[column.property]).format('DD/MM/YYYY'): '' }}
        </span>

        <span v-if="column.type?.isCurrency">
          {{ currencyFormatter.format( (data[column.property] !== null)? data[column.property] : 0 ) }} 
        </span>
      </template>
    </column> 
    <!-- <column :exportable="false">
      <template #body="{data}">

        <router-link
          :to="`/ask/clienti/${data.id}/dettagli`"
          custom
          v-slot="{ href }"
        >
          <prime-button 
            icon="pi pi-eye" 
            class="p-button-rounded p-button-success mr-2" 
            @click="navigateToTab(href)" 
          />
        </router-link> 
        
      </template>
    </column> -->
  </dataTable>
</div>
</template>

<script>
import moment from 'moment'
import { reactive } from 'vue'
import Service from '@/services/levante-api/levante-api'
import DataService from '@/services/levante-api/levante-api-data'

import PrimeButton from'primevue/button'
import InputText from 'primevue/inputtext'
import Calendar from 'primevue/calendar'
import MultiSelect from 'primevue/multiselect'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'

export default {
  name: 'TableKitDataTable',

  props: {
    intialActiveTabs: {
      type: Array,
      required: true
    },

    initialFilters: {
      type: Object,
      required: true
    }
  },

  emits: ['row-fetched', 'row-selected', 'row-filtered', 'data-searching'],

  components: {
    PrimeButton,
    InputText,
    Calendar,
    MultiSelect,
    DataTable,
    Column,
  },

  data(){
    return {
      moment,

      activeTabs: this.intialActiveTabs,
      filters: this.initialFilters,

      currencyFormatter: new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
      }),

      table: {
        filter_key: 'cliente.ids',
        name: 'corsiDataTable',
        loading: false,
        columns: []
      },

      search: {
        text: '',
        fromDate: null,
        toDate: null,
      },

      data: {
        selectedItems: [],

        table:{
          columns: [],
        },

        items: [],
      }
    }
  },


  created() {

    this.service = new Service(this.$eventBus)
    this.dataService = new DataService(this.$eventBus)
    this.getAllColumns()

    this.$eventBus.on('active-tabs-changed', (evt) => {
      this.activeTabs = evt.tabs
    })

    this.$eventBus.on('data-filter-changed', (evt) => {

      this.filters = evt.filter

      const filterObject = this.filterObject
      if(filterObject && filterObject.constructor === Object && Object.keys(filterObject).length > 0){
        if( this.activeTabs.includes(this.table.name) ){
          this.getAllItems(this.filterObject)
          this.updateTabSelectedRemovedFromFilter(filterObject)
        }
      }
    })
    
    this.$eventBus.on('data-all-filter-clean', () => {
      this.data.selectedItems = []
      this.filters.items = {}
      this.data.items = []
      this.$emit('row-fetched', {target: this, rows: 0})
    })
    this.getAllItems()
  },
  

  methods: {
    openDetail(event) {
      let {data} = event
      if ('id' in data) {
        window.open(`/kit/${data.id}/dettagli`,"_self")
      } 
    },
    updateTabSelectedRemovedFromFilter(filterObject){ 
      if (this.table.filter_key in filterObject) {
        this.data.selectedItems = this.data.selectedItems.filter((el) => filterObject[this.table.filter_key].includes(el.id))
      }
    },
    navigateToTab(href){
      window.open(href)
    },

    exportCSV() {
      this.$refs[this.table.name].exportCSV();
    },

    onButtonSearch(/** event **/){
      this.$emit('data-searching', {
        target: this, 
        filter: {
          source: this.table.name,
          target: this.table.name,
          type: 'search',
          items: {
            search: this.search.text,
            from: this.search.fromDate,
            to: this.search.toDate
          }
        },
      })
    },

    onButtonFilter(/** event **/) {
  
      this.$emit('row-filtered', {
        target: this, 
        filter: {
          source: this.table.name,
          target: 'global',
          type: 'filter',
          key: this.table.filter_key,
          items: this.data.selectedItems.map((el) => {
            return {
              key: 'id',
              value: el.id,
              label: el.ragsoc,
              item: {...el},
            }
          })
        }
      })
    },
    
    onChangeSelectedItems(/** event **/) {
      this.$emit('row-selected', {
        target: this, 
        source: this.table.name, 
        items: JSON.parse(JSON.stringify(this.data.selectedItems)),
      })
    },

    getAllItems(opts = {}){
      this.table.loading = true

      for (const [key, value] of Object.entries(this.filters.items)) {
        opts[key] = value
      }
      
      this.service.getKit(opts).then((res) => {
        this.data.items = res.items
        this.$emit('row-fetched', {target: this, rows: res.total})
        this.table.loading = false
      })
    },

    getAllColumns(){
      const allColumns = (this.dataService.getAllColumnsForMindTableKit()).map((el) => reactive({...el}))
      const activeColumns = allColumns.filter((el) => el.default)

      activeColumns.forEach((el) => {delete el.default})
      allColumns.forEach((el) => {delete el.default})
      this.data.table.columns = allColumns
      this.table.columns = activeColumns
    }
  },

  computed: {
    filterObject:{
      get: function() {
        if(this.filters){
          const search = Object.entries(this.filters.searches).filter((el) => {
            if(el[0] === this.table.name){
              return el
            }
          })

          if(search.length > 0){
            const searchOBJ = search[0][1]

            return{
              ...searchOBJ
            }
          }

          return {
            ...this.filters.items
          }
        }

        return {}
      }
    },

    searchFromDate: {
      get: function() {
        return (this.search.fromDate !== null && this.search.fromDate !== '')? moment(this.search.fromDate).toDate(): null
      },
      set: function(value) {
        if(value instanceof Date && !isNaN(value.valueOf())){
          this.search.fromDate = moment(value).format('YYYY-MM-DD')
        }

        if( (value === null) || (value === '')){
          this.search.fromDate = null
        }
      }
    },
    
    searchToDate: {
      get: function() {
        return (this.search.toDate !== null && this.search.toDate !== '')? moment(this.search.toDate).toDate(): null
      },
      set: function(value) {
        if(value instanceof Date && !isNaN(value.valueOf())){
          this.search.toDate = moment(value).format('YYYY-MM-DD')
        }

        if( (value === null) || (value === '')){
          this.search.toDate = null
        }
      }
    },
  }
}
</script>

<style scoped>

  .box-center{
    display: flex; 
    align-items: center; 
    justify-content: center;
  }

</style>
