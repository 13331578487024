<template>
  <Loader v-if="loading" />    
  <Card>
    <template v-slot:title>
      <h3>Gestione Convocazioni</h3>
    </template>
    <template v-slot:content>
      <div>
        <Button @click="addBox" class="p-button-info" :label="(boxes.length==0)?'Nuovo evento':'Chiudi'" id="addboxbtn"/>
        <div v-for="(box, index) in boxes" :key="index" class="box p-5" ><br/>
          <div class="flex">
          <div class="p-field col-12 md:col-3"><label for="nome">Titolo</label>
            <InputText v-model="box.nome" placeholder="Titolo" :class="['inputfield w-full']"/>
            <small v-show="validationErrors.nome && submitted" class="p-error">Campo richiesto o errato.</small>     
          </div>
          <div class="p-field col-12 md:col-3">
            <label for="nome">Costo</label>
            <InputNumber v-model="box.costo" placeholder="Costo dell'evento" :class="['inputfield w-full']"/>
            <small v-show="validationErrors.costo && submitted" class="p-error">Campo richiesto o errato.</small>              

          </div>
          <div class="p-field col-12 md:col-6">
            <label for="nome">Luogo</label>
            <InputText v-model="box.luogo" placeholder="Luogo dell'evento" :class="['inputfield w-full']"/>
            <small v-show="validationErrors.luogo && submitted" class="p-error">Campo richiesto o errato.</small>              

          </div>
        </div>
            <div class='flex'>
              <div class="p-field col-12 md:col-2">
                <label>Data inizio evento</label><br>                   
                <Calendar :class="['inputfield w-full',{'p-invalid': validationErrors.data_inizio && submitted}]" v-model="box.data_inizio" dateFormat="dd/mm/yy" :maxDate="maxDate" @change="onChangeInput($event, 'data_inizio')" showIcon :showOnFocus="false"/>
                <small v-show="validationErrors.data_inizio && submitted" class="p-error">Selezione un giorno.</small>
              </div> 


              <div class="p-field col-12 md:col-2">
                <label>Ora inizio evento</label><br>   
                <Calendar v-model="box.time_in" timeOnly :stepMinute="30" :manualInput="false" @update:modelValue="onChangeInput($event, 'orario', 'in', index)"/>
                <small v-show="validationErrors.time_in && submitted" class="p-error">Seleziona un orario</small>
              
              </div> 
            <div class="p-field col-12 md:col-2">
                <label>Data fine evento</label><br>                   
                <Calendar :class="['inputfield w-full',{'p-invalid': validationErrors.data_fine && submitted}]" v-model="box.data_fine" dateFormat="dd/mm/yy" :maxDate="maxDate" @change="onChangeInput($event, 'data_fine')" showIcon :showOnFocus="false"/>
                <small v-show="validationErrors.data_fine && submitted" class="p-error">Selezione un giorno.</small>
              </div> 


              <div class="p-field col-12 md:col-2">
                <label>Ora fine evento</label><br>    
              <Calendar v-model="box.time_out" timeOnly :stepMinute="30" :manualInput="false" @update:modelValue="onChangeInput($event, 'orario', 'in', index)"/>
              <small v-show="validationErrors.time_out && submitted" class="p-error">Seleziona un orario</small>
              
              </div> 

              <div class="p-field col-12 md:col-2">
                <label>Data disponibilità</label><br>                   
                <Calendar :class="['inputfield w-full',{'p-invalid': validationErrors.data_disp && submitted}]" v-model="box.data_disp" dateFormat="dd/mm/yy" :maxDate="maxDate" @change="onChangeInput($event, 'data_disponibilita')" showIcon :showOnFocus="false"/>
                <small v-show="validationErrors.data_disp && submitted" class="p-error">Selezione un giorno.</small>
              </div> 


              <div class="p-field col-12 md:col-2">
                <label>Ora disponibilità</label><br>    
              <Calendar v-model="box.time_disp" timeOnly :stepMinute="30" :manualInput="false" @update:modelValue="onChangeInput($event, 'orario', 'disponibilita', index)"/>
              <small v-show="validationErrors.time_disp && submitted" class="p-error">Seleziona un orario</small>
              
              </div> 
</div>
<div class="flex">
<div class="p-field col-12"><label for="nomte">Note</label><InputText v-model="box.note" placeholder="Informazioni aggiuntive" :class="['inputfield w-full']"/></div>
</div>
<div class="p-3 col-12">
              <label for="nome"><b>Seleziona i corsi per le convocazioni</b></label><br/>
              <div v-for="day in corsi" :key="day.id">
                <label>
                  <input type="checkbox" v-model="box.corsi[day.id]" :value="day.id"
                  
        :checked="box.corsi.includes(day.id)"
         @change="toggleSelection(day.id)" />
                  {{ day.nome }}
                </label>
              </div>

            </div>
          <Button  class="p-button-success"  icon="pi pi-check" @click="saveBox(index)" label="Salva"/>
        </div>
        <div class="pt-3">
        <DataTable :value="tornei" class="p-datatable-gridlines">
            <Column field="data_inizio" header="Data inizio"></Column>
            <Column field="data_fine" header="Data fine"></Column>
            <Column field="pubblicato" header="Pubblicata"></Column>
            <Column field="luogo" header="Luogo"></Column>
            <Column field="nome" header="Nome"></Column>
            <Column field="descrizione" header="Info"></Column>
            <Column field="convocabili" header="# convocabili"></Column>
            <Column field="disponibili" header="# disponibili"></Column>
            <Column field="convocati" header="# convocati"></Column>
            <Column style="text-align: right">
              <template #body="slotProps">
              <Button @click="openDialog(slotProps.data.id)"
                label="Gestisci"
                class="p-button-warning" />
            </template>  
            </Column>
        </DataTable>
        </div>
      </div>
    </template>
  </Card>





  <div v-if="confirmDialog.visible" class="confirm-dialog">
    <div class="confirm-dialog-content ">
      <h3>Gestione convocazione</h3>
      <div class="grid">
        <div class='p-3 col-12'> <label>
                  <input type="checkbox" 
                         v-model="item.stato" 
                         value="1" />
                 Pubblicata?
                </label></div>
        <div class="p-3 col-6">
          <label for="nome">Nome</label>
          <InputText v-model="item.nome" :class="['inputfield w-full']" />

        </div>

        <div class="p-3 col-6">
          <label for="nome">Costo</label>
          <InputText v-model="item.costo" :class="['inputfield w-full']" />

        </div>
        <div class="p-3 col-12">
          <label for="nome">Luogo</label>
          <InputText v-model="item.luogo" :class="['inputfield w-full']" />

        </div>
        <div class="p-3 col-12">
          <label for="nome">Note</label>
          <InputText v-model="item.descrizione" :class="['inputfield w-full']" />

        </div>
        <div class="p-3 col-12">
          <label for="nome"><b>Convocazioni</b><br/></label>
          <DataTable :value="item.people" class="p-datatable-gridlines">
            <Column field="atleta" header="Atleta"></Column>
            <Column field="datadinascita" header="Data di nascita"></Column>
            <Column field="confermato" header="Disponibile"></Column>
            <Column field="data_conferma" header="Data disponibilità"></Column>
            <Column style="text-align: right" header='Convoca'>
              <template #body="slotProps">
                <label>
                  <input type="checkbox" 
                         v-model="convocati" 
                         :value="slotProps.data.id" />
                  {{ slotProps.data.nome }}
                </label>
              </template>   
            </Column>
        </DataTable>
        </div>
        <br />
      </div>
      <br />
      <Button @click="confirmDialogAccept" label="Salva" />
      <Button @click="confirmDialogReject" label="Annulla" class="p-button-secondary" />
    </div>
  </div>



</template>

<script>
import {ref, onMounted, getCurrentInstance, onUpdated, computed } from 'vue';
import Loader from "@/components/Loader.vue"
import Service from '@/services/levante-api/levante-api'
import moment from 'moment'
import { useToast } from 'primevue/usetoast';


export default {
  components: {
    Loader,
  },
  methods: {
    
  },
  setup () { 
    const self = getCurrentInstance();
    const toast = useToast();
    self.$eventBus = self.appContext.config.globalProperties.$eventBus
    const service = new Service(self.$eventBus)
    const submitted = ref(false)
    const loading = ref(false)
   
    const validationErrors = ref({})
    const types = ref()
    const responsabili = ref()
    const date = moment();
    const minDateInizio = ref();
    const maxDateInizio = ref();
    const minDateFine = ref();
    const tornei = ref();
    const maxDateFine = ref();
    const buttonDays = ref([])
    const boxes = ref([])
    const citta = ref([])
    const corsi = ref()
    const item = ref()
    const corso = ref([])
    const convocati = ref([])
    const lifeTime = 3000
    const confirmDialog = ref({
      visible: false,
      id: null,
      visibile: null,
      selectedCorso: null
    });
    const toggleSelection=(id)=> {
      const index = corso.value.indexOf(id);
      if (index === -1) {
        // Se l'ID non è nell'array, aggiungilo
        corso.value.push(id);
      } else {
        // Se l'ID è già nell'array, rimuovilo
        corso.value.splice(index, 1);
      }
    }


    const toggleSelectionConvocati=(person)=> {
      console.log("toggleSelectionConvocati")
      console.log(person)
      const index = convocati.value.findIndex(item => item.id === person.id);
      console.log(index)
      if (index === -1) {
        // Se l'ID non è nell'array, aggiungilo
        convocati.value.push(person);
      } else {
        // Se l'ID è già nell'array, rimuovilo
        convocati.value.splice(index, 1);
      }
      console.log(convocati.value)
    }

    const confirmDialogReject = () =>{
      confirmDialog.value.visible = false;

    }
    const confirmDialogAccept = () =>{
        console.log(convocati.value)

        let fd = new FormData();
        var box={}
        box.convocati=convocati.value
        box.id=item.value.id
        box.nome=item.value.nome
        box.luogo=item.value.luogo
        box.costo=item.value.costo
        box.note=item.value.descrizione
        box.stato=item.value.stato?'1':'0'
        fd.append('torneo', JSON.stringify(box));
        
        service.saveConvocazione(fd)
        .then((res) => {
          let severity = res.item.esito
          let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
          let detail = res.item.messaggio
          
          if (severity != 'warning') {
            toast.add({severity:severity, summary: summary, detail:detail, life: lifeTime});   
                    
          }

        }).finally(()=>{
          service.getTornei().then((res)=>{
            tornei.value=res.items;
          })

        })




      confirmDialog.value.visible = false;

    }
    const openDialog = (id) =>{
      service.getDetailTorneo(id)
        .then((res) => {
          convocati.value=[]
          item.value=res.items[0];
          item.value.people.forEach( p => {
            p.confermato = p.confermato==0?'In attesa':p.confermato==1?'SI':'NO'
            if (p.convocato){
              convocati.value.push(p.id)
            }
          })
          item.value.stato==0?item.value.stato=false:item.value.stato=true
          console.log(item.value);
          confirmDialog.value.visible = true;
          confirmDialog.value.id = id;

        })
    }

    const onChangeInput = (evt, prop) => {
      let value
      switch (prop) {
        case 'id_utente':
        value = evt.value
        break;
        
        default:
        value = evt.target.value
        break;
      }
      if (value == '' || value == null) {
        validationErrors.value[prop] = true
      }else{
        delete validationErrors.value[prop]
      }
    }
    const validateForm = (box) => {
      // Validazione corso
      let validate_properties = [
        'nome',
        'data_inizio',
        'data_fine',
        'time_in',
        'time_out',
        'data_fine',
        'data_disp',
        'time_disp',
        'luogo'
      ]
      
      validate_properties.forEach( prop => {
        let check
        switch (prop) {
          
          default:
            check = box[prop] == '' || box[prop] == null
        }
        if (check) {
          validationErrors.value[prop] = true
        } else {
          delete validationErrors.value[prop];
        }
      })
      
      
      return !Object.keys(validationErrors.value).length;
    }


    const addBox = () => {
      if (boxes.value.length==0){
       
      boxes.value.push({
        nome: '',
        luogo: '',
        costo:0,
        data_inizio:null,
        data_fine:null,
        corsi:[]
      });
    }else{
      boxes.value.pop();
    }
    }
    const setTime = (el) => {
      let hours = el.getHours().toString().padStart(2,'0');
      let minutes = el.getMinutes().toString().padStart(2,'0');
      return `${hours}:${minutes}:00`
    }
    const saveBox = (index) => {
      submitted.value=true
      const box = boxes.value[index];
      if (validateForm(box)){
      // Implementa la logica di salvataggio dei dati del box qui
        console.log('Saving box', index, box);
        let fd = new FormData();
        box.inizio=moment(box.data_inizio, 'DD/MM/YYYY').format('YYYY-MM-DD')
        box.fine=moment(box.data_fine, 'DD/MM/YYYY').format('YYYY-MM-DD')

        box.disponibilita=moment(box.data_disp, 'DD/MM/YYYY').format('YYYY-MM-DD')
        box.time_disponibilita=setTime(box.time_disp)
        box.in=setTime(box.time_in)
        box.out=setTime(box.time_out)
        box.corsi=corso;
        fd.append('torneo', JSON.stringify(box));
        
        service.sendTorneo(fd)
        .then((res) => {
          let severity = res.item.esito
          let summary = res.item.esito != 'success' ? 'Attenzione' : 'Successo'
          let detail = res.item.messaggio
          
          if (severity != 'warning') {
            toast.add({severity:severity, summary: summary, detail:detail, life: lifeTime});   
            boxes.value.pop(); 
            corso.value=[]               
          }

        }).finally(()=>{
        })
      }else{
        toast.add({severity:'warn', summary: 'Attenzione', detail:`Ci sono dei dati mancanti`});
      }
    }

    const onFileChange = (event, index) => {
      const file = event.target.files[0];
      boxes.value[index].image = file;
    }

    
    const initData = () => {
      // restart var
      service.getTornei().then((res)=>{
        tornei.value=res.items;
      })

      service.getCorsiAttivi().then((res)=>{
        corsi.value=res.items
      })
    }
 
    

    
    onMounted(() => { 
      initData()
    })
    
    return {
      toggleSelection,
      toggleSelectionConvocati,
      tornei,
      convocati,
      loading,
      citta,
      submitted,
      validationErrors,
      types,
      moment,
      date,
      item,
      confirmDialog,
      minDateInizio,
      maxDateInizio,
      minDateFine,
      maxDateFine,
      buttonDays,
      openDialog,
      addBox,
      saveBox,
      onFileChange,
      confirmDialogReject,confirmDialogAccept,
      boxes,
      corsi,
      onChangeInput,
    }
  },
}
</script>

<style>
.text_toupper{
  text-transform: uppercase;
} 
</style>
<style scoped>
.counter{
  position:absolute;
  right:10px;
  top:10px;
}
#container-atleti{
  display: block;
}
.layout-main-container{
  width: fit-content !important;
}
.btn-error-filter{
  width: 200px;
}

.btn-campo{
  background:#ffffff url("/images/campo.jpg") no-repeat right top;
  background-size: contain;
  width: 100px;
  aspect-ratio:1 ;
}

.numero-campo{
  color: black;
  font-size: 40px;
  font-weight: 500;
  position: relative;
  z-index: 2;
}
.confirm-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  max-height: 90%;
  overflow-y: auto;
}

.confirm-dialog-content {
  text-align: center;
}

.confirm-dialog-content h3 {
  margin-top: 0;
}

.confirm-dialog-content button {
  margin: 10px;
}
</style>
